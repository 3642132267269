import React, { useState, useRef, useEffect }from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Map as MPMap, Marker as MPMarker, LineLayer } from "mapbox-gl";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import MapSettings from "../pandora/MapSettings";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";
import NetworkMapSettings from "../pandora/NetworkMapSettings";

import { PlaceCompletionGeo, SectionGeo } from "../api/LCSModels";
import { MapBoxAPIToken } from "../pandora/tokens";

import PlaceDataPopup from "../components/map/PlaceDataPopup";
import PlaceCompletionMarker from "../components/map/PlaceCompletionMarker";
import NetworkMapTopBar from "../components/map/NetworkMapTopBar";
import NetworkMapSectionList from "../components/map/NetworkMapSectionList";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import { ViewContainer, HFlex } from "../pandora/styled";

import "../components/map/PlaceDataPopup.css";


const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = MapBoxAPIToken; 


const Container = styled(ViewContainer)`
    overflow: visible;
    height: 100%;
`

const MapContainer = styled.div`
    width: 100%;
    height: 80vh;
    border-radius: 5px;
`


const NetworkMapView = (): React.ReactElement => {

    const getCachedYear = (): number => {
        const y = LocalStorage.get(LocalStorageKey.CompletionMapYear)
        return y || 2020;
    }

    // State
    const mapContainer = useRef<HTMLDivElement | null >(null);

    const [map, setMap] = useState<MPMap>(null);
    const [markers, setMarkers] = useState<MPMarker[]>([]);
    const [lineIDs, setLineIDs] = useState<string[]>([]);

    const [year, setYear] = useState<number>(getCachedYear());
    const [mapData, setMapData] = useState<SectionGeo[] | null>(null);
    const [sectionFilter, setSectionFilter] = useState<number | null>(null);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Network map |  LCS Online`

    // Effects  
    useEffect(() => { 
        if (map === null) return;
        draw() 
    }, [mapData, map, sectionFilter])

    useEffect(() => {
        setMapData(null);
        setSectionFilter(null);
        getData();

        // Cache selected year
        LocalStorage.set(LocalStorageKey.CompletionMapYear, year)
    }, [year])

    /**
     * Handle initial map load from Mapbox.  
     */
    useEffect(() => {

        const initializeMap = ({ setMap, mapContainer }) => {
            // Create map instance. 
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: MapSettings.Styles.default,
                center: [15, 62],
                zoom: 3,
                reuseMaps: true, 
            });
            
            // Perform initial data load on map instance. 
            map.on('load', () => {
                if (map.loaded()) {
                    setMap(map);
                }
            });
        };

        // Initialise MapBox map instance if map 
        // has been loaded from MapBox API. 
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map])



    // Actions

    /**
     * Request completion data for section
     * mapping for year from API. 
     */
    const getData = async () => {
        try {
            const payload = {year: year};
            const _data = await Thomas.request(APIRequest.getYearSectionData, payload) as unknown as SectionGeo[];
            setMapData(_data);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
        return null;
    }

    /**
     * Get the place data
     * from section data list. Filter for possibly selected
     * filter. 
     */
    const getGeoData = (): PlaceCompletionGeo[] => {
        if (mapData === null) return [];
        const _data = sectionFilter === null ? mapData.map(p => p.places).flat(1) : mapData[sectionFilter].places;
        return _data;        
    }

    /**
     * Get marker data, i.e. place data.
     */
    const getMarkerData = (): PlaceCompletionGeo[] => {
        const _data = getGeoData();
        const places = _data.filter(p => p.fromPlace.id === p.toPlace.id)
        return places;
    }

    /**
     * Get line data for lines. 
     */
    const getLineData = (): PlaceCompletionGeo[] => {
        const _data = getGeoData();
        const lines = _data.filter(p => p.fromPlace.id !== p.toPlace.id)
        return lines;
    }

    /**
     * Draw markers and lines in map. 
     */
    const draw = () => {

        // Remove all current markers and layers on map 
        // to clean map for new pin rendering.
        if (markers.length !== 0) markers.forEach((m: MPMarker) => m.remove());
        if (lineIDs.length !== 0) lineIDs.forEach(id => {
            map.removeLayer(id);
            map.removeSource(id);
        });

        // Iterate data in projects to display on map. 
        const newMarkers: MPMarker[] = [];
        const newLines: string[] = [];

        const markerData: PlaceCompletionGeo[] = getMarkerData();
        const lineData: PlaceCompletionGeo[] = getLineData();

        // Draw place markers
        markerData.forEach((p: PlaceCompletionGeo, index) => {
            const m = drawMarker(p);
            newMarkers.push(m);
        });

        // Draw lines
        lineData.forEach((p: PlaceCompletionGeo, index) => {
            const l = drawLine(index, p);
            newLines.push(l);
        });

        // Store references to lines and markers
        setLineIDs(newLines);
        setMarkers(newMarkers);
    }

    /**
     * Add marker to map. 
     * @param p Place completion data for marker. 
     * @returns Marker instance for storage. 
     */
    const drawMarker = (p: PlaceCompletionGeo) => {
        // Create and render marker 
        const markerNode = document.createElement("div");
        ReactDOM.render(
            <PlaceCompletionMarker id={p.fromPlace.id} placeCompletion={p}/>, 
            markerNode
        );

        // Create and render popup
        const popup = React.createElement(PlaceDataPopup, {data: p});
        const popupContainer = document.createElement('div');
        ReactDOM.render(popup, popupContainer);

        const coordinate = [p.fromPlace.longitude, p.fromPlace.latitude]
        const mpbxPopup = new mapboxgl.Popup({closeButton: false})

        const m = new mapboxgl.Marker(markerNode, {offset: [-15, -15]})     // Offset is width, height / 2 in order to center marker on coordinate
                                    .setLngLat(coordinate)
                                    .setPopup(mpbxPopup.setDOMContent(popupContainer))
                                    .addTo(map);
        return m;
    }

    /**
     * 
     * @param index Line index used for layer identification.
     * @param p Completion data for line. 
     * @returns line id for layer identificatier storage. 
     */
    const drawLine = (index: number, p: PlaceCompletionGeo): string => {
        const layerID = `line-layer-${index}`
        const layer = NetworkMapSettings.Line.getLayerConfig(layerID, p)
        map.addLayer(layer as LineLayer);


        map.on('click', layerID, function (e) {
            const popup = React.createElement(PlaceDataPopup, {data: p});
            const popupContainer = document.createElement('div');
            ReactDOM.render(popup, popupContainer);
            
            new mapboxgl.Popup({closeButton: false})
              .setLngLat(e.lngLat)
              .setDOMContent(popupContainer)
              .addTo(map);
        });

        return layerID;
    }

    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <NetworkMapTopBar 
                data=       {mapData}
                year=       {year}
                setYear=    {setYear}
            />
            <HFlex>
                {mapData !== null &&
                    <NetworkMapSectionList 
                        data=               {mapData}
                        sectionFilter=      {sectionFilter}
                        setSectionFilter=   {setSectionFilter}
                    />
                }
                <MapContainer 
                    ref={el => (mapContainer.current = el)} 
                />
            </HFlex>
        </Container>
    )

}

export default NetworkMapView;
