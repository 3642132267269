import React from "react";
import ReactSwitch from "react-switch";


interface SwitchProps {
    configKey: string;
    value: boolean;
    onChange: (key: string, value: boolean) => void;   
}

/**
 * Switch managing boolean state
 * in config object. 
 */
const Switch = (props: SwitchProps): React.ReactElement => (
    <ReactSwitch 
        onChange=       {(val) => props.onChange(props.configKey, val)} 
        checked=        {props.value} 
        onColor=        {"#198b74"}
        handleDiameter= {15}
        width=          {45}
        height=         {20}
        borderRadius=   {5}
    />
)

export default Switch;




interface SimpleSwitchProps {
    value: boolean;
    onChange: (value: boolean) => void;   
}

/**
 * Switch managing boolean state
 * in state setter.
 */
export const SimpleSwitch = (props: SimpleSwitchProps): React.ReactElement => (
    <ReactSwitch 
        onChange=       {(val) => props.onChange(val)} 
        checked=        {props.value} 
        onColor=        {"#198b74"}
        handleDiameter= {15}
        width=          {45}
        height=         {20}
        borderRadius=   {5}
    />
)

