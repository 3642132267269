import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { TrackLinkMin } from "../../api/LCSModels";

import PLACE_NAMES from "../../pandora/place-names";

import Color from "../../resources/colors";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";

import Overlay from "../Overlay";
import NoDataComponent from "../NoDataComponent";
import LoadingComponent from "../LoadingComponent";

import { GenericPopupProps } from "../GenericPopup";

import TrackLinkContainer from "../data/TrackLinkContainer";

import { HFlex, VFlex } from "../../pandora/styled";
import { ConfirmationPopupAnimation, PulseAnimation } from "../../pandora/animations";



const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;
    width: 60%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;

    p {
        font-size: 12px;        
    }

    .phone-link {
        display: inline-block;
        color: white;
        font-weight: bold;
        margin: 0px 5px;
        transition: 0.2s cubic-bezier(.71,-1.54,.83,.67);
    }

    .phone-link: hover {
        transform: scale(1.5) rotateZ(-20deg);
        color: ${Color.green};
    }

    .title {
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
        background-color: ${Color.darkGrey};
    }

    .container {
        padding: 15px;
        border-radius: 5px;
        background-color: ${Color.darkGrey}
    }

    .container p {
        margin: 0px;
    }

    .border-container {
        padding: 15px;
        border-radius: 5px;
        border: solid 2px ${Color.lightGrey};
    }

    .offer {
        display: inline-block;
        color: ${Color.green};
        font-weight: bold;
        animation: ${PulseAnimation} 1s infinite;
        text-transform: uppercase;
        margin: 0px 5px;
    }
`

const TrackLinkListContainer = styled(VFlex)`
    width: auto;
    max-height: 35vh;
    overflow-y: auto;

    .place-track-links {
        margin-bottom: 20px;
    }

    h4 {
        margin-bottom: 10px;
    }
`


type MappedTrackLinks = Record<string, TrackLinkMin[]>


interface TrackLinkSelectorProps {
    currentTrackLink: TrackLinkMin;
    update: (trackLink: TrackLinkMin) => void;
    setPopup: (popup: GenericPopupProps) => void;
    close: Function;
}

const TrackLinkSelector = (props: TrackLinkSelectorProps): React.ReactElement => {

    // State
    const { currentTrackLink, update, setPopup, close } = props;

    const [trackLinks, setTrackLinks] = useState<MappedTrackLinks | null>(null);

    // Effects
    useEffect(() => {

        /**
         * Request list of Track Links for given signature.
         */
        const getTrackLinks = async () => {
            try {
                // Request track links for signature
                const payload = {fromPlace: currentTrackLink.fromPlace, toPlace: currentTrackLink.toPlace}
                const _trackLinks: TrackLinkMin[] = await Thomas.request(APIRequest.getTrackLinksForPlaceSignature, payload) as TrackLinkMin[]
                setTrackLinks(mapTrackLinksToPlace(_trackLinks));
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
        }

        getTrackLinks();
    }, [])

    // Actions

    /**
     * Map Track Links for given signatures
     * to unique places / lines. 
     */
    const mapTrackLinksToPlace = (tls: TrackLinkMin[]): MappedTrackLinks => {
        const _mappedTrackLinks: MappedTrackLinks = {};
        tls.forEach(tl => {
            [tl.fromPlace, tl.toPlace].forEach(place => {
                if (!Object.keys(_mappedTrackLinks).includes(place)) {
                    _mappedTrackLinks[place] = []
                }
                if (!_mappedTrackLinks[place].includes(tl)) _mappedTrackLinks[place].push(tl)
            })
        })
        return _mappedTrackLinks;
    }


    return (
        <Overlay 
            css=        {"z-index: 10;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer onClick={(e) => e.stopPropagation()}>
                    <HFlex style={{alignItems: "center"}}>
                        <img src={TrackLinkIcon} style={{width: 20, height: 20, marginRight: 15}} />
                        <h2>Set Track Link</h2>
                    </HFlex>
                    <div className="container" style={{margin: "20px 0px 30px"}}>
                        <p>
                            If you wish to change the registered Track Link to a non adjacent Driftplace, please reach out to Atritec customer support at 
                            <a className="phone-link" href="tel:+46763496128" title="Ring inte">+46 76 349 61 28</a>. 
                            The support channel is open from 9AM-2AM. 
                            <br/><br/>
                            Consider signing up for a one week 
                            <span className="offer"> free trial </span>
                            of Atritec Platinum™ for around the clock support and fee discounts.
                            <br/><br/>
                            <span style={{fontSize: 8}}>*Service fees may apply at a rate of 90 SEK / min.</span>
                        </p>
                    </div>
                    <h4 style={{marginBottom: 15}} className="title">Current</h4>
                    <div className="border-container">
                        <TrackLinkContainer 
                            trackLink=          {currentTrackLink}
                            showDatesOption=    {true}
                        />
                    </div>
                    <h4 style={{margin: "20px 0px 15px"}} className="title">Adjacent Track Links</h4>
                    <TrackLinkListContainer className="border-container">
                        {trackLinks === null && 
                            <LoadingComponent message="Fetching Track Links..." />
                        }
                        {trackLinks !== null && Object.keys(trackLinks).length === 0 &&
                            <NoDataComponent message={`Could not find Track Links for signature ${currentTrackLink.fromPlace} ${currentTrackLink.toPlace}`} />
                        }
                        {trackLinks !== null && Object.keys(trackLinks).length !== 0 &&
                            Object.keys(trackLinks).map(place => 
                                <div className="place-track-links">
                                    <h4>{PLACE_NAMES[place] || place}</h4>
                                    {trackLinks[place].map((tl, index) => 
                                        <TrackLinkContainer 
                                            key=                {`tlc-${index}`}
                                            trackLink=          {tl}
                                            showDatesOption=    {true}
                                            selectAction=       {update}
                                            css=                {{marginBottom: 5}}
                                        />
                                    )}
                                </div>    
                        )}
                    </TrackLinkListContainer>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}


export default TrackLinkSelector;