import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { DeletedFomul, DeletedTrackDistance } from "../api/LCSModels";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";

import { SiteView } from "../site/routes";
import Color from "../resources/colors";
import SearchIcon from "../resources/images/magnifying-glass.png";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import NoDataComponent from "../components/NoDataComponent";
import LoadingComponent from "../components/LoadingComponent";

import FomulListItem from "../components/data/FomulListItem";
import TrackDistanceListItem from "../components/data/TrackDistanceListItem";

import ViewHeader from "../components/ViewHeader";
import { HFlex, ViewContainer, Input, QueryResultContainer, VFlex , IconButton} from "../pandora/styled";



const Container = styled(ViewContainer)`
    overflow: visible;
`

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const HContentDiv = styled(ContentDiv)`
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
        margin-right: 10px;
        font-weight: normal;
    }

    ${Input} {
        margin-right: 10px;
    }
`

const DeletedItemsContainer = styled(QueryResultContainer)`
    height: 65vh;
    padding: 0px;

    h5 { 
        margin-bottom: 5px;
        text-indent: 5px;
        font-weight: normal;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${Color.lightGrey};
    } 
`


const BinView = (): React.ReactElement => {

    // State
    const getCachedFomulInterval = (): number =>  LocalStorage.get(LocalStorageKey.BinFomulInterval) ?? DEFAULT_INTERVAL

    const getCachedTrackDistanceInterval = (): number =>  LocalStorage.get(LocalStorageKey.BinTrackDistanceInterval) ?? DEFAULT_INTERVAL

    const DEFAULT_INTERVAL: number = 30;

    const [fomulInterval, setFomulInterval] = useState<number>(getCachedFomulInterval());
    const [trackDistanceInterval, setTrackDistanceInterval] = useState<number>(getCachedTrackDistanceInterval());
    
    const [deletedFomuls, setDeletedFomuls] = useState<DeletedFomul[] | null>(null);
    const [deletedTrackDistances, setDeletedTrackDistances] = useState<DeletedTrackDistance[] | null>(null);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Bin | LCS Online`

    // Effects

    useEffect(() => {
        // Fetch deleted data
        getDeletedFomuls();
        getDeletedTrackDistances();
    }, [])


    // Actions

    /**
     * Request deleted Fomuls from API 
     * with provided interval
     */
    const getDeletedFomuls = async () => {
        setDeletedFomuls(null);
        try {
            const fomulPayload = {days: fomulInterval}
            const _deletedFomuls: DeletedFomul[] = await Thomas.request(APIRequest.getDeletedFomuls, fomulPayload) as DeletedFomul[]
            setDeletedFomuls(_deletedFomuls);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }

        // Cached used search interval
        LocalStorage.set(LocalStorageKey.BinFomulInterval, fomulInterval)
    }

    /**
     * Request deleted TrackDistances from API 
     * with provided interval
     */
    const getDeletedTrackDistances = async() => {
        setDeletedTrackDistances(null);
        try {
            const trackDistancePayload = {days: trackDistanceInterval}
            const _deletedTDs: DeletedTrackDistance[] = await Thomas.request(APIRequest.getDeletedTrackDistances, trackDistancePayload) as DeletedTrackDistance[]
            setDeletedTrackDistances(_deletedTDs);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }

        // Cached used search interval
        LocalStorage.set(LocalStorageKey.BinTrackDistanceInterval, trackDistanceInterval)
    }

    const onKeyPress = (e: React.KeyboardEvent, callable: Function) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            callable();
        }
    }

    return (
        <Container>
            <ViewHeader view={SiteView.Bin} />
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            <HFlex>
                <ContentDiv style={{width: "50%", marginRight: 10}}>
                    <h4>Fomuls</h4>
                    <HContentDiv style={{margin: "20px 0px", backgroundColor: Color.extraDarkGrey}}>
                        <h5>Last</h5>
                        <Input
                            type=           "number"
                            placeholder=    "Days"
                            value=          {fomulInterval}
                            onChange=       {(e) => setFomulInterval(Number(e.target.value))}
                            fontSize=       {12}
                            style=          {{width: 60}}
                            onKeyPress=     {(e) => onKeyPress(e, getDeletedFomuls)}
                        />
                        <h5>days</h5>
                        <IconButton 
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            icon=       {SearchIcon}
                            onClick=    {() => getDeletedFomuls()}
                            style=      {{textAlign: "center", textIndent: 25, marginLeft: "auto", width: "auto"}}
                        >Search</IconButton>
                    </HContentDiv>
                    <DeletedItemsContainer>
                        {deletedFomuls === null &&
                            <LoadingComponent message="Fetching deleted Fomuls..." />
                        }
                        {deletedFomuls !== null && deletedFomuls.length === 0 &&
                            <NoDataComponent message={`Search yielded no results`} />
                        }
                        {deletedFomuls !== null && deletedFomuls.length !== 0 && 
                            deletedFomuls.map((fomul, index) => 
                                <VFlex style={{height: "fit-content", marginTop: 20}}>
                                    <h5>
                                        Deleted <strong>{moment.utc(fomul.updated).local().format("HH:mm Do MMMM YYYY")}</strong>
                                    </h5>
                                    <FomulListItem 
                                        key=    {`result-${index}`}
                                        fomul=  {fomul} 
                                        color=  {Color.extraDarkGrey}
                                    />
                                </VFlex>
                        )}
                    </DeletedItemsContainer>
                </ContentDiv>
                <ContentDiv style={{width: "50%", marginLeft: 10}}>
                    <h4>Track Distances</h4>
                    <HContentDiv style={{margin: "20px 0px", backgroundColor: Color.extraDarkGrey}}>
                        <h5>Last</h5>
                        <Input
                            type=           "number"
                            placeholder=    "Days"
                            value=          {trackDistanceInterval}
                            onChange=       {(e) => setTrackDistanceInterval(Number(e.target.value))}
                            fontSize=       {12}
                            style=          {{width: 60}}
                            onKeyPress=     {(e) => onKeyPress(e, getDeletedTrackDistances)}
                        />
                        <h5>days</h5>
                        <IconButton 
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            icon=       {SearchIcon}
                            onClick=    {() => getDeletedTrackDistances()}
                            style=      {{textAlign: "center", textIndent: 25, marginLeft: "auto", width: "auto"}}
                        >Search</IconButton>
                    </HContentDiv>
                    <DeletedItemsContainer>
                        {deletedTrackDistances === null &&
                            <LoadingComponent message="Fetching deleted Track Distances..." />
                        }
                        {deletedTrackDistances !== null && deletedTrackDistances.length === 0 &&
                            <NoDataComponent message={`Search yielded no results`} />
                        }
                        {deletedTrackDistances !== null && deletedTrackDistances.length !== 0 && 
                            deletedTrackDistances.map((trackDistance, index) => 
                                <VFlex style={{height: "fit-content", marginTop: 20}}>
                                        <h5>
                                            Deleted <strong>{moment.utc(trackDistance.updated).local().format("HH:mm Do MMMM YYYY")}</strong>
                                        </h5>
                                        <TrackDistanceListItem 
                                            key=            {`result-${index}`}
                                            trackDistance=  {trackDistance} 
                                            color=          {Color.extraDarkGrey}
                                        />
                                </VFlex>
                        )}
                    </DeletedItemsContainer>
                </ContentDiv>
            </HFlex>
        </Container>
    )
}

export default BinView;

