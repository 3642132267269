import React from "react";
import styled from "styled-components";

import Color from "../../resources/colors";
import { TrackLinkProfile } from "../../api/LCSModels";

import { HFlex } from "../../pandora/styled";



const Container = styled(HFlex)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: 30px;
    margin-top: -30px;

    padding: 5px;
    border-radius: 5px;

    background-color: ${Color.extraDarkGrey};
`

const TrackLinkProfileItemContainer = styled("div")<{color: string, selected: boolean, hoverColor: string}>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${props => props.selected ? 60 : 40}px;
    height: 100%;
    border-radius: 5px;
    margin-right: 5px;

    background-color: ${props => props.color};
    cursor: pointer;
    transition: 0.3s ease;
    
    &: hover {
        background-color: ${props => props.hoverColor};
    }
`




interface TrackLinkProfileSelectorProps {
    selectedProfile: TrackLinkProfile;
    selectProfile: (value: TrackLinkProfile) => void;
} 


/**
 * Allows selection of available 
 * Track Link profiles in TrackLinkProfile enum. 
 */
const TrackLinkProfileSelector = (props: TrackLinkProfileSelectorProps): React.ReactElement => {

    const { selectedProfile, selectProfile } = props;

    return (
        <Container>
            {Object.keys(TrackLinkProfile).map((p, index) => 
                <TrackLinkProfileItemContainer 
                    key=        {`profile-${index}`}
                    color=      {selectedProfile === TrackLinkProfile[p] ? Color.lightGrey : Color.extraDarkGrey}
                    hoverColor= {Color.lightGrey}
                    selected=   {selectedProfile === TrackLinkProfile[p]}
                    onClick=    {() => selectProfile(TrackLinkProfile[p])}
                >
                    <h6>{p}</h6>
                </TrackLinkProfileItemContainer>
            )}
        </Container>
    )
}

export default TrackLinkProfileSelector;


