import React from 'react';
import styled from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';

import { PlaceCompletionGeo } from "../../api/LCSModels";
import { getCompletionProgressColor } from "../../pandora/pandora";

import Color from "../../resources/colors";
import PlaceIcon from "../../resources/images/place-icon.svg";
import { FadeAnimation } from "../../pandora/animations";


const PlaceMarkerDiv = styled("div")<{color: string}>`
    position: absolute;
    width: 30px;
    height: 30px;

    border-width: 0;
    border-radius: 10px;
    cursor: pointer;

    background-color: ${props => props.color};
    background-image: url(${PlaceIcon});
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;

    animation: ${FadeAnimation} 0.2s ease 1;
    transition: 0.3s ease;

    &: hover {
        transform: scale(1.2);
    }
`

interface MarkerProps {
    id: number
    placeCompletion: PlaceCompletionGeo;
}

/**
 * MapBox marker in NetworkMap
 * for places changing colors depending on 
 * completion progress. 
 */
const PlaceCompletionMarker = (props: MarkerProps): React.ReactElement => {

    const { id, placeCompletion } = props;

    // Get marker color for completion progress. 
    const markerColor = (): string => {
        if (placeCompletion.completion !== null) {
            return Color.green; // place has been manually marked as completed. 
        } else {
            const c = (placeCompletion.completedTrackLinksCount / placeCompletion.trackLinksCount) * 100;
            return getCompletionProgressColor(c)
        }
    }

    return (
        <PlaceMarkerDiv 
            key=    {id}
            color=  {markerColor()}
        />
    )
}



export default PlaceCompletionMarker;

