import React from "react";

import Color from "../resources/colors";
import { Tag } from "../pandora/styled";

interface PositionTagProps {
    atKm: number
    atM: number
    color?: string
    css?: React.CSSProperties
}

const PositionTag = (props: PositionTagProps): React.ReactElement => {
    const message = `${props.atKm} km + ${Math.round(props.atM)} m`
    return (
        <Tag
            color={props.color ?? Color.lightGrey}
            style={props.css ?? {}}
        >{message}</Tag>
    )
}

export default PositionTag;
