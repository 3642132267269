import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import { SiteRoute } from "../../site/routes";
import { SessionMinWData } from "../../api/LCSModels";

import Color from "../../resources/colors";
import CopyIcon from "../../resources/images/copy-icon.svg"; 

import FomulListItem from "../data/FomulListItem";
import TrackDistanceListItem from "../data/TrackDistanceListItem";
import { SessionStatusTagMini } from "../session/SessionStatus";
import IconTag from "../IconTag";

import NoDataComponent from "../NoDataComponent";
import { HFlex, VFlex, Arrow, Tag, OpenTag } from "../../pandora/styled";





const Container = styled(VFlex)`
    padding: 10px;
    padding-right: 15px;
    margin-top: 5px;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    background-color: ${Color.extraDarkGrey};

    transition: 0.3s ease;
`

const Title = styled.h6`
    font-size: 15px;
    color: white;
    margin: 0;
`


interface TrackLinkSessionViewProps {
    session: SessionMinWData
}

/**
 * Component display Sessions for 
 * a given Track Link with its corresponding
 * Fomul and Track Distance data.
 */
const TrackLinkSessionList = (props: TrackLinkSessionViewProps): React.ReactElement => {
    
    // States
    const { session } = props;    
    const [opened, setOpened] = useState<boolean>(false);

    // Check if all fomuls for track link are copies
    let allCopies = session.fomuls.map(f => f.isCopy).every(Boolean)
    if (session.fomuls.length === 0) allCopies = false;

    return (
        <Container>
            <HFlex 
                style={{justifyContent: "space-between", height: 20, alignItems: "center", cursor: "pointer"}}
                onClick={() => setOpened(!opened)}
            >
                <Title>{moment(session.date).format("Do MMMM HH:mm YYYY")}</Title>
                <HFlex style={{alignItems: "center", marginTop: 10}}>
                    {allCopies && 
                        <IconTag 
                            text=   "ONLY COPIES"
                            icon=   {CopyIcon}
                            color=  {Color.orange}
                            style=  {{marginRight: 10}}
                        />
                    }
                    <SessionStatusTagMini 
                        status= {session.status} 
                        css=    {{marginRight: 10}}
                    />
                    <Link to={`${SiteRoute.Session}?id=${session.id}`} style={{display: "block"}}>
                        <OpenTag 
                            color=      {Color.lightGrey} 
                            hoverColor= {Color.extraLightGrey}
                            style=      {{marginRight: 20, width: 50}}
                            title=      {"Open session"}
                        >Open</OpenTag>
                    </Link>
                    <Arrow rotate={opened ? 180 : 0}/>
                </HFlex>
            </HFlex>
            {!opened &&
                <HFlex style={{marginTop: 10}}>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{session.fomuls.length} Fomuls</Tag>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{session.trackDistances.length} Track Distances</Tag>
                </HFlex>
            }
            {opened && 
                <VFlex>
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Fomuls</h5>
                    {session.fomuls.length !== 0 && session.fomuls.map((f, index) => 
                        <FomulListItem 
                            key={`fomul-${index}`}
                            fomul={f} 
                        />
                    )}
                    {session.fomuls.length === 0 && 
                        <NoDataComponent />
                    }
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Track Distances</h5>
                    {session.trackDistances.length !== 0 && session.trackDistances.map((td, index) => 
                        <TrackDistanceListItem 
                            key={`trackDistance-${index}`}
                            trackDistance={td} 
                        />
                    )}
                    {session.trackDistances.length === 0 && 
                        <NoDataComponent />
                    }
                </VFlex>
            }
        </Container>
    )
}


export default TrackLinkSessionList;
