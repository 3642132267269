import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Color from "../../resources/colors";
import SessionIcon from "../../resources/images/session-icon.svg";
import AddIcon from "../../resources/images/add-icon.png";
import DeleteIcon from "../../resources/images/delete-icon.png";

import Overlay from "../Overlay";
import NoDataComponent from "../NoDataComponent";
import LoadingComponent from "../LoadingComponent";
import { HFlex, VFlex, RippleButton, ContentDiv } from "../../pandora/styled";
import { ConfirmationPopupAnimation } from "../../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const sessionSelectorDescription = `
    Available measurement session dates to include in the invoice report. 
    Click to add, click again to remove. Shift + click for multi selection. 
`

const selectedSessionsDescription = `
    Selected measurement session dates to include in the invoice report.
    Click to remove. 
`

// -------------------------------------------------------


const SessionDateConfigContainer = styled(HFlex)`
    width: 150px;
    padding: 15px;
    align-items: center;
    border-radius: 5px;
    overflow-y: auto;
    background-color: ${Color.extraDarkGrey};
`

const SelectButton = styled(RippleButton)`
    height: 25px;
    font-size: 10px;
    line-height: 25px;
    margin-top: 15px;
`

const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;
    width: 50%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
`

const ContentListDiv = styled(ContentDiv)`
    max-height: 60vh;
    overflow-y: auto;

    h4 {
        padding: 10px;
        border-radius: 5px;
        background-color: ${Color.extraDarkGrey};
        margin-bottom: 15px;
        user-select: none;
    }

    p {
        color: white;
        font-size: 12px;
        margin-bottom: 20px;
        margin-left: 5px;
    }

    .sessions, .selected {
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    
        font-weight: normal;
        cursor: pointer;
        user-select: none;

        transition: 0.2s ease;

        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: 90% 50%;

        border: solid 2px ${Color.extraDarkGrey};
    }

    .selected: hover {
        padding-right: 25px;
        background-color: ${Color.red};
        background-image: url(${DeleteIcon});
    }
`

const SessionDateContainer = styled("h5")<{selected: boolean}>`
    border: solid 2px ${props => props.selected ? Color.green : Color.extraDarkGrey} !important;

    &: hover {
        padding-right: 25px;
        background-color: ${props => props.selected ? Color.red : Color.green};
        background-image: url(${props => props.selected ? DeleteIcon : AddIcon});
        border: solid 2px ${props => props.selected ? Color.red : Color.green} !important;
    }
`



interface SessionDateSelectorProps {
    sessions: Date[];
    selected: Date[];
    setSelected: (key: string, value: Date[]) => void;
}

/**
 * Initial session date selector
 * component. On click, an overlay is opened 
 * presenting available session dates for selection. 
 */
const SessionDateSelector = (props: SessionDateSelectorProps): React.ReactElement => {

    // State
    const { sessions, selected, setSelected } = props;
    const [selectorOpened, setSelectorOpened] = useState<boolean>(false);

    return (
        <HFlex style={{height: "100%"}}>
            {selectorOpened &&
                <SessionDateSelectorPopup 
                    sessions=       {sessions}
                    selected=       {selected}
                    setSelected=    {setSelected}
                    close=          {() => setSelectorOpened(false)}
                />
            }
            <VFlex>
                <SessionDateConfigContainer>
                    <VFlex style={{alignItems: "center", marginRight: 20}}>
                        <img src={SessionIcon} style={{marginBottom: 10, width: 20, height: 20}} />
                        <h5 style={{textAlign: "center", fontWeight: "normal"}}>{selected.length} selected session(s)</h5>
                    </VFlex>
                    <SelectButton 
                        color=      {Color.blue}
                        hoverColor= {Color.lightBlue}
                        onClick=    {() => setSelectorOpened(true)}
                    >Select</SelectButton>
                </SessionDateConfigContainer>
            </VFlex>
        </HFlex>
    )
}

export default SessionDateSelector;






interface SessionDateSelectorPopupProps extends SessionDateSelectorProps {
    close: Function;
}

/**
 * Session date selection overlay popup. 
 * Allows for shift+click multiselection. 
 */
const SessionDateSelectorPopup = (props: SessionDateSelectorPopupProps): React.ReactElement => {

    // State
    const { sessions, selected, setSelected, close } = props;

    const [stagedSelected, setStagedSelected] = useState<Date[]>(selected);
    const [isMultiSelection, setIsMultiSelection] = useState<boolean>(false);

    // Actions

    /**
     * Add passed date to the the session dates
     * staged for selection.
     */
    const updateDateSelectionStatus = (date: Date) => {

        let _dates = [];
        if (isMultiSelection && stagedSelected.length !== 0) {
            /**
             * Multi selection is enabled by splicing the available
             * session objects for the given start and end indices 
             * provided by the last selected and currently selected 
             * dates.
             */
            const _cidx = sessions.indexOf(date) + 1;
            const _lidx = sessions.indexOf(stagedSelected.slice(-1)[0])
            if (_cidx > _lidx) {
                _dates = sessions.slice(_lidx, _cidx)
            } else {
                _dates = sessions.slice(_cidx, _lidx)
            }

            // Remove any possibly duplicate session dates
            // that have been selected.
            _dates = _dates.filter(d => !stagedSelected.includes(d))
        } else {
            _dates = [date]
        }

        // Add selected dates if not already in 
        // selected, else remove. 
        if (!stagedSelected.includes(date)) { 
            setStagedSelected([...stagedSelected, ..._dates]);
        } else {
            const _staged = [...stagedSelected];
            _staged.splice(_staged.indexOf(date), 1);
            setStagedSelected(_staged);
        }
    }

    /**
     * On key down press event toggle
     * multi selection flag.
     */
    const onKeyDown = (e) => {
        if (e.key === "Shift") setIsMultiSelection(true);
    }

    /**
     * On key up event toggle
     * multi selection flag.
     */
    const onKeyUp = (e) => {
        if (e.key === "Shift") setIsMultiSelection(false);
    }

    /**
     * Apply and pass staged selected
     * session dates to parent component
     * then close selector popup.
     */
    const performSelection = () => {
        applySelection();
        close();
    }
    
    /**
     * Apply and pass staged selected
     * session dates to parent component for 
     * inclusion in export config, then
     * close selector popup. 
     */
    const applySelection = () => {
        setSelected("sessions", stagedSelected);
    }

    /**
     * Reset staged selected session dates
     * to previously applied and selected ones.
     */
    const discardSelection = () => {
        setStagedSelected(selected);
    }

    /**
     * Reset staged selected session dates
     * to empty selection. 
     */
    const resetSelection = () => {
        setStagedSelected([]);
    }

    // Effects
    useEffect(() => {

        // Register event listeners 
        document.addEventListener("keydown", onKeyDown, false);
        document.addEventListener("keyup", onKeyUp, false);

        return () => {
          document.removeEventListener("keydown", onKeyDown, false);
          document.removeEventListener("keyup", onKeyUp, false);
        };
    }, []);



    return (
        <Overlay 
            css=        {"z-index: 10;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer  onClick={(e) => e.stopPropagation()}>
                    <h3>Select session dates</h3>
                    <HFlex style={{marginTop: 20}}>
                        <ContentListDiv style={{width: "30%", marginRight: 10}}>
                            <h4>{stagedSelected.length} selected</h4>
                            <p>{selectedSessionsDescription}</p>
                            {stagedSelected.length === 0 && 
                                <NoDataComponent message="No session dates selected" />
                            }
                            {stagedSelected.length !== 0 && stagedSelected.map((s, index) => 
                                <h5
                                    key=        {`selected-${index}`}
                                    onClick=    {() => updateDateSelectionStatus(s)}
                                    className=  "selected"
                                    title=      "Remove session date"
                                >{s}</h5>    
                            )}
                        </ContentListDiv>
                        <ContentListDiv style={{width: "70%"}}>
                            <h4>Dates</h4>
                            <p>{sessionSelectorDescription}</p>
                            {sessions.length === 0 &&
                                <LoadingComponent message="Fetching session dates..." />
                            }
                            {sessions.map((s, index) => 
                                <SessionDateContainer
                                    key=        {`sessions-${index}`}
                                    selected=   {stagedSelected.includes(s)}
                                    onClick=    {() => updateDateSelectionStatus(s)}
                                    className=  "sessions"
                                    title=      "Add session date"
                                >{s}</SessionDateContainer>    
                            )}
                        </ContentListDiv>
                    </HFlex>
                    <HFlex style={{justifyContent: "space-between", marginTop: 15}}>
                        {stagedSelected.length !== 0 &&
                            <RippleButton 
                                color=      {Color.red}
                                hoverColor= {Color.lightRed}
                                onClick=    {resetSelection}
                                title=      {"Remove all selected session dates"}
                            >Reset selection</RippleButton>
                        }
                        <HFlex style={{justifyContent: "flex-end"}}>
                            {stagedSelected !== selected && 
                                <RippleButton 
                                    color=      {Color.green}
                                    hoverColor= {Color.lightGreen}
                                    onClick=    {performSelection}
                                    style=      {{marginRight: 10}}
                                    title=      {"Select session dates & close"}
                                >Select</RippleButton>
                            }
                            {stagedSelected !== selected && 
                                <RippleButton 
                                    color=      {Color.blue}
                                    hoverColor= {Color.lightBlue}
                                    onClick=    {applySelection}
                                    style=      {{marginRight: 10}}
                                    title=      {"Apply selected dates"}
                                >Apply</RippleButton>
                            }
                            {stagedSelected !== selected && 
                                <RippleButton 
                                    color=      {Color.red}
                                    hoverColor= {Color.lightRed}
                                    onClick=    {discardSelection}
                                    style=      {{marginRight: 25}}
                                    title=      {"Discard changes"}
                                >Discard changes</RippleButton>
                            }
                            <RippleButton 
                                color=      {Color.darkGrey}
                                hoverColor= {Color.extraLightGrey}
                                onClick=    {() => close()}
                                title=      {"Cancel & close selection"}
                            >Close</RippleButton>
                        </HFlex>
                    </HFlex>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}