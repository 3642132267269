import React from "react";
import styled from "styled-components";

import { AuditResponse } from "../../api/LCSModels";

import Color from "../../resources/colors";
import AuditIcon from "../../resources/images/audit-icon.svg";
import ErrorIcon from "../../resources/images/error-icon-white.png";
import WarningIcon from "../../resources/images/warning-icon-white.png";


import IconTag from "../../components/IconTag";
import { HFlex } from "../../pandora/styled";


const Container = styled(HFlex)`
    padding: 15px;    
    margin-top: 10px;
    justify-content: space-between;

    background-color: ${Color.extraDarkGrey};
    border-radius: 5px;

    cursor: pointer;
    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.extraLightGrey};
        transform: translateY(-2px);
    }

    .icon {
        width: 15px;
        height: 15px;
        margin-right: 20px;
    }

    ${HFlex} {
        align-items: center;
    }

    h5 {
        font-weight: normal;
    }
    
` 


interface AuditListItemProps {
    audit: AuditResponse;
    select: (audit: AuditResponse) => void;
}

const AuditListItem = (props: AuditListItemProps): React.ReactElement => {

    const { audit, select } = props;

    const open = () => select(audit);

    const errorCount = audit.warnings.filter(a => a.isError).length
    
    const warningCount = audit.warnings.filter(a => !a.isError).length

    return (
        <Container onClick={open}>
            <HFlex>
                <img className="icon" src={AuditIcon} />
                <h5><strong>Audit</strong> {audit.metadata.auditDate}</h5>
            </HFlex>
            <HFlex>
                <IconTag 
                    text=   {`${errorCount} errors`}
                    icon=   {ErrorIcon}
                    color=  {Color.red}
                    style=  {{marginRight: 10, backgroundSize: "10px 10px"}}
                />
                <IconTag 
                    text=   {`${warningCount} warnings`}
                    icon=   {WarningIcon}
                    color=  {Color.yellow}
                />
            </HFlex>
        </Container>
    )

}


export default AuditListItem;