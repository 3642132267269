import Redirect from "./redirect";
import { AuthenticatedUser, Role } from "../api/LCSModels";
import Thomas from "../api/Thomas";
import { CookieHandler, LCSOnlineCookies } from "./cookies";


export default class Auth {

    /**
     * Current logged in user instance.
     */
    public static currentUser: AuthenticatedUser;

    /**
     * Retrieve token and set static token
     * on API caller {@link Thomas}. Without 
     * calling the setToken(token:) on Thomas, 
     * API calls won't work.
     */
    public static init = (): boolean => {
        const token = Auth.getToken()
        if (token !== undefined) Thomas.setToken(token)
        return token !== undefined
    }

    /**
     * Verify session with token 
     * is authenticated.
     */
    public static verify = async (): Promise<boolean> => {
        try {
            const res: AuthenticatedUser = await Thomas.authenticate()
            return res !== null;
        } catch (e) {
            Auth.setUser(undefined)
            console.log(e)
            return false
        }
    }

    /**
     * Remove login token cookie and 
     * redirect to ASuite. 
     */
    public static logout = (): void => {
        Auth.removeToken()
        Thomas.setToken(undefined);
        Redirect.asuite()
    }

    /**
     * Set currently logged in user.
     * @param user 
     */
    public static setUser = (user: AuthenticatedUser) => Auth.currentUser = user;

    /**
     * Retrive authentication token 
     * from cookies. 
     */
    private static getToken = (): string | undefined => {
        return CookieHandler.get(LCSOnlineCookies.Auth)
    }

    /**
     * Remove authentication cookie.
     */
    private static removeToken = (): void => {
        CookieHandler.remove(LCSOnlineCookies.Auth)
    }

    public static isAdmin = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attempted use of authenticated user, but user not logged in.")
        return Auth.currentUser.role === Role.Admin;
    }

    public static isEmployee = (): boolean => {
        if (Auth.currentUser === undefined) 
            throw new Error("Attempted use of authenticated user, but user not logged in.")
        return Auth.currentUser.isEmployee;
    }
}
