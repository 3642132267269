import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";

import Color from "../resources/colors";
import { HFlex, Button } from "../pandora/styled";


const Container = styled(HFlex)`
    width: fit-content;

    ${Button} {
        margin-right: 5px;
    }
`

interface YearSelectorProps {
    selectedYear: number | null;
    select: (year?: number) => void;
    includeFutureYears: boolean;
}

const YearSelector = (props: YearSelectorProps): React.ReactElement => {

    // State 
    const MIN_YEAR = 2020
    const MAX_YEAR = 2026

    const CURRENT_MEASUREMENT_YEARS = Array.from({length: (MAX_YEAR + 1 - MIN_YEAR)}, (v, k) => k + MIN_YEAR)
    const { selectedYear, includeFutureYears, select } = props;

    const [years, setYears] = useState<number[] | null>(null);

    // Effects
    useEffect(() => {

        /**
         * Retrieve distinct session years
         */
        const getYears = async () => {
            const _years = await Thomas.request(APIRequest.getAllDistinctSessionYears) as unknown as number[]; 
            setYears(_years);
        }

        if (!includeFutureYears) {
            getYears()
        } else {
            setYears(CURRENT_MEASUREMENT_YEARS)
        }
    }, [])


    return (
        <Container>
            {!includeFutureYears &&
                <Button
                    color=      {selectedYear === undefined ? Color.extraLightGrey : Color.extraDarkGrey}
                    hoverColor= {Color.extraLightGrey}
                    onClick=    {() => select(undefined)}
                >All</Button>
            }
            {years !== null && years.map((y, index) => 
                <Button
                    key=        {`year-${index}`}
                    color=      {selectedYear === y ? Color.extraLightGrey : Color.extraDarkGrey}
                    hoverColor= {Color.extraLightGrey}
                    onClick=    {() => select(y)}
                >{y}</Button>
            )}
        </Container>        
    )
}

export default YearSelector;



