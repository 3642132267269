import React from 'react';
import styled from 'styled-components';

import 'mapbox-gl/dist/mapbox-gl.css';

import Color from "../../resources/colors";
import MarkerIcon from "../../resources/images/data-point-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";


// COMPONENT CONFIGURATION DATA ----------------------------

const DataTypeColorMap = {
    "fomul": Color.blue,
    "trackdistance": Color.orange,
}

// -------------------------------------------------------

const MarkerDiv = styled("div")<{color: string}>`
    position: absolute;
    width: 15px;
    height: 15px;

    background-size: cover;
    mask-image: url(${MarkerIcon});
    mask-size: cover;

    cursor: pointer;
    border-width: 0;

    background-color: ${props => props.color};
    transition: 0.2s ease;

    &: hover {
        transform: scale(1.5);
    }
`


const PlaceMarkerDiv = styled.div`
    position: absolute;
    width: 30px;
    height: 30px;

    border-width: 0;
    border-radius: 10px;

    background-color: ${Color.purple};
    background-image: url(${PlaceIcon});
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;
`

interface MarkerProps {
    id: number
    type: string
}

/**
 * Data marker element in MapView
 * for places, fomuls or trackdistances. 
 */
const Marker = (props: MarkerProps): React.ReactElement => {

    const { id, type } = props;

    // Marker for fomuls or trackdistances
    const genericMarker = (
        <MarkerDiv 
            id={`marker-${id}`} 
            color={DataTypeColorMap[type]}
        />
    )

    // Marker for places 
    const placeMarker = (
        <PlaceMarkerDiv />
    )

    const getMarker = (): React.ReactElement => {
        return (type === "place") ? placeMarker : genericMarker
    }

    return getMarker()
}

export default Marker;

