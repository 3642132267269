import React, { useState } from "react";
import styled from "styled-components";

import Color from "../resources/colors";
import { DataStatus } from "../api/LCSModels";
import { 
    DataStatusColorMap, 
    DataStatusLightColorMap, 
    DataStatusLabelMap 
} from "../pandora/pandora";

import { VFlex, StatusItemContainer, StatusSelectionContainer } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";


const DataStatusOption = styled(StatusItemContainer)`
    min-width: 80px;
    width: fit-content;
    height: 30px;
    margin: 0;
    margin-left: 5px;
    padding: 5px 10px;

    animation: ${FadeAnimation} 0.2s linear 1;
`




interface DataStatusSelectorProps {
    value?: string;
    queryKey: string;
    setValue: (key: string, value: string | number) => void;
}

const DataStatusSelector = (props: DataStatusSelectorProps): React.ReactElement => {
    
    const { queryKey, value, setValue } = props;
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const statusKeys = [...Object.values(DataStatus).filter(i => !Number(i)).reverse(), null]

    return (
        <VFlex
            onMouseEnter=   {() => setIsHovering(true)}
            onMouseLeave=   {() => setIsHovering(false)}
            style=          {{width: "auto", position: "relative"}}
        >
            <DataStatusOption 
                color=      {DataStatusColorMap[value] ?? Color.lightGrey}
                hoverColor= {DataStatusLightColorMap[value] ?? Color.extraLightGrey}
                selected=   {false}
                selectable= {true}
            >
                 <h6>{DataStatusLabelMap[value] ?? "Not specified"}</h6>
            </DataStatusOption>
            {isHovering && 
                <StatusSelectionContainer>
                    {statusKeys.map(ct => 
                        <DataStatusOption 
                            key=        {`status-option-${ct}`}
                            color=      {DataStatusColorMap[DataStatus[ct]] ?? Color.lightGrey}
                            hoverColor= {DataStatusLightColorMap[DataStatus[ct]] ?? Color.extraLightGrey}
                            selected=   {false}
                            selectable= {true}
                            onClick=    {() => setValue(queryKey, DataStatus[ct])}
                        >
                            <h6>{DataStatusLabelMap[DataStatus[ct]] ?? "Not specified"}</h6>
                        </DataStatusOption> 
                    )}
                </StatusSelectionContainer>
            }
        </VFlex>
    )
}

export default DataStatusSelector;

