import React from "react";

import Color from "../resources/colors";
import { Tag } from "../pandora/styled";

interface IconTagProps {
    text: string;
    icon: string;
    color?: string
    style?: React.CSSProperties
}

const IconTag = (props: IconTagProps): React.ReactElement => {
    return (
        <Tag
            color={props.color ?? Color.lightGrey}
            style={props.style ?? {}}
        >
            <img 
                src={props.icon} 
                style={{height: 10, marginRight: 5}}
            />
            {props.text}
        </Tag>
    )
}

export default IconTag;
