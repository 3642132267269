import React, { useState } from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { PlaceData } from "../../api/LCSModels";
import PLACE_NAMES from "../../pandora/place-names";
import Color from "../../resources/colors";
import { getCompletionProgressColor } from "../../pandora/pandora";

import PlaceIcon from "../../resources/images/place-icon.svg";
import TrackDistanceIcon from "../../resources/images/track-distance-icon.svg";
import FomulIcon from "../../resources/images/fomul-icon.svg";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";
import LineIcon from "../../resources/images/line-icon.svg";
import CompletionIcon from "../../resources/images/check-mark-icon.png";
import NotCompletedIcon from "../../resources/images/close-icon.png";

import { HFlex, MetaDataChildItem, IconButton, VFlex } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations";



const Container = styled.div`
    width: auto;
    height: 80px;
    margin-top: 10px;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.extraDarkGrey};

    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    cursor: pointer;
    transition: 0.3s ease;

    h4 {
        font-weight: normal;
    }

    &:hover {
        transform: translateY(-2px);
        background-color: ${Color.extraLightGrey};
    }
`

const ContentGrid = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;

    grid-template-columns: repeat(4, auto) 25px;
    grid-template-rows: 1fr;

    column-gap: 10px;
    row-gap: 10px;
    align-items: center;

    margin-top: 10px;
    margin-right: 50px;
    max-width: 70%;

    img {
        width: auto;
        height: 15px;
        padding: 5px;
        border-radius: 5px;
    }
`

const MetaContent = styled(MetaDataChildItem)`
    align-items: center;
    max-width: 200px;
    h5 {
        font-weight: normal;
    }

    img { 
        height: 15px;
        width: 15px;
    }
`

const SignatureContainer = styled.div`
    width: fit-content;
    margin-left: 15px;
    padding: 5px;
    border-radius: 5px;

    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: ${Color.darkGrey};
`


interface PlaceDataContainerProps {
    place: PlaceData;
    onClick: (place: PlaceData) => void;
}

const PlaceDataListContainer = (props: PlaceDataContainerProps): React.ReactElement => {

    const getCompletionProgress = (): number => {
        if (place.trackLinksCount === 0)
            return 0;
        return (place.completedTrackLinksCount / place.trackLinksCount) * 100
    }

    // State
    const { place, onClick } = props;
    const [completionProgress, setCompletionProgress] = useState<number>(getCompletionProgress());

    // Actions
    const isLine = (): boolean => place.fromPlace != place.toPlace

    return (
        <Container onClick={() => onClick(place)}>
            <HFlex style={{justifyContent: "space-between", alignItems: "flex-end"}}>
                <VFlex>
                    <HFlex style={{width: "fit-content"}}>
                        <img 
                            src={isLine() ? LineIcon : PlaceIcon} 
                            style={{width: 20, height: 20, marginRight: 10}} 
                        />
                        <h4>
                            {PLACE_NAMES[place.fromPlace]} 
                            {place.fromPlace !== place.toPlace ? ` -  ${PLACE_NAMES[place.toPlace]}` : ``}
                        </h4>
                        <SignatureContainer>
                            {place.fromPlace} 
                            {place.fromPlace !== place.toPlace ? ` -  ${place.toPlace}` : ``}
                        </SignatureContainer>
                    </HFlex>    
                    <ContentGrid>
                        <MetaContent>
                            <img src={FomulIcon} />
                            <h5><strong>{place.fomulsCount}</strong> Fomuls</h5>
                        </MetaContent>
                        <MetaContent>
                            <img src={TrackDistanceIcon} />
                            <h5><strong>{place.trackDistancesCount}</strong> Track Distances</h5>
                        </MetaContent>
                        <MetaContent>
                            <img src={SessionIcon} />
                            <h5><strong>{place.sessionsCount}</strong> Sessions</h5>
                        </MetaContent>
                        <MetaContent>
                            <img src={TrackLinkIcon} />
                            <h5><strong>{place.trackLinksCount}</strong> TrackLinks</h5>
                        </MetaContent>
                        <img 
                            src={place.completion !== null ? CompletionIcon : NotCompletedIcon}
                            style={{backgroundColor: place.completion !== null ? Color.green : Color.red}}
                        />
                    </ContentGrid>
                </VFlex>
                <div style={{ width: 70, textAlign: "center"}}>
                    <CircularProgressbar 
                        value={completionProgress} 
                        text={`${place.completedTrackLinksCount}/${place.trackLinksCount}`}
                        styles={buildStyles({
                            textSize: '12px',
                            pathColor: getCompletionProgressColor(completionProgress),
                            textColor: '#FFF',
                            trailColor: Color.lightGrey,
                            backgroundColor: '#3e98c7',
                        })}
                    />
                </div>
            </HFlex>
        </Container>
    )
}

export default PlaceDataListContainer;