import { TrackLinkProfile, TrackLinkExtension, Direction } from "../api/LCSModels";
import { Point } from "./pandora";

interface Profile {
    x: number[];
    y: number[];
}


/**
 * Predefined base coordinates for supported
 * Track Link profiles. 
 */
class Profiles {

    static NUProfile: Profile = {
        x: [-2120, -2600, -2600, -1950, 1950, 2600, 2600, 2120, -2120],
        y: [50, 350, 4750, 5400, 5400, 4750, 350, 50, 50]
    }

    static UUProfile: Profile = {
        x: [-2950, -2950, -3100, -3100, 3100, 3100, 2950, 2950, -2950],
        y: [50, 3500, 3500, 5700, 5700, 3500, 3500, 50, 50]
    }

    static VNProfile: Profile = {
        x: [-1470, -1470, -1545, -1675, -1675, -2500,
            -2500, -2075, -2075, -2075, 2075, 2075,
            2075, -2075, -2075, -2225, -2225,
            2225, 2225, 2075, 2075, 2075, 2500, 2500,
            1675, 1675, 1545, 1470, 1470, -1470],
        y:  [50, 305, 380, 380, 770, 770, 3060, 3485, 770,
             5000, 5000, 4800, 5000, 5000,
             4800, 4800, 5150, 5150, 4800, 4800, 770, 3485, 3060,
             770, 770, 380, 380, 305, 50, 50]
    }

    static AProfile: Profile = {
        x: [-1220, -1495, -1575, -1575, -1700, -1700, -690, 690, 1700, 1700, 1575, 1575, 1495, 1220, -1220],
        y: [130, 430, 430, 1200, 1200, 3640, 4650, 4650, 3640, 1200, 1200, 430, 430, 130, 130]
    }
}


export default class ProfileHandler {

    /**
     * Format track link profile coordinates
     * to x, y key value pairs used for rendering 
     * in Recharts plot. 
     */
    private static format = (profile: Profile): Point[] => {
        let points: Point[] = [];

        if (profile.x.length !== profile.y.length) 
            throw new Error(`X-coordinate and Y-coordinate count is different in ${profile}`)
        
        const pcount = profile.x.length
        for (let i = 0; i < pcount; i++) {
            points.push({x: profile.x[i], y: profile.y[i]})
        }
    
        return points;
    }

    /**
     * Retrieve Track Link profile coordinates
     * for a given Track Link profile. 
     */
    private static getProfile = (p: TrackLinkProfile): Profile => {
        switch(p) {
        case TrackLinkProfile.NU: return Profiles.NUProfile
        case TrackLinkProfile.UU: return Profiles.UUProfile
        case TrackLinkProfile.VN: return Profiles.VNProfile
        case TrackLinkProfile.A: return Profiles.AProfile
        }
    }

    /**
     * Calculate Track Link profile 
     * width extension based on extensionFormula for 
     * Track Link and the current radius of the Fomul.
     * 
     * Refer to the extension calculation alogrithm the below
     * function was based on in the LCS Desktop application 
     * source code. 
     */
    public static getCalculatedProfile = (p: TrackLinkProfile, 
                                          extensionFormula: TrackLinkExtension,
                                          radius: number,
                                          direction: boolean): Point[]  => {
        const profile = ProfileHandler.getProfile(p)

        let innerCurveExtension = 0
        let outerCurveExtension = 0

        if (radius !== 0) {
            const absRadius = Math.abs(radius)
            if (extensionFormula === TrackLinkExtension.Normal) {
                innerCurveExtension = (41 / absRadius) * 1000
                outerCurveExtension = (31 / absRadius) * 1000
            } else if (extensionFormula === TrackLinkExtension.Extended) {
                innerCurveExtension = (200 / absRadius) * 1000
                outerCurveExtension = (31 / absRadius) * 1000
            }
        }

        const adjRadius = radius * (direction ? 1 : -1)

        let leftExtension = 0
        let rightExtension = 0

        if (adjRadius > 0) {
            leftExtension = outerCurveExtension
            rightExtension = innerCurveExtension
        } else  {
            leftExtension = innerCurveExtension
            rightExtension = outerCurveExtension
        }

        let x = profile.x
        let y = profile.y

        x = x.map(p => p > 0 ? p + rightExtension : p)
        x = x.map(p => p < 0 ? p - leftExtension : p)

        const formattedProfile = ProfileHandler.format({x: x, y: y})
        return formattedProfile
    }
}