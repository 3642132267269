import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AxiosResponse } from "axios";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { FomulMin, TrackDistanceMin, SearchResultType } from "../../api/LCSModels";
import FileSaver from "../../site/fileSaver";

import SelectIcon from "../../resources/images/select-icon.svg";
import DeleteIcon from "../../resources/images/delete-icon.png";
import ClearIcon from "../../resources/images/close-icon.png";
import EditIcon from "../../resources/images/edit-icon.svg";

import Color from "../../resources/colors";
import ConfirmationPopup, { Confirmation } from "../ConfirmationPopup";
import { GenericPopupProps } from "../GenericPopup";

import ExportButton from "../ExportButton";
import FomulListItem, { FomulListItemSelectable } from "../data/FomulListItem";
import TrackDistanceListItem, { TrackDistanceSelectableListItem } from "../data/TrackDistanceListItem";
import PropertyUpdateSelector from "../search/PropertyUpdateSelector";

import { QueryResultContainer, HFlex, IconButton } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const ResultTypeExportRequestMap = {
    [SearchResultType.Fomul]: APIRequest.exportMultipleFomuls,
    [SearchResultType.TrackDistance]: APIRequest.exportMultipleTrackDistances
}

const ResultTypeBatchDeletionRequestMap = {
    [SearchResultType.Fomul]: APIRequest.deleteFomulBatch,
    [SearchResultType.TrackDistance]: APIRequest.deleteTrackDistanceBatch
}

// -------------------------------------------------------



const ResultContainer = styled.div`
    height: 95%;
    overflow-y: auto;
    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    ::-webkit-scrollbar-thumb {
        background-color: ${Color.lightGrey}; 
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: ${Color.extraLightGrey}; 
    }

    ::-webkit-scrollbar {
        width: 13px;
    }
`


interface SearchResultContainerProps {
    type: SearchResultType
    result: FomulMin[] | TrackDistanceMin[];
    setPopup: (props: GenericPopupProps) => void;
    triggerSearch: () => void;
}


/**
 * Component listing search result of a 
 * search query for Fomuls or Track Distances. 
 */
const SearchResultContainer = (props: SearchResultContainerProps): React.ReactElement => {

    // State
    const { type, result, triggerSearch, setPopup } = props;

    const [selectionModeActive, setSelectionModeActive] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<number[]>([]);
    const [isMultiSelection, setIsMultiSelection] = useState<boolean>(false);
    const [isEditingMode, setIsEditingMode] = useState<boolean>(false);

    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

    // Actions

    /**
     * Mark data as selected
     */
    const selectData = (id: number) => {
        const data = result.map(r => r.id)
        let _selectedData = [];
        if (isMultiSelection && selectedData.length !== 0) {
            /**
             * Multi selection is enabled by splicing the available
             * session objects for the given start and end indices 
             * provided by the last selected and currently selected 
             * dates.
             */
            const _cidx = data.indexOf(id) + 1;
            const _lidx = data.indexOf(selectedData.slice(-1)[0])
            if (_cidx > _lidx) {
                _selectedData = data.slice(_lidx, _cidx)
            } else {
                _selectedData = data.slice(_cidx, _lidx)
            }
            // Remove any possibly duplicate IDs
            // that have been selected.
            _selectedData = _selectedData.filter(d => !selectedData.includes(d))
        } else {
            _selectedData = [id]
        }
        
        // Add selected IDs if not already in 
        // selected, else remove. 
        if (!selectedData.includes(id)) { 
            setSelectedData([...selectedData, ..._selectedData]);
        } else {
            const _staged = [...selectedData];
            _staged.splice(_staged.indexOf(id), 1);
            setSelectedData(_staged);
        }
    }

    const dataIsSelected = (id: number): boolean => selectedData.includes(id)

    /**
     * Clear selected data
     */
    const clear = () => setSelectedData([]);

    /**
     * Get the correct Fomul component for the 
     * current selection state
     */
    const getFomulItem = (f: FomulMin, index: number): React.ReactElement => {
        if (selectionModeActive) {
            return (
                <FomulListItemSelectable
                    key=            {`result-${index}`}
                    fomul=          {f} 
                    color=          {dataIsSelected(f.id) ? Color.green : Color.extraDarkGrey}
                    hoverColor=     {dataIsSelected(f.id) ? Color.red : Color.extraLightGrey}
                    onClick=        {() => selectData(f.id)}
                />
            )
        } else {
            return (
                <FomulListItem 
                    key=    {`result-${index}`}
                    fomul=  {f} 
                    color=  {Color.extraDarkGrey}
                />
            )
        }
    }

    /**
     * Get the correct Track Distance component 
     * for the current selection state
     */
    const getTrackDistanceItem = (t: TrackDistanceMin, index: number): React.ReactElement => {
        if (selectionModeActive) {
            return (
                <TrackDistanceSelectableListItem
                    key=            {`result-${index}`}
                    trackDistance=  {t} 
                    color=          {dataIsSelected(t.id) ? Color.green : Color.extraDarkGrey}
                    hoverColor=     {dataIsSelected(t.id) ? Color.red : Color.extraLightGrey}
                    onClick=        {() => selectData(t.id)}
                />
            )
        } else {
            return (
                <TrackDistanceListItem 
                    key=            {`result-${index}`}
                    trackDistance=  {t} 
                    color=          {Color.extraDarkGrey}
                />
            )
        }
    }

    /**
     * Request search result export to API. 
     */
    const exportData = async (config: Record<string, boolean>) => {
        try {
            // Get result IDs -  If selection mode, export selected data instead of result
            const IDs = selectionModeActive ? selectedData : result.map(d => d.id)

            // Request Session data export file
            const req = ResultTypeExportRequestMap[type]
            const payload = {IDs: IDs, ...config}
            const res: AxiosResponse = await Thomas.request(req, payload, true);

            // Save file to client
            const saveConfig = `${req.headers["Accept"]}`
            const [, filename] = res.headers['content-disposition'].split('filename=');
            FileSaver.save(res.data, 
                            filename,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of search result completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request batch deletion of selected data to API
     */
     const deleteData = () => {

        // Get result IDs -  If selection mode, delete selected data instead of result
        const IDs = selectionModeActive ? selectedData : result.map(d => d.id)

        const callback = async () => {
            try {
                const req = ResultTypeBatchDeletionRequestMap[type]
                const payload = {IDs: IDs}
                const _ = await Thomas.request(req, payload);
    
                setPopup({
                    title: "Deleted batch!",
                    message: `Deleted ${selectedData.length} ${type === SearchResultType.Fomul ? "Fomuls" : "Track Distances"}`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }

            // Trigger search to database again 
            triggerSearch();
        }

        setConfirmation({
            title: "Warning!",
            message: `Are you sure you want to delete ${IDs.length} ${type === SearchResultType.Fomul ? "Fomuls" : "Track Distances"}?`,
            callback: callback,
            reset: setConfirmation
        })
    }

    
    /**
     * On key down press event toggle
     * multi selection flag.
     */
     const onKeyDown = (e) => {
        if (e.key === "Shift") setIsMultiSelection(true);
    }

    /**
     * On key up event toggle
     * multi selection flag.
     */
    const onKeyUp = (e) => {
        if (e.key === "Shift") setIsMultiSelection(false);
    }


    // Effects
    useEffect(() => {

        // Register event listeners 
        document.addEventListener("keydown", onKeyDown, false);
        document.addEventListener("keyup", onKeyUp, false);

        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
            document.removeEventListener("keyup", onKeyUp, false);
        };
    }, []);



    return (
        <QueryResultContainer>
            {confirmation !== null && 
                <ConfirmationPopup confirmation={confirmation}/> 
            }
            <HFlex style={{justifyContent: "space-between", height: 40, position: "relative"}}>
                <h4 style={{marginTop: 0}}>
                    {selectionModeActive ? 
                        `${selectedData.length} selected` 
                        : 
                        `Query yielded ${result.length} results`
                    }
                </h4>
                {isEditingMode &&
                    <PropertyUpdateSelector 
                        selectedData=   {selectedData}
                        type=           {type}
                        setPopup=       {setPopup}
                        setConfirmation={setConfirmation}
                        close=          {() => setIsEditingMode(false)}
                        triggerSearch=  {triggerSearch}
                    />
                }
                <HFlex style={{alignItems: "center", width: "fit-content", top: -15, right: 0, position: "absolute"}}>
                    {selectionModeActive && selectedData.length !== 0 &&
                        <IconButton 
                            color=      {Color.orange}
                            hoverColor= {Color.lightOrange}
                            icon=       {EditIcon}
                            onClick=    {() => setIsEditingMode(true)}
                            title=      {`Edit ${selectedData.length} selected`}
                            style=      {{
                                textAlign: "center", 
                                textIndent: 25,
                                width: "auto", 
                            }}
                        >{`Edit ${selectedData.length} selected`} </IconButton>
                    }
                    {selectionModeActive && selectedData.length !== 0 &&
                        <IconButton 
                            color=      {Color.red}
                            hoverColor= {Color.lightRed}
                            icon=       {DeleteIcon}
                            onClick=    {deleteData}
                            title=      {`Delete ${selectedData.length} selected`}
                            style=      {{
                                textAlign: "center", 
                                textIndent: 25,
                                width: "auto", 
                            }}
                        >Delete</IconButton>
                    }
                    {selectionModeActive && selectedData.length !== 0 &&
                        <ExportButton 
                            title=  {`Export ${selectedData.length} selected`} 
                            config= {"base"}
                            export= {exportData}
                        />
                    }
                    {selectionModeActive && selectedData.length !== 0 &&
                        <IconButton 
                            color=      {Color.lightGrey}
                            hoverColor= {Color.extraLightGrey}
                            icon=       {ClearIcon}
                            onClick=    {clear}
                            title=      {`Clear selection`}
                            style=      {{
                                textAlign: "center", 
                                textIndent: 25,
                                width: "auto", 
                                marginLeft: 10
                            }}
                        >Clear</IconButton>
                    }
                    {!selectionModeActive &&
                        <IconButton 
                            color=      {Color.red}
                            hoverColor= {Color.lightRed}
                            icon=       {DeleteIcon}
                            onClick=    {deleteData}
                            title=      {`Delete all`}
                            style=      {{
                                textAlign: "center", 
                                textIndent: 25,
                                width: "auto", 
                            }}
                        >Delete</IconButton>
                    }
                    {!selectionModeActive &&
                        <ExportButton 
                            title=  {"Export"} 
                            config= {"base"}
                            export= {exportData}
                        />
                    }
                    <IconButton 
                        color=      {Color.lightGrey}
                        hoverColor= {Color.extraLightGrey}
                        icon=       {SelectIcon}
                        onClick=    {() => setSelectionModeActive(!selectionModeActive)}
                        title=      {`Enable data selection`}
                        style=      {{
                            textAlign: "center", 
                            textIndent: 25,
                            width: "auto", 
                            marginLeft: 10
                        }}
                    >{selectionModeActive ? "Cancel selection" : "Select"}</IconButton>
                </HFlex>
            </HFlex>
            <ResultContainer>
                {type === SearchResultType.Fomul &&
                    result.map((fomul, index) => getFomulItem(fomul, index))
                }
                {type === SearchResultType.TrackDistance &&
                    result.map((trackDistance, index) => getTrackDistanceItem(trackDistance, index))
                }
            </ResultContainer>
        </QueryResultContainer>
    )
}


export default SearchResultContainer;