import styled from "styled-components";
import { Link } from "react-router-dom";

import ArrowIcon from "../resources/images/arrow-icon.svg";
import DeleteIcon from "../resources/images/delete-icon.png";
import AddIcon from "../resources/images/add-icon.png";

import Color from "../resources/colors";
import { ScaleAnimation, FadeAnimation } from "./animations";


export const Flex = styled("div")<{direction: string, css?: string}>`
    display: flex;
    flex-direction:  ${props => props.direction};
    height: 100%;
    width: 100%;
    ${props => props.css ?? ""}
`

export const HFlex = styled("div")<{css?: string}>`
    display: flex;
    width: auto;
    height: auto;
    ${props => props.css ?? ""}
`

export const VFlex = styled("div")<{css?: string}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    ${props => props.css ?? ""}
`

export const Button = styled("div")<{color?: string, hoverColor?: string, css?: string}>`
    width: fit-content;
    height: 25px;
    border-radius: 5px;

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: ${props => props.color ?? Color.green};

    font-size: 12px;
    font-weight: bold;
    color: white;
    line-height: 25px;

    user-select: none;
    cursor: pointer;
    transition: 0.3s ease;

    animation: ${ScaleAnimation} 0.2s linear 1;

    &: hover {
        background-color: ${props => props.hoverColor ?? Color.lightGreen};
    }

    ${props => props.css ?? ""}
`

export const PageIcon = styled("div")<{icon: string, size?: number}>`
    width: ${props => props.size ?? 35}px;
    height: ${props => props.size ?? 35}px;
    margin-right: 20px;

    border-width: 0;
    outline: none;

    background-size: contain;
    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: cover;

    background-color: white;
`

export const Arrow = styled("div")<{rotate: number}>`
    width: 12px;
    height: 12px;

    transition: 0.3s ease;
    transform: rotateX(${props => props.rotate}deg);

    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const Input = styled("input")<{borderColor?: string, fontSize?: number, color?: string, css?: string}>`
    position: relative;
    margin: 0;
    height: 30px;
    width: 100%;
    padding: 10px;

    outline: none;
    border: 0px solid ${Color.green};
    border-radius: 5px;
    
    background-color: ${props => props.color ?? Color.darkGrey};

    text-align: left;
    color: white;
    font-size: ${props => props.fontSize ?? 12}px;
    font-family: Montserrat, sans-serif;
    font-weight: normal;

    box-sizing: border-box;
    transition: 0.3s ease;

    &: focus {
        background-color: ${Color.lightGrey};
    }

    &: hover {
        background-color: ${Color.lightGrey};
    }

    ::placeholder {
        color: white;
        opacity: 50%;
        text-transform: capitalize;
        font-size: ${props => props.fontSize ?? 11}px;
    }

    ${props => props.css ?? ""};
`


export const Tag = styled("div")<{color: string, hoverColor?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;

    background-color: ${props => props.color};

    color: white;
    font-size: 10px;
    font-weight: bold;

    user-select: none;
    cursor: pointer;

    transition: 0.2s ease;

    &: hover {
        background-color: ${props => props.hoverColor ?? props.color};
    }
`

export const OpenTag = styled(Tag)`
    &: hover {
        padding: 5px 15px;
    }
`

export const QueryResultContainer = styled(VFlex)`

    padding: 15px;
    height: 80vh;
    border-radius: 5px;    
    box-sizing: border-box;

    overflow-y: auto;
    background-color: ${Color.darkGrey};

    > * {
        margin-top: 10px;
    }

    &: first-child {
        margin-top: 0px;
    }
`

export const RippleButton = styled("div")<{color: string, hoverColor: string, css?: string}>`
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;

    color: white;
    line-height: 40px;
    font-size: 12px;
    font-weight: bold;

    user-select: none;
    cursor: pointer;

    background-color: ${props => props.color};

    background-position: center;
    transition: background 0.8s;

    &: hover {
        background: ${props => props.color} radial-gradient(circle, transparent 1%, ${props => props.color} 1%) center/15000%;
    }
    &: active {
        background-color: ${props => props.hoverColor};
        background-size: 100%;
        transition: background 0s;
    }

    ${props => props.css ?? ""}
`

export const DeleteButton = styled.div`
    width: 15px;
    height: 15px;
    padding: 5px;

    border-radius: 5px;
    
    background-color: ${Color.red};
    background-image: url(${DeleteIcon});
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;

    cursor: pointer;
    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.lightRed};
    }
`

export const AddButton = styled.div`
    width: 15px;
    height: 15px;
    padding: 10px;

    border-radius: 5px;
    
    background-color: ${Color.green};
    background-image: url(${AddIcon});
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center;

    cursor: pointer;
    transition: 0.3s ease;

    &: hover {
        background-color: ${Color.lightGreen};
    }
`

export const IconButton = styled("div")<{icon: string, color: string, hoverColor: string}>`
    height: 30px;
    width: fit-content;
    margin-right: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;

    background-color: ${props => props.color};
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 10px 50%;

    color: white;
    line-height: 30px;
    font-weight: bold;
    font-size: 12px;
    text-indent: 25px;

    user-select: none;
    cursor: pointer;

    transition: 0.3s linear;

    &: hover {
        background-color: ${props => props.hoverColor};
    }
`

export const ViewContainer = styled.div`
    position: relative;
    min-height: 100%;
    height: auto;
    overflow: auto;
    opacity: 0;

    animation: ${FadeAnimation} 0.7s ease 1;
    animation-fill-mode: forwards;
`

export const Container = styled(ViewContainer)`
    overflow: visible;
`

export const DataViewContainer = styled.div`
    opacity: 0;

    animation: ${FadeAnimation} 0.7s ease 1;
    animation-fill-mode: forwards;
`

export const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 20px;

    background-color: ${Color.darkGrey};
`

export const DescriptionInput = styled("textarea")<{enabled: boolean}>`
    width: 100%;
    height: auto;
    padding: 5px;
    border-radius: 5px;

    font-family: Montserrat, sans-serif;
    color: white;
    font-size: 12px;
    text-align: left;
    font-weight: normal;
    
    background: transparent;
    resize: none;
    outline: none;
    border-width: 0;

    transition: 0.3s ease;

    ::placeholder {
        font-family: Montserrat, sans-serif;
        color: white;
        opacity: 50%;
        font-size: 11px;
    }

    &: focus {
        background: ${Color.lightGrey};
    }

    &: hover {
        background: ${Color.lightGrey};
    }
`

export const StatusItemContainer = styled("div")<{color: string, selected: boolean, selectable: boolean, hoverColor: string}>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${props => props.selected ? 120 : 80}px;
    height: 100%;
    max-height: 20px;

    padding: 5px;
    border-radius: 5px;

    background-color: ${props => props.color};

    transition: 0.3s ease;

    cursor: ${props => props.selectable ? "pointer" : "auto"};
    margin-right: ${props => props.selectable ? 10 : 0}px;

    &: hover {
        background-color: ${props => props.hoverColor};
    }
`

const BackNavButtonContainer = styled("div")<{color: string, hoverColor: string}>`
    display: flex;
    align-items: center;

    width: fit-content;
    height: 30px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin-bottom: 30px;

    background-color: ${props => props.color};

    color: white;
    font-size: 12px;
    font-weight: bold;

    transition: 0.3s ease;
    
    &: hover {
        background-color: ${props => props.hoverColor};
    }
`

interface BackNavButtonProps {
    color: string;
    hoverColor: string;
    link: string;
    text: string;
    rightSide: boolean;
    css?: object;
}

export const BackNavButton = (props: BackNavButtonProps): React.ReactElement => {

    return (
        <Link to={props.link}>
            <BackNavButtonContainer
                color=      {props.color}
                hoverColor= {props.hoverColor}
                style=      {props.css ?? {}}
            >
                {!props.rightSide && <img style={{width: "10px", height: "10px", transform: "rotate(90deg)", marginRight: "10px"}} src={ArrowIcon}/>}
                {props.text}
                {props.rightSide && <img style={{width: "10px", height: "10px", transform: "rotate(270deg)", marginLeft: "10px"}} src={ArrowIcon}/>}
            </BackNavButtonContainer>
        </Link>
    )
}


export const MetaDataChildItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    border-radius: 5px;
    background-color: ${Color.darkGrey};
    
    h5 {
        font-weight: normal;
    }

    h4 {
        font-weight: normal;
    }

    img {
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }

    .completed {
        background-Color: ${Color.green};
    }

    .partially-completed {
        background-Color: ${Color.orange};
    }

    .non-completed {
        background-Color: ${Color.red};
    }

    .completed, .non-completed, .partially-completed {
        width: fit-content;
        padding: 10px;
        margin-left: -5px;
        margin-top: 5px;
        
        border-radius: 5px;
        font-size: 12px;
    }
`

export const ContentGrid = styled(ContentDiv)`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    column-gap: 10px;
    row-gap: 10px;

    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;
`

export const UploadContainer = styled(VFlex)`
    box-sizing: border-box;

    padding: 15px;
    border-radius: 5px;

    background-color: ${Color.darkGrey};

    .switch-container {
        align-items: center;
        width: fit-content;
        padding: 10px;
        margin-top: 20px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }

    .switch-container p {
        margin: 5px 0px;
        font-size: 12px;
    }

    .switch-container ${VFlex} {
        margin-right: 30px;
    }
`


export const StatusSelectionContainer = styled(HFlex)`
    position: absolute;
    top: -10px;
    z-index: 10;

    width: auto;
    padding: 10px;
    border-radius: 5px;

    background-color: ${Color.extraDarkGrey};
`

export const SearchInputContainer = styled(HFlex)`
    margin-top: 2px;
    align-items: center;
    justify-content: flex-start;
    
    padding: 5px;
    background-color: ${Color.extraDarkGrey};
    border-radius: 5px;
    
    span {
        max-width: 100px;
        box-sizing: border-box;

        display: flex;
        align-items: center;

        width: 150px;
        padding: 5px;
        margin-right: 10px;

        text-transform: capitalize;

        border-radius: 5px;
    }

    ${Input} {
        background-color: ${Color.extraDarkGrey};
    }
`

export const IntervalSearchInputContainer = styled(SearchInputContainer)`
    ${Input} {
        max-width: 40px;
    }
    h5 {
        padding: 0px 10px;
    }
`