
/**
 * Enumerations
 * 
 * Keep in mind that some enumerations have references or equivalents
 * in Thomas that need to be maintained if updates to these enumerations 
 * were to be implemented.
 * 
 * Be sure to research how enumeration keys and values are resolved 
 * when passed in requests to Thomas. Typescript enumerations will not 
 * be interpreted correctly in Python if one does not make sure to pass
 * the correct key or value. 
 */


/**
 *  An enum representing different levels of access for users
 */
export enum Role {
    Admin = "Admin",
    Basic = "Basic"
}

/**
 * Session status in annotation delivery pipeline.
 */
export enum SessionStatus {
    Audited = "Audited",
    Delivered = "Delivered",
    Finished = "Finished",    
    Annotating = "Annotating",   
    NotStarted = "NotStarted"
}

/**
 * Version handling enumeration for 
 * Fomuls and Track Distances.
 * 
 * Active - Currently used version 
 * Deprecated - Old version, currently not in use
 * Deleted - Marked as removed.
 */
export enum DataStatus {
    Active = 1,         
    Deprecated = 2,        
    Deleted = 3         
}

/**
 * Export status of Fomuls and Track Distances.
 * 
 * Exported - Has been exported at least once.
 * NotExported - Has never been included in an export.
 */
export enum ExportStatus {
    Exported = 1,            
    NotExported = 2         
}

/**
 * Track Link status in BIS for version handling.
 * 
 * Active - Track Link version currently in use.
 * Deprecated - Old track link version currently not in use.
 * Deleted - TrackLink has been removed from BIS. 
 */
export enum TrackLinkStatus {
    Active = 1,             
    Deleted = 3,             
}

/**
 * Available Track Link profiles 
 * for annotation bounds area.
 */
export enum TrackLinkProfile {
    NU = "NU",            
    UU = "UU",
    VN = "VN",
    A = "A"             
}

/**
 * Extension formula used to calculate profile
 * width for annotation bounds area.
 */
export enum TrackLinkExtension {
    Normal = "41/31",        
    Extended = "200/31"     
}

/**
 * Positive or negative measurement direction 
 * when performing lidar measurement along the tracks.
 */
export enum Direction {
    Positive = 1,
    Negative = 2
}

/**
 * Track Link annotation completion status. 
 */
export enum CompletionStatus {
    NotCompleted = "NonCompleted",
    Partial = "Partial",
    Completed = "Completed"
}

/**
 * Invoicing option 'Alt2' used
 * in invoice templates.
 */
export enum InvoicingOptionAlt2Type {
    Section = "section",
    Place = "place"
}

/**
 * Search result type for data search views. 
 */
export enum SearchResultType {
    Fomul = "Fomul",
    TrackDistance = "TrackDistance"
}




/**
 * AuditTypes for BISudit
 */
export enum AuditType {
    Errors = "Errors", 
    Warnings = "Warnings"
}

/**
 * AuditWarningTypes for BISudit
 */
export enum AuditWarningType {
    NoMatchTrackLink = "NoMatchTrackLink",
    NoMatchFomul = "NoMatchFomul",
    MultipleMatchFomul = "MultipleMatchFomul",
    LostAllStatus1 = "LostAllStatus1",
    MixedDates = "MixedDates",
    MixedOperators = "MixedOperators",
    NonCopyUncompleted = "NonCopyUncompleted",
    NonCopyCompleted = "NonCopyCompleted"
}

/**
 * AuditDataTypes for BISudit
 */
export enum AuditDataType {
    Fomul = "Fomul",
    TrackLink = "TrackLink",
    NoFomul = "NoFomul",
    NoTrackLink = "NoTrackLink"
}



/**
 * Export configurations
 * 
 * Objects containing key value pairs posted
 * as request bodies to Thomas export endpoints
 * for export setting configuration. 
 * Should always be given a default value. 
 */


/**
 * encrypt - Encrupt export file names using encryption algoritm in Thomas.
 * shouldOverrideDate - Override dates according to export overrides registered for Track Links in Thomas.
 */
export const ExportConfig = {
    encrypt: true, 
    shouldOverrideDate: true
}

/**
 * excludeFomuls - Exclude Fomuls from export.
 * excludeTrackDistances - Exclude Track Distances from export.
 */
export const DataExportConfig = {
    excludeFomul: false, 
    excludeTrackDistance: false,
    ...ExportConfig,
}

/**
 * section - Narrow export to given railway network section (Bandel). 
 */
export const TrackLinkExportConfig = {
    section: null
}

/**
 * year - Narrow export to only include data from given year. 
 */
export const ExtendedTrackLinkExportConfig = {
    ...TrackLinkExportConfig,
    year: null
}

/**
 * Composite export config for data for track link
 * i.e. fomuls & trackdistances. 
 */
export const TrackLinkDataExportConfig = {
    ...ExtendedTrackLinkExportConfig,
    ...ExportConfig
}

/**
 * sessions - Session dates to include in invoice export. 
 */
export const InvoiceExportConfig = {
    ...ExportConfig,
    sessions: []
}

/**
 * Direct export config for offline mode support. 
 * Export requires uploaded raw save file. 
 */
export const DirectExportConfig = {
    file: File
}



/**
 * LCS Models
 * 
 * Model interfaces to objects returned from Thomas.
 * Models closely mirror the Pydantic schemas used for
 * object serialization and validation in return values 
 * from API endpoints. 
 * 
 * ----- BEWARE! ------
 * Remember to keep the below object interfaces in sync with 
 * existing Pydantic schemas in Thomas since they will be 
 * used as validation for API response JSON objects.
 */



/**
 * Base model with standard id property. 
 */
export interface LCSModel {
    id: number;
}

export interface HeimdallModel {
    id: string;
}

export interface User extends HeimdallModel { 
    name: string
    isEmployee: boolean
    phone: string
}

export interface AuthenticatedUser extends User {
    role: Role | null
}


// COMPLETION 

export interface PartialCompletion extends LCSModel {
    fromKm: number
    fromM: number
    toKm: number
    toM: number
}

export interface Completion extends LCSModel {
    sessionID: number
    partial?: PartialCompletion
    time: Date
    session: Session
}

// MISCELLANEOUS

export interface ExportOverride extends LCSModel {
    trackLinkID: number
    fromDate: Date
    toDate: Date
    user: User
    updated: Date
}

export interface InvoicingOptionAlt2 extends LCSModel {
    id: number
    type: InvoicingOptionAlt2Type
    value: string
}

export interface TrackLinkChangeLog {
    updated: Date
    trackLinkID: number
    attribute: string
    oldValue?: string
    newValue?: string
}

export interface Flag {
    created: Date
    comment: string
    user: User
    trackLinkID: number
}


// NETWORK / PLACE 

export interface PlaceMin extends LCSModel {
    signature: string
    name: string
}

export interface Place extends PlaceMin {
    longitude: number
    latitude: number
}

export interface PlaceCompletionBase extends LCSModel {
    id: number
    user: User
    time: Date
}

export interface PlaceCompletion extends PlaceCompletionBase {
    fromPlace: PlaceMin
    toPlace: PlaceMin
    user: User
    time: Date
}

export interface PlaceCompletionGeo {
    fromPlace: Place
    toPlace: Place

    trackLinksCount: number
    completedTrackLinksCount: number

    completion?: PlaceCompletionBase
}

export interface Line  {
    fromPlace: Place
    toPlace: Place
}

export interface PlaceData {
    fromPlace: string
    toPlace: string
    
    sessionsCount: number
    fomulsCount: number
    trackDistancesCount: number
    
    trackLinksCount: number
    completedTrackLinksCount: number
    partiallyCompletedTrackLinksCount: number

    totalTrackLinksLength: number
    completedTrackLinksLength: number

    completion?: PlaceCompletion
}

export interface Section {
    sectionNumber: string
    sessionsCount: number
    places: PlaceData[]
}

export interface SectionGeo {
    sectionNumber: string
    places: PlaceCompletionGeo[]
}


// Session

export interface SessionMin extends LCSModel {
    date: Date
    status: SessionStatus
    fomulCount: number
    trackDistanceCount: number
    flagged: boolean
}

export interface Session extends SessionMin, LCSModel {
    note: string
    endTime?: Date
}

export interface AnnotationSession extends LCSModel {
    sessionID: number
    user: User
    start: Date
    end: Date
    comment?: string
}

export interface AnnotationSessionWData extends AnnotationSession {
    fomuls: FomulMin[]
    trackDistances: TrackDistanceMin[]
}



// TrackLink

export interface TrackLinkBase extends LCSModel {
    fromPlaceID: number
    fromNodeID: number
    toPlaceID: number
    toNodeID: number
    linkNumber: number

    fromPlace: string 
    fromNode: string
    toPlace: string 
    toNode: string
    trackNumber?: string

    linkLength: number
    section: string
    direction?: string

    fromKm: number
    fromM: number
    toKm: number
    toM: number

    extensionFormula: TrackLinkExtension
    profile: TrackLinkProfile
}

export interface TrackLink extends TrackLinkBase, LCSModel { 
    version: number
    updated: Date
    status: TrackLinkStatus

    completion?: Completion
    flag?: Flag
    exportOverrides: ExportOverride[]
}

export interface TrackLinkSignature {
    section: string
    fromPlace: string
    fromNode: string
    toPlace: string
    toNode: string
    trackNumber?: string
}

export interface TrackLinkMin extends LCSModel {
    fromPlace: string 
    fromNode: string
    toPlace: string
    toNode: string
    trackNumber?: string
    direction?: string
    section: string
    linkLength: number

    flag?: Flag
    completion?: Completion
    extensionFormula: TrackLinkExtension
    profile: TrackLinkProfile
    status: TrackLinkStatus
}




// Fomul 

export interface FomulBase {
    trackLinkID: number
    sessionID: number

    atKm: number
    atM: number

    northing: number
    easting: number
    gpsFix: number
    satellites: number
    pdop: number

    type: string
    addInfo: string
    note: string
    radius: number
    station?: string

    points: number[]

    frame: number
    isCopy?: boolean

    toFrame?: number
    offset: number[]
    posDir: boolean
    attributes?: string
    softwareVer: string
}

export interface Fomul extends FomulBase, LCSModel {
    version: number
    updated: Date
    status: DataStatus
    exportStatus: ExportStatus

    trackLink: TrackLinkMin
    session: SessionMin

    prevVersionID?: number
    nextVersionID?: number

    longitude: number
    latitude: number    
}

export interface FomulMin extends LCSModel {
    type: string
    atKm: number
    atM: number
    status: DataStatus  
    trackLinkID: number
    isCopy?: boolean;
}

export interface DeletedFomul extends FomulMin {
    updated: Date
}

export interface FomulGeo extends FomulMin {
    longitude: number
    latitude: number

    northing: number
    easting: number
    gpsFix: number
    satellites: number
    pdop: number
}
    


// TrackDistance

export interface TrackDistanceBase {
    sessionID: number
    refTrackLinkID: number
    objTrackLinkNumber: string

    atKm: number
    atM: number

    horDist: number
    verDist: number
    refElev: number
    objElev: number

    northing: number
    easting: number
    gpsFix: number
    satellites: number
    pdop: number

    points: number[]

    frame: number
    offset: number[]
    posDir: boolean
    attributes?: string
    softwareVer?: string
}

export interface TrackDistance extends TrackDistanceBase, LCSModel {
    version: number
    updated: Date
    status: DataStatus
    exportStatus: ExportStatus

    refTrackLink: TrackLinkMin
    session: SessionMin

    prevVersionID?: number
    nextVersionID?: number

    longitude: number
    latitude: number    
}

export interface TrackDistanceMin extends LCSModel {
    atKm: number
    atM: number
    objTrackLinkNumber: string
    horDist: number
    verDist: number
    status: DataStatus  
    refTrackLinkID: number
}

export interface DeletedTrackDistance extends TrackDistanceMin {
    updated: Date
}

export interface TrackDistanceGeo extends TrackDistanceMin {
    longitude: number
    latitude: number

    northing: number
    easting: number
    gpsFix: number
    satellites: number
    pdop: number
}



// Full models

export interface TrackLinkFull extends TrackLink {
    fomuls: Fomul[]
    trackDistances: TrackDistance[]
}

export interface SessionFull extends Session {
    fomuls: Fomul[]
    trackDistances: TrackDistance[]
}

export interface TrackLinkMinWData extends TrackLinkMin {
    fomuls: FomulMin[]
    trackDistances: TrackDistanceMin[]
}

export interface SessionMinWData extends SessionMin {
    fomuls: FomulMin[]
    trackDistances: TrackDistanceMin[]
}

export interface PlaceFull extends Place {
    sessions: SessionMin[];
    trackLinks: TrackLinkMinWData[];
    completion?: PlaceCompletion
}

export interface LineFull extends Line {
    sessions: SessionMin[];
    trackLinks: TrackLinkMinWData[];
    completion?: PlaceCompletion
}

export interface TrackLinkGeo extends TrackLinkMin {
    fomuls: FomulGeo[]
    trackDistances: TrackDistanceGeo[]
}

export interface SessionGeo extends SessionMin {
    fomuls: FomulGeo[]
    trackDistances: TrackDistanceGeo[]
}

export interface PlaceGeo extends Place {
    fomuls: FomulGeo[]
    trackDistances: TrackDistanceGeo[]
}

export interface LineGeo extends Line {
    fomuls: FomulGeo[]
    trackDistances: TrackDistanceGeo[]
}



//Audit Models

export interface AuditWarning {
    isError: boolean;
    message: String;
    type: AuditWarningType;
    affectedDataType: AuditDataType;
    affectedData: {String: any} | FomulMin | TrackLinkMin;
}

export interface AuditMetadata {
    section: String;
    fileNamePrev: String;
    fileNameCurr: String;
    auditDuration: String;
    auditDate: String;
    changedFomuls: String;
    warningAmount: String;
}

export interface AuditResponse extends LCSModel {
    metadata: AuditMetadata;
    warnings: AuditWarning[];
}
