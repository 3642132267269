import { SiteRoute } from "../site/routes";
import { Session, Fomul, TrackDistance, TrackLink } from "../api/LCSModels";


export default class ShareHandler {

    public static INITIAL_LOAD: boolean = true;


    /**
     * Get the current GeoDoc URL from 
     * environment variables. 
     */
    private static getURL = (): string => {
        return process.env.REACT_APP_LCS_URL;
    }

    /**
     * Construct URL link to LCS Online with 
     * query params for LCS Online reference. 
     * @param session 
     */
    private static getResourceURL = (route: string, id: number): string => {
        return `${ShareHandler.getURL()}${route}?id=${id}`;
    }


    public static shareSession = (session: Session): string => {
        return ShareHandler.getResourceURL(SiteRoute.Session, session.id);
    }

    public static shareFomul = (fomul: Fomul): string => {
        return ShareHandler.getResourceURL(SiteRoute.Fomul, fomul.id);
    }

    public static shareTrackDistance = (trackDistance: TrackDistance): string => {
        return ShareHandler.getResourceURL(SiteRoute.TrackDistance, trackDistance.id);
    }

    public static shareTrackLink = (trackLink: TrackLink): string => {
        return ShareHandler.getResourceURL(SiteRoute.TrackLink, trackLink.id);
    }
}


