import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { SessionMin } from "../api/LCSModels";

import Color from "../resources/colors";
import SessionIcon from "../resources/images/session-icon.svg";

import Overlay from "./Overlay";
import NoDataComponent from "./NoDataComponent";
import LoadingComponent from "./LoadingComponent";

import SessionContainer from "./data/SessionContainer";

import { HFlex, VFlex, IconButton } from "../pandora/styled";
import { ConfirmationPopupAnimation } from "../pandora/animations";


const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;

    width: 60%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;

    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;

    .border-container {
        padding: 15px;
        border-radius: 5px;
        border: solid 2px ${Color.lightGrey};
    }
`

const SessionListContainer = styled(VFlex)`
    width: auto;
    height: 40vh;
    overflow-y: auto;

    h4 {
        margin-bottom: 10px;
    }
`

const OpenButton = styled(IconButton)`
    text-align: center; 
    text-indent: 25px;
    width: auto;
    margin-right: 0;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
`


interface SessionSelectorProps {
    selectSession: (session: SessionMin) => void;
    buttonTitle: string;
    icon: string;
    buttonStyle?: React.CSSProperties;
}

const SessionSelector = (props: SessionSelectorProps): React.ReactElement => {

    // State
    const { selectSession, buttonTitle, buttonStyle, icon } = props;
    const [showSelectorPopup, setShowSelectorPopup] = useState<boolean>(false);

    // Effects

    const select = (session: SessionMin) => {
        selectSession(session);
        setShowSelectorPopup(false);
    }

    const showSelector = () => setShowSelectorPopup(true);

    const hideSelector = () => setShowSelectorPopup(false);

    // Actions
    return (
        <div>
            <OpenButton 
                color=      {Color.darkRed}
                hoverColor= {Color.lightRed}
                icon=       {icon}
                onClick=    {showSelector}
                style=      {buttonStyle ?? {}}
            >{buttonTitle}</OpenButton>
            {showSelectorPopup &&
                <CompletionSessionSelectorPopup 
                    selectSession=  {select}
                    close=          {hideSelector}
                />
            }
        </div>

    )
}

export default SessionSelector;



interface CompletionSessionSelectorPopupProps {
    selectSession: (session: SessionMin) => void;
    close: Function;
}

/**
 * Track link selector popup for selecting 
 * track links for batch update. Allows place signature
 * search (fromPlace, toPlace)
 */
const CompletionSessionSelectorPopup = (props: CompletionSessionSelectorPopupProps): React.ReactElement => {

    // State
    const { selectSession, close } = props;

    const [sessions, setSessions] = useState<SessionMin[]>(null);

     // Effects
     useEffect(() => {
        getSessions();
     }, [])

    // Actions

    /**
     * Request all sessions
     */
    const getSessions = async () => {
        try {
            // Request track links for signature
            const payload = {full: false}
            const _sessions: SessionMin[] = await Thomas.request(APIRequest.getAllSessions, payload) as SessionMin[]
            setSessions(_sessions);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            console.log(message)
        }
    }

    return (
        <Overlay 
            css=        {"z-index: 12;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer onClick={(e) => e.stopPropagation()}>
                    <HFlex style={{alignItems: "center", marginBottom: 20}}>
                        <img src={SessionIcon} style={{width: 20, height: 20, marginRight: 15}} />
                        <h2>Select Session</h2>
                    </HFlex>
                    <SessionListContainer className="border-container">
                        {sessions === null && 
                            <LoadingComponent message="Fetching Track Links..." />
                        }
                        {sessions !== null && sessions.length === 0 &&
                            <NoDataComponent message={`No sessions were found.`} />
                        }
                        {sessions !== null && sessions.length !== 0 &&
                            sessions.map((s, index) => 
                                <SessionContainer 
                                    key=          {`s-${index}`}
                                    session=      {s}
                                    selectAction= {() => selectSession(s)}
                                    style=        {{marginTop: 5}}
                                />
                            )
                        }
                    </SessionListContainer>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}


