import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { TrackLinkMin } from "../../api/LCSModels";
import LocalStorage, { LocalStorageKey } from "../../site/localStorage";

import PLACE_NAMES from "../../pandora/place-names";

import Color from "../../resources/colors";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SearchIcon from "../../resources/images/magnifying-glass.png";

import Overlay from "../Overlay";
import NoDataComponent from "../NoDataComponent";
import LoadingComponent from "../LoadingComponent";
import PlacesDataList from "../data-lists/PlacesDataList";

import { GenericPopupProps } from "../GenericPopup";

import TrackLinkContainer from "../data/TrackLinkContainer";

import { HFlex, VFlex, IconButton, Input } from "../../pandora/styled";
import { ConfirmationPopupAnimation, PulseAnimation } from "../../pandora/animations";



const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;
    width: 60%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;

    p {
        font-size: 12px;        
    }

    .phone-link {
        display: inline-block;
        color: white;
        font-weight: bold;
        margin: 0px 5px;
        transition: 0.2s cubic-bezier(.71,-1.54,.83,.67);
    }

    .phone-link: hover {
        transform: scale(1.5) rotateZ(-20deg);
        color: ${Color.green};
    }

    .title {
        width: fit-content;
        padding: 10px;
        border-radius: 5px;
        background-color: ${Color.darkGrey};
    }

    .container {
        padding: 15px;
        border-radius: 5px;
        background-color: ${Color.darkGrey}
    }

    .container p {
        margin: 0px;
    }

    .border-container {
        padding: 15px;
        border-radius: 5px;
        border: solid 2px ${Color.lightGrey};
    }

    .offer {
        display: inline-block;
        color: ${Color.green};
        font-weight: bold;
        animation: ${PulseAnimation} 1s infinite;
        text-transform: uppercase;
        margin: 0px 5px;
    }
`

const TrackLinkListContainer = styled(VFlex)`
    width: auto;
    height: 40vh;
    overflow-y: auto;

    h4 {
        margin-bottom: 10px;
    }
`

const PlaceInput = styled(Input)`
    height: 40px;
    width: 200px;
    background-color: ${Color.darkGrey};
    margin-right: 10px;
`


interface PropertyTrackLinkSelectorProps {
    selectedTrackLinkID?: number;
    trackLinkIDKey: string;
    update: (property: string, value: any) => void;
    setPopup: (popup: GenericPopupProps) => void;
}

/**
 * Selector component in search query result
 * for Fomuls & TrackDistances to enable 
 * batch track link ID reference update. 
 */
const PropertyTrackLinkSelector = (props: PropertyTrackLinkSelectorProps): React.ReactElement => {

    // State
    const { 
        selectedTrackLinkID, 
        trackLinkIDKey,
        update,
        setPopup
     } = props;

    const [showSelectorPopup, setShowSelectorPopup] = useState<boolean>(false);

    // Effects

    /**
     * Select track link to store in batch
     * data update payload. 
     */
    const selectTrackLink = (tl: TrackLinkMin) => {
        update(trackLinkIDKey, tl.id);
        setShowSelectorPopup(false);
    }

    const getTitle = (): string => selectedTrackLinkID === null ? `Select Track Link` : `Track Link with ID ${selectedTrackLinkID} selected.`

    const showSelector = () => setShowSelectorPopup(true);

    const hideSelector = () => setShowSelectorPopup(false);

    // Actions
    return (
        <div>
            <IconButton 
                color=      {selectedTrackLinkID !== null ? Color.extraDarkGrey : Color.blue}
                hoverColor= {selectedTrackLinkID !== null ? Color.extraLightGrey : Color.lightBlue}
                icon=       {TrackLinkIcon}
                onClick=    {showSelector}
                title=      {`Select Track Link`}
                style=      {{
                    textAlign: "center", 
                    textIndent: 25,
                    width: "auto", 
                    marginRight: 0, 
                    fontWeight: "normal"
                }}
            >{getTitle()}</IconButton>
            {showSelectorPopup &&
                <PropertyTrackLinkSelectorPopup 
                    selectedTrackLinkID=    {selectedTrackLinkID}
                    selectTrackLink=        {selectTrackLink}
                    setPopup=               {setPopup}
                    close=                  {hideSelector}
                />
            }
        </div>

    )

}



interface PropertyTrackLinkSelectorPopupProps {
    selectedTrackLinkID?: number;
    selectTrackLink: (tl: TrackLinkMin) => void;
    setPopup: (popup: GenericPopupProps) => void;
    close: Function;
}

/**
 * Track link selector popup for selecting 
 * track links for batch update. Allows place signature
 * search (fromPlace, toPlace)
 */
const PropertyTrackLinkSelectorPopup = (props: PropertyTrackLinkSelectorPopupProps): React.ReactElement => {

    /**
     * Retrieve cached fromPlace, toPlace search values. 
     */
    const getCachedSearch = (key: string): string => {
        const cache = LocalStorage.get(LocalStorageKey.PropertyTrackLinkIDSearch)
        if (cache == null) return "";
        return cache[key];
    }

    // State
    const { 
        selectTrackLink,
        setPopup, 
        close
     } = props;

    const [fromPlace, setFromPlace] = useState<string>(getCachedSearch("fromPlace"));
    const [toPlace, setToPlace] = useState<string>(getCachedSearch("toPlace"));
    const [trackLinks, setTrackLinks] = useState<TrackLinkMin[]>(null);

     // Effects
     useEffect(() => {
        searchTrackLinks();
     }, [])

    // Actions

    /**
     * Request TrackLinks for the specified signature in search. 
     */
    const getTrackLinks = async (_fromPlace: string, _toPlace: string) => {
        try {
            // Request track links for signature
            const payload = {fromPlace: _fromPlace, toPlace: _toPlace}
            const _trackLinks: TrackLinkMin[] = await Thomas.request(APIRequest.getTrackLinksForPlaceSignature, payload) as TrackLinkMin[]
            setTrackLinks(_trackLinks);
            
            // Cache search places
            cacheSearch();
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Get place signature code for its name. 
     */
    const findPlaceSignature = (name: string): string => {
        const signature = Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === name) ?? name;
        return signature
    }

    /**
     * Trigger track link search based on 
     * fromPlace, toPlace search inputs. 
     */
    const searchTrackLinks = () => {
        setTrackLinks(null);

        const _fromPlace = findPlaceSignature(fromPlace);
        const _toPlace = findPlaceSignature(toPlace);
        getTrackLinks(_fromPlace, _toPlace);
    }

    /**
     * Cache search values to LocalStorage. 
     */
    const cacheSearch = () => {
        const cache = {fromPlace: fromPlace, toPlace: toPlace}
        LocalStorage.set(LocalStorageKey.PropertyTrackLinkIDSearch, cache)
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            searchTrackLinks();
        }
    }

    return (
        <Overlay 
            css=        {"z-index: 12;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer onClick={(e) => e.stopPropagation()}>
                    <HFlex style={{alignItems: "center", marginBottom: 20}}>
                        <img src={TrackLinkIcon} style={{width: 20, height: 20, marginRight: 15}} />
                        <h2>Select Track Link</h2>
                    </HFlex>
                    <HFlex style={{alignItems: "center", marginBottom: 20}}>
                        <PlaceInput
                            type=           "text"
                            placeholder=    "From place..."
                            value=          {fromPlace}
                            onChange=       {(e) => setFromPlace(e.target.value)}
                            list=           {"place-names-data-list"}
                            onKeyPress=     {(e) => onKeyPress(e)}
                        />
                        <PlaceInput
                            type=           "text"
                            placeholder=    "To place..."
                            value=          {toPlace}
                            onChange=       {(e) => setToPlace(e.target.value)}
                            list=           {"place-names-data-list"}
                            onKeyPress=     {(e) => onKeyPress(e)}
                        />
                        <PlacesDataList id={"place-names-data-list"} />
                        <IconButton 
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            icon=       {SearchIcon}
                            onClick=    {searchTrackLinks}
                        >Search</IconButton>
                    </HFlex>
                    <TrackLinkListContainer className="border-container">
                        {trackLinks === null && 
                            <LoadingComponent message="Fetching Track Links..." />
                        }
                        {trackLinks !== null && trackLinks.length === 0 &&
                            <NoDataComponent message={`Track Link search yielded no results.`} />
                        }
                        {trackLinks !== null && trackLinks.length !== 0 &&
                            trackLinks.map((tl, index) => 
                                <TrackLinkContainer 
                                    key=                {`tlc-${index}`}
                                    trackLink=          {tl}
                                    showDatesOption=    {false}
                                    selectAction=       {selectTrackLink}
                                    css=                {{marginBottom: 5}}
                                />
                            )
                        }
                    </TrackLinkListContainer>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}


export default PropertyTrackLinkSelector;