import React, { useState, useEffect} from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Section, PlaceData } from "../../api/LCSModels";

import Color from "../../resources/colors";
import { getCompletionProgressColor, formatKilometers } from "../../pandora/pandora";

import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";
import FomulIcon from "../../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../../resources/images/track-distance-icon.svg";
import SectionIcon from "../../resources/images/section-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";
import LineIcon from "../../resources/images/line-icon.svg";

import YearSelector from "../YearSelector";
import PlaceDataListContainer from "./PlaceListDataContainer";
import NoDataComponent from "../NoDataComponent";

import { HFlex, VFlex, MetaDataChildItem, Tag } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations";

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};
`

const ContentGrid = styled(ContentDiv)`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    column-gap: 10px;
    row-gap: 10px;

    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    background-color: ${Color.extraDarkGrey};
    width: 100%;
    height: 300px;

    ${Tag} {
        cursor: auto;
        font-weight: normal;
    }
`


const SectionContainer = styled(VFlex)`
    height: 100%;
    padding: 0px;
    box-sizing: border-box;
    overflow-y: auto;
    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    .section { 
        font-weight: normal;
    }

    .signature {
        background-color: ${Color.extraDarkGrey};
        padding: 10px;
        border-radius: 5px;
        margin-right: 15px;
    }

    .data-container {
        opacity: 0;

        animation: ${FadeAnimation} 0.5s ease 1;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        width: 0px;
    } 
`

const CompletionProgressBarContainer = styled.div`
    width: 90px;
    height: auto;
    text-align: center; 
    margin-right: 20px;

    h5 {
        margin-top: 10px;
        font-weight: normal;
    }
`


interface SectionMetaData {
    placeCount: number;
    lineCount: number;
    completedPlacesCount: number;
    completedLinesCount: number;

    sessions: number;
    fomuls: number;
    trackDistances: number;
    
    trackLinks: number;
    completedTrackLinks: number;
    nonCompletedTrackLinks: number;
    partiallyCompletedTrackLinks: number;

    totalTrackLinksLength: number
    completedTrackLinksLength: number,
    remainingTrackLinksLength: number
}

interface SectionDataContainerProps {
    selectedSection: Section;
    selectedYear: number | null;
    setSelectedYear: (value: number | null) => void;
    selectPlace: (place: PlaceData) => void;
}

const SectionDataContainer = (props: SectionDataContainerProps): React.ReactElement => {

    const getCompletionProgress = (): number => {
        if (sectionMetaData === null || sectionMetaData.trackLinks === 0)
            return 0;
        return (sectionMetaData.completedTrackLinks / sectionMetaData.trackLinks) * 100
    }

    const getMeasurementProgress = (): number => {
        if (sectionMetaData === null || sectionMetaData.trackLinks === 0)
            return 0;
        const progress = +(sectionMetaData.completedTrackLinksLength / sectionMetaData.totalTrackLinksLength).toFixed(2) * 100
        return progress
    }

    const getSectionMetaData = (): SectionMetaData => {
        const places = selectedSection.places.filter(p => p.fromPlace === p.toPlace)
        const lines = selectedSection.places.filter(l => l.fromPlace !== l.toPlace)

        const placeCount = places.length
        const lineCount = lines.length
        const completedPlacesCount = places.filter(p => p.completion !== null).length
        const completedLinesCount = lines.filter(l => l.completion !== null).length

        const fomuls = selectedSection.places.map(p => p.fomulsCount).reduce((a, b) => a + b, 0)
        const trackDistances = selectedSection.places.map(p => p.trackDistancesCount).reduce((a, b) => a + b, 0)
        const sessions = selectedSection.sessionsCount

        const trackLinks = selectedSection.places.map(p => p.trackLinksCount).reduce((a, b) => a + b, 0)
        const completedTrackLinks = selectedSection.places.map(p => p.completedTrackLinksCount).reduce((a, b) => a + b, 0)
        const nonCompletedTrackLinks = selectedSection.places.map(p => p.trackLinksCount - (p.completedTrackLinksCount + p.partiallyCompletedTrackLinksCount)).reduce((a, b) => a + b, 0)
        const partiallyCompletedTrackLinks = selectedSection.places.map(p => p.partiallyCompletedTrackLinksCount).reduce((a, b) => a + b, 0)

        const totalTrackLinksLength = Math.round(selectedSection.places.map(p => p.totalTrackLinksLength).reduce((a, b) => a + b, 0))
        const completedTrackLinksLength = Math.round(selectedSection.places.map(p => p.completedTrackLinksLength).reduce((a, b) => a + b, 0))
        const remainingTrackLinksLength = totalTrackLinksLength - completedTrackLinksLength

        return {
            placeCount: placeCount,
            lineCount: lineCount,
            completedPlacesCount: completedPlacesCount,
            completedLinesCount: completedLinesCount,
            fomuls: fomuls,
            trackDistances: trackDistances,
            sessions: sessions,
            trackLinks: trackLinks,
            completedTrackLinks: completedTrackLinks,
            nonCompletedTrackLinks: nonCompletedTrackLinks,
            partiallyCompletedTrackLinks: partiallyCompletedTrackLinks,
            totalTrackLinksLength: totalTrackLinksLength,
            completedTrackLinksLength: completedTrackLinksLength,
            remainingTrackLinksLength: remainingTrackLinksLength
        }
    }

    // State
    const { selectedSection, selectedYear, setSelectedYear, selectPlace } = props;
    
    const [sectionMetaData, setSectionMetaData] = useState<SectionMetaData | null>(getSectionMetaData());

    const [completionProgress, setCompletionProgress] = useState<number>(getCompletionProgress());
    const [measurementProgress, setMeasurementProgress] = useState<number>(getMeasurementProgress());


    // Effects
    useEffect(() => {
        if (selectedSection === null) return;
        const metadata = getSectionMetaData();
        setSectionMetaData(metadata);
    }, [selectedSection])

    useEffect(() => {
        setCompletionProgress(getCompletionProgress());
        setMeasurementProgress(getMeasurementProgress());
    }, [sectionMetaData])


    return (    
        <SectionContainer>
            <HFlex style={{alignItems: "center", justifyContent: "space-between"}}>
                <HFlex style={{width: "fit-content", alignItems: "center"}}>
                    <img 
                        src=    {SectionIcon} 
                        style=  {{width: 30, height: 30, marginRight: 20}} 
                    />
                    <h1 className="section">Section <b>{selectedSection.sectionNumber}</b></h1>
                </HFlex>
                <YearSelector 
                    selectedYear=       {selectedYear}
                    select=             {setSelectedYear}
                    includeFutureYears= {false}
                />
            </HFlex>
            <HFlex style={{margin: "20px 0px"}}>
                <ContentGrid className="top-box" style={{marginRight: 10}}>
                    <MetaDataChildItem style={{justifyContent: "space-between"}}>
                        <HFlex>
                            <img src={PlaceIcon} />
                            <h5><strong>{sectionMetaData.placeCount}</strong> Places</h5>
                        </HFlex>
                        <Tag color={getCompletionProgressColor(sectionMetaData.completedPlacesCount/sectionMetaData.placeCount)}>
                            {`${sectionMetaData.completedPlacesCount} / ${sectionMetaData.placeCount}`} completed
                        </Tag>
                    </MetaDataChildItem>
                    <MetaDataChildItem style={{justifyContent: "space-between"}}>
                        <HFlex>
                            <img src={LineIcon} />
                            <h5><strong>{sectionMetaData.lineCount}</strong> Lines</h5>
                        </HFlex>
                        <Tag color={getCompletionProgressColor(sectionMetaData.completedLinesCount/sectionMetaData.lineCount)}>
                            {`${sectionMetaData.completedLinesCount} / ${sectionMetaData.lineCount}`} completed
                        </Tag>
                    </MetaDataChildItem>
                    <MetaDataChildItem>
                        <img src={FomulIcon} />
                        <h5><strong>{sectionMetaData.fomuls}</strong> Fomuls</h5>
                    </MetaDataChildItem>
                    <MetaDataChildItem>
                        <img src={TrackDistanceIcon} />
                        <h5><strong>{sectionMetaData.trackDistances}</strong> Track Distances</h5>
                    </MetaDataChildItem>
                    <MetaDataChildItem>
                        <img src={SessionIcon} />
                        <h5><strong>{sectionMetaData.sessions}</strong> Sessions</h5>
                    </MetaDataChildItem>
                    <MetaDataChildItem>
                        <img src={TrackLinkIcon} />
                        <h5><strong>{sectionMetaData.trackLinks}</strong> TrackLinks</h5>
                    </MetaDataChildItem>
                    <MetaDataChildItem style={{backgroundColor: "transparent", justifyContent: "space-between"}}>
                        <VFlex style={{width: "50%", height: "100%", justifyContent: "center"}}>
                            <h5 className="completed">
                                <strong>{sectionMetaData.completedTrackLinks}</strong> Completed
                            </h5>
                            {sectionMetaData.partiallyCompletedTrackLinks !== 0 && 
                                <h5 className="partially-completed">
                                    <strong>{sectionMetaData.partiallyCompletedTrackLinks}</strong> Partially completed
                                </h5>
                            }
                            <h5 className="non-completed">
                                <strong>{sectionMetaData.nonCompletedTrackLinks}</strong> Not completed
                            </h5>
                        </VFlex>
                        <CompletionProgressBarContainer>
                            <CircularProgressbar 
                                value={completionProgress} 
                                text={`${sectionMetaData.completedTrackLinks}/${sectionMetaData.trackLinks}`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathColor: getCompletionProgressColor(completionProgress),
                                    textColor: '#FFF',
                                    trailColor: Color.lightGrey,
                                    backgroundColor: '#3e98c7',
                                })}
                            />
                            <h5>Track Links</h5>
                        </CompletionProgressBarContainer>
                    </MetaDataChildItem>
                    <MetaDataChildItem style={{backgroundColor: "transparent", justifyContent: "space-between"}}>
                        <VFlex style={{width: "50%", height: "100%", justifyContent: "space-around"}}>
                            <h5><strong>{formatKilometers(sectionMetaData.totalTrackLinksLength)}</strong> km total</h5>
                            <h5><strong>{formatKilometers(sectionMetaData.completedTrackLinksLength)}</strong> km measured</h5>
                            <h5><strong>{formatKilometers(sectionMetaData.remainingTrackLinksLength)}</strong> km left</h5>
                        </VFlex>
                        <CompletionProgressBarContainer>
                            <CircularProgressbar 
                                value={measurementProgress} 
                                text={`${formatKilometers(sectionMetaData.completedTrackLinksLength)}/${formatKilometers(sectionMetaData.totalTrackLinksLength)}`}
                                styles={buildStyles({
                                    textSize: '12px',
                                    pathColor: getCompletionProgressColor(measurementProgress),
                                    textColor: '#FFF',
                                    trailColor: Color.lightGrey,
                                    backgroundColor: '#3e98c7',
                                })}
                            />
                            <h5>Kilometers</h5>
                        </CompletionProgressBarContainer>
                    </MetaDataChildItem>
                </ContentGrid>
            </HFlex>
            <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                {selectedSection.places.map((p, index) => 
                    <PlaceDataListContainer 
                        key=    {`place-${index}`}
                        place=  {p}
                        onClick={selectPlace}
                    />
                )}
                {selectedSection.places.length === 0 &&
                    <NoDataComponent 
                        message={`No places found in section ${selectedSection.sectionNumber}`} 
                    />
                }
            </ContentDiv>
        </SectionContainer>
    )
}


export default SectionDataContainer;

