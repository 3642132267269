import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import { SiteRoute } from "../site/routes";
import QueryParser from "../site/queryParser";
import ShareHandler from "../site/share";
import FileSaver from "../site/fileSaver";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import FomulTypes from "../pandora/fomul-types";
import { Fomul, DataStatus, ExportStatus, TrackLinkProfile, TrackLinkMin } from "../api/LCSModels";

import { Point } from "../pandora/pandora";
import ProfileHandler from "../pandora/ProfileHandler";

import { DataStatusColorMap, ExportStatusColorMap } from "../pandora/pandora";
import Color from "../resources/colors";
import FomulIcon from "../resources/images/fomul-icon.svg";
import CopyIcon from "../resources/images/copy-icon.svg";

import ShareButton from "../components/ShareButton";
import ExportButton from "../components/ExportButton";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../components/ConfirmationPopup";

import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import MapComponent from "../components/MapComponent";
import Switch from "../components/Switch";
import TrackLinkContainer from "../components/data/TrackLinkContainer";
import SessionContainer from "../components/data/SessionContainer";
import IconTag from "../components/IconTag";

import TrackLinkSelector from "../components/data/TrackLinkSelector";
import TrackLinkProfileSelector from "../components/data/TrackLinkProfileSelector";
import Plot from "../components/data/Plot";

import { 
    PageIcon, HFlex, ViewContainer, 
    VFlex, Tag, Input, RippleButton, BackNavButton,
    DataViewContainer
} from "../pandora/styled";
import { ScaleAnimation } from "../pandora/animations";



const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const Container = styled(ViewContainer)`
    overflow: visible;
`

const DataInputContainer = styled(HFlex)`
    margin-top: 5px;
    align-items: center;

    h6 {
        width: 80px;
        margin-right: 10px;
        text-transform: capitalize;
    }
`

const ActionButton = styled(RippleButton)`
    animation: ${ScaleAnimation} 0.2s linear 1;
`

const PointList = styled(VFlex)`
    height: 300px;
    max-width: 250px;
    min-width: 150px;

    padding: 10px;
    margin-left: -5px;
    margin-right: 10px;
    border-radius: 5px;

    overflow-y: auto;
    background: ${Color.extraDarkGrey}; 

    ::-webkit-scrollbar {
        width: 0px;
    }   
`



const FomulView = (): React.ReactElement => {

    // State
    const fieldnameMap = {
        "softwareVer": "LCS Version"
    }

    const fomulID = QueryParser.getQueryArg("id");
    const [originalFomul, setOriginalFomul] = useState<Fomul | null | undefined>(undefined);
    const [fomul, setFomul] = useState<Fomul | null | undefined>(undefined);
    const [changedFields, setChangedFields] = useState<Record<string, any> | {}>({});

    const [points, setPoints] = useState<Point[] | null>(null);
    const [profilePoints, setProfilePoints] = useState<Point[] | null>(null);
    const [profile, setProfile] = useState<TrackLinkProfile | null>(null);

    const [showTrackLinkSelector, setShowTrackLinkSelector] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

    // Effects

    /**
     * On fomulID update, which occurs when 
     * Fomul is updated, reset current Fomul state
     * and request current Fomul from API. 
     */
    useEffect(() => { 

        setFomul(undefined);
        setOriginalFomul(undefined);
        setChangedFields({});
        getFomul() 

        document.getElementById("content-container").scroll(0,0)
    }, [fomulID])

    /**
     * Format and retrieve data for 
     * Fomul points and Track Link profile on 
     * Fomul update. 
     */
    useEffect(() => {
        if (fomul === undefined || fomul === null) return;

        // Retrieve and store Fomul points
        let points: Point[] = [];
        for (let i = 0; i < fomul.points.length; i=i+2) {
            points.push({x: fomul.points[i], y: fomul.points[i + 1]})
        }
        setPoints(points);

        // Set profile
        setProfile(fomul.trackLink.profile);

    }, [fomul])

    /**
     * Recalculate Track Link profile points
     * on profile selection update. 
     */
    useEffect(() => {
        if (fomul === undefined || fomul === null) return;

        // Retrieve and store profile points
        const _profilePoints = ProfileHandler.getCalculatedProfile(profile, 
                                                                   fomul.trackLink.extensionFormula, 
                                                                   fomul.radius, 
                                                                   fomul.posDir)
        setProfilePoints(_profilePoints);
    }, [profile])
    

    // Actions

    /**
     * Request Fomul from API. 
     */
    const getFomul = async () => { 
        try {
            if (fomulID === null) 
                throw new Error("Could not parse ID from query parameters.")

            // Request sessions from Thomas. 
            const payload = {id: fomulID}
            const _fomul: Fomul = await Thomas.request(APIRequest.getFomul, payload) as Fomul
            
            // Store Fomul as original version
            // and current version 
            setOriginalFomul(_fomul);
            setFomul(_fomul);

            // Set doc title
            document.title = `#${_fomul.id} ${FomulTypes[_fomul.type]} |  LCS Online`
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not fetch Fomul",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 

            setFomul(null);
        }
    }

    /**
     * Update current Fomul property 
     * in state. 
     */
    const updateField = (key: string, value: any) => {
        const _fomul = {...fomul};
        const _changedFields = {...changedFields};
        _fomul[key] = value;
        _changedFields[key] = value;

        setFomul(_fomul);
        setChangedFields(_changedFields);
    }

    /**
     * Update Fomul points property separately, 
     * since the coordinates are formatted in 
     * x, y pairs for UI rendering. 
     */
    const updatePoints = (index: number, key: string, value: number) => {
        const _points = [...points];
        _points[index][key] = value;
        const ps = []
        _points.forEach(p => ps.push(...Object.values(p)));
        updateField("points", ps);
    }

    /**
     * Request Fomul update to API. 
     */
    const updateChanges = async () => {
        try {
            // Request Fomul PUT update
            const payload = {id: fomul.id, ...changedFields}
            const newFomul: Fomul = await Thomas.request(APIRequest.editFomul, payload) as Fomul

            setFomul(newFomul);
            setOriginalFomul(newFomul);
            setChangedFields({});

            // Push route with new ID
            window.history.replaceState({}, document.title, `${window.location.origin}${SiteRoute.Fomul}?id=${newFomul.id}`);
            
            setPopup({
                title: "Fomul updated!",
                message: `Updated ${Object.keys(changedFields)}`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Fomul Track Link reference update
     */
    const updateFomulTrackLink = (trackLink: TrackLinkMin) => {
        const callback = async () => {
            try {
                const payload = {id: fomul.id, trackLinkID: trackLink.id}
                const newFomul: Fomul = await Thomas.request(APIRequest.setFomulTrackLinkRef, payload) as Fomul
    
                setFomul(newFomul);
                setOriginalFomul(newFomul);
                setChangedFields({});
                
                // Push route with new ID
                window.history.replaceState({}, document.title, `${window.location.origin}${SiteRoute.Fomul}?id=${newFomul.id}`);

                setPopup({
                    title: "Updated Track Link!",
                    message: `Updated Fomul Track Link reference.`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
            setShowTrackLinkSelector(false);
        }

        setConfirmation({
            title: "Confirm!",
            message: `Are you sure you want to update the registered Track Link reference on this Fomul?`,
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Reset changed fields to empty and 
     * set current Fomul as original Fomul. 
     */
    const discardChanges = async () => {
        setFomul(originalFomul);
        setChangedFields({});
    }

    /**
     * Request Fomul deletion to API. 
     */
    const deleteFomul = () => {

        const callback = async () => {
            try {
                // Request Fomul deletion 
                const payload = {id: fomul.id}
                const deletedFomul: Fomul = await Thomas.request(APIRequest.deleteFomul, payload) as Fomul
    
                setFomul(deletedFomul);
                setOriginalFomul(deletedFomul);
                setChangedFields({});
                
                setPopup({
                    title: "Fomul deleted!",
                    message: `Deleted Fomul with ID ${deletedFomul.id}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Attention!",
            message: "Are you sure you want to delete this Fomul?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request undo of Fomul deletion to API. 
     */
    const undoFomulDeletion = async () => {
        try {
            // Request Fomul undo deletion 
            const payload = {id: fomul.id}
            const reactivatedFomul: Fomul = await Thomas.request(APIRequest.undoFomulDeletion, payload) as Fomul

            setFomul(reactivatedFomul);
            setOriginalFomul(reactivatedFomul);
            setChangedFields({});
            
            setPopup({
                title: "Fomul reactivated!",
                message: `Reactivated Fomul with ID ${reactivatedFomul.id}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Fomul export to API. 
     */
    const exportFomul = async (config: Record<string, boolean>) => {
        try {
            const payload = {id: fomul.id, ...config}
            const res: AxiosResponse = await Thomas.request(APIRequest.exportFomul, payload, true);

            // Save file to client
            const saveConfig = `${APIRequest.exportFomul.headers["Accept"]}`
            
            FileSaver.save(res.data, 
                            `Export Fomul ${fomul.id}.res`,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of Fomul with ID ${fomul.id} completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <Container>
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            {confirmation !== null && 
                <ConfirmationPopup confirmation={confirmation}/> 
            }
            {fomul === undefined && 
                <LoadingComponent message="Fetching Fomul..." />
            }
            {fomul === null && 
                <NoDataComponent message="Fomul query yielded no result." />
            }
            {fomul !== null && fomul !== undefined && 
                <DataViewContainer>
                    <HFlex style={{
                        alignItems: "center", 
                        justifyContent: "space-between",
                        marginBottom: "20px", 
                        paddingBottom: "30px",
                        borderBottom: "2px solid #21262c"
                    }}>
                        <HFlex>
                            <PageIcon icon={FomulIcon} />
                            <h1 style={{fontWeight: "normal"}}><b>#{fomul.id}</b> {FomulTypes[fomul.type]}</h1>
                        </HFlex>
                        {Object.keys(changedFields).length !== 0 && fomul.status === DataStatus.Active &&
                            <HFlex>
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {discardChanges}
                                    title=      {"Discard updates"}
                                >Discard changes</ActionButton>
                                <ActionButton 
                                    color=      {Color.green} 
                                    hoverColor= {Color.lightGreen}
                                    onClick=    {updateChanges}
                                    title=      {"Save updates"}
                                >Save</ActionButton>
                            </HFlex>
                        }
                    </HFlex>
                    <HFlex style={{
                        justifyContent: "space-between", 
                        borderRadius: "5px", 
                        backgroundColor: Color.darkGrey, 
                        alignItems: "center", 
                        paddingLeft: "15px", 
                        paddingRight: "10px"
                    }}>
                        <HFlex style={{height: 60, alignItems: "center"}}>
                            <h5 style={{marginRight: 15}}>v{fomul.version}</h5>
                            <h5 style={{fontWeight: "normal", marginRight: 15}}><b>updated</b> {moment.utc(fomul.updated).local().format("YYYY-MM-DD HH:mm")}</h5>
                            <Tag 
                                color=      {DataStatusColorMap[fomul.status]} 
                                style=      {{marginRight: 15, cursor: "auto", fontSize: 12}}
                            >{DataStatus[fomul.status]}</Tag>
                            <Tag 
                                color=      {ExportStatusColorMap[fomul.exportStatus]} 
                                style=      {{cursor: "auto",  fontSize: 12}}
                            >{ExportStatus[fomul.exportStatus]}</Tag>
                            {fomul.isCopy &&
                                <IconTag 
                                    text=   "COPY"
                                    icon=   {CopyIcon}
                                    style=  {{marginLeft: 15}}
                                    color=  {Color.orange}
                                />
                            }
                        </HFlex>
                        <HFlex style={{width: "fit-content"}}>
                            {fomul.status === DataStatus.Active && 
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {deleteFomul}
                                    title=      {"Mark Fomul as deleted"}
                                >Delete</ActionButton>
                            }
                            {fomul.status === DataStatus.Deleted && 
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {undoFomulDeletion}
                                    title=      {"Undo Fomul deletion"}
                                >Undo delete</ActionButton>
                            }
                            <ShareButton 
                                link={ShareHandler.shareFomul(fomul)}
                                setPopup={setPopup}
                            />
                            <ExportButton 
                                title=  {"Export Fomul"} 
                                config= {"base"}
                                export= {exportFomul}
                            />
                        </HFlex>
                    </HFlex>
                    <SessionContainer session={fomul.session} />
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "30%", height: 30, marginRight: "20px"}}>
                            <HFlex style={{alignItems: "center"}}>
                                <Input
                                    type=           "number"
                                    placeholder=    "km"
                                    value=          {fomul.atKm}
                                    onChange=       {(e) => updateField("atKm", e.target.value)}
                                    fontSize=       {15}
                                    style=          {{marginRight: 5, width: 60}}
                                    color=          {Object.keys(changedFields).includes("atKm") ? Color.lightGreen : null}
                                    readOnly=       {fomul.status !== DataStatus.Active}
                                />   
                                <h5 style={{width: "fit-content", marginRight: 10, whiteSpace: "nowrap"}}>km</h5>
                                <Input
                                    type=           "number"
                                    placeholder=    "m"
                                    value=          {Math.round(fomul.atM)}
                                    onChange=       {(e) => updateField("atM", e.target.value)}
                                    fontSize=       {15}
                                    style=          {{marginRight: 5}}
                                    color=          {Object.keys(changedFields).includes("atM") ? Color.lightGreen : null}
                                    readOnly=       {fomul.status !== DataStatus.Active}
                                />   
                                <h5>m</h5>
                            </HFlex>
                        </ContentDiv>
                        <TrackLinkContainer 
                            trackLink=          {fomul.trackLink} 
                            showTrackLinkChange={(fomul.status === DataStatus.Active)  ? () => setShowTrackLinkSelector(true) : undefined}
                            minimalColWidth=    {true}
                            showDatesOption=    {false}
                        />
                    </HFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <h4>Data</h4>
                            {["type", "addInfo", "note", "radius", "station"].map(key =>
                                <DataInputContainer>
                                    <h6 style={{marginRight: 10}}>{fieldnameMap[key] || key}</h6>
                                    <Input
                                        type=           "text"
                                        placeholder=    {"-"}
                                        value=          {fomul[key]}
                                        onChange=       {(e) => updateField(key, e.target.value)}
                                        readOnly=       {fomul.status !== DataStatus.Active}
                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                    />   
                                </DataInputContainer>
                            )}
                        </ContentDiv>
                        <ContentDiv style={{width: "50%"}}>
                            <HFlex>
                                <VFlex style={{marginRight: 20}}>
                                    <h4>GPS</h4>
                                    <HFlex>
                                        <VFlex>
                                            {["northing", "easting", "gpsFix", "satellites", "pdop"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{key}</h6>
                                                    <Input
                                                        type=           "text"
                                                        placeholder=    {"-"}
                                                        value=          {fomul[key]}
                                                        onChange=       {(e) => updateField(key, e.target.value)}
                                                        readOnly=       {fomul.status !== DataStatus.Active}
                                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                                    />   
                                                </DataInputContainer>
                                            )}
                                        </VFlex>
                                    </HFlex>
                                </VFlex>
                                <VFlex>
                                    <h4>Meta</h4>
                                    <HFlex>
                                        <VFlex>
                                            {["frame", "toFrame", "attributes", "softwareVer"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{fieldnameMap[key] || key}</h6>
                                                    <Input
                                                        type=           "text"
                                                        placeholder=    {"-"}
                                                        value=          {fomul[key] ?? "null"}
                                                        onChange=       {(e) => updateField(key, e.target.value)}
                                                        readOnly=       {fomul.status !== DataStatus.Active}
                                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                                    />   
                                                </DataInputContainer>
                                            )}
                                            {["isCopy", "posDir"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{key}</h6>
                                                    {fomul[key] !== null && fomul.status === DataStatus.Active &&
                                                        <Switch 
                                                            configKey= {key}
                                                            value=     {fomul[key]} 
                                                            onChange=  {updateField} 
                                                        />
                                                    }
                                                    {fomul[key] === null && <h6>NULL</h6>}
                                                    {fomul.status !== DataStatus.Active && <h6>{String(fomul[key])}</h6>}
                                                </DataInputContainer>
                                            )}
                                        </VFlex>
                                    </HFlex>
                                </VFlex>
                            </HFlex>
                        </ContentDiv>
                    </HFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <h4>Points</h4>
                            <HFlex style={{marginTop: 20}}>
                                <PointList>
                                    <HFlex style={{marginBottom: 5, justifyContent: "space-around"}}>
                                        <h5>X</h5>
                                        <h5>Y</h5>
                                    </HFlex>
                                    {points !== null && points.map((point, index) => 
                                        <HFlex key={`p${index}`} style={{marginTop: 5}}>
                                            <Input
                                                key=            {`${index}-x`}
                                                type=           "number"
                                                min=            {-5000}
                                                max=            {5000}
                                                placeholder=    {"x"}
                                                value=          {point.x}
                                                onChange=       {(e) => updatePoints(index, "x", parseInt(e.target.value))}
                                                style=          {{marginRight: 5, textAlign: "center"}}
                                                readOnly=       {fomul.status !== DataStatus.Active}
                                            />  
                                            <Input
                                                key=            {`${index}-y`}
                                                type=           "number"
                                                min=            {-200}
                                                max=            {7000}
                                                placeholder=    {"y"}
                                                value=          {point.y}
                                                onChange=       {(e) => updatePoints(index, "y", parseInt(e.target.value))}
                                                style=          {{textAlign: "center"}}
                                                readOnly=       {fomul.status !== DataStatus.Active}
                                            />  
                                        </HFlex>
                                    )}
                                </PointList>
                                <VFlex style={{alignItems: "center"}}>  
                                    <TrackLinkProfileSelector 
                                        selectedProfile={profile}
                                        selectProfile={setProfile}
                                    />
                                    <Plot 
                                        points=     {points ?? []}
                                        profile=    {profilePoints}
                                    />
                                </VFlex>
                            </HFlex>
                        </ContentDiv>
                        <ContentDiv style={{width: "50%", minHeight: 200}}>
                            <MapComponent 
                                link=           {SiteRoute.Map + `?fomul=${fomul.id}`}
                                centerPoint=    {[fomul.longitude, fomul.latitude]}
                                dataType=       {"fomul"}
                            />
                        </ContentDiv>
                    </HFlex>
                    {(fomul.prevVersionID !== null || fomul.nextVersionID !== null) &&
                        <ContentDiv style={{marginTop: 20, height: 30, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <h4 style={{marginBottom: 0}}>Versions</h4>
                            <HFlex style={{height: 40}}>
                                {fomul.prevVersionID !== null &&
                                    <BackNavButton 
                                        color=      {Color.darkGrey} 
                                        hoverColor= {Color.extraLightGrey}
                                        text=       "Previous version"
                                        link=       {`${SiteRoute.Fomul}?id=${fomul.prevVersionID}`}
                                        rightSide=  {false}
                                        css=        {{marginLeft: 10}}
                                    />
                                }
                                {fomul.nextVersionID !== null &&
                                    <BackNavButton 
                                        color=      {Color.darkGrey} 
                                        hoverColor= {Color.extraLightGrey}
                                        text=       "Next version"
                                        link=       {`${SiteRoute.Fomul}?id=${fomul.nextVersionID}`}
                                        rightSide=  {true}
                                        css=        {{marginLeft: 10}}
                                    />
                                }
                            </HFlex>
                        </ContentDiv>
                    }
                </DataViewContainer>
            }
            {showTrackLinkSelector && fomul !== null && fomul !== undefined && 
                <TrackLinkSelector 
                    currentTrackLink=   {fomul.trackLink}
                    update=             {updateFomulTrackLink}
                    setPopup=           {setPopup}
                    close=              {() => setShowTrackLinkSelector(false)}
                />
            }
        </Container>
    )   
}


export default FomulView;