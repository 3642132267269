import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { SiteRoute } from "../../site/routes";
import { LineFull, Place } from "../../api/LCSModels";

import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";
import LineIcon from "../../resources/images/line-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";

import PlaceCompletionTag from "./PlaceCompletionTag";
import MapComponent from "../MapComponent";
import SessionTrackLinkList from "../session/SessionTrackLinkList";
import SessionContainer from "../data/SessionContainer";
import NoDataComponent from "../NoDataComponent";

import Color from "../../resources/colors";

import { HFlex, IconButton, Tag } from "../../pandora/styled";
import { 
    PlaceMetaData, PlaceDataType, ContentDiv, 
    PlaceContainer, getPlaceMetaData, getCompletionProgress, getMeasurementProgress 
} from "./common";
import PlaceMetaDataComponent from "./PlaceMetaData";



const PlaceButtonContainer = styled(HFlex)`
    align-items: center;

    width: auto;
    height: 25px;
    padding: 10px 15px;
    border-radius: 5px;    

    background-color: ${Color.extraDarkGrey};

    cursor: pointer;
    transition: 0.2s ease;

    &: hover {
        background-color: ${Color.lightGrey};
    }   

    img {
        widht: 20px;
        height: 20px;
        margin-right: 20px;
    }

    h5 {
        margin-right: 20px;
    }
`


interface PlaceDataContainerProps {
    line: LineFull;
    openPlace: (signature: string) => void;
    completePlace: () => void;
    removePlaceCompletion: () => void;
}   

/**
 * Container for aggregating line data. 
 */
const LineDataContainer = (props: PlaceDataContainerProps): React.ReactElement => {

    // State
    const { line, openPlace, completePlace, removePlaceCompletion } = props;

    const [selectedPlaceDataType, setSelectedPlaceDataType] = useState<PlaceDataType>(PlaceDataType.TrackLinks)
    const [placeMetaData, setPlaceMetaData] = useState<PlaceMetaData | null>(getPlaceMetaData(line));
    
    const completionProgress: number = getCompletionProgress(placeMetaData);
    const measurementProgress: number = getMeasurementProgress(placeMetaData);

    // Effects
    useEffect(() => {
        if (line === null) return;
        const metadata = getPlaceMetaData(line);
        setPlaceMetaData(metadata);
    }, [line])

    // Actions

    /**
     * Get coordinates for line fromPlace and toPlace. 
     */
    const getPlaceCoordinates = (): [number, number][] => {
        return [
            [line.fromPlace.longitude, line.fromPlace.latitude], 
            [line.toPlace.longitude, line.toPlace.latitude]
        ]
    }
    
    return (
        <PlaceContainer>
            <HFlex style={{alignItems: "center", justifyContent: "space-between"}}>
                <HFlex style={{width: "fit-content", alignItems: "center"}}>
                    <img 
                        src=    {LineIcon} 
                        style=  {{width: 30, height: 30, marginRight: 20}} 
                    />
                    <PlaceButton 
                        place=      {line.fromPlace} 
                        openPlace=  {openPlace}
                    />
                    <h3 className="place-separator">-</h3>
                    <PlaceButton 
                        place=      {line.toPlace}
                        openPlace=  {openPlace}
                    /> 
                </HFlex>
                <PlaceCompletionTag
                    completion=         {line.completion}
                    complete=           {completePlace}
                    removeCompletion=   {removePlaceCompletion}
                />  
            </HFlex>
            <HFlex style={{margin: "20px 0px"}}>
                <PlaceMetaDataComponent 
                    placeMetaData=      {placeMetaData}
                    completionProgress= {completionProgress}
                    measurementProgress={measurementProgress}
                />
                <ContentDiv className="top-box">
                    <MapComponent 
                        centerPoint=    {[line.fromPlace.longitude, line.fromPlace.latitude]}
                        dataType=       {"place"}
                        points=         {getPlaceCoordinates()}
                        zoom=           {8}
                        link=           {SiteRoute.Map + `?line=${line.fromPlace.signature}-${line.toPlace.signature}`}
                    />
                </ContentDiv>
            </HFlex>
            <HFlex style={{margin: "20px 0px", height: 50}}>
                <IconButton     
                    color=      {selectedPlaceDataType === PlaceDataType.TrackLinks ? Color.lightGrey : Color.extraDarkGrey}
                    hoverColor= {selectedPlaceDataType === PlaceDataType.TrackLinks ? Color.lightGrey : Color.extraLightGrey}
                    icon=       {TrackLinkIcon}
                    onClick=    {() => setSelectedPlaceDataType(PlaceDataType.TrackLinks)}
                    style=      {{textAlign: "center", textIndent: 10, width: 150}}
                >Track Links</IconButton>
                <IconButton     
                    color=      {selectedPlaceDataType === PlaceDataType.Sessions ? Color.lightGrey : Color.extraDarkGrey}
                    hoverColor= {selectedPlaceDataType === PlaceDataType.Sessions ? Color.lightGrey : Color.extraLightGrey}
                    icon=       {SessionIcon}
                    onClick=    {() => setSelectedPlaceDataType(PlaceDataType.Sessions)}
                    style=      {{textAlign: "center", textIndent: 10, width: 150}}
                >Sessions</IconButton>
            </HFlex>
            {selectedPlaceDataType === PlaceDataType.TrackLinks &&
                <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                    <h3 style={{margin: "15px 0px 20px"}}>Track Links ({line.trackLinks.length})</h3>
                    {line.trackLinks.map(tl => 
                        <SessionTrackLinkList 
                            key=        {tl.id}
                            trackLink=  {tl} 
                        />
                    )}
                    {line.trackLinks.length === 0 &&
                        <NoDataComponent message={`No Track Links found in ${line.fromPlace}`} />
                    }
                </ContentDiv>
            }
            {selectedPlaceDataType === PlaceDataType.Sessions &&
                <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                    <h3 style={{margin: "15px 0px 20px"}}>Sessions ({line.sessions.length})</h3>
                    {line.sessions.map(s => 
                        <SessionContainer 
                            key=        {s.id}
                            session=    {s} 
                            style=      {{backgroundColor: Color.extraDarkGrey, marginTop: 5}}
                        />
                    )}
                    {line.sessions.length === 0 &&
                        <NoDataComponent message={`No sessions found in ${line.fromPlace}`} />
                    }
                </ContentDiv>
            }
        </PlaceContainer>
    )
}

export default LineDataContainer;




interface PlaceButtonProps {
    place: Place;
    openPlace: (signature: string) => void;
}

/**
 * Button pointing to place views. 
 */
const PlaceButton = (props: PlaceButtonProps): React.ReactElement => (
    <PlaceButtonContainer 
        onClick=    {() => props.openPlace(props.place.signature)}
        title=      {`Open ${props.place.name}`}
    >
        <img src={PlaceIcon} />
        <h5>{props.place.name}</h5>
        <Tag 
            color=      {Color.lightGrey} 
        >{props.place.signature}</Tag>
    </PlaceButtonContainer>
)

