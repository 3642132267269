import React from "react";
import styled from "styled-components";


import Color from "../../resources/colors";
import FlagIconWhite from "../../resources/images/flag-icon-white.svg";

import { HFlex } from "../../pandora/styled";



const Container = styled(HFlex)<{flagged: boolean}>`
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin-left: 10px;

    width: fit-content;
    padding: 10px 15px;

    border-radius: 5px;
    background-color: ${props => props.flagged ? Color.red : Color.darkGrey};

    cursor: pointer;
    user-select: none;
    
    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.flagged ? Color.lightRed : Color.extraLightGrey};
        padding: 10px 25px;
    }

    img { 
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`


interface SessionFlagProps {
    flagged: boolean;
    setFlagged: (flagged: boolean) => void;
}

const SessionFlagComponent = (props: SessionFlagProps): React.ReactElement => {
    const { flagged, setFlagged } = props;
    return (
        <Container 
            flagged=    {flagged}
            onClick=    {() => setFlagged(!flagged)}
        >
            <img src={FlagIconWhite} />
            <h5>{flagged ? "Remove flag" : "Set flagged"}</h5>
        </Container>
    )
}

export default SessionFlagComponent;
