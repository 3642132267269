import React from "react";
import styled from "styled-components";

import { AnnotationSession } from "../../api/LCSModels";

import { HFlex } from "../../pandora/styled";
import Color from "../../resources/colors";
import AnnotationAnimation from "../../resources/images/annotation-animation.svg";


const Container = styled(HFlex)<{css?: string}>`
    align-items: center;
    justify-content: center;
    justify-self: flex-end;

    width: fit-content;
    padding: 5px;

    border-radius: 5px;
    background-color: ${Color.darkGrey};

    ${props => props.css ?? ""}
`

const AnimationContainer = styled.img`
    width: auto;
    height: 30px;
    margin-right: 10px;
`


interface ActiveAnnotationSessionProps {
    annotation: AnnotationSession;
}

/**
 * Component showing currently 
 * annotated session by user. 
 */
const ActiveAnnotationSession = (props: ActiveAnnotationSessionProps): React.ReactElement => {
    return (
        <Container>
            <AnimationContainer src={AnnotationAnimation} />
            <h6>{props.annotation.user.name} annotating...</h6>
        </Container>        
    )
}

export default ActiveAnnotationSession;