import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ExportConfig, DataExportConfig } from "../api/LCSModels";

import Color from "resources/colors";
import ExportIcon from "../resources/images/export-icon.svg";

import Switch from "./Switch";
import { HFlex, VFlex, RippleButton } from "../pandora/styled";
import { VerticalExpand } from "../pandora/animations";


const Container = styled(VFlex)<{opened: boolean}>`
    position: relative;
    justify-content: center;

    width: auto;
    min-width: 150px;
    height: auto;
    min-height: 40px;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;

    z-index: 5;
    border-radius: 5px;

    max-height: 300px;
    overflow-y: visible;

    background-color: ${props => props.opened ? "black" : Color.green};

    user-select: none;
    cursor: pointer;

    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.opened ? "black" : Color.lightGreen};
    }
`

const ConfigContainer = styled(VFlex)`
    height: 0px;
    margin-top: -100%;
    padding: 10px;

    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;

    overflow-y: auto;
    overflow-x: hidden;
    background-color: black;
    animation: ${VerticalExpand} 0.5s 1;
    animation-fill-mode: forwards;

    &: first-child {
        margin-top: 0px;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }   
`



const ConfigKeyMap = {
    encrypt: "Encrypt",
    shouldOverrideDate: "Override date",
    excludeFomul: "Exclude Fomul",
    excludeTrackDistance: "Exclude TD"
}

interface ExportButtonProps {
    title: string;
    config: string;
    export: (config: Record<string, boolean>) => void;
}

const ExportButton = (props: ExportButtonProps): React.ReactElement => {

    // State
    const [config, setConfig] = useState<Record<string, boolean> | null>(null);
    const [opened, setOpened] = useState<boolean>(false);


    // Effects
    useEffect(() => {
        switch (props.config) {
            case "base": {
                setConfig(ExportConfig);
                break;
            }
            case "data": {
                setConfig(DataExportConfig);
                break;
            }
            default: {
                break;
            }
        }
    }, [])


    // Actions
    const toggleOpen = () => setOpened(!opened) 

    const performExport = () => props.export(config)

    const updateConfig = (key: string, checked: boolean) => {
        const _config = {...config};
        _config[key] = checked;
        console.log(_config);
        setConfig(_config);
    }

    return (
        <Container opened={opened}> 
            <HFlex onClick={toggleOpen} style={{height: "100%", alignItems: "center"}}>
                <img src={ExportIcon} style={{width: 15, height: 15, marginRight: 10}} />
                <h5>{props.title}</h5>
            </HFlex>
            {opened && 
                <div style={{position: "absolute", top: 35, right: 0, left: 0, overflow: "hidden", width: "100%"}}>
                    <ConfigContainer>
                        <div style={{height: 2, marginBottom: 10, backgroundColor: Color.extraLightGrey}}></div>
                        {Object.keys(config).reverse().map(key =>
                            <HFlex 
                                key={key} 
                                style={{marginTop: 10, alignItems: "center"}}
                            >
                                <Switch 
                                    configKey= {key}
                                    value=     {config[key]} 
                                    onChange=  {updateConfig} 
                                />
                                <h6 style={{marginLeft: 10}}>{ConfigKeyMap[key]}</h6>
                          </HFlex>
                        )}
                        <RippleButton 
                            color=      {Color.green} 
                            hoverColor= {Color.lightGreen}
                            onClick=    {performExport}
                            title=      {"Export data"}
                            style=      {{marginTop: 20, height: 25, lineHeight: "25px", textAlign: "center"}}
                        >Export</RippleButton>
                    </ConfigContainer>
                </div>
            }
        </Container>
    )
}

export default ExportButton;


