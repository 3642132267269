import { PlaceCompletionGeo } from "../api/LCSModels";
import { getCompletionProgressColor } from "../pandora/pandora"



export interface Layer {
    id: string;
    type: string;
    source: object;
    paint: object;
}

export default class NetworkMapSettings {


    public static Line = class {

        private static type = "line";

        /**
         * Construct source object with coordinates
         * for the given place completion data, in this
         * case only used for lines. 
         */
        private static getSource = (p: PlaceCompletionGeo ): object => {
            const coordinates = [
                [p.fromPlace.longitude, p.fromPlace.latitude],
                [p.toPlace.longitude, p.toPlace.latitude]
            ]

            return {
                "type": "geojson",
                "data": {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "LineString",
                        "coordinates": coordinates
                    }
                }
            }
        }

        /**
         * Get the color applied to the line layer 
         * depending on its completion progress. 
         */
        private static getColor = (p: PlaceCompletionGeo): object => {
            const progress = p.completion !== null ? 100 : Math.round((p.completedTrackLinksCount / p.trackLinksCount) * 100)
            return {
                "line-color": getCompletionProgressColor(progress),
                "line-width": 3
            }
        }

        /**
         * Get the GEOJSON configuration data
         * for a line layer. 
         * @param id ID for layer identification
         * @param p line completion data 
         * @returns Mapbox LineLayer configuration object. 
         */
        public static getLayerConfig = (id: string, p: PlaceCompletionGeo ): Layer => {
            return {
                "id": id,
                "type": NetworkMapSettings.Line.type,
                "source": NetworkMapSettings.Line.getSource(p),
                "paint": NetworkMapSettings.Line.getColor(p)
            }   
        }
    }    
}


