import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import Color from "../../resources/colors";
import CompletionIcon from "../../resources/images/check-mark-icon.png";

import { PlaceCompletion } from "../../api/LCSModels";

import { HFlex, VFlex, DeleteButton } from "../../pandora/styled";


const Container = styled(HFlex)<{color: string, hoverColor: string, cursor: string}>`
    align-items: center;
    padding: 10px;
    margin-left: 10px;

    border-radius: 5px;
    background-color: ${props => props.color};
    cursor: ${props => props.cursor};
    user-select: none;

    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.hoverColor};
    }

    h5 { 
        margin-top: 5px;
    }

    img {
        height: 15px;
        width: 15px;
        margin-right: 15px;
    }
`


interface CompletionTagProps {
    completion: PlaceCompletion;
    complete: () => void;
    removeCompletion: () => void;
}

const PlaceCompletionTag = (props: CompletionTagProps): React.ReactElement => {

    // State
    const { completion, complete, removeCompletion: performRemoveCompletion } = props;
    
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const isNotCompleted = completion === null
    const title = isNotCompleted ? `Mark as completed` : `Completed`
    const color = isNotCompleted ? Color.extraDarkGrey : Color.green
    const hoverColor = isNotCompleted ? Color.extraLightGrey : null
    const cursor = isNotCompleted ? "pointer" : "auto"
    const subtitle = isNotCompleted ? `` : `${moment(completion.time).format("Do MMMM HH:mm YYYY")} by ${completion.user.name}`
    
    // Actions
    const onClick = () => {
        if (isNotCompleted) complete();
    }

    const removeCompletion = (e) => {
        e.stopPropagation();
        e.preventDefault();
        performRemoveCompletion();
    }

    return (
        <Container 
            color=          {color}
            hoverColor=     {hoverColor}
            cursor=         {cursor}
            onMouseOver=    {() => setIsHovering(true)}
            onMouseLeave=   {() => setIsHovering(false)}
            onClick=        {onClick}
        >
            <img src={CompletionIcon} />
            <VFlex style={{height: "fit-content"}}>
                <h4>{title}</h4>
                {completion !== null && 
                    <h5 style={{fontWeight: "normal"}}>{subtitle}</h5>
                }
            </VFlex>
            {completion !== null && 
                <HFlex style={{alignItems: "center"}}>
                    {isHovering && 
                        <DeleteButton 
                            onClick={removeCompletion} 
                            style=  {{marginLeft: 20}}
                            title=  {"Remove completion"}
                        />
                    }
                </HFlex>
            }
        </Container>
    )
}

export default PlaceCompletionTag;