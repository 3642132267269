import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";

import { SiteView } from "../site/routes";
import MetricsIcon from "../resources/images/metrics-icon.svg";

import ViewHeader from "../components/ViewHeader";
import { HFlex, ViewContainer } from "../pandora/styled";





const Container = styled(ViewContainer)`
    overflow: visible;
`



const MetricsView = (): React.ReactElement => {

    // Set doc title
    document.title = `Metrics |  LCS Online`

    return (
        <Container>
            <ViewHeader view={SiteView.Metrics} />
        </Container>
    )
}

export default MetricsView;