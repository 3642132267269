import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";

import { SiteRoute } from "../../site/routes";
import FomulTypes from "../../pandora/fomul-types";
import { FomulGeo, TrackDistanceGeo } from "../../api/LCSModels";
import { convertDMS } from "../../pandora/pandora";
import MapSettings from "../../pandora/MapSettings";

import FomulIcon from "../../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../../resources/images/track-distance-icon.svg";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";

import PositionTag from "../../components/PositionTag";
import { HFlex, VFlex, IconButton } from "../../pandora/styled";
import Color from "../../resources/colors";


// COMPONENT CONFIGURATION DATA ----------------------------

const DataTypeColorMap = {
    "fomul": Color.blue,
    "trackdistance": Color.orange
}

const DataTypeHoverColorMap = {
    "fomul": Color.lightBlue,
    "trackdistance": Color.lightOrange
}

const DataTypeIconMap = {
    "fomul": FomulIcon,
    "trackdistance": TrackDistanceIcon
}

// -------------------------------------------------------


const PopupContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;

    width: 100%;
    height: 100%
    border-radius: 5px;

    color: white;
    font-family: Montserrat, sans-serif;
    
    background: transparent;
    overflow: hidden;

    h4, h5 {
        color: ${Color.extraLightGrey};
    }

    ${IconButton} {
        margin-right: 0px;
        height: 20px;
        line-height: 20px;
        width: 50%;
    }

    ${IconButton}: first-of-type {
        margin-right: 10px;
        text-indent: 40px;
    }

    .position-container {
        margin: 20px 0px;
        cursor: "auto";
        justify-content: center;
    }
`

const GPSDataContainer = styled(VFlex)`
    width: auto;
    margin: 0px 0px 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: ${Color.lightGrey};

    h5 { 
        margin: 5px 0px;
        font-weight: normal;
        color: white;
    }
`


interface MapDataPopupProps {
    data: FomulGeo | TrackDistanceGeo
    type: string;
}

/**
 * Popup in map for 
 * MapView data, i.e. fomuls or track distances. 
 */
const MapDataPopup = (props: MapDataPopupProps): React.ReactElement => {

    // State
    const [type, setType] = useState<string>(props.type);
    const [data, setData] = useState<FomulGeo | TrackDistanceGeo>(props.data);
    const coordinates: [string, string] = convertDMS(data.latitude, data.longitude);

    // Effects
    useEffect(() => setData(props.data), [props.data]);
    useEffect(() => setType(props.type), [props.type]);

    // Actions

    /**
     * Get proper popup title for data type. 
     */
    const getTitle = (): string => {
        switch (type) {
            case MapSettings.Types.Fomul: 
                return FomulTypes[(data as FomulGeo).type]
            case MapSettings.Types.TrackDistance: 
                return `TD ${Math.round((data as TrackDistanceGeo).horDist)} mm`
            default:
                throw new Error(`Unknown map data type passed to MapDataPopup ${type}`)
        }
    }

    /**
     * Get proper URL route for data type 
     * with ID. 
     */
    const getDataLink = (): string => {
        switch (type) {
            case MapSettings.Types.Fomul: 
                return `${SiteRoute.Fomul}?id=${data.id}`
            case MapSettings.Types.TrackDistance: 
                return `${SiteRoute.TrackDistance}?id=${data.id}`
            default:
                throw new Error(`Unknown map data type passed to MapDataPopup ${type}`)
        }
    }

    /**
     * Get proper URL for Track Link 
     * with ID. 
     */
    const getTrackLinkLink = (): string => {
        switch (type) {
            case MapSettings.Types.Fomul: 
                return `${SiteRoute.TrackLink}?id=${(data as FomulGeo).trackLinkID}`
            case MapSettings.Types.TrackDistance: 
                return `${SiteRoute.TrackLink}?id=${(data as TrackDistanceGeo).refTrackLinkID}`
            default:
                throw new Error(`Unknown map data type passed to MapDataPopup ${type}`)
        }
    }

    /**
     * Perform navigation to URL for
     * popup data link. 
     */
    const navigate = (url: string): void => window.location.assign(url);

    return (
        <PopupContainer id={`mapdata-${type}-${data.id}`}>
            <VFlex css={"padding: 15px;"}>
                <HFlex style={{justifyContent: "space-between", marginBottom: "10px"}}>
                    <h4 color={Color.extraLightGrey}>{"#" + data.id}</h4>
                    <h5 color={Color.extraLightGrey}>{coordinates[0] + " " + coordinates[1]}</h5>
                </HFlex>
                <h3>{getTitle()}</h3>
                <HFlex className="position-container">
                    <PositionTag 
                        atKm=   {data.atKm} 
                        atM=    {data.atM} 
                        css=    {{cursor: "auto"}}
                    />
                </HFlex>
                <GPSDataContainer>
                    <h5><strong>Northing:</strong> {data.northing}</h5>
                    <h5><strong>Easting:</strong> {data.easting}</h5>
                    <h5><strong>GPS Fix:</strong> {data.gpsFix}</h5>
                    <h5><strong>PDOP:</strong> {data.pdop}</h5>
                    <h5><strong>Satellites:</strong> {data.satellites}</h5>
                </GPSDataContainer>
                <HFlex style={{marginTop: 20}}>
                    <IconButton 
                        icon=       {DataTypeIconMap[type]}
                        color=      {DataTypeColorMap[type]}
                        hoverColor= {DataTypeHoverColorMap[type]}
                        onClick=    {() => navigate(getDataLink())}
                    >Open</IconButton>
                    <IconButton 
                        icon=       {TrackLinkIcon}
                        color=      {Color.green}
                        hoverColor= {Color.lightGreen}
                        onClick=    {() => navigate(getTrackLinkLink())}
                    >Track Link</IconButton>
                </HFlex>
            </VFlex>
        </PopupContainer>
    );
}

export default MapDataPopup;
