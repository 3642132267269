import styled from "styled-components";

import { LineFull, PlaceFull, TrackLinkStatus } from "../../api/LCSModels";

import Color from "../../resources/colors";
import { FadeAnimation } from "../../pandora/animations";
import { VFlex } from "../../pandora/styled";


export enum PlaceDataType {
    Sessions = "Sessions", 
    TrackLinks = "TrackLinks"
}

export interface PlaceMetaData {
    fomuls: number;
    trackDistances: number;
    sessions: number;
    
    trackLinks: number;
    completedTrackLinks: number;
    nonCompletedTrackLinks: number;
    partiallyCompletedTrackLinks: number;
    
    totalTrackLinksLength: number;
    completedTrackLinksLength: number;
}


export const PlaceContainer = styled(VFlex)`
    height: 100%;
    padding: 0px;
    box-sizing: border-box;
    overflow-y: auto;
    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    .top-box {
        background-color: ${Color.extraDarkGrey};
        width: 50%;
        height: 250px;
    }

    .place-name { 
        font-weight: normal;
    }

    .signature {
        background-color: ${Color.extraDarkGrey};
        padding: 10px;
        border-radius: 5px;
        margin-right: 15px;
    }

    .data-container {
        opacity: 0;

        animation: ${FadeAnimation} 0.5s ease 1;
        animation-fill-mode: forwards;
    }

    ::-webkit-scrollbar {
        width: 0px;
    } 
`

export const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};
`

export const ContentGrid = styled(ContentDiv)`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    column-gap: 10px;
    row-gap: 10px;

    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;
`

/**
 * Aggregate Place meta data.
 */
export const getPlaceMetaData = (p: LineFull | PlaceFull): PlaceMetaData => {
    const trackLinks = p.trackLinks.filter(tl => tl.status !== TrackLinkStatus.Deleted)

    const fomulsCount: number = trackLinks.map(tl => tl.fomuls.length).reduce((a, b) => a + b, 0)
    const trackDistancesCount: number = trackLinks.map(tl => tl.trackDistances.length).reduce((a, b) => a + b, 0)

    const sessionsCount: number = p.sessions.length
    const trackLinksCount: number = trackLinks.length

    const completedTrackLinks = trackLinks.filter(tl => tl.completion !== null && tl.completion.partial === null)
    const completedTrackLinksCount: number = completedTrackLinks.length;
    const nonCompletedTrackLinksCount: number = trackLinks.filter(tl => tl.completion === null).length;
    const partiallyCompletedTrackLinksCount: number = trackLinks.filter(tl => tl.completion !== null && tl.completion.partial !== null).length;

    const totalTrackLinksLength = Math.round(trackLinks.map(tl => tl.linkLength).reduce((a, b) => a + b, 0))
    const completedTrackLinksLength = Math.round(completedTrackLinks.map(tl => tl.linkLength).reduce((a, b) => a + b, 0))

    return {
        fomuls: fomulsCount,
        trackDistances: trackDistancesCount,
        sessions: sessionsCount,
        trackLinks: trackLinksCount,
        completedTrackLinks: completedTrackLinksCount,
        nonCompletedTrackLinks: nonCompletedTrackLinksCount,
        partiallyCompletedTrackLinks: partiallyCompletedTrackLinksCount,
        totalTrackLinksLength: totalTrackLinksLength,
        completedTrackLinksLength: completedTrackLinksLength
    }
}

/**
 * Calculate completion progress. 
 */
export const getCompletionProgress = (placeMetaData: PlaceMetaData): number => {
    if (placeMetaData.trackLinks === 0)
        return 0;
    return (placeMetaData.completedTrackLinks / placeMetaData.trackLinks) * 100
}

/**
 * Calculate measurement progress. 
 */
export const getMeasurementProgress = (placeMetaData: PlaceMetaData): number => {
    if (placeMetaData.trackLinks === 0)
        return 0;
    const progress = +(placeMetaData.completedTrackLinksLength / placeMetaData.totalTrackLinksLength).toFixed(2) * 100
    return progress
}