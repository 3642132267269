import React, { useState } from "react";
import styled from "styled-components";

import Color from "../resources/colors";
import { CompletionStatus } from "../api/LCSModels";
import { 
    CompletionStatusColorMap, 
    CompletionStatusLightColorMap, 
    CompletionStatusLabelMap 
} from "../pandora/pandora";

import { HFlex, StatusItemContainer, StatusSelectionContainer } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";


const CompletionStatusOption = styled(StatusItemContainer)`
    min-width: 100px;
    width: fit-content;
    height: 30px;
    margin: 0;
    margin-left: 5px;
    padding: 5px 10px;

    animation: ${FadeAnimation} 0.2s linear 1;
`



interface CompletionStatusSelectorProps {
    value?: string;
    queryKey: string;
    setValue: (key: string, value: string | number) => void;
}

const CompletionStatusSelector = (props: CompletionStatusSelectorProps): React.ReactElement => {
    
    const { queryKey, value, setValue } = props;
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const statusKeys = [...Object.keys(CompletionStatus).reverse(), null]

    return (
        <HFlex
            onMouseEnter=   {() => setIsHovering(true)}
            onMouseLeave=   {() => setIsHovering(false)}
            style=          {{width: "auto", position: "relative"}}
        >

            <CompletionStatusOption 
                color=      {CompletionStatusColorMap[CompletionStatus[value]] ?? Color.lightGrey}
                hoverColor= {CompletionStatusLightColorMap[CompletionStatus[value]] ?? Color.extraLightGrey}
                selected=   {false}
                selectable= {true}
            >
                 <h6>{CompletionStatusLabelMap[CompletionStatus[value]] ?? "Not specified"}</h6>
            </CompletionStatusOption>
            {isHovering && 
                 <StatusSelectionContainer>
                    {statusKeys.map(ct => 
                        <CompletionStatusOption
                            key=        {`status-option-${ct}`}
                            color=      {CompletionStatusColorMap[CompletionStatus[ct]] ?? Color.lightGrey}
                            hoverColor= {CompletionStatusLightColorMap[CompletionStatus[ct]] ?? Color.extraLightGrey}
                            selected=   {false}
                            selectable= {true}
                            onClick=    {() => setValue(queryKey, ct ?? "")}
                        >
                            <h6>{CompletionStatusLabelMap[CompletionStatus[ct]] ?? "Not specified"}</h6>
                        </CompletionStatusOption> 
                    )}
                </StatusSelectionContainer>           
            }
        </HFlex>
    )
}

export default CompletionStatusSelector;

