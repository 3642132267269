import React from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import { SiteView, AppViewConfig, Site } from "../site/routes";

import LCSOnlineLogo from "../resources/images/lcs-online-logo.png";
import Color from "../resources/colors";

import ViewOptionButton from "../components/ViewOptionButton";
import { VFlex } from "../pandora/styled";



const Container = styled(VFlex)`
    position: relative;
    width: 150px;
    height: auto;
    padding: 20px;
    bottom: 0;
    top: 0;

    padding-right: 50px;
    padding-bottom: 0px;
    align-items: center;

    background: ${Color.darkGrey};
`

const Separator = styled.div`
    width: 100%;
    height: 2px;
    
    margin-top: 15px;
    margin-bottom: 15px;

    background-color: ${Color.lightGrey};
`

const Logo = styled.img`
    max-width: 75%; 
    height: auto;
    margin: 0;
`   

const ViewOptionList = styled(VFlex)`
    margin-top: 30px;
    align-items: center;
    overflow-x: visible
    
    ::-webkit-scrollbar {
        width: 0px;
    }  
`


const SideBar = (): React.ReactElement => {

    const location = useLocation()
    const history = useHistory()

    // State
    const selectedView: SiteView = SiteView[location.pathname];

    const dataViews: AppViewConfig[] = Site.AppViews.getDataViews()
    const toolViews: AppViewConfig[] = Site.AppViews.getToolViews()

    // Actions

    // Push selected route on view selection. 
    const navigate = (v: SiteView) => { 
        if (v !== selectedView) 
            history.push(v as unknown as string)
    }

    return (   
        <Container>
            <Logo src={LCSOnlineLogo} />
            <ViewOptionList>
                {dataViews.map((viewConfig: AppViewConfig) => 
                        <ViewOptionButton 
                            //@ts-ignore
                            isSelected=     {SiteView[viewConfig.view] as SiteView === selectedView}
                            viewConfig=     {viewConfig}
                            key=            {viewConfig.view}
                            navigate=       {() => navigate(viewConfig.view)}
                        />
                    )
                }
                <Separator />
                {toolViews.map((viewConfig: AppViewConfig) => 
                        <ViewOptionButton 
                            //@ts-ignore
                            isSelected=     {SiteView[viewConfig.view] as SiteView === selectedView}
                            viewConfig=     {viewConfig}
                            key=            {viewConfig.view}
                            navigate=       {() => navigate(viewConfig.view)}
                        />
                    )
                }
            </ViewOptionList>
        </Container>
    )
}

export default SideBar;