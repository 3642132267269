import React, { useState, useEffect } from "react";
import styled from "styled-components";
 
import { DropContainerAnimation } from "../../pandora/animations";
import Color from "../../resources/colors";
import UploadIcon from "../../resources/images/upload-icon.svg";

import { GenericPopupProps } from "../../components/GenericPopup";
import { VFlex, Button } from "../../pandora/styled";
import LoadingComponent from "../../components/LoadingComponent";


const Container = styled("div")<{color: string, minimal?: boolean}>`
    position: relative;
    display: flex;
    min-height: ${props => props.minimal ? "100px" : "400px"};
    width: ${props => props.minimal ? "300px" : "auto"};
    height: 100%;
    align-items: center;
    justify-content: center;    

    background-color: transparent;
    border: solid 8px ${Color.lightGrey};
    border-radius: 5px;
    margin-top: 10px;

    transition: 0.3s ease;

    &: hover {
        border: solid 8px ${props => props.color};
    }
`

const DropContainer = styled("div")<{color: string}>`
    position: absolute;
    background-color: ${props => props.color};
    width: 200px;
    height: 200px;
    border-radius: 50%;

    background-image: url(${UploadIcon});
    background-size: auto 25%;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: ${DropContainerAnimation} 1.5s ease-out infinite;
`




interface UploadZoneProps {
    file: File;
    isUploading: boolean;
    typeColor: string;
    setFile: (file) => void;
    setPopup: (value: GenericPopupProps | null) => void;
    performUpload?: () => void;
    noUpload?: boolean;
    minimal?: boolean;
}


/**
 * Component handling file uploading and file state
 * management before attaching to an API request.
 */
const UploadZone = (props: UploadZoneProps): React.ReactElement => {

    // State
    const dropRef = React.createRef<HTMLDivElement>();
    const fileInput = React.createRef<HTMLInputElement>();
    
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState(props.file);

    // Effects
    useEffect(() => { setFile(props.file) }, [props.file])

    useEffect(() => {
        const div = dropRef.current

        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        // returned function will be called on component unmount 
        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [])

    // Actions

    /**
     * Perform validation check on 
     * files before upload. 
     */
    const uploadValid = (files): boolean => {

        // Check file count
        if (files.length > 1) {
            props.setPopup(null)
            props.setPopup({
                title: "Varning!",
                message: "Endast en fil tillåten.",
                color: Color.red,
                setPopup: props.setPopup
            })
            return false;
        }
        return true;
    }

    // Register drag and drop 
    // events and listeners
    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0 && !props.isUploading) {
            setIsDragging(true);
        }
    }
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false);
    }

    const handleDrop = (e) => {    
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false);
        
        // Handle drop by validating files 
        // and passing them along to parent container 
        // handling upload. 
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {   
            if (uploadValid(e.dataTransfer.files)) {
                const res = e.dataTransfer.files[0]
                setFile(res)
                props.setFile(res);
                e.dataTransfer.clearData()
            }
        }
    }


    /**
     * Handle file selection with file 
     * system selection prompt.
     * Perform validation checks.
     */
    const handleFileSelection = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (uploadValid(e.target.files)) {
                const res = e.target.files[0] 
                setFile(res);
                props.setFile(res);
            }
        }
    }

    return (
        <Container color={props.typeColor} minimal={props.minimal}>
            <div style={{height: "100%", width: "100%", zIndex: 10, position: "absolute"}} ref={dropRef}></div>
            {!isDragging && !props.isUploading && 
                <VFlex css={"justify-content: center; align-items: center; position: absolute;"}>
                    {file !== null && 
                        <VFlex css={"height: auto; justify-content: center; align-items: center;"}>
                            <h3 style={{fontSize: props.minimal ? 12 : 18}}>{file.name}</h3>
                            {!props.minimal &&
                                    <h4 style={{
                                    fontWeight: "normal", 
                                    marginTop: 15,
                                }}>{Math.round(file.size / 1000) + " kB"}</h4>
                            }
                        </VFlex>
                    }
                    {file === null && !props.minimal &&
                        <VFlex css={"height: auto; justify-content: center; align-items: center;"}>
                            <img src={UploadIcon} style={{width: 40, height: 40, margin: 20}} />
                            <h4>Drag and drop or select file</h4>
                        </VFlex>
                    }
                    <Button 
                        onClick={() => fileInput.current.click()}
                        color=      {Color.lightGrey}
                        hoverColor= {Color.extraLightGrey}
                        style={{zIndex: 10, marginTop: props.minimal ? 10 : 30}}
                    >Select file</Button>
                    {file !== null && !props.noUpload && 
                        <Button 
                            onClick=    {props.performUpload}
                            color=      {props.typeColor}
                            hoverColor= {props.typeColor}
                            style=      {{zIndex: 10, marginTop: 10}}
                        >Upload file</Button>
                    }
                </VFlex>
            }
            {isDragging &&
                <DropContainer color={props.typeColor} /> 
            }
            {props.isUploading && !props.noUpload && 
                <LoadingComponent message={"Uploading..."} />
            }
            <input
                ref=        {fileInput}
                style=      {{display:'none'}}
                type=       {"file"}
                onChange=   {handleFileSelection}
            />
        </Container>
    )
}

export default UploadZone;




