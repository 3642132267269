
export enum HTTPRequestMethod {
    POST = "POST",
    PUT = "PUT",
    GET = "GET",
    DELETE = "DELETE"
}

enum APIRoute {
    User = "user",
    Upload = "upload",
    Export = "export",
    Place = "place",
    Network = "network",
    Invoicing = "invoicing",
    Session = "session",
    Fomul = "fomul",
    TrackDistance = "trackdistance",
    TrackLink = "tracklink",
    Completed = "completed",
    NonCompleted = "noncompleted",
    Override = "override",
    Versions = "versions",
    Annotation = "annotation",
    Geo = "geo",
    Section = "section", 
    Alt2 = "alt2",
    Audit="audit"
}


/**
 * Request protocol defining keys 
 * expected and required for performing an API 
 * request. Some optional keys, like content type and headers
 * defined optional key-value pairs inserted into the request
 * header. 
 */
export interface Request {
    method: HTTPRequestMethod
    keys: string[]
    public?: boolean
    contentType?: string 
    headers?: Record<string, any>
    opts?: Record<string, any>
    route: (...args: any[]) => string
}

/**
 * 
 */
export default class APIRequest {

    /**
     * Construct generic API route. 
     */
    private static makeURL = (...args: Array<string | number>): string => "/" + args.map(String).join("/");

    // USER:

    // Get the logged in user
    static auth: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.User, "current")
    }

    
    // EXPORT:

    // Export single Fomul for given ID
    static exportFomul: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "encrypt", "shouldOverrideDate"],
        headers: {
            "Accept": "text/plain; charset=iso-8850-1"
        },
        opts: {
            "responseType": "blob",
        },
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Export, APIRoute.Fomul, fomulID)
    }

    // Export multiple Fomuls for given IDs
    static exportMultipleFomuls: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["IDs", "encrypt", "shouldOverrideDate"],
        headers: {
            "Accept": "application/x-zip-compressed"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.Fomul, "multiple")
    }

    // Export single TrackDistance for given ID
    static exportTrackDistance: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "encrypt", "shouldOverrideDate"],
        headers: {
            "Accept": "text/plain; charset=iso-8850-1"
        },
        opts: {
            "responseType": "blob",
        },
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackDistance, trackDistanceID)
    }

    // Export multiple Track Distances for given IDs
    static exportMultipleTrackDistances: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["IDs", "encrypt", "shouldOverrideDate"],
        headers: {
            "Accept": "text/plain; charset=iso-8850-1"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackDistance, "multiple")
    }

    // Export all Fomuls and TrackDistances for session with given ID
    static exportSession: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", 
                "encrypt",
                "shouldOverrideDate",
                "excludeFomul",
                "excludeTrackDistance"
            ],
        headers: {
            "Accept": "application/x-zip-compressed"
        },
        opts: {
            "responseType": "blob",
        },
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Export, APIRoute.Session, sessionID)
    }

    // Export Fomuls and TrackDistances for all sessions
    static exportAllSessions: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["encrypt",
                "shouldOverrideDate",
                "excludeFomul",
                "excludeTrackDistance"
            ],
        headers: {
            "Accept": "application/x-zip-compressed"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.Session, "all")
    }

    // Export all completed track links or for a given section
    static exportCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["shouldOverrideDate"],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, APIRoute.Completed)
    }

    // Export all non completed track links or for a given section
    static exportNonCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, APIRoute.NonCompleted)
    }

    // Export all non completed track links or for a given section
    static exportRemainingTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, "remaining")
    }

    // Export all Fomuls and TrackDistances for a given track link
    static exportTrackLink: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id"],
        headers: {
            "Accept": "application/x-zip-compressed"
        },
        opts: {
            "responseType": "blob",
        },
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, trackLinkID)
    }

    // Export all Fomul deviations on non completed track links, optionally for a given section
    static exportFomulsForNonCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, APIRoute.NonCompleted, APIRoute.Fomul)
    }

    // Export all Track Distances deviations on non completed track links, optionally for a given section
    static exportTDsForNonCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, APIRoute.NonCompleted, APIRoute.TrackDistance)
    }

    // Export invoice support data
    static exportInvoiceData: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackLink, "invoice")
    }

    // Export a TrackDistance file directly without matching to database. Intended to use with offline mode
    static uploadTrackDistanceDirectExport: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        headers: {
            "Accept": "text/plain; charset=iso-8850-1"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.TrackDistance, "direct")
    }

    // Export a Fomul directly from a local SavedFomuls file. Does no database matching, just returns the export
    static uploadFomulDirectExport: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        headers: {
            "Accept": "application/x-zip-compressed"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.Export, APIRoute.Fomul, "direct")
    }





    // UPLOAD:

    // Upload a list of sessions
    static uploadSessions: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.Session)
    }

    // Upload a list of places
    static uploadPlaces: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.Place)
    }

    // Upload a list of Fomuls
    static uploadFomuls: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.Fomul)
    }

    // Upload a list of TrackDistances
    static uploadTrackDistances: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.TrackDistance)
    }

    // Upload TrackLinks export file
    static uploadTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.TrackLink)
    }

    // Upload completed TrackLinks save file
    static uploadCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.TrackLink, APIRoute.Completed)
    }

    // Upload export overrides
    static uploadTrackLinkDateOverrides: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        route: () => APIRequest.makeURL(APIRoute.Upload, APIRoute.TrackLink, APIRoute.Override)
    }


    // FOMUL:

    // Get all versions of a given Fomul
    static getFomulVersions: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID, APIRoute.Versions)
    }

    // Retrieve Fomul for ID
    static getFomul: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID)
    }

    // Edit Fomul with update payload
    static editFomul: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID)
    }

    // Delete Fomul, i.e. set Fomul status to .Deleted
    static deleteFomul: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID)
    }

    //  Retrieve all deleted Fomuls in the last N days
    static getDeletedFomuls: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["days"],
        route: () => APIRequest.makeURL(APIRoute.Fomul, "deleted")
    }    

    // Undo Fomul deletion, i.e. set Fomul status to .Active from .Deleted
    static undoFomulDeletion: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID, "undo-delete")
    }

    // Get Geo representation of requested Fomuls
    static getGeoFomuls: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID, APIRoute.Geo)
    }

    // Get all resulting Fomuls in database for a given query
    static getFomulsForQuery: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Fomul, "query")
    }

    // Delete batch of Fomuls for given IDs
    static deleteFomulBatch: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["IDs"],
        route: () => APIRequest.makeURL(APIRoute.Fomul, "batch")
    }

    // Edit batch of Fomuls for given IDs
    static editFomulBatch: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["IDs", "values"],
        route: () => APIRequest.makeURL(APIRoute.Fomul, "batch")
    }

    // Set Fomul Track Link reference
    static setFomulTrackLinkRef: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "trackLinkID"],
        route: (fomulID: number) => APIRequest.makeURL(APIRoute.Fomul, fomulID, APIRoute.TrackLink)
    }


    // TRACK DISTANCE:

    // Get all versions of a given TrackDistance
    static getTrackDistanceVersions: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID, APIRoute.Versions)
    }

    // Retrieve TrackDistance for ID
    static getTrackDistance: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID)
    }

    // Edit TrackDistance with update payload
    static editTrackDistance: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID)
    }

    // Delete TrackDistance, i.e. set TrackDistance status to .Deleted
    static deleteTrackDistance: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID)
    }

    //  Retrieve all deleted TrackDistances in the last N days
    static getDeletedTrackDistances: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["days"],
        route: () => APIRequest.makeURL(APIRoute.TrackDistance, "deleted")
    }    

    // Undo TrackDistance deletion, i.e. set TrackDistance status to .Active from .Deleted
    static undoTrackDistanceDeletion: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID, "undo-delete")
    }

    // Get Geo representation of requested Track Distances
    static getGeoTrackDistances: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID, APIRoute.Geo)
    }

    // Get all resulting Fomuls in database for a given query
    static getTrackDistancesForQuery: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.TrackDistance, "query")
    }

    // Delete batch of TrackDistances for given IDs
    static deleteTrackDistanceBatch: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["IDs"],
        route: () => APIRequest.makeURL(APIRoute.TrackDistance, "batch")
    }

    // Edit batch of TrackDistances for given IDs
    static editTrackDistanceBatch: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["IDs", "values"],
        route: () => APIRequest.makeURL(APIRoute.TrackDistance, "batch")
    }

    // Set TrackDistance Track Link reference
    static setTrackDistanceTrackLinkRef: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "trackLinkID"],
        route: (trackDistanceID: number) => APIRequest.makeURL(APIRoute.TrackDistance, trackDistanceID, APIRoute.TrackLink)
    }


    // TRACK LINK: 

    // Get all resulting TrackLinks in database for a given query
    static getTrackLinkForQuery: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.TrackLink, "query")
    }

    // Get all TrackLinks 
    static getAllTrackLinks: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["full"],
        route: () => APIRequest.makeURL(APIRoute.TrackLink, "all")
    }

    // Retrieve TrackLink for a given ID
    static getTrackLink: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID)
    }

    // Get all versions for a given TrackLink
    static getTrackLinkVersions: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id", "full"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, APIRoute.Versions)
    }

    // Get all sessions for a TrackLink
    static getSessionsForTrackLink: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "sessions")
    }

    // Get all completed TrackLinks
    static getCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["full"],
        route: () => APIRequest.makeURL(APIRoute.TrackLink, APIRoute.Completed, "all")
    }

    // Get all non completed Tracklinks
    static getNonCompletedTrackLinks: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["full"],
        route: () => APIRequest.makeURL(APIRoute.TrackLink, APIRoute.NonCompleted, "all")
    }

    // Get all TrackLinks for a given section
    static getTrackLinksForSection: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sectionID: number) => APIRequest.makeURL(APIRoute.TrackLink, "section", sectionID)
    }

    // Get all completed TrackLinks for a given section
    static getCompletedTrackLinksForSection: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sectionID: number) => APIRequest.makeURL(APIRoute.TrackLink, "section", sectionID, APIRoute.Completed)
    }

    // Get all completed TrackLinks for a given section
    static getNonCompletedTrackLinksForSection: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sectionID: number) => APIRequest.makeURL(APIRoute.TrackLink, "section", sectionID, APIRoute.NonCompleted)
    }

    // Mark TrackLink as completed
    static completeTrackLink: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "sessionID"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "complete")
    }

    // Mark TrackLink as not completed
    static uncompleteTrackLink: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "complete")
    }

    // Add TrackLink date override
    static addTrackLinkOverride: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "fromDate", "toDate"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, APIRoute.Override)
    }

    // Remove TrackLink date override
    static deleteTrackLinkOverride: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (overrideID: number) => APIRequest.makeURL(APIRoute.TrackLink, APIRoute.Override, overrideID)
    }

    // Format TrackLink file export
    static formatTrackLinkData: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        contentType: "multipart/form-data",
        headers: {
            "Accept": "application/vnd.openxmlformats-offcedocument.spreadsheetml.sheet"
        },
        opts: {
            "responseType": "blob",
        },
        route: () => APIRequest.makeURL(APIRoute.TrackLink, "format")
    }

    // Get Fomul and Track Distance Geo representations for given Track Link
    static getTrackLinkGeoData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, APIRoute.Geo)
    }

    // Mark TrackLink as uncompleted 
    static removeTrackLinkCompletion: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "complete")
    }

    // Get all change logs for a given Track Link
    static getTrackLinkChangeLog: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "changelog")
    }

    // Get Track Links for signature
    static getTrackLinksForPlaceSignature: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["fromPlace", "toPlace"],
        route: () => APIRequest.makeURL(APIRoute.TrackLink, APIRoute.Place)
    }

    // Mark Track Link as flagged
    static setTrackLinkFlagged: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id", "comment"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "flagged")
    }

    //  Remove Track Link flag
    static unsetTrackLinkFlagged: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (trackLinkID: number) => APIRequest.makeURL(APIRoute.TrackLink, trackLinkID, "flagged")
    }


    // SESSION:

    // Create a new Session
    static getSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: () => APIRequest.makeURL(APIRoute.Session)
    }
    
    // Create a new Session
    static getAllDistinctSessionYears: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Session, "years")
    }

    // Get all Sessions in database
    static getAllSessions: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["full"],
        route: () => APIRequest.makeURL(APIRoute.Session, "all")
    }

    // Get all session dates
    static getAllSessionDates: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Session, "all", "dates")
    }

    // Get Min version of all sessions in interval
    static getSessionsForInterval: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["start", "end"],
        route: () => APIRequest.makeURL(APIRoute.Session, "interval")
    }

    // Get all TrackLinks for a given session
    static getTrackLinksForSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, "tracklinks")
    }

    // Get all Fomuls for a given session
    static getFomulsForSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.Fomul)
    }

    // Get all TrackDistances for a given session
    static getTrackDistancesForSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.TrackDistance)
    }

    // Get all AnnotationSessions for a given Session
    static getAllAnnotationSessionsForSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.Annotation, "all")
    }

    // Retrieve an optional current AnnotationSession for a session
    static getActiveAnnotationSession: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.Annotation, "current")
    }

    // Update Session note
    static updateSessionNote: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "note"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, "note")
    }   
 
    // Update Session status
    static updateSessionStatus: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "status"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, "status")
    }   

    // Get Fomul and Track Distance Geo representations for given Session
    static getSessionGeoData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.Geo)
    }

    // Mark Session as flagged
    static setSessionFlagged: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, "flagged")
    }

    // Mark Session as flagged
    static unsetSessionFlagged: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, "flagged")
    }

    // Get data for session based on annotation session
    static getDataForAnnotationSessions: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sessionID: number) => APIRequest.makeURL(APIRoute.Session, sessionID, APIRoute.Annotation, "data")
    }

    // Update Annotation Session comment
    static setAnnotationSessionComment: Request = {
        method: HTTPRequestMethod.PUT,
        keys: ["id", "comment"],
        route: (annotationSessionID: number) => APIRequest.makeURL(APIRoute.Session,  APIRoute.Annotation, annotationSessionID, "comment")
    }

    // Remove Annotation session comment
    static removeAnnotationSessionComment: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (annotationSessionID: number) => APIRequest.makeURL(APIRoute.Session,  APIRoute.Annotation, annotationSessionID, "comment")
    }




    // NETWORK:

    // Get Place object for ID
    static getPlace: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (placeID: number) => APIRequest.makeURL(APIRoute.Network, APIRoute.Place, placeID)
    }

    // Get Place data for signature
    static getPlaceData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["signature"],
        route: () => APIRequest.makeURL(APIRoute.Network, APIRoute.Place)
    }

    // Get Place geo data for id
    static getPlaceGeoData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (placeID: number) => APIRequest.makeURL(APIRoute.Network, APIRoute.Place, placeID, APIRoute.Geo)
    }

    // Get Line data for signature
    static getLineData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["fromPlace", "toPlace"],
        route: () => APIRequest.makeURL(APIRoute.Network, "line")
    }

    // Get Line geo data for signature
    static getLineGeoData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (lineSignature: string) => APIRequest.makeURL(APIRoute.Network, "line", lineSignature, APIRoute.Geo)
    }

    // Get all places in railway network
    static getAllPlaces: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Network, APIRoute.Place, "all")
    }
    
    // Get all sections in railway network
    static getAllSections: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Network, APIRoute.Section, "all")
    }

    // Get place data for section
    static getSectionData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["id"],
        route: (sectionID: string) => APIRequest.makeURL(APIRoute.Network, APIRoute.Section, sectionID, "places")
    }

    // Mark Place or Line as completed
    static completePlace: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["fromPlaceID", "toPlaceID"],
        route: () => APIRequest.makeURL(APIRoute.Network, APIRoute.Place, "complete")
    }

    // Mark Place or Line as uncompleted
    static uncompletePlace: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (placeCompletionID: number) => APIRequest.makeURL(APIRoute.Network, APIRoute.Place, "complete", placeCompletionID)
    }

    // Mark Place or Line as uncompleted
    static getYearSectionData: Request = {
        method: HTTPRequestMethod.GET,
        keys: ["year"],
        route: () => APIRequest.makeURL(APIRoute.Network, APIRoute.Section, "years")
    }


    // INVOICING:

    // Add invoicing option alt 2 section
    static addInvoicingAlt2Section: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["section"],
        route: () => APIRequest.makeURL(APIRoute.Invoicing, APIRoute.Alt2, APIRoute.Section)
    }

    // Retrieve all registered Al2 sections
    static getAllInvoicingAlt2Sections: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Invoicing, APIRoute.Alt2, APIRoute.Section, "all")
    }

    // Add invoicing option alt 2 section
    static addInvoicingAlt2Place: Request = {
        method: HTTPRequestMethod.POST,
        keys: ["place"],
        route: () => APIRequest.makeURL(APIRoute.Invoicing, APIRoute.Alt2, APIRoute.Place)
    }

    // Retrieve all registered Al2 sections
    static getAllInvoicingAlt2Places: Request = {
        method: HTTPRequestMethod.GET,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Invoicing, APIRoute.Alt2, APIRoute.Place, "all")
    }

    // Remove registered Alt2 option
    static removeAlt2Option: Request = {
        method: HTTPRequestMethod.DELETE,
        keys: ["id"],
        route: (id: number) => APIRequest.makeURL(APIRoute.Invoicing, APIRoute.Alt2, id)
    }



    //Audit

    // Audit BIS files before and after update
    static audit: Request = {
        method: HTTPRequestMethod.POST,
        keys: [],
        route: () => APIRequest.makeURL(APIRoute.Audit)
    }
    

}

