import React, { useState, useEffect } from 'react';
import { useIdleTimer } from "react-idle-timer";
import {
    useHistory,
    useLocation
} from "react-router-dom";

import './App.css';

import Auth from "./site/auth";
import Redirect from "./site/redirect";
import { Site, SiteRoute } from "./site/routes";

import AppRouting from "./views/AppRouting";
import LoadingScreen from "./views/LoadingScreen"



function App(): React.ReactElement {

    const history = useHistory()
    const location = useLocation()
    
    // State
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Effects

    /**
     * Initial effect requesting 
     * verification of the user authenitcation 
     * token. If token is not valid, redirect
     * to ASuite (with a redirect request) 
     * for authentication. 
     */
     useEffect(() => {

        // Verify Thomas connection by
        // authenticating user token. If Thomas not reachable or 
        // token has expired (i.e. response.status !== 200), redirect to 
        // ASuite login route. 
        const verifyAuth = async () => {
            const isVerified = await Auth.verify()
            if (isVerified) {
                setIsLoading(false)
            } else {
                Redirect.asuite(true)
            }

            
            // Determined proper route
            const currRoute: string = location.pathname
            let query: string = location.search
            let navRoute: string;

            // If user is not authenticated and is trying to access a private 
            // route, redirect to login page. 
            if (!Site.routeExists(currRoute)) {
                // if route doesn't exist, redirect to
                // 404 page with requested route as a 
                // query string. 
                navRoute = SiteRoute.Error
                query = `?r=${currRoute.replace("/", "")}`
            }
            
            // Push route with querystrings
            history.push({ 
                pathname: navRoute,
                search: query
            })
        }
        
        verifyAuth()
     }, []);

    /**
     * Idle timer forces LCS Online page refresh every 
     * 5 minutes to prevent asynchrous data states. 
     */
    const _= useIdleTimer({
        timeout: 1000 * 60 * 5,
        onIdle: () => window.location.reload(),
    })


    const ui = () => {        
        return (
            isLoading ? 
            <LoadingScreen /> 
            : 
            <div className="App">
                <AppRouting /> 
            </div>
        )
    }

    return ui();
}

export default App;
