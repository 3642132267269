import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import qs from "qs";

import APIRequest, { HTTPRequestMethod, Request } from "./APIRequest";
import { LCSModel, HeimdallModel, AuthenticatedUser } from "./LCSModels";


/**
 * API request constructor to LCS Online main API Thomas. 
 */
export default class Thomas {
    
    /**
    * Interceptor to prevent axios to change date to utc in request. 
    */
    static initInterceptor = () => {
        axios.interceptors.request.use((config) => {
            config.paramsSerializer = (params) => qs.stringify(params, {
              serializeDate: (date: Date) => dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ') });
            return config;
          })
    }

    /**
     * Authentication token stored as global state in Thomas 
     * class. Set upon successful user authentication. 
     */
    private static APIToken: string | undefined = undefined;

    /**
     * Base URL of API. Stored in environment for seamless
     * local development and production build. 
     */
    private static baseURL: string = process.env.REACT_APP_API_URL;

    /**
     * Construct request header with dynamic parameters.
     * @param token API access token. 
     * @param contentType Request content type. Default: application/json
     */
    private static getHeaders = (token: string, contentType?: string, rHeaders?: Record<string, string | number>): Record<string, string | number> => {
        return {
            "Authorization": `Bearer ${token}`,
            "Content-Type": contentType ?? "application/json",
            ...rHeaders
        }
    }

    /**
     * Set static class instance property.
     * API authentication 'Bearer' token. 
     * @param token 
     */
    public static setToken = (token: string | undefined): void => {
        Thomas.APIToken = token
    };

    /**
     * Retrieve user for SSO 
     * cookie auth token. 
     */
    public static async authenticate(): Promise<AuthenticatedUser> {
        return await Thomas.request<AuthenticatedUser>(APIRequest.auth) as AuthenticatedUser;
    }

    /**
     * Helper function to construct 
     * request API route with any ID args. 
     */
     private static getRoute(r: (...args: any[]) => string, data?: Record<string | number | symbol, any>): string {
        let id: number = data?.id
        // Check FormData
        const asFormData = data as FormData;
        if (asFormData !== null && id == undefined) {
            try { 
                id = parseInt(asFormData.get("id") as string) 
            } catch (e) { 
                id = undefined;
            }
        }
        return Thomas.baseURL + r(id ?? "")
    }    

    /**
     * Dispatch request to Heimdall via axios. 
     * @param r Request with HTTP method, route and required payload keys for request. {@link Request}
     * @param data Payload, 'data, request body' in request, alt. as query strings for GET requests. 
     * @param rawResponse Flag if raw response from API call should be returned or error checked result.
     * @param asQueryStrings Force payload argument to be applied to request as query strings.
     * @param queryStrings Optional extra query strings used when supplying request body or form data. 
     */
    public static async request<I extends LCSModel | HeimdallModel | File>(r: Request, data?: Record<string | number | symbol, any>, rawResponse?: boolean, asQueryStrings: boolean = false, queryStrings?: Record<string, any>): Promise<I | Array<I> | AxiosResponse<I>> {

        // Requests cannot be dispatched wihout 
        // instantiated caller set in user authentication. 
        if (Thomas.APIToken === undefined && !r.public) 
            throw new Error("FATAL ERROR: Thomas APIToken not set.")

        const {method, route, keys, contentType, headers: rHeaders, opts} = r;

        keys.forEach((key: string) => {
            if (!(key in data)) {
                // Handle FormData key check 
                const asFormData = data as FormData;
                if (asFormData !== null && [...asFormData.keys()].includes(key)) return;
                throw new Error(`FATAL ERROR: Missing required key: ${key} in payload.`)
            }
        })

        // Setup payload - "GET" HTTP method does not support 
        // sending request bodies. Replace with "params" key. 
        const payloadKey: string = (method === HTTPRequestMethod.GET || asQueryStrings) ? "params" : "data"
        let payload: Record<string, Record<string, unknown>> = {[payloadKey]: data}
        
        // Setup request URL with provided 
        // route and any ID args
        const url: string = Thomas.getRoute(route, data)

        // Configure request headers for API request
        const headers = Thomas.getHeaders(Thomas.APIToken, contentType, rHeaders)

        // Compose request
        const req: Record<string, any> = {
            "headers": headers,
            "url": url,
            "method": method,
            ...opts,
            ...payload
        }

        // Add forced query strings to request
        // WARNING! This would override the payload passed 
        // for GET requests
        if (queryStrings != null) req["params"] = queryStrings

        // Dispatch request
        try {
            const res: AxiosResponse = await axios(req);

            // Return entire response if raw
            if (rawResponse) return res;

            // Raise error on unsuccessful request
            if (res.status !== 200) throw new Error(res.data);
            
            // If request was successful, return data
            return res.data as I;
        } catch (e) {
            console.log("ERROR RESPONSE", e.response)
            throw e
        }
    }

    /**
     * Attempt to break out and return an error message
     * from the API error object. 
     */
    public static getErrorMessage = (e): string => {


        // FIXME: This could probably be improved a lot for better error communication!
        // HINT: Check for keys in all cases. Error object differs a lot which causes some problems. 
        console.log(JSON.stringify(e))
        console.log(e.message, e.data, e.response, Object.keys(e))
        
        let res;
        const errorKeys = Object.keys(e)
        
        switch (true) {
        case (errorKeys.includes("response")):
            res = e.response;
            break;
        case (errorKeys.includes("data")):
            res = e.data;
            break;
        case (e.message != null):
            return e.message;
        default:
            return `Error is ${JSON.stringify(e)}` 
        }   

        let resKeys = Object.keys(res)
        switch (true) {
        case (resKeys.includes("data")):
            return (Object.keys(res.data).includes("detail")) ? res.data.detail : res.data;
        case (res.message):
            return res.message;
        case (res.statusText):
            return `${res.status}: ${res.statusText}`;
        default:
            return `Error is ${JSON.stringify(res)}` 
        }
    }
}
