const PLACE_NAMES = {
    "Avky": "Avesta Krylbo",
    "Blg": "Borlänge central",
    "Sär": "Sävenäs rangerbangård",
    "Gbm": "Göteborg Marieholm",
    "Bhs": "Bohus",
    "Fsb": "Fosieby",
    "Övn": "Östervärn",
    "M": "Malmö central",
    "Lu": "Lund c",
    "Mgb": "Malmö godsbangård",
    "Dma": "Dannemora",
    "Hrm": "Hargshamn",
    "Hak": "Hallstavik",
    "Gä": "Gävle central",
    "Hl": "Horndal",
    "Fgc": "Fagersta central",
    "Ggm": "Grängesbergs malmbangård",
    "Hfr": "Hofors",
    "Mra": "Mora",
    "Åg": "Ånge",
    "Gö": "Gällö",
    "Lsl": "Långsele",
    "Nld": "Nyland",
    "Sne": "Sandarne",
    "Vv": "Vallvik",
    "Vns": "Vännäs",
    "Söv": "Skelleftehamns övre",
    "Uvn": "Ursviken",
    "Ptå": "Piteå",
    "Gst": "Gammelstad",
    "Le": "Luleå",
    "Uf": "Ulriksfors",
    "Äs": "Älvsjö",
    "Äsg": "Älvsjö godsbangård",
    "Spå": "Spånga",
    "Bx": "Boxholm",
    "Sbl": "Skärblacka",
    "Fg": "Finspång",
    "Oxd": "Oxelösund",
    "Et": "Eskilstuna central",
    "Fgl": "Fågelsta",
    "Mot": "Motala central",
    "Jn": "Järna",
    "Söd": "Södertälje hamn",
    "Kp": "Köping",
    "Bro": "Bro",
    "Bål": "Bålsta",
    "Tb": "Tillberga",
    "Vån": "Västerås norra",
    "Shr": "Surahammar",
    "Brh": "Brattheden",
    "Vso": "Virsbo",
    "Smj": "Smedjebacken",
    "Vk": "Västervik",
    "Kla": "Kumla",
    "Ms": "Mosås",
    "Ör": "Örebro central",
    "Sk": "Skövde central",
    "Mst": "Mariestad",
    "Bof": "Bofors",
    "Dg": "Degerfors",
    "Bjb": "Björneborg",
    "Khn": "Kristinehamn",
    "Ks": "Karlstads central",
    "Kil": "Kil",
    "Ar": "Arvika",
    "Rts": "Rottneros",
    "Hlf": "Hällefors",
    "Gms": "Grums",
    "Ål": "Åmål",
    "Bef": "Bengtsfors",
    "Nka": "Nykroppa",
    "Sf": "Storfors",
    "Uv": "Uddevalla central",
    "Mkl": "Munkedal",
    "Lys": "Lysekil",
    "Snu": "Stenungsund",
    "Let": "Lilla Edet",
    "Thn": "Trollhättan",
    "Lkp": "Lidköping",
    "F": "Falköpings central",
    "Vb": "Varberg",
    "Fabg": "Falkenbergs godsstation",
    "Lme": "Limmared",
    "Trg": "Trelleborg",
    "Y": "Ystad",
    "Gss": "Gärsnäs",
    "Ö": "Örtofta",
    "Hm": "Hässleholm",
    "Bml": "Bromölla",
    "Sög": "Sölvesborg",
    "Kh": "Karlshamn",
    "Av": "Alvesta",
    "O": "Osby",
    "Äh": "Älmhult",
    "Vs": "Vislanda",
    "N": "Nässjö central",
    "Kac": "Kalmar central",
    "Kas": "Kalmar södra",
    "Mss": "Mönsterås",
    "Oh": "Oskarshamn",
    "V": "Värnamo",
    "Sbu": "Strömsnäsbruk",
    "Bih": "Billesholm",
    "Pt": "Perstorp",
    "Kl": "Klippan",
    "Gk": "Göteborg Kville",
    "Tpu": "Tappudden",
    "Wibe": "Wibe",
    "Al": "Arlöv",
    "Lia": "Lillaryd",
    "Of": "Olofström",
    "Nr": "Norrköpings central",
    "Ätk": "Ättekulla",
    "Vmat": "Vilhelmina timmer",
    "Lyt": "Lycksele timmer",
    "Ston": "Stockviksverkens nedre",
    "Hbr": "Holsbybrunn",
    "Ljun": "Ljungaverks lastplats",
    "Sårs": "Söråkers södra",
    "Crs": "Kristianstads södra",
    "Vru": "Vekerum",
    "Jögb": "Jönköpings godsbangård",
    "Hh": "Hallstahammar",
    "Våv": "Västerås västra",
    "Gäb": "Gävle godsbangård",
    "Fär": "Fliskär",
    "Vn": "Värtan",
    "Nosk": "Nordskogen",
    "Skog": "Skoghall",
    "Måp": "Månsarp",
    "Hsb": "Hedensbyn",
    "Vrö": "Värö",
    "Pöb": "Pölsebo",
    "Gsh": "Göteborg Skandiahamnen",
    "Lkö": "Landskrona Östra",
    "Kst": "Kistinge",
    "Gmp": "Gamlarp",
    "Bmo": "Båramo",
    "Bsd": "Brunnsgård",
    "Hdr": "Halmstads rangerbangård",
    "Hbgb": "Helsingborgs godsbangård",
    "xSlu": "Slussen",
    "Mkln": "Munkedals nedre",
    "xÅtp": "Åtorp",
    "Mklh": "Munkedals hamn",
    "xVst": "Vadstena",
    "xAsk": "Aska",
    "xFmö": "Forsmöllan",
    "xLjd": "Ljungbyheds grusgrop",
    "xUö": "Uppsala Östra",
    "xBn": "Bor Norra",
    "xSjv": "Sjövägen",
    "xSn": "Stensjön",
    "xHöo": "Hösjömo",
    "xGby": "Gimarpsby",
    "xGim": "Gimarp",
    "xEyd": "Elgaryd",
    "xEkh": "Ekebohult",
    "xIpr": "Impregneringen",
    "xOhs": "Ohs Bruk",
    "xLgn": "Läggesta nedre",
    "xMfd": "Mariefred",
    "xJås": "Jädraås",
    "xBöp": "Brösarp",
    "xBös": "Brösarp södra",
    "xRav": "Ravlundabro",
    "xRaä": "Ravlunda bränneri",
    "xVtk": "Vitaby kyrka",
    "xVty": "Vitaby",
    "xScs": "Scoutstugan",
    "xSoo": "S:t Olof",
    "xGyb": "Gyllebosjö",
    "xÖvl": "Östra Vemmerlöv",
    "xVir": "Virestad",
    "xHy": "Hesselby",
    "xEkn": "Eken",
    "xMus": "Munkebos",
    "xNys": "Nygårds Stall Dalhem",
    "xTeg": "Tule grusgrop",
    "xTe": "Tule",
    "xKht": "Kambshagtorp",
    "xRok": "Roma kungsgård",
    "xRa": "Roma",
    "wLnb": "Lundsbrunn kurort",
    "xLnb": "Lundsbrunn",
    "xDkn": "Dala kvarn",
    "xSmk": "Skogsmark",
    "xMyå": "Myråsen",
    "xTve": "Tveta",
    "wSa": "Skara",
    "xSac": "Skara camping",
    "xFgr": "Fagerrör",
    "xFie": "Fiskåsen",
    "xLäe": "Länninge",
    "xTog": "Tomtskog",
    "xTsk": "Trollskogen",
    "xVas": "Valstad",
    "xFåt": "Fårhult",
    "xAkm": "Ankarsrum",
    "xLåö": "Långsjön",
    "xFsd": "Fagersand",
    "xHjt": "Hjorted",
    "xTob": "Totebo",
    "xBlä": "Blägda",
    "xSpä": "Spångenäs",
    "xTna": "Tuna",
    "xVäm": "Väderum",
    "xVen": "Vena",
    "xGnl": "Gnötteln",
    "xHhf": "Hultsfreds Hembygdspark",
    "xMsa": "Målilla Sanatorium",
    "xGåv": "Gårdveda",
    "xFla": "Flaten",
    "xHap": "Hultarp",
    "xVsm": "Virserum",
    "xHjm": "Hjortöström",
    "xMop": "Mosstorp",
    "xTib": "Triabo",
    "xHäs": "Hultanäs",
    "wSsu": "Sissehult",
    "xHpf": "Hultsfred (xHpf)",
    "xHbo": "Hybo",
    "xLba": "Långbacka",
    "xDo": "Delsbo",
    "xFos": "Fredriksfors",
    "xSdu": "Sandudden",
    "xNv": "Näsviken",
    "xFsa": "Forsa",
    "xHkl": "Hudiksvall (xHkl)",
    "xJ": "Järle",
    "xN": "Nora stad",
    "xÖdh": "Ödhult",
    "xKpr": "Kopperå",
    "xÅa": "Åseda (xÅa)",
    "Fb": "Forsbacka",
    "Snv": "Sandviken",
    "Ös": "Östersunds central",
    "Id": "Iggesund",
    "Stu": "Stugsund",
    "Suc": "Sundsvall c",
    "Lmn": "Långviksmon",
    "Uå": "Umeå central",
    "Äy": "Älvsbyn",
    "Bdn": "Boden central",
    "Nvn": "Notviken",
    "Fv": "Frövi",
    "E": "Eslöv",
    "Åhus": "Åhus",
    "Slbk": "Solbacka",
    "Klbn": "Klockarbäcken",
    "Sui": "Skuckuviken",
    "Gry": "Gryttje",
    "Sy": "Stockaryd",
    "Lsb": "Ljusne bruk",
    "Lsp": "Lycksele industrispår",
    "Gua": "Gullberna",
    "Åby": "Åby",
    "Bst": "Bastuträsk",
    "Ju": "Jularbo",
    "Hsd": "Härnösands central",
    "xVht": "Värdshuset",
    "Kms": "Kimstad",
    "Er": "Ervalla",
    "xMfå": "Mariefreds ångbåtsstation",
    "Cst": "Stockholms central",
    "Hrbg": "Hallsbergs rangerbangård",
    "xGhn": "Gripsholmsviken",
    "Kng": "Kvarnängen",
    "xHhn": "Hjorthagen",
    "Jbo": "Jordbro",
    "Brny": "Bro Nygård",
    "Åbyg": "Åby godsbangård",
    "xErn": "Ervalla NBVJ",
    "Ök": "Örnsköldsviks central",
    "Aal": "Annedal",
    "Fln": "Falun central",
    "xMln": "Marielund",
    "Spjb": "Spjutbäcken",
    "Slg": "Sällinge",
    "xJbn": "Jagbacken",
    "Löö": "Lödöse",
    "Tm": "Tomteboda bangård",
    "xL": "Löth",
    "Aik": "Aitik",
    "Nyh": "Nynäshamn",
    "Sst": "Stockholms södra",
    "In": "Insjön",
    "Humn": "Husums norra",
    "Ryk": "Rydöbruk",
    "Hes": "Helmershus",
    "Hgl": "Hagalund",
    "Ndt": "Norrsundet",
    "Sege": "Sege",
    "xTp": "Torpa",
    "Fil": "Fillan",
    "xVbä": "Verkebäck",
    "Hj": "Hjulsbro",
    "Kvf": "Kvillsfors",
    "Nkp": "Närkes Kvarntorp",
    "Ökn": "Örnsköldsvik norra",
    "Tud": "Tunadal",
    "xLm": "Lilla Mon",
    "Ara": "Arnäsvall",
    "Hyb": "Hyltebruk",
    "xSm": "Stora Mon",
    "Nkv": "Nykvarn",
    "Säg": "Söräng",
    "Åb": "Åsensbruk",
    "Foo": "Foodia",
    "Vsö": "Viresjö",
    "xKh": "Källarhalsen",
    "Krd": "Kållered",
    "Saf": "Staffanstorp",
    "Båp": "Brågarp",
    "Rum": "Runemo",
    "Kär": "Kärrgruvan",
    "xÄp": "Älvstorp",
    "R": "Rotebro",
    "xPh": "Pershyttan",
    "xPgn": "Pershyttevägen",
    "Rby": "Rockneby",
    "Mbä": "Malmbäck",
    "xGyt": "Gyttorp",
    "Ek": "Eksjö",
    "Upv": "Upplands Väsby",
    "xTx": "Taxinge-Näsby",
    "xHön": "Härnön",
    "Rs": "Rosersberg",
    "xSöy": "Sjöbygget",
    "Vhe": "Västerhaninge",
    "Vag": "Vargön",
    "Bbe": "Blyberg",
    "xHdt": "Hedlandet",
    "Bra": "Brista",
    "xSvb": "Svartbäcken Nedre",
    "Edv": "Edsbyverken",
    "Pii": "Pitkäjärvi",
    "wTås": "Tallås Grusgropsväxel",
    "Eds": "Edsbyns södra",
    "xTås": "Tallås",
    "Bfo": "Billingsfors",
    "Vta": "Vetlanda",
    "Hnd": "Handen",
    "Åks": "Åkers styckebruk",
    "xFia": "Finkelboda",
    "Bjf": "Bjørnfjell",
    "xPte": "Pallanite",
    "xÅbo": "Åbo",
    "Tjs": "Tjugonde slussen",
    "Bma": "Blomstermåla",
    "Sun": "Sunne",
    "Vgd": "Vaggeryd",
    "Hvd": "Hjältevad",
    "xBb": "Brobacka",
    "Ben": "Bredviken",
    "xA": "Anten",
    "xDir": "Dirhuvden",
    "xArl": "Arelid",
    "Vln": "Vallnäs",
    "Gse": "Gunsen",
    "Tli": "Tomelilla",
    "xQo": "Kvarnabo",
    "Kisa": "Kisa",
    "Nk": "Nyköpings central",
    "Fid": "Filipstad",
    "xGr": "Gräfsnäs",
    "Lyg": "Lyckselesågen",
    "U": "Uppsala central",
    "zKöp": "f.d. Köpingebro",
    "La": "Ludvika",
    "Åa": "Åseda",
    "Ep": "Enköping",
    "Trd": "Traryd",
    "Sid": "Silverdalens bruk",
    "Be": "Backe",
    "Toy": "Torsby",
    "Hud": "Hulingsryd",
    "Övm": "Överum",
    "xF": "Faringe",
    "Hf": "Hultsfred",
    "xMg": "Moga",
    "Snl": "Skanlog",
    "xAlg": "Almunge",
    "Vme": "VME",
    "Tim": "Timsfors",
    "Svi": "Södra Vi",
    "Nbr": "Nybybruk",
    "Mrd": "Markaryd",
    "xLbr": "Länna bruk",
    "xLna": "Länna",
    "xLöt": "Löt",
    "Si": "Simrishamn",
    "xSlä": "Selknä",
    "Sur": "Skutskär",
    "Kröb": "Krökesbo",
    "xLh": "Lövstahagen",
    "xFb": "Funbo",
    "Vib": "Vimmerby",
    "Möa": "Mörlunda",
    "xGa": "Gunsta",
    "xB": "Bärby",
    "xSa": "Skölsta",
    "Vå": "Västerås central",
    "xÅs": "Årsta",
    "xFl": "Fyrislund",
    "Smu": "Smålands Burseryd",
    "Fra": "Fristad",
    "Kör": "Kungsör",
    "Sl": "Sala",
    "Het": "Hestra",
    "K": "Katrineholms central",
    "Bs": "Borås central",
    "Nyb": "Nybro",
    "Lrd": "Landeryd",
    "Fre": "Fredrikslund",
    "Ghyn": "Grythyttans norra",
    "Vo": "Vansbro",
    "Äbg": "Ängelsberg",
    "Htg": "Hoting",
    "Sum": "Storuman",
    "Ko": "Kornsjö gränsen",
    "Fs": "Fors",
    "Fi": "Fiskeby",
    "Jäy": "Järlövsby",
    "Hpbg": "Hallsbergs personbangård",
    "Hge": "Hagge",
    "Lom": "Lomsmyren",
    "Sv": "Storvik",
    "Lp": "Linköpings central",
    "Lå": "Laxå",
    "Åp": "Åstorp",
    "Ors": "Orsa",
    "Jho": "Jakobshyttan",
    "Hs": "Hasselfors",
    "Våb": "Vålberg",
    "My": "Mjölby",
    "Mh": "Moholm",
    "Skn": "Skänninge",
    "Höja": "Höja",
    "Vdb": "Värmlands Bro",
    "Sfl": "Säffle",
    "Vld": "Vinlidsberg",
    "Bn": "Bollnäs",
    "Rte": "Röste",
    "Me": "Marmaverken",
    "Arb": "Arboga",
    "S": "Sveg",
    "Bia": "Birsta",
    "Trå": "Timrå",
    "Ls": "Ljusdal",
    "Grd": "Grevaryd",
    "Sr": "Storå",
    "Hkl": "Hudiksvall",
    "Gsv": "Göteborg Sävenäs",
    "Or": "Olskroken",
    "G": "Göteborgs central",
    "Gb": "Göteborgs norra",
    "Öv": "Östavall",
    "Dns": "Dynäs",
    "Ay": "Alby",
    "Svk": "Svenstavik",
    "Hmr": "Hammar",
    "Mdl": "Moradal",
    "Cas": "Callans såg",
    "Bsb": "Bensjöbacken",
    "Bf": "Brunflo",
    "Töv": "Töva",
    "Jpn": "Järpen",
    "Vnt": "Vackernäset",
    "Hmfs": "Hissmofors",
    "Kroj": "Krokom OJ",
    "Ma": "Mattmar",
    "Äfs": "Äggfors",
    "Str": "Storlien",
    "Uågb": "Umeå godsbangård",
    "Gim": "Gimonäs",
    "Hod": "Holmsund",
    "Jrn": "Jörn",
    "Mk": "Murjek",
    "Ntv": "Nattavaara",
    "Hpn": "Hallsberg Posten",
    "Kju": "Kjula",
    "Fok": "Folkesta",
    "Åggb": "Ånge godsbangård",
    "Kmb": "Kiruna malmbangård",
    "Pea": "Peuravaara",
    "Svv": "Svappavaara",
    "Kos": "Koskullskulle",
    "wRos": "Ropsten",
    "wBod": "Bodal",
    "wTov": "Torsvik",
    "wBag": "Baggeby",
    "wLab": "Larsberg",
    "wAga": "Aga",
    "wSkr": "Skärsätra",
    "wKoa": "Kottla",
    "wHöb": "Högberga",
    "wBvi": "Brevik",
    "wKäp": "Käppala",
    "wGåh": "Gåshaga",
    "wGåb": "Gåshaga brygga",
    "wAlv": "Alvik",
    "wAll": "Alléparken",
    "wKlv": "Klövervägen",
    "wSms": "Smedslätten",
    "wÅls": "Ålstensgatan",
    "wÅlG": "Ålstens Gård",
    "wHlt": "Höglandstorget",
    "wOll": "Olovslund",
    "wNot": "Nockeby torg",
    "wNob": "Nockeby",
    "Kv": "Krokvik",
    "Rut": "Rautas",
    "Rsn": "Rensjön",
    "Bfs": "Bergfors",
    "Tnk": "Torneträsk",
    "Sbk": "Stenbacken",
    "Kpe": "Kaisepakte",
    "Soa": "Stordalen",
    "Ak": "Abisko östra",
    "Bln": "Björkliden",
    "Kå": "Kopparåsen",
    "Vj": "Vassijaure",
    "Rsi": "Råtsi",
    "Stk": "Sikträsk",
    "Lin": "Linaälv",
    "Håk": "Håmojåkk",
    "Har": "Harrå",
    "Fjå": "Fjällåsen",
    "Lab": "Lappberg",
    "Gy": "Gäddmyr",
    "Kx": "Kalixfors",
    "Kia": "Kirunavaara",
    "Gv": "Gällivare",
    "Apt": "Aptas",
    "Mtn": "Mertainen",
    "Kaa": "Koijuvaara",
    "Pc": "Polcirkeln",
    "Kva": "Koskivaara",
    "Klv": "Kilvo",
    "Nrt": "Nuortikon",
    "Rps": "Ripats",
    "Hrt": "Harrträsk",
    "Hfs": "Holmfors",
    "Lså": "Ljuså",
    "Grs": "Gransjö",
    "Stä": "Sandträsk",
    "Glt": "Gullträsk",
    "Lkä": "Lakaträsk",
    "Nb": "Näsberg",
    "Tet": "Tolikberget",
    "Sän": "Sävastklinten",
    "Svt": "Sävast",
    "Sby": "Norra Sunderbyn",
    "Sus": "Sunderbyns sjukhus",
    "Bds": "Bodens södra",
    "Bud": "Buddbyn",
    "Nyf": "Nyfors",
    "Krb": "Karsbäcken",
    "Ldl": "Lidlund",
    "Sto": "Storträsk",
    "Thm": "Träskholm",
    "Mhn": "Myrheden",
    "Ltk": "Långträsk",
    "Sbi": "Storblåliden",
    "Klr": "Koler",
    "Sts": "Storsund",
    "Jnt": "Järneträsk",
    "Ktä": "Korsträsk",
    "Lrg": "Laduberg",
    "Brg": "Brännberg",
    "Dbn": "Degerbäcken",
    "Ht": "Hednoret",
    "Hls": "Hällnäs",
    "Tvb": "Tväråbäck",
    "Tvä": "Tvärålund",
    "Vdn": "Vindeln",
    "Yö": "Yttersjön",
    "Etk": "Ekträsk",
    "Luä": "Lubboträsk",
    "Åst": "Åsträsk",
    "Kaä": "Kattisträsk",
    "Ge": "Gottne",
    "Bjö": "Björnsjö",
    "Bj": "Björna",
    "Lnv": "Långvattnet",
    "Thö": "Trehörningsjö",
    "Nrs": "Norrfors",
    "Brs": "Brattsbacka",
    "Oxm": "Oxmyran",
    "Nyå": "Nyåker",
    "Öä": "Öreälv",
    "Hbä": "Högbränna",
    "Hsö": "Hörnsjö",
    "Dgm": "Degermyr",
    "Fsm": "Forsmo",
    "Öså": "Österås",
    "Slj": "Selsjön",
    "Baö": "Backsjön",
    "Gnå": "Grönåsen",
    "Som": "Stormyran",
    "Ap": "Aspeå",
    "Hoå": "Holmån",
    "Sop": "Skorped",
    "Käv": "Kälvattnet",
    "Anö": "Anundsjö",
    "Msl": "Mellansel",
    "Bet": "Bruksberget",
    "Koj": "Kosjärv",
    "Ran": "Rian",
    "Klx": "Kalix",
    "Set": "Stensundberget",
    "Vsk": "Vuonoskogen",
    "Kei": "Keräsjoki",
    "Hp": "Haparanda",
    "Mjv": "Morjärv",
    "Vtv": "Vitvattnet",
    "Lpk": "Lappträsk",
    "Kri": "Karungi",
    "Huö": "Hundsjön",
    "Nml": "Niemisel",
    "Brå": "Bjurå",
    "Gen": "Gåsträsken",
    "Sbä": "Sågbäcken",
    "Vän": "Vännäs norra",
    "Amk": "Arnemark",
    "Ffs": "Finnforsfallet",
    "Skl": "Skellefteå",
    "Btb": "Brattby",
    "Brd": "Brännland",
    "Uåö": "Umeå östra",
    "Öal": "Österalnö",
    "Glk": "Glommersträsk",
    "Håb": "Hålbergsliden",
    "Abb": "Abborrträsk",
    "Norl": "Nordlunda",
    "Ajr": "Arvidsjaur",
    "Åm": "Åmsele",
    "An": "Arvån",
    "Ly": "Lycksele",
    "Kat": "Kattisavan",
    "Gun": "Gunnarn",
    "Åd": "Ådalsliden",
    "Btå": "Betåsen",
    "Tsg": "Tågsjöberg",
    "Ros": "Rossön",
    "Nkr": "Norra Kikkejaur",
    "Mos": "Moskosel",
    "Vaj": "Varjisträsk",
    "Kåb": "Kåbdalis",
    "Mtm": "Maitum",
    "Jk": "Jokkmokk",
    "Pj": "Porjus",
    "Lms": "Lomselenäs",
    "Srs": "Sorsele",
    "Sir": "Sorsele industrispår",
    "Sgä": "Slagnäs",
    "Ava": "Avaviken",
    "Dta": "Dorotea",
    "Sti": "Storbergets industrispår",
    "Mlf": "Meselefors",
    "Vma": "Vilhelmina",
    "Lvd": "Lövliden",
    "Vjm": "Vojmån",
    "Hög": "Högbysjön",
    "Gia": "Gideåbacka",
    "Köa": "Könsa",
    "Sbe": "Saluböle",
    "Ruk": "Rundvik",
    "Nog": "Nordmaling",
    "Non": "Norrsjön",
    "Änö": "Ängersjö",
    "Hös": "Hössjön",
    "Noe": "Norrmjöle",
    "Sök": "Stöcke",
    "Slm": "Solum",
    "Han": "Harasjön",
    "Häb": "Hämrasviken",
    "Döe": "Drömme",
    "Bja": "Bjästa",
    "Gån": "Gålnäs",
    "Bön": "Bodsjön",
    "Grö": "Grötingen",
    "Ny": "Nyhem",
    "Ksg": "Karlsberg",
    "Dk": "Dockmyr",
    "Gsn": "Gastsjön",
    "Övö": "Övsjö",
    "Kln": "Kälarne",
    "Rob": "Roback",
    "Hå": "Håsjö",
    "Sngå": "Singsån",
    "Ru": "Ragunda",
    "Bsg": "Bispgården",
    "Fgö": "Fångsjöbacken",
    "Ga": "Graninge",
    "Hlm": "Helgum",
    "Ågy": "Ångebyn",
    "Dy": "Dysjön",
    "Bä": "Bräcke",
    "Ng": "Norrhög",
    "Msö": "Mellansjö",
    "Jå": "Juån",
    "Osö": "Ovansjö",
    "Tl": "Tallåsen",
    "Ltr": "Loster",
    "Hnn": "Hennan",
    "Hnb": "Hälsingenybo",
    "Öbn": "Örabäcken",
    "Rsö": "Ramsjö",
    "Lot": "Lottefors",
    "Ab": "Arbrå",
    "Vl": "Vallsta",
    "Små": "Simeå",
    "Ksö": "Karsjö",
    "Lö": "Lörstrand",
    "Jr": "Järvsö",
    "Skä": "Skästra",
    "Ob": "Ockelbo",
    "Mog": "Mo grindar",
    "Döl": "Döljebro",
    "Hdn": "Holmsveden",
    "Sym": "Skrytmyran",
    "Rbo": "Röstbo",
    "Ltj": "Lilltjära",
    "Kls": "Kilafors",
    "Sfn": "Storflon",
    "Nä": "Nälden",
    "Tåv": "Trångsviken",
    "Mi": "Mörsil",
    "Un": "Undersåker",
    "Åre": "Åre",
    "Du": "Duved",
    "Ånn": "Ånn",
    "Ens": "Enafors",
    "Stv": "Stavre",
    "Pl": "Pilgrimstad",
    "Ope": "Ope",
    "Ei": "Erikslund",
    "Jbg": "Johannisberg",
    "Ft": "Fränsta",
    "To": "Torpshammar",
    "Visk": "Viskan",
    "Std": "Stöde",
    "Nsö": "Nedansjö",
    "Vm": "Vattjom",
    "Jhl": "Johannedal",
    "Svj": "Svedje",
    "Möl": "Mörtsal",
    "Spk": "Sprängsviken",
    "Fnö": "Frånö",
    "Krf": "Kramfors",
    "Väy": "Västeraspby",
    "Pm": "Prästmon",
    "Lkm": "Lökom",
    "Ögd": "Övergård",
    "Stå": "Sollefteå",
    "Skk": "Skönvik",
    "Sen": "Stavreviken",
    "Hsy": "Hussjöby",
    "Hän": "Häggsjön",
    "Hnl": "Hällenyland",
    "Hfj": "Hamrångefjärden",
    "Hly": "Hilleby",
    "Tdj": "Trödje",
    "Krl": "Kringlan",
    "Ax": "Axmarby",
    "Säu": "Sunnäsbruk",
    "Gui": "Gussi",
    "Shv": "Söderhamns västra",
    "Käe": "Källene",
    "Lsn": "Losesjön",
    "Myra": "Myra",
    "Boda": "Boda",
    "Via": "Via",
    "Sten": "Stegsskogen",
    "Håg": "Harmånger",
    "Åmy": "Åmyran",
    "Gnp": "Gnarp",
    "Tjä": "Tjärnvik",
    "Åkg": "Årskogen",
    "Gön": "Gårdsjön",
    "Maj": "Maj",
    "Njb": "Njurundabommen",
    "Nly": "Nolby",
    "Sik": "Svartvik",
    "Mbv": "Murbergsviken",
    "Äsb": "Älandsbro",
    "Mb": "Mobodarne",
    "Osl": "Oslättfors",
    "Kfn": "Kolforsen",
    "Aft": "Alfta",
    "Edn": "Edsbyn",
    "Vna": "Voxna",
    "Grn": "Göringen",
    "Åkt": "Åskott",
    "Lit": "Lit",
    "Mfn": "Munkflohögen",
    "Js": "Jämtlands Sikås",
    "Löb": "Lövberga",
    "Yl": "Ytterhogdal",
    "Öhlg": "Överhogdals grusgrop",
    "Rjn": "Röjan",
    "Åsal": "Åsarna central",
    "Brn": "Brånan",
    "Fkr": "Fåker",
    "Klh": "Kallholsfors",
    "Fda": "Furudal",
    "Born": "Born",
    "Thd": "Tallhed",
    "Älo": "Älvho",
    "Feö": "Fågelsjö",
    "Smo": "Strömsbro",
    "Åh": "Åshammar",
    "Jb": "Järbo",
    "Msn": "Medskogsheden",
    "Mrs": "Morshyttan",
    "By": "Byvalla",
    "Dgn": "Dalgränsen",
    "Hä": "Hästbo",
    "Tså": "Torsåker",
    "Snt": "Snyten",
    "Hma": "Hökmora",
    "Kbn": "Karbenning",
    "Dn": "Dagarn",
    "Skb": "Skinnskatteberg",
    "Krn": "Krampen",
    "Nkt": "Näverkärret",
    "Sba": "Spannarboda",
    "Hg": "Hagaström",
    "Nvl": "Norra Valbo",
    "Vmu": "Villersmuren",
    "Kgd": "Kungsgården",
    "Gad": "Granstanda",
    "Ryg": "Ryggen",
    "Koä": "Korsnäs",
    "Hno": "Hinsnoret",
    "Orn": "Ornäs",
    "Slnä": "Sellnäs",
    "Uly": "Ulvshyttan",
    "Rmh": "Rämshyttan",
    "Gäg": "Gräsberg",
    "Khy": "Klenshyttan",
    "Gg": "Grängesberg",
    "Stl": "Ställdalen",
    "Hlt": "Herrhult",
    "Dgö": "Daglösen",
    "Gdl": "Geijersdal",
    "Sdm": "Sandmon",
    "Keb": "Kejsarbäcken",
    "Rbä": "Repbäcken",
    "Gnf": "Gagnef",
    "Lsd": "Leksand",
    "Lea": "Leksboda",
    "Tlg": "Tällberg",
    "Rv": "Rättvik",
    "Gså": "Garsås",
    "Snb": "Snickarbo",
    "Hdm": "Hedemora",
    "Vhy": "Vikmanshyttan",
    "St": "Säter",
    "Gtf": "Gustafs",
    "Sau": "Stora Tuna",
    "Gyo": "Grycksbo",
    "Vad": "Vad",
    "Sre": "Söderbärke",
    "Nbg": "Norberg",
    "Mim": "Mimer",
    "Spy": "Spännarhyttan",
    "Kbä": "Kolbäck",
    "Dt": "Dingtuna",
    "Jbk": "Jädersbruk",
    "Morp": "Munktorp",
    "Vsg": "Valskog",
    "Feb": "Fellingsbro",
    "Ålg": "Ålsäng",
    "Öa": "Ökna",
    "Aä": "Alväng",
    "Vka": "Vika",
    "Mrai": "Mora kommuns industrispår",
    "Mäk": "Märbäck",
    "Mlg": "Malung",
    "Mfj": "Mockfjärd",
    "Böo": "Björbo",
    "Råg": "Rågsveden",
    "Dj": "Dala-Järna",
    "Sån": "Stenåsen",
    "Kvä": "Karlstad Välsviken",
    "Skr": "Skåre",
    "Kud": "Klingerud",
    "Srt": "Strömtorp",
    "Svå": "Svartå",
    "Öl": "Ölme",
    "Ve": "Väse",
    "Srr": "Skattkärr",
    "Vdv": "Vedevåg",
    "Ld": "Lindesberg",
    "Rlå": "Rällså",
    "Kpg": "Kopparberg",
    "Knp": "Knapptorp",
    "Vik": "Vikersvik",
    "Kf": "Kortfors",
    "Gr": "Granbergsdal",
    "Kt": "Kapellet",
    "Ke": "Karlberg",
    "Tmö": "Tomteboda övre",
    "Åbe": "Årstaberg",
    "So": "Solna",
    "Udl": "Ulriksdal",
    "Huv": "Huvudsta",
    "Sub": "Sundbyberg",
    "Nst": "Stockholms norra",
    "Sci": "Stockholm City",
    "Sod": "Stockholm Odenplan",
    "Hu": "Huddinge",
    "Flb": "Flemingsberg",
    "Uts": "Utsikten",
    "Tu": "Tumba",
    "Södy": "Söderby",
    "Gau": "Garnudden",
    "Dån": "Dånviken",
    "Öte": "Östertälje",
    "Söu": "Södertälje syd undre",
    "Bre": "Bränninge",
    "Söc": "Södertälje centrum",
    "Mö": "Mölnbo",
    "Fle": "Flen",
    "Gn": "Gnesta",
    "Koe": "Kolke",
    "B": "Björnlunda",
    "Sh": "Stjärnhov",
    "Nsj": "Nyckelsjön",
    "Sp": "Sparreholm",
    "Skv": "Skebokvarn",
    "Sde": "Sköldinge",
    "Spn": "Stolpstugan",
    "Bt": "Baggetorp",
    "Vr": "Vingåker",
    "Hgö": "Högsjö",
    "Km": "Kilsmo",
    "På": "Pålsboda",
    "Söö": "Södertälje syd övre",
    "Bjn": "Björnkulla",
    "Msj": "Malmsjö",
    "Täl": "Tälle",
    "Skms": "Skymossen",
    "Öj": "Östansjö",
    "Hlö": "Hölö",
    "Vhd": "Vagnhärad",
    "Lre": "Lästringe",
    "Tba": "Tystberga",
    "Ssa": "Sjösa",
    "Ebg": "Enstaberga",
    "Jår": "Jönåker",
    "Åba": "Ålberga",
    "Kon": "Kolmården",
    "Gtå": "Getå",
    "Stö": "Strångsjö",
    "Smt": "Simonstorp",
    "xÖst": "Stockholms Östra",
    "xDjö": "Djursholms Ösby",
    "xLdm": "Lindholmen",
    "xSsu": "Stocksund",
    "xUnv": "Universitetet",
    "xMöb": "Mörby",
    "xBvv": "Bråvallavägen",
    "xDje": "Djursholms Ekeby",
    "xEnb": "Enebyberg",
    "xRnb": "Roslags Näsby",
    "xTbl": "Tibble",
    "xEsa": "Ensta",
    "xVis": "Visinge",
    "xTäb": "Täby kyrkby",
    "xKgl": "Kragstalund",
    "xBäl": "Bällsta",
    "xVlt": "Vallentuna",
    "xOta": "Ormsta",
    "xMnb": "Molnby",
    "xFsu": "Frösunda",
    "xEkg": "Ekskogen",
    "xKår": "Kårsta",
    "xVev": "Vendevägen",
    "xÖga": "Östberga",
    "xAlp": "Altorp",
    "xLhl": "Lahäll",
    "xNbe": "Näsbyallé",
    "xNbp": "Näsbypark",
    "xTäc": "Täby Centrum",
    "xGpf": "Galoppfältet",
    "xVbm": "Viggbyholm",
    "xHgä": "Hägernäs",
    "xAre": "Arninge",
    "xRdb": "Rydbo",
    "xTäl": "Täljö",
    "xÅru": "Åkers Runö",
    "xÅbg": "Åkersberga",
    "xTug": "Tunagård",
    "xÖsk": "Österskär",
    "Una": "Uppsala norra",
    "Myn": "Myrbacken",
    "Kn": "Knivsta",
    "Eby": "Ekeby",
    "Säy": "Säby",
    "Bna": "Brunna",
    "Jla": "Järlåsa",
    "Må": "Morgongåva",
    "Hy": "Heby",
    "Ist": "Isätra",
    "Kmy": "Kummelby",
    "Hgv": "Häggvik",
    "Skby": "Skavstaby",
    "Mr": "Märsta",
    "Sam": "Samnan",
    "Srv": "Storvreta",
    "Sas": "Salsta",
    "Jlo": "Järlebo",
    "Öh": "Örbyhus",
    "Tbo": "Tobo",
    "Säp": "Skärpan",
    "Tip": "Tierp",
    "Os": "Orrskog",
    "Mrm": "Marma",
    "Bom": "Bomansberget",
    "Gmo": "Gimo",
    "Bvr": "Blackvreten",
    "Arne": "Arlanda nedre",
    "Arns": "Arlanda södra",
    "Arnc": "Arlanda central",
    "Arnn": "Arlanda norra",
    "Bdo": "Broddbo",
    "Ry": "Rosshyttan",
    "Rt": "Ransta",
    "Tot": "Toresta",
    "Eko": "Ekolsund",
    "Gib": "Grillby",
    "Lub": "Lundby",
    "Ttu": "Tortuna",
    "Duo": "Duvbo",
    "Bkb": "Barkarby",
    "Jkb": "Jakobsberg",
    "Khä": "Kallhäll",
    "Kän": "Kungsängen",
    "Rke": "Rekarne",
    "Gru": "Grundbro",
    "Alä": "Almnäs",
    "Ryb": "Ryssjöbrink",
    "Lg": "Läggesta",
    "Mby": "Malmby",
    "Sgs": "Strängnäs",
    "Häd": "Härad",
    "Bva": "Barva",
    "Gttu": "Gamla Tortuna",
    "xStä": "Storängen",
    "xNeg": "Neglinge",
    "xHed": "Henriksdal",
    "xSik": "Sickla",
    "xNka": "Nacka",
    "xSjl": "Saltsjö-Järla",
    "xLiä": "Lillängen",
    "xSdn": "Saltsjö-Duvnäs",
    "xÖsv": "Östervik",
    "xFsä": "Fisksätra",
    "xIgb": "Igelboda",
    "xRvä": "Ringvägen",
    "xSbn": "Saltsjöbaden",
    "xTip": "Tippen",
    "xTab": "Tattby",
    "xEvb": "Erstaviksbadet",
    "xSln": "Solsidan",
    "Skg": "Skogås",
    "Fas": "Farsta strand",
    "Hön": "Högdalen",
    "Öso": "Ösmo",
    "Ssä": "Segersäng",
    "Hfa": "Hemfosa",
    "Ts": "Tungelsta",
    "Nks": "Nyköping södra",
    "Vre": "Vrena",
    "Sii": "Silinge",
    "Ksu": "Kvicksund",
    "Ssh": "Strömsholm",
    "Fsö": "Flens övre",
    "Mlö": "Mellösa",
    "Hnä": "Hälleforsnäs",
    "Bgv": "Bälgviken",
    "Hål": "Hållsta",
    "Skrp": "Skogstorp",
    "Nh": "Norsholm",
    "Gi": "Gistad",
    "Lgm": "Linghem",
    "Vsd": "Vikingstad",
    "Mt": "Mantorp",
    "Vt": "Vretstorp",
    "Lln": "Linddalen",
    "Gdö": "Gårdsjö",
    "Fa": "Finnerödja",
    "Äl": "Älgarås",
    "Sle": "Slätte",
    "T": "Töreboda",
    "Vä": "Väring",
    "Rmtp": "Regumatorp",
    "Ss": "Stenstorp",
    "Fn": "Falköpings norra",
    "Å": "Åsbro",
    "Skm": "Stenkumla",
    "Lmo": "Löcknamon",
    "Rus": "Runsala",
    "Mdm": "Mariedamm",
    "Go": "Godegård",
    "D": "Degerön",
    "Öna": "Önaskogen",
    "Hsa": "Hovsta",
    "Kbg": "Karlsborg",
    "Ip": "Igelstorp",
    "Tbr": "Tibro",
    "Fgs": "Fagersanna",
    "Mp": "Mölltorp",
    "Hova": "Hova",
    "Lyd": "Lyrestad",
    "Hsr": "Hasslerör",
    "Lnå": "Lugnås",
    "Fhm": "Forshem",
    "Lve": "Lovene",
    "Jps": "Järpås",
    "Gro": "Gubbero",
    "Am": "Almedal",
    "P": "Partille",
    "Sel": "Sävedalen",
    "Hr": "Herrljunga",
    "Fby": "Floby",
    "Kä": "Källeryd",
    "Vgå": "Vårgårda",
    "Agg": "Algutsgården",
    "A": "Alingsås",
    "Bgs": "Bryngenäs",
    "Ndv": "Norsesund västra",
    "Fd": "Floda",
    "Sn": "Stenkullen",
    "Lr": "Lerum",
    "J": "Jonsered östra",
    "Dl": "Dingle",
    "Rbh": "Rabbalshede",
    "Tnu": "Tanum",
    "Kgs": "Kragenäs",
    "Ske": "Skee",
    "Smd": "Strömstad",
    "Hal": "Hallinden",
    "Bsa": "Brastad",
    "Lye": "Lyse",
    "Svg": "Svenshögen",
    "Lj": "Ljungskile",
    "Sve": "Säve",
    "Yb": "Ytterby",
    "Kde": "Kode",
    "Sth": "Stora Höga",
    "Mdn": "Mölndals nedre",
    "Ldo": "Lindome",
    "Lgd": "Ledsgård",
    "Kb": "Kungsbacka",
    "Lek": "Lekarekulle",
    "Få": "Frillesås",
    "Haa": "Hamra",
    "Tye": "Tyllered",
    "Teo": "Torebo",
    "Fabp": "Falkenberg personstation",
    "He": "Heberg",
    "Bp": "Brännarp",
    "Btp": "Biskopstorp",
    "Ea": "Eldsberga",
    "Laov": "Laholm västra",
    "Bån": "Båstad norra",
    "För": "Förslöv",
    "Vip": "Viarp",
    "Baa": "Barkåkra",
    "Ä": "Ängelholm",
    "Fur": "Furet",
    "Hd": "Halmstads central",
    "Fgå": "Fagerås",
    "Hbd": "Högboda",
    "Bu": "Brunsbergstunneln",
    "En": "Edane",
    "Ot": "Ottebol",
    "Åt": "Åmotfors",
    "Cg": "Charlottenberg",
    "Än": "Älvängen",
    "Agb": "Agnesberg",
    "Alh": "Alvhem",
    "Öx": "Öxnered",
    "Veas": "Velanda Södra",
    "Vpm": "Varpemossen",
    "Tbn": "Torbacken",
    "Drt": "Dals Rostock",
    "Bäf": "Bäckefors",
    "Ed": "Ed",
    "Mon": "Mon",
    "Rås": "Råskogen",
    "Skbl": "Skälebol",
    "Ml": "Mellerud",
    "El": "Edsvalla",
    "Sgm": "Segmon",
    "Byä": "Byälven",
    "Töe": "Tösse",
    "Ånm": "Ånimskog",
    "Kpm": "Köpmannebro",
    "Erk": "Erikstad",
    "Brl": "Brålanda",
    "Fdf": "Frändefors",
    "Bjh": "Bjurhem",
    "Mdö": "Mölndals övre",
    "Myk": "Mölnlycke",
    "Häy": "Härryda",
    "Hås": "Hindås",
    "Rd": "Rävlanda",
    "Boy": "Bollebygd",
    "Rö": "Rödberg",
    "Sae": "Sandared",
    "Ryr": "Ryr",
    "Vg": "Vänersborg central",
    "Gop": "Grästorp",
    "Håp": "Håkantorp",
    "Vaa": "Vara",
    "Ved": "Vedum",
    "Lju": "Ljung",
    "Boa": "Borgstena",
    "Ffl": "Frufällan",
    "Vfo": "Viskafors",
    "See": "Skene",
    "Hre": "Horred",
    "Vdi": "Veddige",
    "Bäb": "Bäckebron",
    "Lyv": "Lysvik",
    "Hvr": "Håverud",
    "Rån": "Råsjön",
    "Tsl": "Tisselskog",
    "Dld": "Dals Långed",
    "Lgb": "Långbron",
    "Vf": "Vartofta",
    "Jö": "Jönköpings central",
    "Sm": "Sandhem",
    "Mu": "Mullsjö",
    "Ho": "Habo",
    "Bry": "Bankeryd",
    "Hka": "Huskvarna",
    "Th": "Tenhult",
    "Fm": "Forserum",
    "Äng": "Äng",
    "Bor": "Bor",
    "Rym": "Rydaholm",
    "Apd": "Aplared",
    "Hil": "Hillared",
    "Grm": "Grimsås",
    "Gnö": "Gnosjö",
    "Hto": "Hillerstorp",
    "Soe": "Stolpen",
    "Syd": "Skillingaryd",
    "Kvh": "Klevshult",
    "Hrl": "Hörle",
    "Fod": "Forsheda",
    "Rft": "Reftele",
    "Sdr": "Smålandsstenar",
    "Sph": "Skeppshult",
    "Tou": "Torup",
    "Bög": "Brännögård",
    "Om": "Oskarström",
    "Lkn": "Lindekullen",
    "Smn": "Sommen",
    "Tns": "Tranås",
    "Gp": "Gripenberg",
    "Frd": "Frinnaryd",
    "Ras": "Ralingsås",
    "Fls": "Flisby",
    "Vim": "Vimnarp",
    "Gt": "Grimstorp",
    "Utp": "Ulvstorp",
    "Ahm": "Aleholm",
    "Rk": "Rörvik",
    "Lns": "Lidnäs",
    "Mo": "Moheda",
    "Gåp": "Gåvetorp",
    "Bld": "Blädinge",
    "Era": "Eneryda",
    "Diö": "Diö Norra",
    "Tun": "Tunneby",
    "Hv": "Hästveda",
    "Mud": "Mosselund",
    "Gm": "Gemla",
    "Räp": "Räppe",
    "Vö": "Växjö",
    "Em": "Emmaboda",
    "Ård": "Åryd",
    "Hvp": "Hovmantorp",
    "Lo": "Lessebo",
    "Sru": "Skruv",
    "Vfa": "Vissefjärda",
    "Hmö": "Holmsjö",
    "Spj": "Spjutsbygd",
    "Ck": "Karlskrona central",
    "Örs": "Örsjö",
    "Tre": "Trekanten",
    "Esn": "Ekenässjön",
    "Kba": "Korsberga",
    "Ory": "Ormaryd",
    "Hul": "Hult",
    "Mnd": "Mariannelund",
    "Bg": "Berga",
    "Rf": "Rimforsa",
    "Gng": "Gullringen",
    "Vibh": "Vimmerby Hamra",
    "Sro": "Storebro",
    "Bsä": "Bjärka-Säby",
    "Åvg": "Åtvidaberg",
    "Fal": "Falerum",
    "Nl": "Nelhammar",
    "Gal": "Gamleby",
    "Hkö": "Hökön",
    "Rud": "Ruda",
    "Lrp": "Lockarp",
    "Blv": "Burlöv",
    "Hb": "Helsingborgs central",
    "Mlb": "Mellby",
    "Väd": "Vätteryd",
    "Tö": "Tjörnarp",
    "Hö": "Höör",
    "Sg": "Stehag",
    "Dat": "Dammstorp",
    "Thl": "Tornhill",
    "Flp": "Flackarp",
    "Hjp": "Hjärup",
    "Åk": "Åkarp",
    "Vid": "Västra Ingelstad",
    "Ög": "Östra Grevie",
    "Svö": "Skytts Vemmerlöv",
    "Lnk": "Lernacken",
    "Stp": "Svågertorp",
    "Ka": "Kattarp",
    "Vh": "Vegeholm",
    "Öda": "Ödåkra",
    "Mia": "Maria",
    "Hlp": "Hasslarp",
    "Sie": "Stävie",
    "Fl": "Flädie",
    "Lma": "Lomma",
    "Gan": "Gantofta",
    "Våk": "Vallåkra",
    "Tgp": "Tågarp",
    "Bib": "Billeberga",
    "Tp": "Teckomatorp",
    "Knä": "Knäred",
    "Bjm": "Bjärnum",
    "Gnd": "Genevad",
    "Fin": "Finja",
    "Ty": "Tyringe",
    "Vto": "Västra Torup",
    "Hyl": "Hyllstofta",
    "Kvi": "Kvidinge",
    "Käb": "Kärreberga",
    "Bjuv": "Bjuv",
    "Mör": "Mörarp",
    "Påa": "Påarp",
    "Kg": "Kävlinge",
    "Atp": "Attarp",
    "Vöv": "Vinslöv",
    "Önd": "Önnestad",
    "Cr": "Kristianstads central",
    "Crgb": "Kristianstad GBG",
    "Kap": "Karpalund",
    "Fki": "Fjälkinge",
    "Sak": "Sandbäck",
    "Mru": "Mörrum",
    "Åmn": "Ångsågsmossen",
    "Bhb": "Bräkne-Hoby",
    "Rb": "Ronneby",
    "Nät": "Nättraby",
    "Hsk": "Hanaskog",
    "Vnö": "Vinnö",
    "Rka": "Rinkaby",
    "Tri": "Triangeln",
    "Hie": "Hyllie",
    "Saö": "Skabersjö",
    "Sea": "Svedala",
    "Lmm": "Lemmeströ",
    "Srp": "Skurup",
    "Rdg": "Rydsgård",
    "Rye": "Rynge",
    "Vlb": "Vilhelmsborg",
    "Cph": "Copenhagen Airport",
    "Phm": "Peberholm"
}

export default PLACE_NAMES;

