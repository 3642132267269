import React, { useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest, { Request } from "../api/APIRequest";

import { SiteView } from "../site/routes";
import Color from "../resources/colors";

import TrackLinkIcon from "../resources/images/tracklink-icon.svg";
import PlaceIcon from "../resources/images/place-icon.svg";
import FomulIcon from "../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../resources/images/track-distance-icon.svg";
import SessionIcon from "../resources/images/session-icon.svg";

import { SimpleSwitch } from "../components/Switch";
import ViewHeader from "../components/ViewHeader";
import UploadZone from "../components/upload/UploadZone";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import { ViewContainer, VFlex, HFlex } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";



// COMPONENT CONFIGURATION DATA ----------------------------

enum UploadType {
    TrackLinks = "Track Links",
    Places = "Places",

    Sessions = "Sessions",
    Fomuls = "Fomuls",
    TrackDistances = "Track Distances",
    CompletedTrackLinks = "Completed Track Links",
    TrackLinkOverrides = "Track Link Overrides"
}

const UploadTypeColorMap = {
    [UploadType.TrackLinks]: Color.blue,
    [UploadType.Places]: Color.yellow,
    [UploadType.Sessions]: Color.green,
    [UploadType.Fomuls]: Color.red,
    [UploadType.TrackDistances]: Color.orange,
    [UploadType.CompletedTrackLinks]: Color.purple,
    [UploadType.TrackLinkOverrides]: Color.lightBlue
}

const UploadTypeIconMap = {
    [UploadType.TrackLinks]: TrackLinkIcon,
    [UploadType.Places]: PlaceIcon,
    [UploadType.Sessions]: SessionIcon,
    [UploadType.Fomuls]: FomulIcon,
    [UploadType.TrackDistances]: TrackDistanceIcon,
    [UploadType.CompletedTrackLinks]: TrackLinkIcon,
    [UploadType.TrackLinkOverrides]: TrackLinkIcon
}

const UploadTypeRequestMap = {
    [UploadType.TrackLinks]: APIRequest.uploadTrackLinks,
    [UploadType.Places]: APIRequest.uploadPlaces,
    [UploadType.Sessions]: APIRequest.uploadSessions,
    [UploadType.Fomuls]: APIRequest.uploadFomuls,
    [UploadType.TrackDistances]: APIRequest.uploadTrackDistances,
    [UploadType.CompletedTrackLinks]: APIRequest.uploadCompletedTrackLinks,
    [UploadType.TrackLinkOverrides]: APIRequest.uploadTrackLinkDateOverrides
}

const UploadTypeDescriptionMap = {
    [UploadType.TrackLinks]: null,
    [UploadType.Places]: {
        "Signatur": "string",
        "Platsnamn": "string"
    },
    [UploadType.Sessions]: {
        "date": "date 2020-04-03 21:41:00",
        "status": "string (Status enum)",
        "note": "string",
        "username": "string"
    },
    [UploadType.Fomuls]: null,
    [UploadType.TrackDistances]: null,
    [UploadType.CompletedTrackLinks]: {
        "Bdl": "string",
        "frplts": "string",
        "frnod": "string",
        "tiplts": "string",
        "tinod": "string",
        "spår": "string / number / null",
        "lnkl": "number"
    },
    [UploadType.TrackLinkOverrides]: {
        "Bdl": "string",
        "frplts": "string",
        "frnod": "string",
        "tiplts": "string",
        "tinod": "string",
        "spår": "string / number / null"
    }
}

// -------------------------------------------------------




const Container = styled(ViewContainer)`
    overflow: visible;
`

const UploadContainer = styled(VFlex)`
    box-sizing: border-box;

    padding: 15px;
    border-radius: 5px;
    
    background-color: ${Color.darkGrey};

    .switch-container {
        align-items: center;
        width: fit-content;
        padding: 10px;
        margin-top: 20px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }

    .switch-container p {
        margin: 5px 0px;
        font-size: 12px;
    }

    .switch-container ${VFlex} {
        margin-right: 30px;
    }
`

const UploadContainerGrid = styled.div`
    display: flex;

    grid-template-columns: repeat(${Object.keys(UploadType).length}, 1fr);
    grid-template-row: auto;
    column-gap: 10px;
`

const UploadTypeContainer = styled(VFlex)<{type: string, selected: boolean}>`
    padding: 15px;
    align-items: center;
    border-radius: 5px;

    cursor: pointer;
    transition: 0.3s ease;

    background-color: ${props => props.selected ? Color.lightGrey : "transparent"};

    &: hover {
        transform: translateY(-2px);
        background-color: ${Color.lightGrey};        
    }

    &: hover h5 {
        background-color: ${props => UploadTypeColorMap[UploadType[props.type]]};
    }
    
    h5 {
        padding: 5px;
        border-radius: 5px;
        text-align: center;

        user-select: none;
        background-color: ${props => props.selected ? UploadTypeColorMap[UploadType[props.type]] : "transparent"};

        transition: 0.3s ease;
    }
`

const UploadTypeFormatContainer = styled(VFlex)`
    margin-top: 30px;
    animation: ${FadeAnimation} 0.5s ease 1;    

    h4, h5 {
        max-width: 100px;
        text-align: center;
    }

    h5 {
        font-weight: normal;
        margin-top: 10px;
    }

    .container {
        margin-right: 10px;
        min-width: 100px;
    }

    .header {
        font-weight: bold;
        background-color: ${Color.lightGrey};
        padding: 5px;
        border-radius: 5px;
    }
`


const UploadView = (): React.ReactElement => {

    // State
    const [selectedType, setSelectedType] = useState<UploadType>(UploadType.TrackLinks);
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    
    const [tlNoDeprecation, setTLNoDeprecation] = useState<boolean>(false); // Flag only used for Track Link upload

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Upload |  LCS Online`

    // Actions
    const selectUploadType = (type: UploadType) => {
        setFile(null);
        setSelectedType(type);
    }

    /**
     * Request file upload to API. 
     */
    const upload = async () => {      
        try {
            setIsUploading(true);
            const req: Request = UploadTypeRequestMap[selectedType]  // Get corresponding API request for selected upload type. 
            const payload = new FormData();
            payload.append("file", file);

            const queryStrings = selectedType === UploadType.TrackLinks ? {noDeprecation: !tlNoDeprecation} : null;
            const _ = await Thomas.request(req, payload, false, false, queryStrings) 
            
            // Update state
            setFile(null);
            setIsUploading(false);
            
            setPopup({
                title: "Upload complete!",
                message: `${selectedType} uploaded sucessfully`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            setIsUploading(false);
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <ViewHeader view={SiteView.Upload} />
            <UploadContainer>
                <UploadContainerGrid>
                    {Object.keys(UploadType).map(type => 
                        <UploadTypeContainer 
                            selected=   {selectedType === UploadType[type]}
                            type=       {type}
                            onClick=    {() => selectUploadType(UploadType[type])}
                        >
                            <img 
                                src={UploadTypeIconMap[UploadType[type]]}
                                style={{width: 30, height: 30, marginBottom: 15}}
                            />
                            <h5>{UploadType[type]}</h5>
                        </UploadTypeContainer>
                    )}
                </UploadContainerGrid>
                <UploadZone 
                    file=           {file}
                    isUploading=    {isUploading}
                    typeColor=      {UploadTypeColorMap[selectedType]}
                    setFile=        {setFile}
                    setPopup=       {setPopup}
                    performUpload=  {upload}
                />
                {UploadTypeDescriptionMap[selectedType] !== null &&
                    <UploadTypeFormatContainer>
                        <h3>Expected format</h3>
                        <HFlex style={{margin: "20px 0px"}}>
                            {Object.keys(UploadTypeDescriptionMap[selectedType]).map((key, index) => 
                                <div className="container" key={index}>
                                    <h4 className="header">{key}</h4>
                                    <h5>{UploadTypeDescriptionMap[selectedType][key]}</h5>
                                </div>
                            )}
                        </HFlex>
                    </UploadTypeFormatContainer>
                }
                {selectedType === UploadType.TrackLinks &&
                    <HFlex className="switch-container">
                        <VFlex>
                            <h4>Offline mode</h4>
                            <p>
                                Switch on to upload track links without
                                <br></br>
                                deprecating existing versions in case of update. 
                            </p>
                        </VFlex>
                        <SimpleSwitch 
                            value=     {tlNoDeprecation} 
                            onChange=  {setTLNoDeprecation} 
                        />
                    </HFlex>
                }
            </UploadContainer>
        </Container>
    )
}

export default UploadView;