import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";
import { SessionMin, SessionStatus } from "../api/LCSModels";

import Color from "../resources/colors";
import { SiteView } from "../site/routes";

import { ViewContainer } from "../pandora/styled";
import SessionsList from "../components/session/SessionsList";

import ViewHeader from "../components/ViewHeader";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import { SessionStatusTagMini } from "../components/session/SessionStatus";

import SessionFilter, { Filter, MappedSessionsDates } from "../components/session/SessionFilter";
import { HFlex } from "../pandora/styled";




interface SessionIntervalPayload {
    start: Date;
    end: Date;
}


const Container = styled(ViewContainer)`
    overflow: visible;
`

const SessionsView = (): React.ReactElement => {

    // State
    const [sessions, setSessions] = useState<SessionMin[] | null>(null);
    const [sessionDates, setSessionDates] = useState<MappedSessionsDates | null>(null);
    const [filter, setFilter] = useState<Filter>({year: null, month: null, date: null});
    const [sessionStatuses, setSessionStatuses] = useState<Record<SessionStatus, number> | {}>({});
    const [flaggedCount, setFlaggedCount] = useState<number>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    
    // Set doc title
    document.title = `Sessions |  LCS Online`

    // Effects

    useEffect(() => {

        // Attempt to set cached session dates
        setSessionDates(getCachedSessionDates());

        let interval: SessionIntervalPayload;
        // Attempt to retrieve and apply cached filter
        const cachedFilter = LocalStorage.get(LocalStorageKey.SessionsQueryFilter);
        if (cachedFilter !== undefined && cachedFilter !== null) {
            setFilter(cachedFilter);
            interval = getIntervalFromFilter(cachedFilter);
        } else {
            // Construct default session interval filter
            const currDate = new Date(new Date().toDateString());
            const intervalStart =  moment().subtract(2, 'months').startOf('month').startOf("day").toDate()
            interval = {start: intervalStart, end: currDate}
        }
            
        getSessions(interval);  
        getSessionDates();
    }, [])

    /**
     * Fetch sessions when filter updates
     */
    useEffect(() => {
        if (filter.year === null) return;
        const interval = getIntervalFromFilter(filter);
        getSessions(interval);  

        // Cache filter
        LocalStorage.set(LocalStorageKey.SessionsQueryFilter, filter);
    }, [filter])

    useEffect(() => {
        
        // Get session status map
        if (sessions === null) return;
        const _statuses = getSessionStatuses();
        setSessionStatuses(_statuses);

        // Get session flagged count
        const _flaggedCount = sessions.filter(s => s.flagged).length
        setFlaggedCount(_flaggedCount);
    }, [sessions])


    // Methods

    /**
     * Request all session dates in database from API
     */
    const getSessionDates = async () => {
        try {
            // Request session dates from Thomas. 
            const res: AxiosResponse = await Thomas.request(APIRequest.getAllSessionDates, {}, true); 
            
            const _sessionDates: Date[] = [];
            res.data.forEach(d => {
                const parsedDate = moment(d, "YYYY-MM-DD").toDate()
                _sessionDates.push(parsedDate)
            })

            setSessionDates(mapSessionDates(_sessionDates));
            
            // Cache retrieved session dates
            LocalStorage.set(LocalStorageKey.SessionDates, _sessionDates)
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not fetch Session dates",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request sessions for given date interval 
     * from Thomas API.
     */
    const getSessions = async (interval: SessionIntervalPayload) => { 
        try {
            // Request sessions from Thomas. 
            setIsLoading(true);
            const _sessions: SessionMin[] = await Thomas.request(APIRequest.getSessionsForInterval, interval) as SessionMin[]
            setSessions(_sessions);

            setIsLoading(false);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not fetch Sessions",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Construct date interval from filter.
     */
    const getIntervalFromFilter = (filter: Filter): SessionIntervalPayload => {

        const year: number = Number(filter.year!);
        const month = filter.month;
        const date = filter.date;

        let start: Date;
        let end: Date;

        if (month === null) {
            // YEAR - Get start and end of year
            start = moment({year: year}).startOf("year").toDate();
            end = moment({year: year}).endOf("year").toDate();
        } else if (date === null) {
            // MONTH - Get start and end of month
            const _month = Number(month!) - 1   // Moment uses zero based indexing for month 
            start = moment({year: year, month: _month}).startOf("month").toDate();
            end = moment({year: year, month: _month}).endOf("month").toDate();
        } else {
            // DAY - Get start and end of day
            const _month = Number(month!) - 1   // Moment uses zero based indexing for month 
            const _date = Number(date!)
            start = moment({year: year, month: _month, day: _date}).startOf("day").toDate();
            end = moment({year: year, month: _month, day: _date}).endOf("day").toDate();
        }
        
        return {start: start, end: end}
    }

    /**
     * Map session dates for every date for month
     * for year from list of session dates.
     */
    const mapSessionDates = (_sessionsDates: Date[]): MappedSessionsDates => {
        const mapped: MappedSessionsDates = {};
        _sessionsDates.forEach((d: Date) => {
            const sessionDate = moment(d)
            const year = sessionDate.format("Y")
            const month = sessionDate.format("M")
            const date = sessionDate.format("D")
            
            if (!Object.keys(mapped).includes(year)) { 
                mapped[year] = {}; 
            }
            if (!Object.keys(mapped[year]).includes(month)) {  
                mapped[year][month] = []; 
            }
            if (!Object.keys(mapped[year][month]).includes(date)) { 
                mapped[year][month].push(date)
            }
        })
        return mapped;
    }

    /**
     * Attempt to retrieve cached session dates
     * from LocalStorage, will be exchanged with data
     * from onComponentMount request.
     */
    const getCachedSessionDates = (): MappedSessionsDates | null => {
        const cachedDates = LocalStorage.get(LocalStorageKey.SessionDates)
        if (cachedDates === null) 
            return null;
        return mapSessionDates(cachedDates);
    }

    /**
     * Count session statuses 
     * in search result.
     */
    const getSessionStatuses = (): Record<SessionStatus, number> | {} => { 
        let statuses = {};
        Object.keys(SessionStatus).forEach(status => {
            const count = sessions.filter(s => s.status === status).length
            if (count) statuses[status] = count
        })
        return statuses;
    }



    return (
        <Container>
            <ViewHeader view={SiteView.Sessions} />
            <SessionFilter 
                sessionDates=   {sessionDates}
                filter=     {filter}
                setFilter=  {setFilter}
            />
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            {isLoading && 
                <LoadingComponent message="Fetching sessions..." />
            }
            {sessions !== null && sessions.length === 0 && 
                <NoDataComponent message="There are no sessions registered." />
            }
            {sessions !== null && !isLoading && 
                <div>
                    {Object.keys(sessionStatuses).length !== 0 &&
                        <HFlex style={{margin: "30px 0px 10px"}}>
                            {Object.keys(sessionStatuses).map(s => 
                                <SessionStatusTagMini 
                                    status= {SessionStatus[s]}
                                    text=   {`${sessionStatuses[s]} ${s}`}
                                    css=    {{marginRight: 10, height: 20}}
                                />
                            )}
                            {flaggedCount !== 0 &&
                                <SessionStatusTagMini 
                                    status= {SessionStatus.NotStarted}
                                    text=   {`${flaggedCount} Flagged`}
                                    css=    {{height: 20}}
                                />
                            }
                        </HFlex>
                    }
                    <SessionsList 
                        sessions=    {sessions}
                    />  
                </div>
            }
        </Container>
    )
}

export default SessionsView;