import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { SiteRoute } from "../../site/routes";
import { TrackLinkMinWData, TrackLinkStatus } from "../../api/LCSModels";

import Color from "../../resources/colors";
import CopyIcon from "../../resources/images/copy-icon.svg"; 
import FlagIcon from "../../resources/images/flag-icon-red.svg";

import FomulListItem from "../data/FomulListItem";
import TrackDistanceListItem from "../data/TrackDistanceListItem";
import { CompletionTagMini } from "../track-link/CompletionTag";
import IconTag from "../IconTag";

import NoDataComponent from "../NoDataComponent";
import { HFlex, VFlex, Arrow, Tag, OpenTag } from "../../pandora/styled";





const Container = styled(VFlex)`
    height: auto;
    padding: 10px;
    padding-right: 15px;
    margin-top: 5px;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    background-color: ${Color.extraDarkGrey};

    transition: 0.3s ease;
`



interface SessionTrackLinkListProps {
    trackLink: TrackLinkMinWData
}

/**
 * Component display Track Links for 
 * a given Session with its corresponding
 * Fomul and Track Distance data.
 */
const SessionTrackLinkList = (props: SessionTrackLinkListProps): React.ReactElement => {
    
    // States
    const { trackLink } = props;    
    const [opened, setOpened] = useState<boolean>(false);

    // Check if all fomuls for track link are copies
    let allCopies = trackLink.fomuls.map(f => f.isCopy).every(Boolean)
    if (trackLink.fomuls.length === 0) allCopies = false;

    return (
        <Container>
            <HFlex 
                style={{justifyContent: "space-between", height: 20, alignItems: "center", cursor: "pointer"}}
                onClick={() => setOpened(!opened)}
            >
                <h4>{`${trackLink.fromPlace} ${trackLink.fromNode} ${trackLink.toPlace} ${trackLink.toNode} ${trackLink.trackNumber ?? ""}`}</h4>
                <HFlex style={{alignItems: "center", marginTop: 10}}>
                    {trackLink.flag !== null && trackLink.flag !== undefined &&
                        <img 
                            src=    {FlagIcon} 
                            style=  {{width: 20, height: 20, marginRight: 20}} 
                        />
                    }
                    {trackLink.status === TrackLinkStatus.Deleted &&
                        <Tag 
                            color=      {Color.red} 
                            style=      {{marginRight: 10, cursor: "auto"}}
                        >Deleted</Tag>
                    }
                    {allCopies && 
                        <IconTag 
                            text=   "ONLY COPIES"
                            icon=   {CopyIcon}
                            color=  {Color.orange}
                            style=  {{marginRight: 10}}
                        />
                    }
                    <CompletionTagMini 
                        completion= {trackLink.completion}
                        css=        {{marginRight: 10}}
                    />
                    <Link to={`${SiteRoute.TrackLink}?id=${trackLink.id}`} style={{display: "block"}}>
                        <OpenTag 
                            color=      {Color.lightGrey} 
                            hoverColor= {Color.extraLightGrey}
                            style=      {{marginRight: 10, width: 50}}
                            title=      {"Open track link"}
                        >Open</OpenTag>
                    </Link>
                    <Arrow rotate={opened ? 180 : 0}/>
                </HFlex>
            </HFlex>
            {!opened &&
                <HFlex style={{marginTop: 10}}>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{trackLink.fomuls.length} Fomuls</Tag>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{trackLink.trackDistances.length} Track Distances</Tag>
                </HFlex>
            }
            {opened && 
                <VFlex>
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Fomuls</h5>
                    {trackLink.fomuls.length !== 0 && trackLink.fomuls.map((f, index) => 
                        <FomulListItem 
                            key={`fomul-${index}`}
                            fomul={f} 
                        />
                    )}
                    {trackLink.fomuls.length === 0 && 
                        <NoDataComponent />
                    }
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Track Distances</h5>
                    {trackLink.trackDistances.length !== 0 && trackLink.trackDistances.map((td, index) => 
                        <TrackDistanceListItem 
                            key={`trackDistance-${index}`}
                            trackDistance={td} 
                        />
                    )}
                    {trackLink.trackDistances.length === 0 && 
                        <NoDataComponent />
                    }
                </VFlex>
            }
        </Container>
    )
}


export default SessionTrackLinkList;
