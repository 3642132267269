import React from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { SectionGeo } from "../../api/LCSModels";
import { getCompletionProgressColor } from "../../pandora/pandora";

import Color from "../../resources/colors";
import { HFlex } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations"



const SectionListItem = styled(HFlex)<{selected: boolean}>`
    align-items: center;
    justify-content: space-between;

    padding: 5px 10px;
    border-radius: 5px;
    min-height: 30px;
    
    background-color: ${props => props.selected ? Color.extraLightGrey : Color.darkGrey};
    color: white;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    transition: 0.3s ease;
    animation: ${FadeAnimation} 0.3s ease;

    .progress-container {
        width: 20px;
        height: auto;
        text-align: center;
    }

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`

const Container = styled.div`
    height: 80vh;
    width: 150px;
    margin-right: 15px;
    box-sizing: border-box;
    
    overflow-y: auto;

    &: first-child {
        margin-top: 0px;
    }

    ${SectionListItem} {
        margin-top: 3px;
    }

    ::-webkit-scrollbar {
        width: 0px;
    } 
`


interface NetworkMapSectionListProps {
    data: SectionGeo[];
    sectionFilter: number | null;
    setSectionFilter: (value: number | null) => void;
}

/**
 * List of sections for a given 
 * SectionGeo response presenting 
 * section completion progress. 
 */
const NetworkMapSectionList = (props: NetworkMapSectionListProps): React.ReactElement => {

    // Calculate section completion progress
    // for each section in passed data. 
    const getProgress = (d: SectionGeo[]): Record<string, number> => {
        let _progress: Record<string, number> | {} = {}

        d.forEach(s => {
            const sp = s.places.map(p => p.completion !== null ? 1 : (p.completedTrackLinksCount / p.trackLinksCount)).reduce((a, b) => a + b, 0)
            const spr = Math.round(sp / s.places.length * 100);  // as percentage
            _progress[s.sectionNumber] = spr
        })

        return _progress;
    }

    // State
    const { data, sectionFilter, setSectionFilter } = props;
    const progress: Record<string, number> = getProgress(data);

    return (
        <Container>
            <SectionListItem 
                onClick=    {() => setSectionFilter(null)}
                selected=   {sectionFilter == null}
            >All</SectionListItem>
            {data.map((s, index) => 
                <SectionListItem 
                    key=        {`section-list-item-${index}`}
                    onClick=    {() => setSectionFilter(index)}
                    selected=   {sectionFilter == index}
                >
                    {s.sectionNumber}
                    <div className="progress-container">
                        <CircularProgressbar 
                            value={progress[s.sectionNumber]} 
                            styles={buildStyles({
                                textSize: '18px',
                                pathColor: getCompletionProgressColor(progress[s.sectionNumber]),
                                textColor: '#FFF',
                                trailColor: Color.lightGrey,
                                backgroundColor: '#3e98c7',
                            })}
                        />
                    </div>
                </SectionListItem>    
            )}
        </Container>
    )
}

export default NetworkMapSectionList;