import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import { TrackLinkGeo, SessionGeo, FomulGeo, TrackDistanceGeo, PlaceGeo, LineGeo } from "../../api/LCSModels";
import MapSettings, { MapData } from "../../pandora/MapSettings";
import FomulTypes from "../../pandora/fomul-types";

import Color from "../../resources/colors";

import FomulIcon from "../../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../../resources/images/track-distance-icon.svg";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";
import LineIcon from "../../resources/images/line-icon.svg";

import { HFlex, Tag, OpenTag } from "../../pandora/styled";



// COMPONENT CONFIGURATION DATA ----------------------------

const MapDataTypeIconMap = {
    [MapSettings.Types.Fomul]: FomulIcon,
    [MapSettings.Types.TrackDistance]: TrackDistanceIcon,
    [MapSettings.Types.TrackLink]: TrackLinkIcon,
    [MapSettings.Types.Session]: SessionIcon,
    [MapSettings.Types.Place]: PlaceIcon,
    [MapSettings.Types.Line]: LineIcon
}

// -------------------------------------------------------


const TopBar = styled(HFlex)`

    align-items: center;
    justify-content: space-between;

    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: ${Color.darkGrey};
    
    h3 {
        font-weight: normal;
    }

    img {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }

    .id-container {
        margin-right: 10px;
    }

    .container {
        padding-right: 10px;
        margin: 0px 10px;
        border-right: 1px solid ${Color.lightGrey};
    }

    ${Tag} {
        cursor: auto;
        margin-right: 10px;
        font-weight: normal;
    }

    ${HFlex} {
        width: fit-content;
        align-items: center;
    }
`


interface MapTopBarProps {
    type: string;
    data: MapData;
}

/**
 * TopBar in MapView used to 
 * aggregate and summarize given data in map. 
 */
const MapTopBar = (props: MapTopBarProps): React.ReactElement => {

    // State
    const { data, type } = props;

    const [fomulCount, setFomulCount] = useState<number | null>(null);
    const [trackDistanceCount, setTrackDistanceCount] = useState<number | null>(null);

    const [avgGPSFix, setAvgGPSFix] = useState<number | null>(null);
    const [avgPDOP, setAvgPDOP] = useState<number | null>(null);
    const [avgSatellites, setAvgSatellites] = useState<number | null>(null);

    // Effects
    useEffect(() => {

        // Calculate average GPS data
        // for the given data set. 
        const _avgGPSFix = MapSettings.getAverageDataPoint(type, data, "gpsFix");
        setAvgGPSFix(_avgGPSFix);

        const _avgPDOP = MapSettings.getAverageDataPoint(type, data, "pdop");
        setAvgPDOP(_avgPDOP);

        const _avgSatellites = MapSettings.getAverageDataPoint(type, data, "satellites");
        setAvgSatellites(_avgSatellites);

        const dataCount = MapSettings.getDataCount(type, data);
        setFomulCount(dataCount[0]);
        setTrackDistanceCount(dataCount[1]);
    }, [])

    // Actions

    /**
     * Get color for quality
     * of GPS fix. 
     */
    const getGPSFixQualityColor = (gpsFix: number): string => {
        switch (true) {
            case (gpsFix >= 4): return Color.green;
            case (gpsFix === 3): return Color.orange;
            case (gpsFix < 3): return Color.red;
        }
        return Color.lightGrey;
    }

    /**
     * Get href to data view
     * depending on current map data type. 
     */
    const getLink = (): string => {
        const route = MapSettings.getTypeRoute(type)
        switch (type) {
            case MapSettings.Types.Fomul:
            case MapSettings.Types.TrackDistance:
            case MapSettings.Types.TrackLink:
            case MapSettings.Types.Session:
                return `${route}?id=${data["id"]}`

            case MapSettings.Types.Place:
            case MapSettings.Types.Line:
                return `${route}?s=places`

            default:
                throw new Error(`Unknown map data type passed to MapDataPopup ${type}`)
        }
    }

    /**
     * Get title for top bar
     * depending on current map data type. 
     */
    const getTitle = (): string => {
        switch (type) {
            case MapSettings.Types.Fomul: 
                return FomulTypes[(data as FomulGeo).type]

            case MapSettings.Types.TrackDistance: 
                return `TD ${Math.round((data as TrackDistanceGeo).horDist)} mm`

            case MapSettings.Types.TrackLink:
                const trackLink = (data as TrackLinkGeo)
                return `${trackLink.fromPlace} ${trackLink.fromNode} ${trackLink.toPlace} ${trackLink.toNode} ${trackLink.trackNumber ?? ""}`

            case MapSettings.Types.Session:
                const session = (data as SessionGeo)
                return moment(session.date).format("Do MMMM HH:mm YYYY")

            case MapSettings.Types.Place:
                const place = (data as PlaceGeo)
                return `${place.name}`

            case MapSettings.Types.Line:
                const line = (data as LineGeo)
                return `${line.fromPlace.name} - ${line.toPlace.name}`

            default:
                throw new Error(`Unknown map data type passed to MapDataPopup ${type}`)
        }
    }

    return (
        <TopBar>
            <HFlex>
                <img src={MapDataTypeIconMap[type]} />
                <h3>
                    {data["id"] !== undefined && 
                        <span className="id-container"><strong>#{data["id"]}</strong></span> 
                    }
                    {getTitle()}
                </h3>
            </HFlex>
            <HFlex>
                <HFlex className="container">
                    {avgGPSFix !== null &&
                        <Tag
                            color={getGPSFixQualityColor(avgGPSFix)}
                            hoverColor={getGPSFixQualityColor(avgGPSFix)}
                        >Average GPS Fix: {avgGPSFix}</Tag>
                    }
                    {avgPDOP !== null &&
                        <Tag
                            color={Color.lightGrey}
                            hoverColor={Color.lightGrey}
                        >Average PDOP: {avgPDOP}</Tag>
                    }
                    {avgSatellites !== null &&
                        <Tag
                            color={Color.lightGrey}
                            hoverColor={Color.lightGrey}
                        >Average satellites: {avgSatellites}</Tag>
                    }
                </HFlex>
                <HFlex className="container">
                    {fomulCount !== null &&
                        <Tag
                            color={Color.lightGrey}
                            hoverColor={Color.lightGrey}
                        >{fomulCount} Fomuls</Tag>
                    }
                    {trackDistanceCount !== null &&
                        <Tag
                            color={Color.lightGrey}
                            hoverColor={Color.lightGrey}
                        >{trackDistanceCount} Track Distances</Tag>
                    }
                </HFlex>
                <Link to={`${getLink()}`} style={{display: "block"}}>
                    <OpenTag 
                        color=      {Color.blue} 
                        hoverColor= {Color.lightBlue}
                        style=      {{marginLeft: 20, cursor: "pointer"}}
                        title=      {"Open data"}
                    >Open data</OpenTag>
                </Link>
            </HFlex>
        </TopBar>
    )
}

export default MapTopBar;