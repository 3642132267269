import React from "react";

import PLACE_NAMES from "../../pandora/place-names";



interface PlaceNamesDataListProps {
    id: string;
}

/**
 * Data list used to present 
 * input options to the user. 
 */
const PlaceNamesDataList = (props: PlaceNamesDataListProps): React.ReactElement => {
    return (
        <datalist id={props.id}>
            {Object.keys(PLACE_NAMES).map(place => 
                <option 
                    key=   {`option-${place}`}
                    value=  {PLACE_NAMES[place]} 
                />
            )}
        </datalist>
    )
}

export default PlaceNamesDataList;