import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import Thomas from "./api/Thomas";
import Auth from "./site/auth";
import App from './App';
import Redirect from "./site/redirect";


/**
 * If user not authenticated, 
 * redirects to ASuite login.
 */
const authenticated = Auth.init()
if (!authenticated) Redirect.asuite(true)

//Register interceptors
Thomas.initInterceptor();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App /> 
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()