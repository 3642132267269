import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import useIsMount from "../../pandora/hooks/useIsMount";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { SessionMin } from "../../api/LCSModels";

import { SiteRoute } from "../../site/routes";
import { TrackLinkMin, TrackLinkStatus } from "../../api/LCSModels";

import FlagIcon from "../../resources/images/flag-icon-red.svg";
import Color from "../../resources/colors";

import LoadingComponent from "../LoadingComponent";
import NoDataComponent from "../NoDataComponent";
import { CompletionTagMini } from "../track-link/CompletionTag";
import { HFlex, VFlex, OpenTag, Tag } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations"



const Container = styled(VFlex)<{selectable: boolean}>`
    position: relative;
    justify-content: center;
    padding: 10px;
    height: 60px;

    box-sizing: border-box;
    border-radius: 5px;

    background-color: ${Color.darkGrey};
    transition: 0.2s ease;

    &: hover {
        ${props => props.selectable ? `transform: translateY(-2px);` : ``}
        background-color: ${props => props.selectable ? Color.extraLightGrey : Color.darkGrey};
        cursor: ${props => props.selectable ? "pointer" : "auto"};
    }
`

const SignatureContainer = styled(HFlex)<{minimalColWidth: boolean}>`
    display: grid;
    height: 30px;

    grid-template-columns: auto repeat(2, ${props => props.minimalColWidth ? "auto" : "110px"}) auto;
    column-gap: 10px;

    span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        border-radius: 5px;
        padding: 5px 10px;
    }

    span > h4 {
        margin-right: 5px;
    }

    h4 {
        font-weight: normal;
        font-size: 13px;
    }
`

const SessionListContainer = styled(VFlex)`
    position: absolute;
    right: calc(100% + 20px);
    top: -20px;

    padding: 10px 10px 20px;
    width: 250px;
    height: fit-content;
    border-radius: 5px;

    background-color: black;
    z-index: 10;

    animation: ${FadeAnimation} 0.3s ease 1;
`

const SessionListItem = styled.h5`
    margin-top: 5px;
    padding: 10px;

    font-weight: normal;
    border-radius: 5px;
    background-color: ${Color.darkGrey};

    &: hover {
        background-color: ${Color.extraLightGrey};
    }
`



interface TrackLinkContainerProps {
    trackLink: TrackLinkMin;
    showDatesOption: boolean;
    selectAction?: (trackLink: TrackLinkMin) => void;
    showTrackLinkChange?: Function;
    minimalColWidth?: boolean;
    css?: React.CSSProperties;
    targetBlank?: boolean;
}

/**
 * Container used to display Track Links in 
 * a standardized way in UI . 
 */
const TrackLinkContainer = (props: TrackLinkContainerProps): React.ReactElement => {

    // State
    const { trackLink, showTrackLinkChange, selectAction, showDatesOption, css, minimalColWidth, targetBlank } = props;    

    const isMount = useIsMount();

    const [sessions, setSessions] = useState<SessionMin[] | null>(null);
    const [showSessions, setShowSessions] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    // Effects
    useEffect(() => {
        if (isMount || !showSessions || sessions !== null) return;

        /**
         * Request sessions for Track Link 
         * to display list of sessions on hover. 
         */
        const getSessions = async () => {
            try {
                setIsLoading(true);
                const payload = {id: trackLink.id, withData: false}
                const _sessions: SessionMin[] = await Thomas.request(APIRequest.getSessionsForTrackLink, payload) as SessionMin[];
                setSessions(_sessions);
            } catch (e) {
                console.log(e)
            }
            setIsLoading(false);
        }

        getSessions();
    }, [showSessions])

    return (
        <Container 
            selectable= {selectAction !== undefined} 
            style=      {css ?? {}}
            onClick=    {() => selectAction !== undefined ? selectAction(trackLink) : null}
        >
            <HFlex style={{justifyContent: "space-between", alignItems: "center"}}>
                <SignatureContainer minimalColWidth={minimalColWidth ?? false}>
                    <h4 style={{minWidth: 30, lineHeight: "30px"}}><b>{`${trackLink.section}`}</b></h4>
                    <span>
                        <h4><b>{`${trackLink.fromPlace}`}</b></h4>
                        <h4>{`${trackLink.fromNode}`}</h4>
                    </span>
                    <span style={{marginRight: 10}}>
                        <h4><b>{`${trackLink.toPlace}`}</b></h4>
                        <h4>{`${trackLink.toNode}`}</h4>
                    </span>
                    {(trackLink.trackNumber || trackLink.direction) &&
                        <span>
                            {trackLink.trackNumber && <h4>{`${trackLink.trackNumber}`}</h4>}
                            {trackLink.direction && <h4>{`${trackLink.direction}`}</h4>}
                        </span>
                    }
                </SignatureContainer>
                <HFlex style={{alignItems: "center"}}>
                    {trackLink.flag !== null && trackLink.flag !== undefined &&
                        <img 
                            src=    {FlagIcon} 
                            style=  {{width: 20, height: 20, marginRight: 20}} 
                        />
                    }
                    {trackLink.status === TrackLinkStatus.Deleted &&
                        <Tag 
                            color=      {Color.red} 
                            style=      {{marginRight: 10, cursor: "auto"}}
                        >Deleted</Tag>
                    }
                    <CompletionTagMini 
                        completion= {trackLink.completion}
                        css=        {{marginRight: 10}}
                    />
                    {showDatesOption &&
                        <Tag 
                            color=          {Color.lightGrey} 
                            hoverColor=     {Color.extraLightGrey}
                            style=          {{marginRight: 10, position: "relative"}}
                            onMouseEnter=   {() => setShowSessions(true)}
                            onMouseLeave=   {() => setShowSessions(false)}
                        >
                            Sessions
                            {showSessions &&
                                <SessionListContainer>
                                    <h4 style={{marginBottom: 10}}>Sessions</h4>
                                    {isLoading && 
                                        <LoadingComponent message="Fetching sessions" />
                                    }
                                    {sessions !== null && sessions.length === 0 &&
                                        <NoDataComponent message="No sessions" />
                                    }
                                    {sessions !== null && sessions.length !== 0 && sessions.map(s => 
                                        <Link to={`${SiteRoute.Session}?id=${s.id}`} style={{display: "block"}}>
                                            <SessionListItem>{moment(s.date).format("Do MMMM HH:mm YYYY")}</SessionListItem>
                                        </Link>
                                    )}
                                </SessionListContainer>
                            }
                        </Tag>
                    }
                    {showTrackLinkChange !== undefined &&
                        <OpenTag 
                            color=          {Color.lightGrey} 
                            hoverColor=     {Color.orange}
                            style=          {{marginRight: 10, position: "relative"}}
                            onClick=        {() => showTrackLinkChange()}
                            title=          {"Change Track Link"}
                        >
                            Change
                        </OpenTag>
                    }
                    <Link to={`${SiteRoute.TrackLink}?id=${trackLink.id}`} style={{display: "block"}} target={targetBlank ? "_blank": "_self"}>
                        <OpenTag 
                            color=      {Color.lightGrey} 
                            hoverColor= {Color.extraLightGrey}
                            style=      {{marginRight: 10}}
                            title=      {"Show Track Link data view"}
                        >Open Track Link</OpenTag>
                    </Link>
                </HFlex>
            </HFlex>
        </Container>
    )
}


export default TrackLinkContainer;
