import React from "react";
import styled from "styled-components";

import { SiteView, Site, AppViewConfig } from "../site/routes";

import FormatIcon from "../resources/images/format-icon.svg";
import ExportIcon from "../resources/images/export-icon.svg";
import AuditIcon from "../resources/images/audit-icon.svg";
import TrackLinkIcon from "../resources/images/tracklink-icon.svg";
import FomulIcon from "../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../resources/images/track-distance-icon.svg";
import NetworkIcon from "../resources/images/network-icon.svg";
import SessionIcon from "../resources/images/session-icon.svg";
import UploadIcon from "../resources/images/upload-icon.svg";
import MetricsIcon from "../resources/images/metrics-icon.svg";
import BinIcon from "../resources/images/delete-icon.png";


import { PageIcon, HFlex, VFlex } from "../pandora/styled";

// COMPONENT CONFIGURATION DATA ----------------------------

const SiteViewIconMap = {
    [SiteView.Format]: FormatIcon,
    [SiteView.Export]: ExportIcon,
    [SiteView.Audit]: AuditIcon,
    [SiteView.TrackLinks]: TrackLinkIcon,
    [SiteView.Network]: NetworkIcon,
    [SiteView.SearchFomuls]: FomulIcon,
    [SiteView.SearchTrackDistances]: TrackDistanceIcon,
    [SiteView.Sessions]: SessionIcon,
    [SiteView.Upload]: UploadIcon,
    [SiteView.Metrics]: MetricsIcon,
    [SiteView.Bin]: BinIcon
}

// -------------------------------------------------------


const Header = styled(HFlex)`
    align-items: flex-start; 
    margin-bottom: 20px; 
    padding-bottom: 30px; 
    border-bottom: 2px solid #21262c;

    p {
        font-size: 14px;
        color: white;
        margin: 10px 0px 0px 0px;
        margin-right: 50%;
    }

    @media only screen and (max-width: 1200px) {
        p {
            margin-right: 30%;
        }
    }

    @media only screen and (max-width: 750px) {
        p {
            margin-right: 20%;
        }
    }
`


interface ViewHeaderProps {
    view: SiteView
}

/**
 * View header used to display 
 * view title and icon for a specified view.
 */
const ViewHeader = (props: ViewHeaderProps): React.ReactElement => {

    const { view } = props;
    const viewConfig: AppViewConfig | null = Site.AppViews.getViewConfig(view);

    if (!viewConfig) return <div style={{background: "red"}}>Could not find view config for {view}</div>

    return (
        <Header>
            <PageIcon icon={SiteViewIconMap[view]}/>
            <VFlex>
                <h1>{viewConfig.name}</h1>
                {viewConfig.description && 
                    <p>{viewConfig.description}</p>
                }
            </VFlex>
        </Header>
    )
}

export default ViewHeader;