import React, { useState } from "react";
import styled from "styled-components";

import LinkIcon from "../resources/images/link-icon.png";
import CheckIcon from "../resources/images/check-mark-icon.png";

import Color from "../resources/colors";
import { GenericPopupProps } from "./GenericPopup";




const LinkButton = styled("div")<{clicked: boolean}>`
    height: 30px;
    width: fit-content;
    margin-right: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;

    background-color: ${props => props.clicked ? Color.green : Color.blue};
    background-image: url(${props => props.clicked ? CheckIcon : LinkIcon});
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 10px 50%;

    color: white;
    line-height: 30px;
    font-weight: bold;
    font-size: 12px;
    text-indent: 25px;

    user-select: none;
    cursor: pointer;

    transition: 0.3s linear;

    &: hover {
        background-color: ${props => props.clicked ? Color.green : Color.lightBlue};
    }
`



interface ShareButtonProps {
    link: string;
    displayField?: boolean;
    setPopup: (value: GenericPopupProps) => void;
}

const ShareButton = (props: ShareButtonProps): React.ReactElement => {

    // State
    const { link, setPopup } = props;
    const [clicked, setClicked] = useState<boolean>(false);

    // Actions

    /**
     * Copy link to clipboard.
     */
    const share = () => {
        navigator.clipboard.writeText(link)
        setClicked(true);
        setPopup({
            title: "Link copied!",
            message: "The link has been copied to your clipboard.",
            color: Color.green,
            setPopup: setPopup
        }) 
    }

    return (
        <div>
            <LinkButton 
                clicked={clicked}
                onClick={share}
                title=  {"Copy resource URL"}
            >{clicked ? "Link copied" : "Share link"}</LinkButton>
        </div>
    )
}


export default ShareButton;

