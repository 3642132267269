import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import FomulTypes from "../../pandora/fomul-types";
import { SiteRoute } from "../../site/routes";
import { DataStatus, FomulMin } from "../../api/LCSModels";

import Color from "../../resources/colors";
import CopyIcon from "../../resources/images/copy-icon.svg";

import { HFlex, Tag } from "../../pandora/styled";
import PositionTag from "../PositionTag";
import IconTag from "../IconTag";


const Container = styled(HFlex)<{backgroundColor?: string, hoverColor?: string}>`
    align-items: center;
    justify-content: space-between;

    padding: 10px;
    margin-top: 5px;
    height: 45px;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    cursor: pointer;
    background-color: ${props => props.backgroundColor ?? Color.darkGrey};

    transition: 0.3s ease;

    &: hover {
        transform: translateY(-2px);
        background-color: ${props => props.hoverColor ?? Color.extraLightGrey};
    }

    ${HFlex} {
        align-items: center;
    }
`



interface FomulListItemProps {
    fomul: FomulMin
    color?: string
    targetBlank?: boolean;
}

/**
 * Container used to list Fomuls in a standardized 
 * way in UI lists. Link to Fomul instance view. 
 */
const FomulListItem = (props: FomulListItemProps): React.ReactElement => {
    
    // States
    const { fomul, color, targetBlank} = props;

    return (
        <Link to={`${SiteRoute.Fomul}?id=${fomul.id}`} style={{display: "block"}} target={targetBlank ? "_blank": "_self"}>
            <Container backgroundColor={color}> 
                <HFlex>
                    <h6 style={{marginRight: 10}}>#{fomul.id}</h6>
                    <PositionTag 
                        atKm={fomul.atKm} 
                        atM={fomul.atM} 
                    />
                    <h6 style={{fontWeight: "normal", marginLeft: 10}}>{FomulTypes[fomul.type]}</h6>
                </HFlex>
                <HFlex>
                    {fomul.isCopy && 
                        <IconTag 
                            text=   "COPY"
                            icon=   {CopyIcon}
                            style=  {{marginLeft: 20}}
                            color=  {Color.orange}
                        />
                    }
                    {fomul.status === DataStatus.Deleted &&
                        <Tag 
                            color=      {Color.red} 
                            style=      {{marginLeft: 20}}
                        >Deleted</Tag>
                    }
                    {fomul.status === DataStatus.Deprecated &&
                        <Tag 
                            color=      {Color.yellow} 
                            style=      {{marginLeft: 20}}
                        >Deprecated</Tag>
                    }
                </HFlex>
            </Container>
        </Link>
    )
}


export default FomulListItem;



interface FomulListItemSelectableProps extends FomulListItemProps {
    hoverColor?: string;
    onClick: () => void;
}

/**
 * Container used to list Fomuls in a standardized way in
 * UI lists that are selectable. Allows for multiselection. 
 */
export const FomulListItemSelectable = (props: FomulListItemSelectableProps): React.ReactElement => {
    
    const { fomul, color, hoverColor, onClick } = props;    

    return (
        <Container 
            backgroundColor={color}
            hoverColor=     {hoverColor}
            onClick=        {onClick}
        >
            <HFlex>
                <h6 style={{marginRight: 10}}>#{fomul.id}</h6>
                <PositionTag 
                    atKm={fomul.atKm} 
                    atM={fomul.atM} 
                />
                <h6 style={{fontWeight: "normal", marginLeft: 10}}>{FomulTypes[fomul.type]}</h6>
            </HFlex>
            <HFlex>
                {fomul.isCopy && 
                    <IconTag 
                        text=   "COPY"
                        icon=   {CopyIcon}
                        style=  {{marginLeft: 20}}
                        color=  {Color.orange}
                    />
                }
                {fomul.status === DataStatus.Deleted &&
                    <Tag 
                        color=      {Color.red} 
                        style=      {{marginLeft: 20}}
                    >Deleted</Tag>
                }
                {fomul.status === DataStatus.Deprecated &&
                    <Tag 
                        color=      {Color.yellow} 
                        style=      {{marginLeft: 20}}
                    >Deprecated</Tag>
                }
            </HFlex>
        </Container>
    )
}