import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { InvoicingOptionAlt2, InvoicingOptionAlt2Type } from "../../api/LCSModels";

import PLACE_NAMES from "../../pandora/place-names";

import Color from "../../resources/colors";
import AddIcon from "../../resources/images/add-icon.png";
import DeleteIcon from "../../resources/images/delete-icon.png";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";

import { GenericPopupProps } from "../GenericPopup";

import Overlay from "../Overlay";
import NoDataComponent from "../NoDataComponent";
import LoadingComponent from "../LoadingComponent";
import { HFlex, VFlex, ContentDiv, IconButton, Button } from "../../pandora/styled";
import { ConfirmationPopupAnimation } from "../../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const InvoicingOptionAlt2TypeTitleMap = {
    [InvoicingOptionAlt2Type.Section]: "Sections",
    [InvoicingOptionAlt2Type.Place]: "Places"
}

const InvoicingOptionAlt2TypeDescriptionMap = {
    [InvoicingOptionAlt2Type.Section]: "Available railway network sections available for registration as Alt2 invoice option. Click to add.",
    [InvoicingOptionAlt2Type.Place]: "Available railway network places available for registration as Alt2 invoice option. Click to add."
}

const InvoicingOptionAlt2TypeSelectedDescriptionMap = {
    [InvoicingOptionAlt2Type.Section]: "Registered Alt2 invoice option sections. Click to remove.",
    [InvoicingOptionAlt2Type.Place]: "Registered Alt2 invoice option places. Click to remove."
}

const InvoicingOptionAlt2RequestMap = {
    [InvoicingOptionAlt2Type.Section]: APIRequest.addInvoicingAlt2Section,
    [InvoicingOptionAlt2Type.Place]: APIRequest.addInvoicingAlt2Place
}

// -------------------------------------------------------


const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;
    width: 50%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
`

const ContentListDiv = styled(ContentDiv)`
    max-height: 60vh;
    overflow-y: auto;

    h4 {
        padding: 10px;
        border-radius: 5px;
        background-color: ${Color.extraDarkGrey};
        margin-bottom: 15px;
        user-select: none;
    }

    p {
        height: 25px;
        color: white;
        font-size: 12px;
        margin-bottom: 20px;
        margin-left: 5px;
    }

    .sessions, .selected {
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
        
        cursor: pointer;
        user-select: none;
        font-weight: normal;

        transition: 0.2s ease;

        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: 95% 50%;

        border: solid 2px ${Color.extraDarkGrey};
    }

    .selected: hover {
        padding-right: 25px;
        background-color: ${Color.red};
        background-image: url(${DeleteIcon});
    }

    ::-webkit-scrollbar {
        width: 0px;
    } 
`

const SessionDateContainer = styled.h5`
    &: hover {
        padding-right: 25px;
        background-color: ${Color.green};
        background-image: url(${AddIcon});
    }
`



interface SessionDateSelectorPopupProps {
    close: Function;
    setPopup: (value: GenericPopupProps | null) => void
}

const InvoicingOptions = (props: SessionDateSelectorPopupProps): React.ReactElement => {

    // State
    const { close, setPopup } = props;

    const [sections, setSections] = useState<string[] | null>(null);
    const [sectionOptions, setSectionOptions] = useState<InvoicingOptionAlt2[] | null>(null);

    const [places, setPlaces] = useState<string[]>(Object.values(PLACE_NAMES).sort());
    const [placeOptions, setPlaceOptions] = useState<InvoicingOptionAlt2[] | null>(null);

    const [selectedOptionType, setSelectedOptionType] = useState<InvoicingOptionAlt2Type>(InvoicingOptionAlt2Type.Section);


    // Effects
    useEffect(() => {

        /**
         * Retrieve registered options and
         * section + places data lists. 
         */
        const getData = async () => {
            try {
                const _sections = await Thomas.request(APIRequest.getAllSections) as unknown as string[]; 
                const _sectionOptions = await Thomas.request(APIRequest.getAllInvoicingAlt2Sections) as InvoicingOptionAlt2[];
                const _placeOptions = await Thomas.request(APIRequest.getAllInvoicingAlt2Places) as InvoicingOptionAlt2[];

                setSections(_sections);
                setSectionOptions(_sectionOptions);
                setPlaceOptions(_placeOptions);
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
        }

        getData();
    }, [])

    // Actions

    /**
     * Post option value for currently 
     * selected option type. 
     */
    const registerOption = async (value: string) => {
        try {
            const payload = {[selectedOptionType]: getOptionValue(value)}
            const req = InvoicingOptionAlt2RequestMap[selectedOptionType]
            const _option = await Thomas.request(req, payload) as InvoicingOptionAlt2[];

            // Add option to avaiable options
            const _options = [...getCurrentSelectedData(), _option];
            getCurrentSelectionSetter()(_options);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request deregistration of option 
     * for option ID.
     */
    const unregisterOption = async (option: InvoicingOptionAlt2) => {
        try {
            const payload = {id: option.id}
            const _ = await Thomas.request(APIRequest.removeAlt2Option, payload);

            // Remove option from local options
            const _options = [...getCurrentSelectedData()];
            _options.splice(_options.indexOf(option), 1);
            getCurrentSelectionSetter()(_options);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Return data, sections or places that are
     * currently open for registration as options
     * for selected option type.
     */
    const getCurrentSelectionData = (): string[] | null => selectedOptionType === InvoicingOptionAlt2Type.Section ? sections : places;

    /**
     * Get the currently selected data for 
     * the selected option type.
     */
    const getCurrentSelectedData = (): InvoicingOptionAlt2[] | null => selectedOptionType === InvoicingOptionAlt2Type.Section ? sectionOptions : placeOptions;

    /**
     * Get label string for 
     * option list item. 
     */
    const getSelectedDataLabel = (value: string): string => selectedOptionType === InvoicingOptionAlt2Type.Section ? value : PLACE_NAMES[value];

    /**
     * Get the current state setter 
     * for the selected option type.
     */
    const getCurrentSelectionSetter = (): Function => selectedOptionType === InvoicingOptionAlt2Type.Section ? setSectionOptions : setPlaceOptions;

    /**
     * Return the value posted to the API 
     * for option registration. 
     * Section as is, places should be registered 
     * with their signature, rather than their name. 
     */
    const getOptionValue = (value: string): string => {
        if (selectedOptionType === InvoicingOptionAlt2Type.Place) {
            return Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === value) ?? value;
        }
        return value;
    }

    /**
     * Determine whether or not the 
     * given option is selected, determines
     * rendering in lists. 
     */
    const isOptionSelected = (option: string): boolean => {
        const selectedData = getCurrentSelectedData()
        if (selectedData === null) return false;
        const selectedOptions = selectedData.map(d => 
            selectedOptionType === InvoicingOptionAlt2Type.Place ? PLACE_NAMES[d.value] : d.value
        )
        return selectedOptions.includes(option)
    }

    return (
        <Overlay 
            css=        {"z-index: 10;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer  onClick={(e) => e.stopPropagation()}>
                    <h3>Invoicing options</h3>
                    <HFlex style={{marginTop: 20}}>
                        <IconButton     
                            color=      {selectedOptionType === InvoicingOptionAlt2Type.Section ? Color.blue : Color.lightGrey}
                            hoverColor= {selectedOptionType === InvoicingOptionAlt2Type.Section ? Color.blue : Color.extraLightGrey}
                            icon=       {TrackLinkIcon}
                            onClick=    {() => setSelectedOptionType(InvoicingOptionAlt2Type.Section)}
                            style=      {{textAlign: "center", textIndent: 10, width: 100}}
                        >Section</IconButton>
                        <IconButton     
                            color=      {selectedOptionType === InvoicingOptionAlt2Type.Place ? Color.blue : Color.lightGrey}
                            hoverColor= {selectedOptionType === InvoicingOptionAlt2Type.Place ? Color.blue : Color.extraLightGrey}
                            icon=       {PlaceIcon}
                            onClick=    {() => setSelectedOptionType(InvoicingOptionAlt2Type.Place)}
                            style=      {{textAlign: "center", textIndent: 10, width: 100}}
                        >Place</IconButton>
                    </HFlex>
                    <HFlex style={{marginTop: 20}}>
                        <ContentListDiv style={{width: "30%", marginRight: 10}}>
                            <h4>Selected</h4>
                            <p>{InvoicingOptionAlt2TypeSelectedDescriptionMap[selectedOptionType]}</p>
                            {getCurrentSelectedData() === null && 
                                <NoDataComponent message="None selected" />
                            }
                            {getCurrentSelectedData() !== null && 
                                getCurrentSelectedData().map((o, index) => 
                                    <h5
                                        key=        {`selected-${index}`}
                                        className=  "selected"
                                        title=      "Remove option"
                                        onClick=    {() => unregisterOption(o)}
                                    >{getSelectedDataLabel(o.value)}</h5>    
                            )}
                        </ContentListDiv>
                        <ContentListDiv style={{width: "70%"}}>
                            <h4>{InvoicingOptionAlt2TypeTitleMap[selectedOptionType]}</h4>
                            <p>{InvoicingOptionAlt2TypeDescriptionMap[selectedOptionType]}</p>
                            {getCurrentSelectionData() === null &&
                                <LoadingComponent message="Fetching session dates..." />
                            }
                            {getCurrentSelectionData() !== null && 
                                getCurrentSelectionData().filter(o => !isOptionSelected(o)).map((o, index) => 
                                    <SessionDateContainer
                                        key=        {`sessions-${index}`}
                                        className=  "sessions"
                                        title=      "Add option"
                                        onClick=    {() => registerOption(o)}
                                    >{o}</SessionDateContainer>    
                            )}
                        </ContentListDiv>
                    </HFlex>
                    <HFlex style={{justifyContent: "space-between", marginTop: 15}}>
                        <Button 
                            color=      {Color.darkGrey}
                            hoverColor= {Color.extraLightGrey}
                            onClick=    {() => close()}
                            title=      {"Cancel & close selection"}
                        >Close</Button>
                    </HFlex>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}

export default InvoicingOptions;
