import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import LocalStorage, { LocalStorageKey } from "../../site/localStorage";

import { SiteRoute } from "../../site/routes";
import { PlaceCompletionGeo } from "../../api/LCSModels";
import { getCompletionProgressColor } from "../../pandora/pandora";

import Color from "../../resources/colors";
import PlaceIcon from "../../resources/images/place-icon.svg";
import LineIcon from "../../resources/images/line-icon.svg";

import { HFlex, Tag, VFlex } from "../../pandora/styled";



const PopupContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;

    width: 100%;
    height: 100%
    border-radius: 5px;

    color: white;
    font-family: Montserrat, sans-serif;
    
    background: transparent;
    overflow: hidden;

    img { 
        width: 20px;
        height: 20px;
    }

    h6 {
        padding: 2px 5px;
        border-radius: 5px;
        background-color: ${Color.lightGrey};
        margin-right: 3px !important;
    }

    h5 {
        font-weight: normal;
        line-height: 13px;
    }

    .progress-container {
        width: 60px;
        height: auto;
        padding: 0px;
        text-align: center;
        margin-right: 20px;
    }

    ${Tag} {
        margin: 20px 0px 10px;
        cursor: auto;
    }
`

const TopContainer = styled(HFlex)`
    align-items: center;
    
    * {
        margin-right: 10px;
    }
`

const CompletionContainer = styled(HFlex)`
    align-items: center;

    margin-top: 10px;
    padding: 10px;

    background-color: ${Color.lightGrey};
    border-radius: 5px;
`



interface PlaceDataPopupProps {
    data: PlaceCompletionGeo
}

/**
 * Get Marker popup in Network map
 * presenting completion progress data 
 * for the given place of line. 
 * 
 * Allows place and line configurations based 
 * on fromPlace == toPlace which indicates if it's a 
 * line or not. 
 */
const PlaceDataPopup = (props: PlaceDataPopupProps): React.ReactElement => {

    // State
    const [place, setPlace] = useState<PlaceCompletionGeo>(props.data);

    // Effects
    useEffect(() => setPlace(props.data), [props.data]);

    // Actions

    /**
     * Passed place data is place or line. 
     */
    const isPlace = (): boolean => place.fromPlace.id === place.toPlace.id

    /**
     * Get Place or Line icon. 
     */
    const getIcon = (): string =>  isPlace() ? PlaceIcon : LineIcon;

    /**
     *  Navigate to given place or line 
     *  view by setting LocalStorage search keys since 
     *  PlaceView does not support query string search arguments. 
     */
    const openPlace = (): void => {
        const placeSearch = {fromPlace: place.fromPlace.signature, toPlace: place.toPlace.signature}
        LocalStorage.set(LocalStorageKey.PlaceSearch, placeSearch)
        window.location.assign(`${SiteRoute.Network}?s=places`);
    }

    /**
     * Calculate place / line completion progress. 
     */
    const getCompletionProgress = (): number => {
        const p = place.completion !== null ? 100 : Math.round((place.completedTrackLinksCount / place.trackLinksCount) * 100)
        return p; // as percentage
    }

    const getOpenTitle = (): string => isPlace() ? `Open ${place.fromPlace.name}` : `Open ${place.fromPlace.name} - ${place.toPlace.name}`

    return (
        <PopupContainer id={`mapdata-${place.fromPlace.id}`}>
            <VFlex css={"padding: 15px;"}>
                <TopContainer>
                    <img 
                        src={getIcon()} 
                    />
                    <h6>{place.fromPlace.signature}</h6>
                    <h5>{place.fromPlace.name}</h5>
                    {!isPlace() &&
                        <HFlex style={{alignItems: "center"}}>
                            <h4>-</h4>
                            <h6>{place.toPlace.signature}</h6>
                            <h5>{place.toPlace.name}</h5>
                        </HFlex>
                    }
                </TopContainer>
                {place.completion !== null &&
                    <Tag color={Color.green}>Completed</Tag>
                } 
                <CompletionContainer>
                    <div className="progress-container">
                        <CircularProgressbar 
                            value={getCompletionProgress()} 
                            text={`${place.completedTrackLinksCount}/${place.trackLinksCount}`}
                            styles={buildStyles({
                                textSize: '18px',
                                pathColor: getCompletionProgressColor(getCompletionProgress()),
                                textColor: '#FFF',
                                trailColor: Color.darkGrey,
                                backgroundColor: '#3e98c7',
                            })}
                        />
                    </div>
                    <VFlex>
                        <h5><strong>{place.completedTrackLinksCount}</strong> completed track links</h5>
                        <h5><strong>{place.trackLinksCount}</strong> track links total</h5>
                    </VFlex>
                </CompletionContainer>
                <Tag 
                    color=      {Color.blue}
                    hoverColor= {Color.lightBlue}
                    style=      {{cursor: 'pointer', marginTop: 20, width: "auto"}}
                    onClick=    {openPlace}
                >{getOpenTitle()}</Tag>
            </VFlex>
        </PopupContainer>
    );
}

export default PlaceDataPopup;
