import React from "react";
import styled from "styled-components";

import { SiteView, AppViewConfig } from "../site/routes";

import SessionIcon from "../resources/images/session-icon.svg";
import FomulIcon from "../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../resources/images/track-distance-icon.svg";
import UploadIcon from "../resources/images/upload-icon.svg";
import ExportIcon from "../resources/images/export-icon.svg";
import AuditIcon from "../resources/images/audit-icon.svg";
import TrackLinkIcon from "../resources/images/tracklink-icon.svg";
import NetworkIcon from "../resources/images/network-icon.svg";
import MapIcon from "../resources/images/map-icon.svg";
import MetricsIcon from "../resources/images/metrics-icon.svg";
import FormatIcon from "../resources/images/format-icon.svg";
import BinIcon from "../resources/images/delete-icon.png"

import Color from "../resources/colors";

import { RippleButton } from "../pandora/styled";
import { ItemContainerAnimation } from "../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const SiteViewIconMap = {
    [SiteView.Sessions]: SessionIcon,
    [SiteView.Upload]: UploadIcon,
    [SiteView.Export]: ExportIcon,
    [SiteView.Audit]: AuditIcon,
    [SiteView.TrackLinks]: TrackLinkIcon,
    [SiteView.Network]: NetworkIcon,
    [SiteView.SearchFomuls]: FomulIcon,
    [SiteView.SearchTrackDistances]: TrackDistanceIcon,
    [SiteView.Map]: MapIcon,
    [SiteView.Metrics]: MetricsIcon,
    [SiteView.Format]: FormatIcon,
    [SiteView.Bin]: BinIcon
}

const SiteViewColorMap = {
    [SiteView.Sessions]: Color.green,
    [SiteView.Upload]: Color.lightBlue,
    [SiteView.Export]: Color.purple,
    [SiteView.Audit]: Color.red,
    [SiteView.TrackLinks]: Color.blue,
    [SiteView.Network]: Color.yellow,
    [SiteView.SearchFomuls]: Color.lightOrange,
    [SiteView.SearchTrackDistances]: Color.lightPurple,
    [SiteView.Map]: Color.orange,
    [SiteView.Metrics]: Color.yellow,
    [SiteView.Format]: Color.turqoise,
    [SiteView.Bin]: Color.red
}

// -------------------------------------------------------


const ViewOptionIcon = styled("div")<{icon: string, color: string}>`
    width: 20px;
    height: 20px;
    margin-right: 15px;

    border-width: 0;
    outline: none;

    cursor: pointer;

    background-size: cover;
    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: cover;

    background-color: ${props => props.color};

    transition: 0.3s ease;
`

const ViewOptionButtonContainer = styled(RippleButton)<{optionColor: string, selected: boolean}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;

    padding: 5px;
    padding-left: 15px;

    border-left: 3px solid ${props => props.selected ? props.optionColor : "transparent"};

    transition: background 0.8s, border 0.3s ease;
    animation: ${ItemContainerAnimation} 0.3s 1;

    &: hover {
        border-left: 3px solid ${props => props.optionColor};
    }

    &: hover ${ViewOptionIcon} {
        background-color: ${props => props.optionColor};
    }
`

const ViewOptionTitle = styled("h3")<{isSelected: boolean}>`
    margin: 0;

    text-align: left;
    font-weight: bold;
    font-size: 11px;
    color: ${props => props.isSelected ? "white" :  Color.extraLightGrey};

    transition: 0.3s ease;
`

interface ViewOptionProps {
    viewConfig: AppViewConfig;
    isSelected: boolean;
    navigate: (v: SiteView) => void
}

const ViewOptionButton = (props: ViewOptionProps): React.ReactElement => {

    const { isSelected, viewConfig, navigate } = props
    const iconColor: string = props.isSelected ? SiteViewColorMap[viewConfig.view] : Color.extraLightGrey

    return (
        <ViewOptionButtonContainer
            selected=           {isSelected}
            color=              {Color.darkGrey}
            hoverColor=         {Color.extraLightGrey}
            optionColor=        {SiteViewColorMap[viewConfig.view]}
            onClick=            {() => navigate(viewConfig.view)}
        >
            <ViewOptionIcon 
                color=   {iconColor}
                icon=    {SiteViewIconMap[viewConfig.view]}
            />
            <ViewOptionTitle isSelected={isSelected}>
                {viewConfig.name}
            </ViewOptionTitle>
        </ViewOptionButtonContainer>
    )
}

export default ViewOptionButton;
