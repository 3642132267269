import React, { useEffect } from "react";
import styled from "styled-components";

import { PopupEntranceAnimation } from "../pandora/animations";
import PopupIcon from "../resources/images/warning-icon.svg";
import Color from "../resources/colors";



const Container = styled.div`
    position: fixed;
    z-index: 1;

    right: 10px;
    bottom: 50px;

    width: 20%;
    height: fit-content;
    padding: 20px;

    border-radius: 5px;
    background-color: black;
    color: white;
    overflow: hidden; 

    animation: ${PopupEntranceAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
    transition: 0.3s ease;
`

const Icon = styled("div")<{color: string}>`
    position: absolute;
    left: 15px;
    top: 15px;
    height: 40px;
    width: 40px;

    mask: url(${PopupIcon}) no-repeat 50% 50%;
    mask-size: cover;
    background-color: ${props => props.color};
`

const Title = styled("h1")<{size: number, weight?: string}>`
    position: relative;
    margin: 0px;
    width: auto;
    height: fit-content;

    font-size: ${props => props.size}px;
    font-weight: ${props => props.weight ?? "bold"};
    color: white;
    
    text-align: left;
    white-space: normal;
    word-break: break-word;
`




export interface GenericPopupProps {
    title?: string;
    message: string;
    color?: Color;
    timeout?: number;
    setPopup: (value: GenericPopupProps | null) => void;
}


/**
 * Usage:
    {popup && <GenericPopup 
                title=          {popup.title}
                message=        {popup.message}
                color=          {popup.color}
                setPopup=       {setPopup}
            />
    }
 * @param title Popup title
 * @param timeout Time after which popup should be removed.  
 * @param message Error description. 
 * @param setPopup State setter for error on parent Component. Used to remove warning popup. 
 */
const GenericPopup = (props: GenericPopupProps): React.ReactElement => {

    const { title, message, color, timeout, setPopup } = props;

    const TIMEOUT: number = timeout ?? 5000;

    // Remove popup after TIMEOUT delay. 
    useEffect(() => {
        const timer = setTimeout(() => {
            setPopup(null);
        }, TIMEOUT);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            <Icon color={color ?? Color.red}/>
            <div style={{paddingLeft: "50px"}}>
                <Title size={14} style={{marginBottom: 5}}>{title ?? "Something went wrong"}</Title>
                <Title size={11} weight={"normal"}>{message}</Title>
            </div>
        </Container>
    )
}

export default GenericPopup;