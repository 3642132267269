import React, { useState, useRef, useEffect }from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Map as MPMap, Marker as MPMarker } from "mapbox-gl";

import Thomas from "../api/Thomas";
import { Request } from "../api/APIRequest";
import QueryParser from "../site/queryParser";
import MapSettings, { MapData,  MarkerData } from "../pandora/MapSettings";

import { FomulGeo, TrackDistanceGeo, TrackLinkGeo, SessionGeo, PlaceGeo, LineGeo } from "../api/LCSModels";
import { MapBoxAPIToken } from "../pandora/tokens";

import Color from "../resources/colors";

import MapDataPopup from "../components/map/MapDataPopup";
import Marker from "../components/map/Marker";
import TopBar from "../components/map/MapViewTopBar";

import LoadingComponent from "../components/LoadingComponent";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import { ViewContainer } from "../pandora/styled";

import "../components/map/MapDataPopup.css";


const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = MapBoxAPIToken; 


const Container = styled(ViewContainer)`
    overflow: visible;
    height: 100%;
`

const MapContainer = styled.div`
    width: 100%;
    height: 80vh;
    border-radius: 5px;
`


const MapView = (): React.ReactElement => {

    // State
    const args = QueryParser.getQuery()
    const mapContainer = useRef<HTMLDivElement | null >(null);

    const [map, setMap] = useState<MPMap>(null);
    const [markers, setMarkers] = useState<MPMarker[]>([]);
    const [dataType, setDataType] = useState<string | null>(null);

    const [mapData, setMapData] = useState<MapData | null>(null);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Map |  LCS Online`

    // Effects  
    useEffect(() => {

        // Set map data type
        const arg = getArgType();
        if (arg === null) {
            setPopup({
                message: "No query parameters passed to view.",
                setPopup: setPopup
            })
        } else {
            setDataType(arg);    
        }
    }, [])

    useEffect(() => {
        if (dataType === null) return;

        // Request data from API 
        const performFetch = async () => {
            const req = MapSettings.QueryArgRequestMap[dataType]
            const argID = getArgValue();
            const d = await getData(req, argID)
            setMapData(d);
        }

        performFetch();
    }, [dataType]);

    useEffect(() => { 
        if (map === null) return;
        drawMarkers() 
    }, [mapData, map])


    /**
     * Handle initial map load from Mapbox.  
     */
    useEffect(() => {

        const initializeMap = ({ setMap, mapContainer }) => {
            // Create map instance. 
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: MapSettings.Styles.default,
                center: [13, 55],
                zoom: 1,
                reuseMaps: true, 
            });
            
            // Perform initial data load on map instance. 
            map.on('load', () => {
                if (map.loaded()) {
                    setMap(map);
                }
            });
        };

        // Initialise MapBox map instance if map 
        // has been loaded from MapBox API. 
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map])



    // Actions

    /**
     * Get argument type for mapview 
     * from query strings. 
     */
    const getArgType = (): string | null => {
        if (Object.keys(args).length === 0) return null;
        return Object.keys(args)[0];
    }

    /**
     * Get argument value for mapview
     * from query strings. 
     */
    const getArgValue = (): string | number | null => {
        if (Object.keys(args).length === 0) return null;
        return Object.values(args)[0] as string | number;
    }

    const getZoom = (d: FomulGeo | TrackDistanceGeo): object => {
        const zoom = MapSettings.getZoom(dataType)
        return {center: [d.longitude, d.latitude], zoom: zoom}
    }

    /**
     * Perform request to API 
     * for given data ID from query strings. 
     */
    const getData = async (req: Request, id: string | number): Promise<MapData | null> => {
        try {
            const payload = {id: id};
            const _data = await Thomas.request(req, payload) as MapData
            return _data;
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
        return null;
    }

    /**
     * Construct the marker data 
     * passed to the marker in MapBox for 
     * the different mapdata types. 
     */
    const getMarkerData = (): MarkerData => {

        switch (dataType) {
            // Utilise Typescript switch fallthough to return 
            // same value for several values
            case MapSettings.Types.Fomul: 
                return MapSettings.constructMarkerData([mapData as FomulGeo], []);

            case MapSettings.Types.TrackDistance: 
                return MapSettings.constructMarkerData([], [mapData as TrackDistanceGeo]);

            case MapSettings.Types.TrackLink: { 
                const d: TrackLinkGeo = {...mapData as TrackLinkGeo}
                return MapSettings.constructMarkerData(d.fomuls, d.trackDistances);
            }

            case MapSettings.Types.Session: {
                const d: SessionGeo = {...mapData as SessionGeo}
                return MapSettings.constructMarkerData(d.fomuls, d.trackDistances);
            }

            case MapSettings.Types.Place: {
                const d: PlaceGeo = {...mapData as PlaceGeo}
                return MapSettings.constructMarkerData(d.fomuls, d.trackDistances);
            }

            case MapSettings.Types.Line: {
                const d: LineGeo = {...mapData as LineGeo}
                return MapSettings.constructMarkerData(d.fomuls, d.trackDistances);
            }

            default:
                throw new Error(`Unknown datatype ${dataType}`)
        }
    }

    /**
     * Get marker data and draw markers in map. 
     */
    const drawMarkers = () => {

        // Remove all current markers on map 
        // to clean map for new pin rendering.
        if (markers.length !== 0) markers.forEach((m: MPMarker) => m.remove());

        // Iterate data in projects to display on map. 
        const newMarkers: MPMarker[] = [];
        const markerData: MarkerData = getMarkerData();

        if (markerData.fomul === undefined || markerData.trackdistance === undefined) return; 

        const markerDataKeys: string[] = Object.values(MapSettings.MarkerDataKeys)

        markerDataKeys.forEach((markerType: string) => {

            const markerTypeData: FomulGeo[] | TrackDistanceGeo[] = markerData[markerType];
            
            markerTypeData.forEach((d: FomulGeo | TrackDistanceGeo, index) => {
                const popupData = {data: d, type: markerType}
                const m = drawMarker(d, popupData);
                newMarkers.push(m);
                
                // Zoom to initial data point
                if (index === 0) {
                    map.flyTo(getZoom(d))
                    if (markerTypeData.length === 1) m.togglePopup()
                }
            });
        })
        
        setMarkers(newMarkers);
    }

    /**
     * Draw marker on map. 
     * @param d Data - Fomul or TrackDistance
     * @param popupData Data to pass to popup. 
     * @returns Marker instance for storage. 
     */
    const drawMarker = (d: FomulGeo | TrackDistanceGeo, popupData: {data: FomulGeo | TrackDistanceGeo, type: string}) => {
        // Create and render marker 
        const markerNode = document.createElement("div");
        ReactDOM.render(<Marker id={d.id} type={popupData.type}/>, markerNode);

        // Create and render popup
        const popup = React.createElement(MapDataPopup, popupData);
        const popupContainer = document.createElement('div');
        ReactDOM.render(popup, popupContainer);

        const coordinate = [d.longitude, d.latitude]
        const mpbxPopup = new mapboxgl.Popup({closeButton: false})

        const m = new mapboxgl.Marker(markerNode)
                                    .setLngLat(coordinate)
                                    .setPopup(mpbxPopup
                                    .setDOMContent(popupContainer))
                                    .addTo(map);
        return m;
    }

    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
           {mapData !== null && 
                <TopBar
                    type={dataType}
                    data={mapData}
                />
            }
            {mapData === null && 
                <LoadingComponent message={`Fetching data ${getArgType()} for id ${getArgValue()}`} />
            }
            <MapContainer 
                ref={el => (mapContainer.current = el)} 
            />
        </Container>
    )

}

export default MapView;
