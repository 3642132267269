import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import { SiteRoute } from "../../site/routes";
import Color from "../../resources/colors";
import { SessionMin } from "../../api/LCSModels";

import FlagIcon from "../../resources/images/flag-icon-white.svg";

import { HFlex, VFlex } from "../../pandora/styled";
import SessionStatusItem from "./SessionStatus";
import { getDateComponent } from "./SessionFilter";
import { FadeAnimation } from "../../pandora/animations";




const DateTitle = styled("div")<{fontSize: number, marginTop: number, marginBottom: number, width?: string}>`
    width:${props => props.width ?? "fit-content"};
    max-width: ${props => props.width ?? "150px"};

    height: auto;
    padding: 10px;
    border-radius: 5px;

    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;

    background-color: ${Color.extraDarkGrey};

    color: white;
    font-weight: bold;
    font-size: ${props => props.fontSize}px;
`

const SessionsGrid = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, 30%);

    column-gap: 10px;
    row-gap: 5px;
    opacity: 0;

    animation: ${FadeAnimation} 0.7s ease 1;
    animation-fill-mode: forwards;
`

const SessionListItemContainer = styled(HFlex)<{flagged: boolean}>`
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;

    border-radius: 5px;
    background-color: ${props => props.flagged ? Color.red : Color.darkGrey};

    transition: 0.3s ease;

    &: hover {
        transform: translateY(-2px);
        background-color: ${props => props.flagged ? Color.lightRed : Color.extraLightGrey};
    }
`


type InnerMappedSessions = Record<string, SessionMin[]>
type MappedSessions = Record<string, Record<string, InnerMappedSessions>>



interface SessionListItemProps {
    session: SessionMin;
}

const SessionListItem = (props: SessionListItemProps) => {

    const { session } = props;
    const date = moment(session.date).format("HH:mm")

    return (
        <SessionListItemContainer flagged={session.flagged}>
            {session.flagged &&
                <img 
                    src={FlagIcon} 
                    style={{width: 20, height: 20, marginRight: 10}} 
                />
            }
            <h4 style={{alignSelf: "flex-start", marginRight: "auto"}}>{date}</h4>
            <VFlex style={{justifyContent: "center", width: "fit-content", marginRight: "10px"}}>
                <h6>{session.fomulCount} Fomul</h6>
                <h6>{session.trackDistanceCount} TD</h6>
            </VFlex>
            <SessionStatusItem 
                status=     {props.session.status} 
                selectable= {false}
            />
        </SessionListItemContainer>
    )
}



interface SessionsListProps {
    sessions: SessionMin[];
}

const SessionsList = (props: SessionsListProps): React.ReactElement => {

    // Actions

    /**
     * Map returned sessions from Thomas to
     * their respective year, month and date components. 
     * Key mapping used together with filter to 
     * determine session UI component rendering for filter.
     */
    const mapSessions = (_sessions: SessionMin[]): MappedSessions => {
        const mapped: MappedSessions = {};
        _sessions.forEach((s: SessionMin) => {

            const sessionDate = moment(s.date)
            const year = sessionDate.format("Y")
            const month = sessionDate.format("M")
            const date = sessionDate.format("D")
            
            if (!Object.keys(mapped).includes(year)) { 
                mapped[year] = {}; 
            }
            if (!Object.keys(mapped[year]).includes(month)) {  
                mapped[year][month] = {}; 
            }
            if (!Object.keys(mapped[year][month]).includes(date)) { 
                mapped[year][month][date] = []; 
            }

            mapped[year][month][date].push(s)
        })
        return mapped;
    }

    // State
    const { sessions } = props;
    const [mappedSessions, setMappedSessions] = useState<MappedSessions | {}>({});
    
    // Effects
    useEffect(() => {
        const _mapped = mapSessions(sessions);
        setMappedSessions(_mapped);
    }, [sessions])

    return (
        <VFlex style={{boxSizing: "border-box"}}>
            {Object.keys(mappedSessions).reverse().map(year => 
                <VFlex>
                    <DateTitle fontSize={30} marginTop={40} marginBottom={0}>{getDateComponent("year", year)}</DateTitle>
                    {Object.keys(mappedSessions[year]).reverse().map(month => 
                        <VFlex>
                            <DateTitle fontSize={25} marginTop={25} marginBottom={0}>{getDateComponent("month", month)}</DateTitle>
                            {Object.keys(mappedSessions[year][month]).reverse().map(date => 
                                <div>
                                    <DateTitle fontSize={18} marginTop={5} marginBottom={5}>{getDateComponent("day", date)}</DateTitle>
                                    <SessionsGrid>
                                        {mappedSessions[year][month][date].map((s: SessionMin) => (
                                            <Link to={`${SiteRoute.Session}?id=${s.id}`} style={{textDecoration: "none"}}>
                                                <SessionListItem 
                                                    key=        {s.id}
                                                    session=    {s}
                                                />
                                            </Link>
                                        ))}
                                    </SessionsGrid>
                                </div>
                            )}
                        </VFlex>
                    )}
                </VFlex>
            )}
        </VFlex>
    )
}

export default SessionsList;
