import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import Color from "../../resources/colors";
import { TrackLink, ExportOverride } from "../../api/LCSModels";

import NoDataComponent from "../NoDataComponent";
import { HFlex, VFlex, DeleteButton, AddButton } from "../../pandora/styled";
import { ScaleAnimation } from "../../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const DATE_FORMAT = "YYYY-MM-DD"
const DATE_FORMAT_TIME = DATE_FORMAT + " HH:mm"

// -------------------------------------------------------

const ListContainer = styled(VFlex)`
    height: 150px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 0px;
    }   
`

const ListItemContainer = styled(HFlex)`
    align-items: center;

    height: auto;
    padding: 10px;
    margin-bottom: 5px;

    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};

    animation: ${ScaleAnimation} 0.8s 1;

    h5, h6 {
        font-weight: normal;
        margin-bottom: 5px;
    }
`


interface ExportOverrideListItemProps {
    exportOverride: ExportOverride;
    deleteOverride: (override: ExportOverride) => void;
}

const ExportOverrideListItem = (props: ExportOverrideListItemProps) => {

    const { exportOverride, deleteOverride } = props;
    
    return (
        <ListItemContainer>
            <VFlex>
                <h5><b>From</b> {moment(exportOverride.fromDate).format(DATE_FORMAT)}</h5>
                <h5><b>To</b> {moment(exportOverride.toDate).format(DATE_FORMAT)}</h5>
                <h6>Added by {exportOverride.user.name} on {moment.utc(exportOverride.updated).local().format(DATE_FORMAT_TIME)}</h6>
            </VFlex>
            <DeleteButton 
                onClick={() => deleteOverride(exportOverride)}
            />
        </ListItemContainer>
    )

}




interface ExportOverrideViewProps {
    trackLink: TrackLink;
    addOverride: (fromDate: string, toDate: string) => void;
    deleteOverride: (override: ExportOverride) => void;
}

/**
 * Component listing export override objects
 * for a given Track Link. Refer to Thomas backend
 * or api/LCSModels to learn how Export Overrides work. 
 */
const ExportOverrideView = (props: ExportOverrideViewProps): React.ReactElement => {

    // State
    const { trackLink, addOverride, deleteOverride } = props;
    const [fromDate, setFromDate] = useState<string>(moment(new Date()).format(DATE_FORMAT));
    const [toDate, setToDate] = useState<string>(moment(new Date()).format(DATE_FORMAT));

    // Actions
    const addExportOverride = () => {
        addOverride(fromDate, toDate);
    }

    return (
        <VFlex>
            {trackLink.exportOverrides.length !== 0 && 
                <ListContainer>
                    {trackLink.exportOverrides.map(o =>
                        <ExportOverrideListItem 
                            key=            {o.id}
                            exportOverride= {o}
                            deleteOverride= {deleteOverride}
                        />
                    )}
                </ListContainer>
            }
            {trackLink.exportOverrides.length === 0 && 
                <NoDataComponent 
                    message={"No export overrides"} 
                    css=    {"height: 150px;"}
                />
            }
            <HFlex style={{
                justifyContent: "space-between", 
                paddingTop: 10,
                borderTop: `solid 1px ${Color.lightGrey}`
            }}>
                <HFlex style={{alignItems: "center"}}>
                    <h5 style={{marginRight: 10}}>From</h5>
                    <input 
                        className="date-input"
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                    <h5 style={{marginRight: 10, marginLeft: 10}}>To</h5>
                    <input 
                        className="date-input"
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </HFlex>
                <AddButton 
                    onClick={addExportOverride} 
                    style=  {{marginLeft: 10}}
                    title=  {"Add export override"}
                />
            </HFlex>
        </VFlex>
    )

}

export default ExportOverrideView;


