import React, { useState, useRef, useEffect }from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Map as MPMap, Marker as MPMarker } from "mapbox-gl";

import { MapBoxAPIToken } from "../pandora/tokens";

import MapIcon from "../resources/images/map-icon.svg";
import Color, { alpha } from "../resources/colors";

import Marker from "./map/Marker";
import { IconButton } from "../pandora/styled";
import { FadeAnimation, PulseAnimation } from "../pandora/animations";


const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = MapBoxAPIToken; 



const Container = styled.div`
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const NavOverlay = styled.div`
    position: absolute;
    z-index: 5;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    background-color: ${alpha(Color.darkGrey, 0.85)};

    animation ${FadeAnimation} 0.3s 1;
`

const OpenTitle = styled.h1`
    text-transform: uppercase;
    animation ${PulseAnimation} 1s ease-out infinite;
`

const OpenMapButton = styled(IconButton)`
    position: absolute;
    z-index: 5;
    top: 5px;
    right: 5px;

    width: auto;
    height: 20px;
    margin: 0px;

    font-size: 11px;
    text-align: center; 
    line-height: 20px;

    transition: 0.2s ease;

    &: hover {
        padding: 5px 20px;
    }
`



interface MapProps {
    dataType: string
    centerPoint: [number, number]
    points?: [number, number][]
    zoom?: number
    link?: string
}

const MapComponent = (props: MapProps): React.ReactElement => {

    const { link, points, zoom, centerPoint, dataType } = props;

    // State
    const MARKER_DTYPES = ["fomul", "trackdistance", "place"]
    const mapContainer = useRef<HTMLDivElement | null >(null);

    const [map, setMap] = useState<MPMap>(null);
    const [hovering, setHovering] = useState<boolean>(false);
    const [isInteractive, setIsInteractive] = useState<boolean>(MARKER_DTYPES.includes(dataType));

    // Effects

    /**
     * Handle initial map load from Mapbox. 
     */
    useEffect(() => {

        const initialZoom = {
            center: centerPoint,
            zoom: zoom ?? 13
        }  

        const initializeMap = ({ setMap, mapContainer }) => {

            // Create map instance. 
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: `mapbox://styles/atritecrasmus/ckq5nj2sb0p0718oh0zsakt1n`,
                center: [13, 55],
                zoom: 1,
                reuseMaps: true, 
                interactive: isInteractive
            });
            
            // Perform initial data load on map instance. 
            map.on('load', () => {
                if (map.loaded()) {
                    setMap(map);
                    try {
                        map.flyTo(initialZoom)
                    } catch (e) {
                        console.log(e)
                    }
                }
            });
        };
        
        // Initialise MapBox map instance if map 
        // has been loaded from MapBox API. 
        if (!map) 
            initializeMap({ setMap, mapContainer });
            setMarkers();
    }, [map])


    const setMarkers = () => {
        if (map === null) return;
        if (MARKER_DTYPES.includes(dataType)) {
            const ps = points ?? [centerPoint]
            ps.forEach((p, index) => {
                addMarker(index, p);
            })
        }
    }

    const addMarker = (id: number, point: [number, number]) => {
        const markerNode = document.createElement("div");
        ReactDOM.render(<Marker id={id} type={dataType}/>, markerNode);
        const m = new mapboxgl.Marker(markerNode)
                                    .setLngLat(point)
                                    .addTo(map);    
    }


    return (
        <Container
            onMouseEnter = {(e) => setHovering(true)}
            onMouseLeave = {(e) => setHovering(false)}
        >
            {hovering && !isInteractive &&
                <Link to={link} style={{display: "block"}}>
                    <NavOverlay>
                        <img src={MapIcon} style={{width: 40, height: 40, marginBottom: 20}} />
                        <OpenTitle>Open map</OpenTitle>
                    </NavOverlay>
                </Link>
            }
            {isInteractive &&
                <Link to={link} style={{display: "block"}}>
                    <OpenMapButton 
                        color=      {Color.extraDarkGrey}
                        hoverColor= {Color.lightGrey}
                        icon=       {MapIcon}
                        title=      {`Open location in map`}
                    >Open in map</OpenMapButton>
                </Link>
            }
            <div 
                ref={el => (mapContainer.current = el)} 
                style={
                    {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0
                    }
            }/>
        </Container>
    )

}

export default MapComponent;