import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { AnnotationSessionWData } from "../../api/LCSModels";

import Color from "../../resources/colors";
import CopyIcon from "../../resources/images/copy-icon.svg"; 
import FlagIcon from "../../resources/images/flag-icon-white.svg";
import DeleteIcon from "../../resources/images/delete-icon.png";

import CommentInput from "../CommentInput";
import FomulListItem from "../data/FomulListItem";
import TrackDistanceListItem from "../data/TrackDistanceListItem";
import IconTag from "../IconTag";

import NoDataComponent from "../NoDataComponent";
import { HFlex, VFlex, Arrow, Tag, IconButton } from "../../pandora/styled";
import { FadeAnimation } from "../../pandora/animations";




const Container = styled(VFlex)`
    height: auto;
    padding: 10px;
    padding-right: 15px;
    margin-top: 5px;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    background-color: ${Color.extraDarkGrey};

    transition: 0.3s ease;

    ${IconButton} {
        text-align: center;
        textindent: 10px;
        width: auto;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
        padding: 5px 10px;
    }

    .comment {
        margin-right: 20px;
        color: ${Color.red};
        animation: ${FadeAnimation} 0.3s ease 1;
    }
`



interface AnnotationSessionDataListProps {
    annotation: AnnotationSessionWData;
    setComment: (annotationSession: AnnotationSessionWData, comment: string) => void;
    removeComment: (annotationSession: AnnotationSessionWData) => void;
}

/**
 * Component displays Annotation Sessions for 
 * a given Session with its corresponding
 * Fomul and Track Distance data.
 */
const AnnotationSessionDataList = (props: AnnotationSessionDataListProps): React.ReactElement => {
    
    // States
    const { annotation, removeComment, setComment } = props;    

    const [opened, setOpened] = useState<boolean>(false);
    const [showCommentInput, setShowCommentInput] = useState<boolean>(false);

    const start = moment.utc(annotation.start).local().format("HH:mm")
    const end = moment.utc(annotation.end).local().format("HH:mm")
    const date = moment.utc(annotation.end).local().format("Do MMMM YYYY")

    // Check if all fomuls for annotation session are copies
    let allCopies = annotation.fomuls.map(f => f.isCopy).every(Boolean)
    if (annotation.fomuls.length === 0) allCopies = false;

    // Actions
    const openCommentInput = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowCommentInput(true);
    }

    const updateComment = (comment: string) => {
        setComment(annotation, comment);
    }

    const deleteComment = () => {
        removeComment(annotation);
    }

    return (
        <Container>
            {showCommentInput &&
                <CommentInput 
                    updateComment=  {updateComment}
                    close=          {() => setShowCommentInput(false)}
                />    
            }
            <HFlex 
                style={{justifyContent: "space-between", height: 20, alignItems: "center", cursor: "pointer"}}
                onClick={() => setOpened(!opened)}
            >
                <h5 style={{fontWeight: "normal"}}> {start} - {end} {date} | <b>{annotation.user.name}</b></h5>
                <HFlex style={{alignItems: "center", marginTop: 10}}>
                    {allCopies && 
                        <IconTag 
                            text=   "ONLY COPIES"
                            icon=   {CopyIcon}
                            color=  {Color.orange}
                            style=  {{marginRight: 10}}
                        />
                    }
                    {annotation.comment === null && 
                        <IconButton     
                            color=      {Color.darkGrey}
                            hoverColor= {Color.red}
                            icon=       {FlagIcon}
                            onClick=    {openCommentInput}
                        >Add comment</IconButton>
                    }
                    {annotation.comment !== null && 
                        <HFlex style={{alignItems: "center"}}>
                            <h5 className="comment">"{annotation.comment}"</h5>
                            <IconButton     
                                color=      {Color.darkGrey}
                                hoverColor= {Color.red}
                                icon=       {DeleteIcon}
                                onClick=    {deleteComment}
                            >Remove comment</IconButton>
                        </HFlex>
                    }
                    <Arrow rotate={opened ? 180 : 0}/>
                </HFlex>
            </HFlex>
            {!opened &&
                <HFlex style={{marginTop: 10}}>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{annotation.fomuls.length} Fomuls</Tag>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, cursor: "auto", fontWeight: "normal"}}
                    >{annotation.trackDistances.length} Track Distances</Tag>
                </HFlex>
            }
            {opened && 
                <VFlex>
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Fomuls</h5>
                    {annotation.fomuls.length !== 0 && annotation.fomuls.map((f, index) => 
                        <FomulListItem 
                            key={`fomul-${index}`}
                            fomul={f} 
                        />
                    )}
                    {annotation.fomuls.length === 0 && 
                        <NoDataComponent />
                    }
                    <h5 style={{marginTop: 15, marginBottom: 10}}>Track Distances</h5>
                    {annotation.trackDistances.length !== 0 && annotation.trackDistances.map((td, index) => 
                        <TrackDistanceListItem 
                            key={`trackDistance-${index}`}
                            trackDistance={td} 
                        />
                    )}
                    {annotation.trackDistances.length === 0 && 
                        <NoDataComponent />
                    }
                </VFlex>
            }
        </Container>
    )
}


export default AnnotationSessionDataList;
