import qs from "query-string";


export default class QueryParser {

    public static INITIAL_LOAD: boolean = true;

    /**
     * Get the current query params from the
     * current URL.
     */
    public static getQuery = (): Record<string, unknown> => {
        return qs.parse(window.location.search)
    }

    /**
     * Parse the query string arguments. 
     * @param q 
     */
    public static getQueryArg = (key: string, isNumber: boolean = true): string | number | null => {
        const q = QueryParser.getQuery();
        if (!Object.keys(q).includes(key)) return null;
        try {
            const arg = q[key] as string;
            if (isNumber)
                return parseInt(arg)
            return arg
        } catch {
            return null
        }
    }
}
