import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { Flag } from "../../api/LCSModels";

import Color from "../../resources/colors";
import FlagIconWhite from "../../resources/images/flag-icon-white.svg";
import DeleteIcon from "../../resources/images/delete-icon.png";

import CommentInput from "../CommentInput";
import { HFlex, VFlex } from "../../pandora/styled";



const Container = styled(HFlex)<{flagged: boolean}>`
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    margin-left: 10px;

    width: fit-content;
    padding: 15px 20px;

    border-radius: 5px;
    background-color: ${props => props.flagged ? Color.red : Color.darkGrey};

    cursor: pointer;
    user-select: none;
    
    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.flagged ? Color.lightRed : Color.extraLightGrey};
    }

    img { 
        width: 25px;
        height: 25px;
        margin-right: 15px;
    }

    p {
        margin: 5px 0px;
        font-size: 12px;
    }
`


interface TrackLinkFlagProps {
    flag?: Flag;
    setFlag: (comment: string) => void;
    removeFlag: () => void;
}

const TrackLinkFlag = (props: TrackLinkFlagProps): React.ReactElement => {

    const { flag, setFlag, removeFlag } = props;

    return (
        <Container flagged={flag !== null}>
            {flag === null ?
                <SetFlaggedButton setFlag={setFlag}/>
                :
                <FlaggedComponent 
                    flag=       {flag}
                    removeFlag= {removeFlag} 
                />
            }
        </Container>
    )
}

export default TrackLinkFlag;


interface SetFlaggedButtonProps {
    setFlag: (comment: string) => void;
}
const SetFlaggedButton = (props: SetFlaggedButtonProps): React.ReactElement =>  {

    const { setFlag } = props;
    const [showCommentInput, setShowCommentInput] = useState<boolean>(false);

    return (
        <HFlex 
            onClick={() => setShowCommentInput(true)}
            style=  {{alignItems: "center"}}
        >
            {showCommentInput &&
                <CommentInput 
                    updateComment=  {setFlag}
                    close=          {() => setShowCommentInput(false)}
                />            
            }
            <img src={FlagIconWhite} />
            <h5>Set flagged</h5>
        </HFlex>
    )
}


interface FlaggedComponentProps {
    flag: Flag;
    removeFlag: () => void;
}
const FlaggedComponent = (props: FlaggedComponentProps): React.ReactElement=> {
    
    const { flag, removeFlag } = props;
    const [isHovering, setIsHovering] = useState<boolean>(false);    
    const date = moment.utc(flag.created).local().format("Do MMMM");
    
    return (
        <HFlex 
            style=          {{height: "fit-content", alignItems: "center"}}
            title=          {"Click to remove flag"}
            onClick=        {removeFlag}
            onMouseEnter=   {() => setIsHovering(true)}
            onMouseLeave=   {() => setIsHovering(false)}
        >   
            <img src={isHovering ? DeleteIcon : FlagIconWhite} />
            <VFlex>
                <h5>Flagged by {flag.user.name} on {date}</h5>
                <p>{flag.comment}</p>
            </VFlex>
        </HFlex>
    )
}