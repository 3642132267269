import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { SiteRoute } from "../site/routes";
import QueryParser from "../site/queryParser";

import PlaceView from "./PlaceView";
import SectionView from "./SectionView";

import PlaceIcon from "../resources/images/place-icon.svg";
import SectionIcon from "../resources/images/section-icon.svg";
import MapIcon from "../resources/images/map-icon.svg";

import Color from "../resources/colors";
import { SiteView } from "../site/routes";

import ViewHeader from "../components/ViewHeader";
import { HFlex, ViewContainer, IconButton } from "../pandora/styled";


enum NetworkSelectionType {
    sections = "sections",
    places = "places"
}

const Container = styled(ViewContainer)`
    overflow: visible;
`


const NetworkView = (): React.ReactElement => {

    // Set doc title
    document.title = `Network |  LCS Online`
    
    // State
    const QUERY_ARG = "s"
    const querySelectionType = QueryParser.getQueryArg("s", false) as string
    
    const [selectedType, setSelectedType] = useState<NetworkSelectionType>(NetworkSelectionType.sections)

    const sectionURL: string = SiteRoute.Network + `?${QUERY_ARG}=${NetworkSelectionType.sections}`
    const placeURL: string = SiteRoute.Network + `?${QUERY_ARG}=${NetworkSelectionType.places}`

    // Effects
    useEffect(() => {
        if (querySelectionType === null) switchToSectionsView();
        const _type = NetworkSelectionType[querySelectionType];
        setSelectedType(_type);
    }, [querySelectionType])

    // Actions
    const switchToPlaceView = () => {
        setURLPath(placeURL);
        setSelectedType(NetworkSelectionType.places);
    }

    const switchToSectionsView = () => {
        setURLPath(sectionURL);
        setSelectedType(NetworkSelectionType.sections);
    }

    const setURLPath = (path: string) => {
        const url = `${window.location.origin}${path}`
        window.history.replaceState({}, document.title, url);
    }

    return (
        <Container>
            <ViewHeader view={SiteView.Network} />
            <HFlex style={{marginTop: 10, marginBottom: 20, justifyContent: "space-between"}}>
                <HFlex style={{alignItems: 'center'}}>
                    <Link to={`${sectionURL}`} style={{display: "block"}}>
                        <IconButton     
                            color=      {selectedType === NetworkSelectionType.sections ? Color.extraLightGrey : Color.darkGrey}
                            hoverColor= {Color.extraLightGrey}
                            icon=       {SectionIcon}
                            onClick=    {() => setSelectedType(NetworkSelectionType.sections)}
                            style=      {{textAlign: "center", textIndent: 10, width: 200}}
                        >Sections</IconButton>
                    </Link>
                    <Link to={`${placeURL}`} style={{display: "block"}}>
                        <IconButton     
                            color=      {selectedType === NetworkSelectionType.places ? Color.extraLightGrey : Color.darkGrey}
                            hoverColor= {Color.extraLightGrey}
                            icon=       {PlaceIcon}
                            onClick=    {() => setSelectedType(NetworkSelectionType.places)}
                            style=      {{textAlign: "center", textIndent: 10, width: 200}}
                        >Places</IconButton>
                    </Link>
                </HFlex>
                <Link to={`${SiteRoute.NetworkMap}`} style={{display: "block"}}>
                    <IconButton     
                        color=      {Color.blue}
                        hoverColor= {Color.lightBlue}
                        icon=       {MapIcon}
                        style=      {{textAlign: "center", textIndent: 10, width: 200}}
                    >Network map</IconButton>
                </Link>
            </HFlex>
            {selectedType === NetworkSelectionType.sections &&
                <SectionView 
                    switchToPlaceView={switchToPlaceView}
                />
            }
            {selectedType === NetworkSelectionType.places &&
                <PlaceView />
            }
        </Container>
    )
}

export default NetworkView;