import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import Color from "../../resources/colors";
import { VFlex, HFlex, Button, Arrow } from "pandora/styled";
import LoadingComponent from "../LoadingComponent";



const Container = styled(VFlex)`
    padding: 15px;
    width: auto;

    background-color: ${Color.darkGrey};
    border-radius: 5px;

    h4 {
        width: fit-content;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const ContentGrid = styled.div`
    display: grid;

    grid-template-columns: 0.5fr 1fr 1fr;
    grid-template-rows: auto;

    column-gap: 10px;
    row-gap: 5px;

    padding: 0;
    height: auto;
    margin-top: 10px;
`

const ContentGridChild = styled(VFlex)`
    height: 200px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 0px;
    }   
`

const FilterSelect = styled(Button)`
    animation: none;
`


export interface Filter {
    year: string;
    month: string;
    date: string;
}

export type MappedSessionsDates = Record<string, Record<string, string[]>>

export const getDateComponent = (component: string, value: string): string => {
    switch (component) {
        case ("year"):
            return moment({year: Number(value)}).format("Y")
        case ("month"):
            return moment({month: Number(value) - 1}).format("MMMM")
        case ("day"):
            if (value === "31") return "31st"
            return moment({day: Number(value)}).format("Do")
        default:
            throw new Error(`Unknown Date component key '${component}' passed to getDateStringLiteral`)
    }
}



interface SessionFilterProps {
    sessionDates: MappedSessionsDates | null;
    filter: Filter;
    setFilter: (value: Filter | null) => void;
}

const SessionFilter = (props: SessionFilterProps): React.ReactElement => {

    const { sessionDates, filter, setFilter } = props;
    const [opened, setOpened] = useState<boolean>(false);
    
    // Actions

    /**
     * Update filter configuration object 
     * used to filter Sessions in session list. 
     * @param e Click event
     * @param key Date component to update.
     * @param value Date component filter value. 
     */
    const updateFilter = (e, key: string, value: string) => {
        e.stopPropagation();
        let _filter;
        if (key === "year") {
            _filter = {year: value, month: null, date: null};
        } else if (key === "month") {
            _filter = {year: filter.year, month: value, date: null};
        } else {
            _filter = {...filter};
        }
        _filter[key] = value;
        
        setFilter(_filter);
    }

    return (
        <Container>
            <HFlex 
                onClick={() => setOpened(!opened)}
                style={{justifyContent: "space-between", alignItems: "center", cursor: "pointer"}}
            >
                <h4>Search sessions</h4>
                <HFlex style={{alignItems: "center"}}>
                    {filter.year && 
                        <h4>{getDateComponent("year", filter.year)}</h4>
                    }
                    {filter.month && 
                        <h4 style={{marginLeft: 10}}>{getDateComponent("month", filter.month)}</h4>
                    }
                    {filter.date && 
                        <h4 style={{marginLeft: 10}}>{getDateComponent("day", filter.date)}</h4>
                    }
                    <Arrow 
                        rotate= {opened ? 180 : 0}
                        style=  {{marginLeft: 20}}
                    />
                </HFlex>
            </HFlex>
            {opened && 
                <div>
                    {sessionDates === null &&
                        <LoadingComponent message="Fetching session dates..." />
                    }
                    {sessionDates !== null &&
                        <ContentGrid>
                            <ContentGridChild>
                                {Object.keys(sessionDates).map(year => 
                                    <FilterSelect 
                                        key=        {year}
                                        color=      {filter.year === year ? Color.extraLightGrey : Color.lightGrey}
                                        hoverColor= {Color.extraLightGrey}
                                        onClick=    {(e) => updateFilter(e, "year", year)}
                                        style=      {{marginTop: 10, width: "auto"}}
                                    >{getDateComponent("year", year)}</FilterSelect>
                                )}
                            </ContentGridChild>
                            <ContentGridChild>
                                {filter.year !== null && Object.keys(sessionDates[filter.year]).map(month => 
                                    <FilterSelect 
                                        key=        {month}
                                        color=      {filter.month === month ? Color.extraLightGrey : Color.lightGrey} 
                                        hoverColor= {Color.extraLightGrey}
                                        onClick=    {(e) => updateFilter(e, "month", month)}
                                        style=      {{marginTop: 10, width: "auto"}}
                                    >{getDateComponent("month", month)}</FilterSelect>
                                )}
                            </ContentGridChild>
                            <ContentGridChild>
                                {filter.month !== null && Object.values(sessionDates[filter.year][filter.month]).map(date => 
                                    <FilterSelect 
                                        key=        {date}
                                        color=      {filter.date === date ? Color.extraLightGrey : Color.lightGrey}
                                        hoverColor= {Color.extraLightGrey}
                                        onClick=    {(e) => updateFilter(e, "date", date)}
                                        style=      {{marginTop: 10, width: "auto"}}
                                    >{getDateComponent("day", date)}</FilterSelect>
                                )}
                            </ContentGridChild>
                        </ContentGrid>
                    }
                </div>
            }
        </Container>
    )
}



export default SessionFilter;
