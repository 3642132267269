import React, { useEffect, useState } from "react";
import styled from "styled-components";

import 'react-circular-progressbar/dist/styles.css';

import Thomas from "../api/Thomas";
import APIRequest, { Request } from "../api/APIRequest";
import { PlaceFull, LineFull, PlaceCompletion } from "../api/LCSModels";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";

import PLACE_NAMES from "../pandora/place-names";
import Color from "../resources/colors";

import SearchIcon from "../resources/images/magnifying-glass.png";

import PlacesDataList from "../components/data-lists/PlacesDataList";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import NoDataComponent from "../components/NoDataComponent";
import LoadingComponent from "../components/LoadingComponent";

import PlaceDataContainer from "../components/section/PlaceDataContainer";
import LineDataContainer from "../components/section/LineDataContainer";

import { HFlex, ViewContainer, QueryResultContainer, Input, IconButton, VFlex, ContentDiv, Container } from "../pandora/styled";


interface Search {
    fromPlace: string;
    toPlace: string;
}

const PlaceListContainer = styled(QueryResultContainer)`
    height: calc(100% - 100px);
    padding: 0px;
`

const PlaceContentDiv = styled(ContentDiv)`
    padding: 15px;
`

const PlaceListItem = styled("h4")<{selected: boolean}>`
    margin-top: 5px;
    padding: 10px 15px;

    border-radius: 5px;

    background-color: ${props => props.selected ? Color.extraLightGrey : Color.extraDarkGrey};
    font-weight: normal;
    font-size: 12px;

    cursor: pointer;
    user-select: none;
    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.selected ? Color.extraLightGrey : Color.extraLightGrey};
    }
`



const PlaceView = (): React.ReactElement => {

    const getCachedSearch = (): Search => {
        const cachedSearch = LocalStorage.get(LocalStorageKey.PlaceSearch)
        const defaultPlace = Object.keys(PLACE_NAMES)[0]
        return cachedSearch ?? {fromPlace: defaultPlace, toPlace: defaultPlace}
    }

    const getCachedSearchInput = (): string => {
        const cachedSearch = getCachedSearch()
        const signature = Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === cachedSearch.fromPlace) ?? cachedSearch.fromPlace;
        return signature
    }

    // State
    const [places, setPlaces] = useState<string[]>(Object.values(PLACE_NAMES).sort())

    const [searchedPlace, setSearchedPlace] = useState<Search | null>(getCachedSearch());
    const [searchInput, setSearchInput] = useState<string>(getCachedSearchInput());

    const [selectedPlace, setSelectedPlace] = useState<PlaceFull | LineFull | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Effects
    useEffect(() => {
        if (searchedPlace === null) return;
        getPlaceData();
    }, [searchedPlace])


    // Actions

    const isPlaceRequest = (): boolean => searchedPlace.fromPlace === searchedPlace.toPlace

    const getPlaceData = async () => {

        // Reset place state
        setSelectedPlace(null);

        const getRequestConfig = (): Request => isPlaceRequest() ? APIRequest.getPlaceData : APIRequest.getLineData

        const getRequestPayload = (): object => isPlaceRequest() ? {signature: searchedPlace.fromPlace} : searchedPlace

        // Fetch requested searched place
        try {
            setIsLoading(true);
            const req = getRequestConfig();
            const payload = getRequestPayload();
            const _place: PlaceFull = await Thomas.request(req, payload) as PlaceFull
            setSelectedPlace(_place);

            // Cache searched result
            LocalStorage.set(LocalStorageKey.PlaceSearch, searchedPlace);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
        setIsLoading(false);
    }

    const completePlace = async () => {
        try {

            let payload;
            if (isPlaceRequest()) {
                const p = selectedPlace as PlaceFull
                payload = {fromPlaceID: p.id, toPlaceID: p.id}
            } else {
                const l = selectedPlace as LineFull
                payload = {fromPlaceID: l.fromPlace.id, toPlaceID: l.toPlace.id}
            }

            const _completion = await Thomas.request(APIRequest.completePlace, payload, false, true) as PlaceCompletion;
            
            // Update completion state locally
            const _selectedPlace = {...selectedPlace, completion: _completion};
            setSelectedPlace(_selectedPlace);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    const removePlaceCompletion = async () => {
        try {
            const payload = {id: selectedPlace.completion.id}
            const _ = await Thomas.request(APIRequest.uncompletePlace, payload);
            
            // Update completion state locally
            const _selectedPlace = {...selectedPlace, completion: null};
            setSelectedPlace(_selectedPlace);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    /**
     * Attempt to find place signature
     * from name inputted, else use inputed signature. 
     * 
     * Make sure to format signature to [UPPERCASE][lowercase...].
     */
    const findPlaceSignature = (name: string): string => {
        let signature = Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === name)
        if (signature === undefined) {
            signature = name[0].toUpperCase() + name.substring(1).toLowerCase()
        }
        return signature;
    }

    const selectPlace = (name: string) => {
        setSearchInput(name);
        const signature = findPlaceSignature(name)
        setSearchedPlace({fromPlace: signature, toPlace: signature});
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            selectPlace(e.target.value);
        }
    }

    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <HFlex>
                <PlaceContentDiv style={{width: "20%", marginRight: 10, height: "75vh"}}>
                    <h3>Places</h3>
                    <HFlex style={{alignItems: "center", marginTop: 10}}>
                        <Input
                            type=           "text"
                            placeholder=    "Search..."
                            value=          {searchInput}
                            onChange=       {(e) => setSearchInput(e.target.value)}
                            list=           {"place-names-data-list"}
                            fontSize=       {12}
                            onKeyPress=     {(e) => onKeyPress(e)}
                            style=          {{margin: "10px 0px", height: 35, backgroundColor: Color.lightGrey}}
                        />
                        <PlacesDataList id={"place-names-data-list"} />
                        <IconButton 
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            icon=       {SearchIcon}
                            onClick=    {() => selectPlace(searchInput)}
                            style=      {{textAlign: "center", marginLeft: 10, width: 20, height: 25}}
                        ></IconButton>
                    </HFlex>
                    <PlaceListContainer>
                        {places.map((place, index) => 
                            <PlaceListItem 
                                key=        {`place-${index}`}
                                selected=   {place === searchedPlace.fromPlace}
                                onClick=    {() => selectPlace(place)}
                            >
                                {place}
                            </PlaceListItem>
                        )}
                    </PlaceListContainer>
                </PlaceContentDiv>
                <PlaceContentDiv style={{width: "80%", marginLeft: 10, height: "75vh", padding: 20}}>
                    {selectedPlace === null && isLoading && 
                        <LoadingComponent message={`Fetching place data...`} />
                    }
                    {selectedPlace === null && !isLoading && 
                        <NoDataComponent message="Select a place..." />
                    }
                    {selectedPlace !== null && isPlaceRequest() &&
                        <PlaceDataContainer 
                            selectedPlace=         {selectedPlace as PlaceFull}
                            completePlace=         {completePlace}
                            removePlaceCompletion= {removePlaceCompletion}
                        />
                    }
                    {selectedPlace !== null && !isPlaceRequest() &&
                        <LineDataContainer 
                            line=                   {selectedPlace as LineFull}
                            openPlace=              {selectPlace}
                            completePlace=          {completePlace}
                            removePlaceCompletion=  {removePlaceCompletion}
                        />
                    }
                </PlaceContentDiv>
            </HFlex>
        </Container>
    )
}

export default PlaceView;