import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { Section, PlaceData } from "../api/LCSModels";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";

import Color from "../resources/colors";
import SearchIcon from "../resources/images/magnifying-glass.png";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import NoDataComponent from "../components/NoDataComponent";
import LoadingComponent from "../components/LoadingComponent";

import SectionDataContainer from "../components/section/SectionDataContainer";

import { HFlex, ViewContainer, QueryResultContainer, Input, IconButton, VFlex, MetaDataChildItem } from "../pandora/styled";




const Container = styled(ViewContainer)`
    overflow: visible;
`

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};
`

const SectionListContainer = styled(QueryResultContainer)`
    height: calc(100% - 100px);
    padding: 0px;
`

const SectionListItem = styled("h4")<{selected: boolean}>`
    margin-top: 5px;
    padding: 10px 15px;

    border-radius: 5px;

    background-color: ${props => props.selected ? Color.extraLightGrey : Color.extraDarkGrey};
    font-weight: normal;
    font-size: 12px;

    cursor: pointer;
    user-select: none;
    transition: 0.3s ease;

    &: hover {
        background-color: ${props => props.selected ? Color.extraLightGrey : Color.extraLightGrey};
    }
`


interface SectionViewProps {
    switchToPlaceView: Function;
}

const SectionView = (props: SectionViewProps): React.ReactElement => {

    const getCachedSearch = (): string => {
        const cachedSearch = LocalStorage.get(LocalStorageKey.SectionSearch)
        return cachedSearch ?? ""
    }

    // State
    const { switchToPlaceView } = props;

    const [sections, setSections] = useState<string[] | null>(null);

    const [searchedSection, setSearchedSection] = useState<string | null>(getCachedSearch());
    const [searchInput, setSearchInput] = useState<string>(getCachedSearch());

    const [selectedSection, setSelectedSection] = useState<Section | null>(null);
    const [selectedYear, setSelectedYear] = useState<number | null>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);


    // Effects
    useEffect(() => {
        getAllSections();
    }, [])

    useEffect(() => {
        if (searchedSection === null) return;
        getSectionData();
    }, [searchedSection])

    useEffect(() => {
        if (selectedYear === null) return;
        getSectionData(selectedYear);
    }, [selectedYear])


    // Actions
    const getAllSections = async () => {
        try {
            const _sections = await Thomas.request(APIRequest.getAllSections) as unknown as string[]; 
            setSections(_sections);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    const getSectionData = async (year?: number) => {

        // Reset place state
        setSelectedSection(null);

        // Fetch requested searched place
        try {
            setIsLoading(true);

            const payload = {id: searchedSection, year: year}
            const _section: Section = await Thomas.request(APIRequest.getSectionData, payload) as unknown as Section
            setSelectedSection(_section);

            // Cache searched result
            LocalStorage.set(LocalStorageKey.SectionSearch, searchedSection);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
        setIsLoading(false);
    }

    const selectSection = (name: string) => {
        setSearchInput(name);
        setSearchedSection(name);
    }

    const selectPlace = (place: PlaceData) => {
        const search = {fromPlace: place.fromPlace, toPlace: place.toPlace}
        LocalStorage.set(LocalStorageKey.PlaceSearch, search);
        switchToPlaceView();
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            setSearchedSection(e.target.value);
        }
    }

    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <HFlex>
                <ContentDiv style={{width: "20%", marginRight: 10, height: "75vh"}}>
                    <h3>Sections</h3>
                    <HFlex style={{alignItems: "center", marginTop: 10}}>
                        <Input
                            type=           "text"
                            placeholder=    "Search..."
                            value=          {searchInput}
                            onChange=       {(e) => setSearchInput(e.target.value)}
                            fontSize=       {12}
                            onKeyPress=     {(e) => onKeyPress(e)}
                            style=          {{margin: "10px 0px", height: 35, backgroundColor: Color.lightGrey}}
                        />
                        <IconButton 
                            color=      {Color.blue}
                            hoverColor= {Color.lightBlue}
                            icon=       {SearchIcon}
                            onClick=    {() => setSearchedSection(searchInput)}
                            style=      {{textAlign: "center", marginLeft: 10, width: 20, height: 25}}
                        ></IconButton>
                    </HFlex>
                    <SectionListContainer>
                        {sections === null && 
                            <LoadingComponent message={`Fetching ${searchedSection} data...`} />
                        }
                        {sections !== null && sections.length === 0 && 
                            <NoDataComponent message="No sections found..." />
                        }
                        {sections !== null && sections.map((place, index) => 
                            <SectionListItem 
                                key=        {`section-${index}`}
                                selected=   {place === searchedSection}
                                onClick=    {() => selectSection(place)}
                            >
                                {place}
                            </SectionListItem>
                        )}
                    </SectionListContainer>
                </ContentDiv>
                <ContentDiv style={{width: "80%", marginLeft: 10, height: "75vh", padding: 20}}>
                    {selectedSection === null && isLoading && 
                        <LoadingComponent message={`Fetching ${searchedSection} data...`} />
                    }
                    {selectedSection === null && !isLoading && 
                        <NoDataComponent message="Select a section..." />
                    }
                    {selectedSection !== null && 
                        <SectionDataContainer 
                            selectedSection=    {selectedSection}
                            selectedYear=       {selectedYear}
                            setSelectedYear=    {setSelectedYear}
                            selectPlace=        {selectPlace}
                        />
                    }
                </ContentDiv>
            </HFlex>
        </Container>
    )
}

export default SectionView;