import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Color from "../resources/colors";
import FlagIcon from "../resources/images/flag-icon-red.svg";

import Overlay from "./Overlay";

import { HFlex, VFlex, Input, Button } from "../pandora/styled";
import { ConfirmationPopupAnimation } from "../pandora/animations";



const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const CommentInputContainer = styled(VFlex)`
    position: relative;
    width: 40%;
    min-width: 500px;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
`




interface CommentInputProps {
    updateComment: (comment: string) => void;
    close: Function;
}

const CommentInput = (props: CommentInputProps): React.ReactElement => {

    // State
    const { updateComment, close } = props;

    const [comment, setComment] = useState<string>("");

    // Methods
    const closeView = (e) => {
        e.preventDefault();
        e.stopPropagation();
        close();
    }

    const setCommentAndClose = () => {
        updateComment(comment);
        close();
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            setCommentAndClose()
        }
    }

    const onKeyUp = (e) => {
        if (e.key === "Escape") close();
    }

    // Effects
    useEffect(() => {
        // Register event listeners 
        document.addEventListener("keyup", onKeyUp, false);
        return () => {
          document.removeEventListener("keyup", onKeyUp, false);
        };
    }, []);

    return (
        <Overlay 
            css=        {"z-index: 10; cursor: auto;"} 
            onClick=    {closeView}
        >
            <Container>
                <CommentInputContainer onClick={(e) => e.stopPropagation()}>
                    <HFlex style={{alignItems: "center"}}>
                        <img src={FlagIcon} style={{width: 20, height: 20, marginRight: 15}} />
                        <h3>Add flag comment</h3>
                    </HFlex>
                    <p style={{margin: "15px 0px 20px", fontSize: 12}}>Provide a comment to flag this instance.</p>
                    <HFlex style={{alignItems: "center"}}>
                        <Input
                            type=           "text"
                            placeholder=    "Comment"
                            value=          {comment}
                            onChange=       {(e) => setComment(e.target.value)}
                            fontSize=       {12}
                            style=          {{height: 40}}
                            onKeyPress=     {onKeyPress}
                        />  
                        <Button 
                            color=      {Color.red}
                            hoverColor= {Color.lightRed}
                            onClick=    {setCommentAndClose}
                            title=      {"Cancel & close selection"}
                            style=      {{height: "100%", width: 100, marginLeft: 10, textAlign: "center"}}
                        >Comment</Button>
                    </HFlex>
                </CommentInputContainer>
            </Container>
        </Overlay>
    )
}


export default CommentInput;