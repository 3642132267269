import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { getCompletionProgressColor, formatKilometers } from "../../pandora/pandora";

import Color from "../../resources/colors";

import FomulIcon from "../../resources/images/fomul-icon.svg";
import TrackDistanceIcon from "../../resources/images/track-distance-icon.svg";
import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";

import { HFlex, MetaDataChildItem } from "../../pandora/styled";
import { PlaceMetaData, ContentGrid } from "./common";


interface PlaceMetaDataProps {
    placeMetaData: PlaceMetaData;
    completionProgress: number;
    measurementProgress: number;
}

/**
 * Component presenting aggregated
 * completion and annotation data for 
 * places and lines. 
 */
const PlaceMetaDataComponent = (props: PlaceMetaDataProps): React.ReactElement => {
    const { placeMetaData, completionProgress, measurementProgress } = props;
    return (
        <ContentGrid className="top-box" style={{marginRight: 10}}>
            <MetaDataChildItem>
                <img src={FomulIcon} />
                <h5><strong>{placeMetaData.fomuls}</strong> Fomuls</h5>
            </MetaDataChildItem>
            <MetaDataChildItem>
                <img src={TrackDistanceIcon} />
                <h5><strong>{placeMetaData.trackDistances}</strong> Track Distances</h5>
            </MetaDataChildItem>
            <MetaDataChildItem>
                <img src={SessionIcon} />
                <h5><strong>{placeMetaData.sessions}</strong> Sessions</h5>
            </MetaDataChildItem>
            <MetaDataChildItem>
                <img src={TrackLinkIcon} />
                <h5><strong>{placeMetaData.trackLinks}</strong> TrackLinks</h5>
            </MetaDataChildItem>
            <MetaDataChildItem style={{flexDirection: "column", backgroundColor: "transparent", justifyContent: "flex-start", alignItems: "flex-start"}}>
                <h5 className="completed">
                    <strong>{placeMetaData.completedTrackLinks}</strong> Completed
                </h5>
                {placeMetaData.partiallyCompletedTrackLinks !== 0 && 
                    <h5 className="partially-completed">
                        <strong>{placeMetaData.partiallyCompletedTrackLinks}</strong> Partially completed
                    </h5>
                }
                <h5 className="non-completed">
                    <strong>{placeMetaData.nonCompletedTrackLinks}</strong> Not completed
                </h5>
            </MetaDataChildItem>
            <HFlex style={{justifyContent: "center"}}>
                <div style={{ width: 90, height: "auto", padding: 20, textAlign: "center"}}>
                    <CircularProgressbar 
                        value={completionProgress} 
                        text={`${placeMetaData.completedTrackLinks}/${placeMetaData.trackLinks}`}
                        styles={buildStyles({
                            textSize: '12px',
                            pathColor: getCompletionProgressColor(completionProgress),
                            textColor: '#FFF',
                            trailColor: Color.lightGrey,
                            backgroundColor: '#3e98c7',
                        })}
                    />
                    <h5 style={{marginTop: 10, fontWeight: "normal"}}>Track Links</h5>
                </div>
                <div style={{ width: 90, height: "auto", padding: 20, textAlign: "center"}}>
                    <CircularProgressbar 
                        value={measurementProgress} 
                        text={`${formatKilometers(placeMetaData.completedTrackLinksLength)}/${formatKilometers(placeMetaData.totalTrackLinksLength)}`}
                        styles={buildStyles({
                            textSize: '12px',
                            pathColor: getCompletionProgressColor(measurementProgress),
                            textColor: '#FFF',
                            trailColor: Color.lightGrey,
                            backgroundColor: '#3e98c7',
                        })}
                    />
                    <h5 style={{marginTop: 10, fontWeight: "normal"}}>Kilometers</h5>
                </div>
            </HFlex>
        </ContentGrid>
    )
}

export default PlaceMetaDataComponent;