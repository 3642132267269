

export default class Redirect {

    //public static ASuite: string = ""
    public static ASuite: string = process.env.REACT_APP_ASUITE_URL;

    /**
     * Direct redirect to the specified URL. 
     */
    public static to(url: string) {
        window.location.assign(url);
    }

    /**
     * Redirect to ASuite. 
     * @param shouldReturn if true, then the ASuite URL is constructed with query params
     * indicating a request to redirect back to LCSOnline, whilst preserving the 
     * query params sent to LCSOnline.
     * 
     * This type of redirect request happens when the user is not authenticated
     * and needs to log in at ASuite before using LCSOnline. 
     */
    public static asuite(shouldReturn: boolean = false) {
        // Retriev ASuite URL
        let link = process.env.REACT_APP_ASUITE_URL
        if (shouldReturn) {
            // Construct redirect request picked up by ASuite
            const route = window.location.pathname
            let params = window.location.search
            if (route !== "/") params += `&r=${route.substring(1)}`
            link += `/?s=lcsonline${params.replace("?", "&")}`
        }
        window.location.assign(link)
    }
}

