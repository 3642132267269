import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { SiteRoute } from "../../site/routes";
import { TrackDistanceMin, DataStatus } from "../../api/LCSModels";

import Color from "../../resources/colors";

import { HFlex, Tag } from "../../pandora/styled";
import PositionTag from "../PositionTag";


const Container = styled(HFlex)<{backgroundColor?: string, hoverColor?: string}>`
    align-items: center;
    justify-content: space-between;

    padding: 10px;
    margin-top: 5px;
    height: 45px;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    cursor: pointer;
    background-color: ${props => props.backgroundColor ?? Color.darkGrey};

    transition: 0.3s ease;

    &: hover {
        transform: translateY(-2px);
        background-color: ${props => props.hoverColor ?? Color.extraLightGrey};
    }

    ${HFlex} {
        align-items: center;
    }
`



interface TrackDistanceListItemProps {
    trackDistance: TrackDistanceMin
    color?: string
}

/**
 * Container used to list Track Distances
 * in a standardized way in UI lists. 
 */
const TrackDistanceListItem = (props: TrackDistanceListItemProps): React.ReactElement => {
    
    // States
    const { trackDistance, color } = props;    

    return (
        <Link to={`${SiteRoute.TrackDistance}?id=${trackDistance.id}`} style={{display: "block"}}>
            <Container backgroundColor={color}>
                <HFlex>
                    <h6 style={{marginRight: 10}}>#{trackDistance.id}</h6>
                    <PositionTag 
                        atKm={trackDistance.atKm} 
                        atM={trackDistance.atM} 
                    />
                    <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>Objektspår</b> {trackDistance.objTrackLinkNumber}</h6>
                    <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>H</b> {trackDistance.horDist.toFixed(1)} mm</h6>
                    <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>V</b> {trackDistance.verDist.toFixed(1)} mm</h6>
                </HFlex>
                <HFlex>
                    {trackDistance.status === DataStatus.Deleted &&
                        <Tag 
                            color=      {Color.red} 
                            style=      {{marginLeft: 20}}
                        >Deleted</Tag>
                    }
                    {trackDistance.status === DataStatus.Deprecated &&
                        <Tag 
                            color=      {Color.yellow} 
                            style=      {{marginLeft: 20}}
                        >Deprecated</Tag>
                    }
                </HFlex>
            </Container>
        </Link>
    )
}


export default TrackDistanceListItem;




interface TrackDistanceListItemSelectableProps extends TrackDistanceListItemProps {
    hoverColor?: string;
    onClick: () => void;
}

/**
 * Container used to list TrackDistances in a standardized way in
 * UI lists that are selectable. Allows for multiselection. 
 */
export const TrackDistanceSelectableListItem = (props: TrackDistanceListItemSelectableProps): React.ReactElement => {
    
    // States
    const { trackDistance, color, hoverColor, onClick } = props; 

    return (
        <Container 
            backgroundColor={color}
            hoverColor=     {hoverColor}
            onClick=        {onClick}
        >
            <HFlex>
                <h6 style={{marginRight: 10}}>#{trackDistance.id}</h6>
                <PositionTag 
                    atKm={trackDistance.atKm} 
                    atM={trackDistance.atM} 
                />
                <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>Objektspår</b> {trackDistance.objTrackLinkNumber}</h6>
                <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>H</b> {trackDistance.horDist.toFixed(1)} mm</h6>
                <h6 style={{fontWeight: "normal", marginLeft: 15}}><b>V</b> {trackDistance.verDist.toFixed(1)} mm</h6>
            </HFlex>
            <HFlex>
                {trackDistance.status === DataStatus.Deleted &&
                    <Tag 
                        color=      {Color.red} 
                        style=      {{marginLeft: 20}}
                    >Deleted</Tag>
                }
                {trackDistance.status === DataStatus.Deprecated &&
                    <Tag 
                        color=      {Color.yellow} 
                        style=      {{marginLeft: 20}}
                    >Deprecated</Tag>
                }
            </HFlex>
        </Container>
    )
}