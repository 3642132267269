import React, { useState } from "react";
import styled from "styled-components";

import { AuditDataType, AuditWarning} from "../../api/LCSModels";

import Color from "../../resources/colors";

import FomulListItem from "../data/FomulListItem";

import { HFlex, VFlex, Arrow, Tag, OpenTag } from "../../pandora/styled";
import TrackLinkContainer from "components/data/TrackLinkContainer";
import ErrorIcon from "../../resources/images/error-icon.png";
import WarningIcon from "../../resources/images/warning-icon.png";


const Container = styled(VFlex)`
    min-height: 40px;
    height: auto;
    padding: 10px;
    padding-right: 15px;
    margin-top: 10px;
    justify-content: center;

    box-sizing: border-box;
    border-radius: 5px;

    user-select: none;
    background-color: ${Color.extraDarkGrey};

    transition: 0.3s ease;

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    h5 {
        font-weight: normal;
    }
`

const SignatureContainer = styled(HFlex)<{minimalColWidth: boolean}>`
    display: grid;
    height: 30px;

    grid-template-columns: auto repeat(2, ${props => props.minimalColWidth ? "auto" : "110px"}) auto;
    column-gap: 10px;

    span {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        border-radius: 5px;
        padding: 5px 10px;
    }

    span > h4 {
        margin-right: 5px;
    }

    h4 {
        font-weight: normal;
        font-size: 13px;
    }
`

interface AuditViewProps {
    warning: AuditWarning;
}

/**
 * Component display AuditWarnings for 
 * a given audit with corresponding fomul or track link
 */
const AuditWarningListItem = (props: AuditViewProps): React.ReactElement => {
    
    // States
    const { warning } = props;    
    const [opened, setOpened] = useState<boolean>(false);
    var isTrackLink: boolean = false;
    var isFomul: boolean = false;
    var isNotFomul: boolean = false;
    var isNotTrackLink: boolean = false;
    var trackLink;
    var fomul;


    // Check the affected dataType to show respective data
    if (warning.affectedDataType == AuditDataType.TrackLink){
        isTrackLink = true;
        trackLink = warning.affectedData;
    } else if (warning.affectedDataType == AuditDataType.Fomul){
        isFomul = true;
        fomul = warning.affectedData;
    } else if (warning.affectedDataType == AuditDataType.NoFomul){
        isNotFomul = true;
    } else if (warning.affectedDataType == AuditDataType.NoTrackLink){
        isNotTrackLink = true;
    }

    return (
        <Container>
            <HFlex 
                style={{justifyContent: "space-between", height: 30, alignItems: "center", cursor: "pointer"}}
                onClick={() => setOpened(!opened)}
            >   
                <HFlex style={{alignItems: "center"}}>
                    <img className="icon" src={warning.isError ? ErrorIcon : WarningIcon} />
                    <h5>{`${warning.message}`}</h5>
                </HFlex>
                <HFlex style={{alignItems: "center"}}>
                    <HFlex style={{alignItems: "center"}}>
                    <Tag 
                        color=      {warning.isError ? Color.red : Color.yellow} 
                        style=      {{cursor: "auto", margin: "10px"}}
                    >{warning.type}</Tag>
                    </HFlex>
                    <Arrow rotate={opened ? 180 : 0}/>
                </HFlex>
            </HFlex>
            {opened && 
                <VFlex style={{margin: "15px 0px 10px"}}>
                    {isTrackLink && 
                        <TrackLinkContainer 
                            trackLink=          {trackLink}
                            showDatesOption=    {true}
                            targetBlank=        {true}
                        />
                    }
                    {isFomul && 
                        <FomulListItem 
                            fomul=          {fomul} 
                            targetBlank=    {true}
                        />
                    }
                    {isNotTrackLink &&
                        <Container style = {{backgroundColor: Color.darkGrey}}>
                            <SignatureContainer minimalColWidth={true}>
                                <HFlex style={{alignItems: "center"}}>
                                    <span>
                                        <h4><b>From place ID: </b></h4>
                                        <h4>{warning.affectedData["fromPlace"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>From node ID: </b></h4>
                                        <h4>{warning.affectedData["fromNode"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>To place ID: </b></h4>
                                        <h4>{warning.affectedData["toPlace"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>To node ID: </b></h4>
                                        <h4>{warning.affectedData["toNode"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>Link number: </b></h4>
                                        <h4>{warning.affectedData["linkNumber"]}</h4>
                                    </span>
                                </HFlex>
                            </SignatureContainer>
                        </Container>
                    }
                    {isNotFomul &&
                        <Container style = {{backgroundColor: Color.darkGrey}}>
                            <SignatureContainer minimalColWidth={true}>
                                <HFlex style={{alignItems: "center"}}>
                                    <span>
                                        <h4><b>Km: </b></h4>
                                        <h4>{warning.affectedData["km"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>M: </b></h4>
                                        <h4>{warning.affectedData["m"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>Frame: </b></h4>
                                        <h4>{warning.affectedData["frame"]}</h4>
                                    </span>
                                    <span>
                                        <h4><b>Type: </b></h4>
                                        <h4>{warning.affectedData["type"]}</h4>
                                    </span>
                                </HFlex>
                            </SignatureContainer>
                        </Container>
                    }
                </VFlex>
            }
        </Container>
    )
}


export default AuditWarningListItem;
