import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { SectionGeo, PlaceCompletionGeo } from "../../api/LCSModels";

import { getCompletionProgressColor } from "../../pandora/pandora";
import Color from "../../resources/colors";

import LineIcon from "../../resources/images/line-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";
import SectionIcon from "../../resources/images/section-icon.svg";

import IconTag from "../IconTag";
import LoadingComponent from "../LoadingComponent";
import NoDataComponent from "../NoDataComponent";
import YearSelector from "../YearSelector";
import { HFlex, Tag } from "../../pandora/styled";


const TopBar = styled(HFlex)`

    align-items: center;
    justify-content: space-between;

    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: ${Color.darkGrey};
    
    ${Tag} {
        cursor: auto;
        margin-right: 10px;
        font-weight: normal;
    }

    ${HFlex} {
        width: fit-content;
        align-items: center;
    }

    .progress-container {
        width: 50px;
        height: auto;
        padding: 0px;
        text-align: center;
    }

    .metadata-container > * {
        margin-right: 20px;
    }

    .metadata-container > .progress-container {
        margin-right: 30px;
    }
`


interface Metadata {
    sectionCount: number;
    placeCount: number;
    lineCount: number;
    completionProgress: number;
}

interface MapTopBarProps {
    data: SectionGeo[] | null;
    year: number;
    setYear: (value: number) => void;
}

/**
 * TopBar in network map. Aggregates network 
 * completion status data for a given measurement year. 
 */
const NetworkMapTopBar = (props: MapTopBarProps): React.ReactElement => {

    // State
    const { data, year, setYear } = props;
    const [metadata, setMetadata] = useState<Metadata | null>(null);

    // Effects
    useEffect(() => {
        if (data === null) return;
        setMetadata(getMetaData());
    }, [data])

    // Actions

    /**
     * Summarize section metadata. 
     */
    const getMetaData = (): Metadata => {
        const sectionCount = data.length;

        const _placesAndLines = data.map(s => s.places).flat(1)
        const places = _placesAndLines.filter(p => p.fromPlace.id == p.toPlace.id)
        const lines = _placesAndLines.filter(p => p.fromPlace.id != p.toPlace.id)
        
        const placeCount = places.length
        const lineCount = lines.length

        const completionProgress = getCompletionProgress(_placesAndLines);

        return {
            sectionCount: sectionCount,
            placeCount: placeCount,
            lineCount: lineCount,
            completionProgress: completionProgress
        }
    }

    /**
     * Calculate completion progress for given places. 
     */
    const getCompletionProgress = (ps: PlaceCompletionGeo[]): number => {
        const completion = ps.map(p => p.completion !== null ? 1 : (p.completedTrackLinksCount / p.trackLinksCount)).reduce((a, b) => a + b, 0)
        return Math.round(completion / ps.length * 100);  // as percentage
    }

    return (
        <TopBar>    
            {data === null &&
                <LoadingComponent style={{marginTop: 0, maxHeight: 50}}/>
            }
            {data !== null && data.length === 0 &&
                <NoDataComponent />
            }
            {data !== null && data.length > 0 && metadata !== null &&
                <HFlex className="metadata-container">
                    <div className="progress-container">
                        <CircularProgressbar 
                            value={metadata.completionProgress} 
                            text={`${metadata.completionProgress}%`}
                            styles={buildStyles({
                                textSize: '18px',
                                pathColor: getCompletionProgressColor(metadata.completionProgress),
                                textColor: '#FFF',
                                trailColor: Color.lightGrey,
                                backgroundColor: '#3e98c7',
                            })}
                        />
                    </div>
                    <IconTag 
                        text=   {`${metadata.sectionCount} sections`}
                        icon=   {SectionIcon}
                        color=  {Color.lightGrey}
                    />
                    <IconTag 
                        text=   {`${metadata.placeCount} places`}
                        icon=   {PlaceIcon}
                        color=  {Color.lightGrey}
                    />
                    <IconTag 
                        text=   {`${metadata.lineCount} lines`}
                        icon=   {LineIcon}
                        color=  {Color.lightGrey}
                    />
                </HFlex>
            }
            <YearSelector 
                selectedYear=       {year}
                select=             {setYear}
                includeFutureYears= {true}
            />
        </TopBar>
    )
}

export default NetworkMapTopBar;





                            