

/**
 * Predefined local storage
 * data keys.
 */
export enum LocalStorageKey {
    SessionsQueryFilter = "sessions-query-filter",
    SessionDates = "session-dates",

    FomulSearchParams = "fomul-search-params", 
    TrackDistanceSearchParams = "track-distance-search-params", 
    TrackLinkSearchParams = "track-link-search-params",

    FomulSearchResult = "fomul-search-result",
    TrackDistanceSearchResult = "track-distance-search-result",
    TrackLinkSearchResult = "track-link-search-result",

    BinFomulInterval = "bin-fomul-interval",
    BinTrackDistanceInterval = "bin-track-distance-interval",

    PlaceSearch = "place-search",
    SectionSearch = "section-search",
    CompletionMapYear = "completion-map-year",

    PropertyTrackLinkIDSearch = "property-track-link-id-search",
    AuditHistory = "audit-history"
}

export default class Storage {

    /**
     * Retrieve local storage data for key
     */
    public static get(key: LocalStorageKey): any | null | undefined {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch {
            return null;
        }
    } 

    /**
     * Set local storage data for key
     */
    public static set(key: LocalStorageKey, value: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(value));    
        } catch {
            console.log(`Could not parse object set for saving in LocalStorage: ${value}`)
        }
    }

    /**
     * Remove local storage data for key
     */
    public static remove(key: LocalStorageKey): void {
        localStorage.removeItem(key);
    } 

    /**
     * Clear all local storage data
     */
    public static clear(): void {
        localStorage.clear();
    }
}


