import React, { useState, useEffect } from "react";

import { PlaceFull } from "../../api/LCSModels";

import { SiteRoute } from "../../site/routes";

import TrackLinkIcon from "../../resources/images/tracklink-icon.svg";
import SessionIcon from "../../resources/images/session-icon.svg";
import PlaceIcon from "../../resources/images/place-icon.svg";

import PlaceCompletionTag from "./PlaceCompletionTag";
import MapComponent from "../MapComponent";
import SessionTrackLinkList from "../session/SessionTrackLinkList";
import SessionContainer from "../data/SessionContainer";
import NoDataComponent from "../NoDataComponent";

import Color from "../../resources/colors";

import { HFlex, IconButton } from "../../pandora/styled";
import { PlaceMetaData, PlaceDataType, ContentDiv, PlaceContainer, getPlaceMetaData, getCompletionProgress, getMeasurementProgress } from "./common";
import PlaceMetaDataComponent from "./PlaceMetaData";




interface PlaceDataContainerProps {
    selectedPlace: PlaceFull;
    completePlace: () => void;
    removePlaceCompletion: () => void;
}   

const PlaceDataContainer = (props: PlaceDataContainerProps): React.ReactElement => {

    // State
    const { selectedPlace, completePlace, removePlaceCompletion } = props;

    const [selectedPlaceDataType, setSelectedPlaceDataType] = useState<PlaceDataType>(PlaceDataType.TrackLinks)
    const [placeMetaData, setPlaceMetaData] = useState<PlaceMetaData | null>(getPlaceMetaData(selectedPlace));
    
    const completionProgress: number = getCompletionProgress(placeMetaData);
    const measurementProgress: number = getMeasurementProgress(placeMetaData);

    // Effects
    useEffect(() => {
        if (selectedPlace === null) return;
        const metadata = getPlaceMetaData(selectedPlace);
        setPlaceMetaData(metadata);
    }, [selectedPlace])

    return (
        <PlaceContainer>
            <HFlex style={{alignItems: "center", justifyContent: "space-between"}}>
                <HFlex style={{alignItems: "center"}}>
                    <img 
                        src=    {PlaceIcon} 
                        style=  {{width: 30, height: 30, marginRight: 20}} 
                    />
                    <h2 className="signature"><strong>{selectedPlace.signature}</strong></h2>
                    <h1 className="place-name">{selectedPlace.name}</h1>
                </HFlex>
                <PlaceCompletionTag
                    completion=         {selectedPlace.completion}
                    complete=           {completePlace}
                    removeCompletion=   {removePlaceCompletion}
                />
            </HFlex>
            <HFlex style={{margin: "20px 0px"}}>
                <PlaceMetaDataComponent 
                    placeMetaData=      {placeMetaData}
                    completionProgress= {completionProgress}
                    measurementProgress={measurementProgress}
                />
                <ContentDiv className="top-box">
                    <MapComponent 
                        link=           {SiteRoute.Map + `?place=${selectedPlace.id}`}
                        centerPoint=    {[selectedPlace.longitude, selectedPlace.latitude]}
                        dataType=       {"place"}
                    />
                </ContentDiv>
            </HFlex>
            <HFlex style={{margin: "20px 0px", height: 50}}>
                <IconButton     
                    color=      {selectedPlaceDataType === PlaceDataType.TrackLinks ? Color.lightGrey : Color.extraDarkGrey}
                    hoverColor= {selectedPlaceDataType === PlaceDataType.TrackLinks ? Color.lightGrey : Color.extraLightGrey}
                    icon=       {TrackLinkIcon}
                    onClick=    {() => setSelectedPlaceDataType(PlaceDataType.TrackLinks)}
                    style=      {{textAlign: "center", textIndent: 10, width: 150}}
                >Track Links</IconButton>
                <IconButton     
                    color=      {selectedPlaceDataType === PlaceDataType.Sessions ? Color.lightGrey : Color.extraDarkGrey}
                    hoverColor= {selectedPlaceDataType === PlaceDataType.Sessions ? Color.lightGrey : Color.extraLightGrey}
                    icon=       {SessionIcon}
                    onClick=    {() => setSelectedPlaceDataType(PlaceDataType.Sessions)}
                    style=      {{textAlign: "center", textIndent: 10, width: 150}}
                >Sessions</IconButton>
            </HFlex>
            {selectedPlaceDataType === PlaceDataType.TrackLinks &&
                <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                    <h3 style={{margin: "15px 0px 20px"}}>Track Links ({selectedPlace.trackLinks.length})</h3>
                    {selectedPlace.trackLinks.map(tl => 
                        <SessionTrackLinkList 
                            key=        {tl.id}
                            trackLink=  {tl} 
                        />
                    )}
                    {selectedPlace.trackLinks.length === 0 &&
                        <NoDataComponent message={`No Track Links found in ${selectedPlace.name}`} />
                    }
                </ContentDiv>
            }
            {selectedPlaceDataType === PlaceDataType.Sessions &&
                <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                    <h3 style={{margin: "15px 0px 20px"}}>Sessions ({selectedPlace.sessions.length})</h3>
                    {selectedPlace.sessions.map(s => 
                        <SessionContainer 
                            key=        {s.id}
                            session=    {s} 
                            style=      {{backgroundColor: Color.extraDarkGrey, marginTop: 5}}
                        />
                    )}
                    {selectedPlace.sessions.length === 0 &&
                        <NoDataComponent message={`No sessions found in ${selectedPlace.name}`} />
                    }
                </ContentDiv>
            }
        </PlaceContainer>
    )


}

export default PlaceDataContainer;
