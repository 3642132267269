import React from "react";

import Color from "../../resources/colors";
import { SessionStatus } from "../../api/LCSModels";
import { SessionStatusColorMap } from "../../pandora/pandora";

import { Tag, StatusItemContainer } from "../../pandora/styled";



interface SessionStatusProps {
    status: SessionStatus;
    selectable: boolean;
    selected?: boolean;
    clicked?: (status: SessionStatus) => void;
} 

const SessionStatusItem = (props: SessionStatusProps): React.ReactElement => {

    const { status, selectable, selected, clicked } = props;

    const onClick = () => {
        if (clicked !== undefined)
            clicked(status);
    }

    return (
        <StatusItemContainer 
            color=      {selectable ? (selected ? SessionStatusColorMap[status] : Color.darkGrey) : SessionStatusColorMap[status]}
            hoverColor= {SessionStatusColorMap[status]}
            selected=   {selected}
            selectable= {selectable}
            onClick=    {onClick}
        >
            <h6>{props.status}</h6>
        </StatusItemContainer>
    )
}

export default SessionStatusItem;




interface SessionStatusTagMiniProps {
    status: SessionStatus;
    text?: string;
    css?: React.CSSProperties;
}

export const SessionStatusTagMini = (props: SessionStatusTagMiniProps): React.ReactElement => {
    const { status, text } = props;
    return (
        <Tag 
            color=      {SessionStatusColorMap[status]} 
            style=      {{cursor: "auto", ...props.css}}
        >{text ?? status}</Tag>
    )
}