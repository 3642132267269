import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import { SiteRoute } from "../site/routes";
import QueryParser from "../site/queryParser";
import ShareHandler from "../site/share";
import FileSaver from "../site/fileSaver";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { Session, AnnotationSession, TrackLinkMinWData, AnnotationSessionWData } from "../api/LCSModels";

import Color from "../resources/colors";
import SessionIcon from "../resources/images/session-icon.svg";
import FlagIcon from "../resources/images/flag-icon-red.svg";
import TrackLinkIcon from "../resources/images/tracklink-icon.svg";

import SessionStatusUpdater from "../components/session/SessionStatusUpdater";
import AnnotationSessionList from "../components/session/AnnotationSessionList";
import ActiveAnnotationSession from "../components/session/ActiveAnnotationSession";
import SessionTrackLinkList from "../components/session/SessionTrackLinkList";
import AnnotationSessionDataList from "../components/session/AnnotationSessionDataList";

import ShareButton from "../components/ShareButton";
import ExportButton from "../components/ExportButton";
import SessionFlagComponent from "../components/session/SessionFlag";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import MapComponent from "../components/MapComponent";

import { 
    PageIcon, HFlex, ViewContainer, 
    BackNavButton, DescriptionInput, VFlex, Button, Tag, 
    DataViewContainer, IconButton
} from "../pandora/styled";


enum SelectedDataView {
    TrackLink = "TrackLink",
    AnontationSession = "AnnotationSession"
}


const ContentDiv = styled.div`
    width: auto;
    height: 200px;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};
`

const Container = styled(ViewContainer)`
    overflow: visible;
`

const SessionView = (): React.ReactElement => {

    // State
    const [session, setSession] = useState<Session | null | undefined>(undefined);
    const [activeAnnotationSession, setActiveAnnotationSession] = useState<AnnotationSession | null>(null);
    const [trackLinks, setTrackLinks] = useState<TrackLinkMinWData[] | null>(null);
    const [annotationSessionData, setAnnotationSessionData] = useState<AnnotationSessionWData[] | null>(null);

    const [fomulCount, setFomulCount] = useState<number>(0);
    const [trackDistanceCount, setTrackDistanceCount] = useState<number>(0);

    const [originalNote, setOriginalNote] = useState<string | null>(null);
    const [sessionNote, setSessionNote] = useState<string | null>(null);

    const [selectedDataView, setSelectedDataView] = useState<SelectedDataView>(SelectedDataView.TrackLink);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Effects
    useEffect(() => {

        /**
         * Request session instance, annotation sessions and
         * data for Track Link for this session from API. 
         */
        const getSession = async () => { 
            try {
                const id = QueryParser.getQueryArg("id")
                if (id === null) 
                    throw new Error("Could not parse ID from query parameters.")

                // Request sessions from Thomas. 
                const payload = {id: id}
                const _session: Session = await Thomas.request(APIRequest.getSession, payload) as Session
                const _activeAnnotationSession = await Thomas.request(APIRequest.getActiveAnnotationSession, payload) as AnnotationSession
                const _trackLinks: TrackLinkMinWData[] = await Thomas.request(APIRequest.getTrackLinksForSession, payload) as TrackLinkMinWData[]
                const _annotationSessionData: AnnotationSessionWData[] = await Thomas.request(APIRequest.getDataForAnnotationSessions, payload) as AnnotationSessionWData[]

                setSession(_session);
                setActiveAnnotationSession(_activeAnnotationSession);
                setTrackLinks(_trackLinks);
                setAnnotationSessionData(_annotationSessionData);

                setSessionNote(_session.note);
                setOriginalNote(_session.note);

                // Set doc title
                document.title = `${moment(_session.date).format("Do MMMM HH:mm YYYY")} |  LCS Online`
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    title: "Could not fetch session",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 

                setSession(null);
            }
        }

        getSession()
    }, [])

    /**
     * Determine Fomul and TD count 
     * for session. 
     */
    useEffect(() => {

        if (trackLinks === null) return;

        let _fomulCount = 0;
        let _trackDistanceCount = 0;
        trackLinks.forEach(tl => {
            _fomulCount += tl.fomuls.length
            _trackDistanceCount += tl.trackDistances.length
        })

        setFomulCount(_fomulCount);
        setTrackDistanceCount(_trackDistanceCount);
    }, [trackLinks])


    
    // Actions

    /**
     * Request note update for session to API. 
     */
    const updateNote = async () => {
        try {
            setOriginalNote(sessionNote)
            const payload = {id: session.id, note: sessionNote}
            const _ = await Thomas.request(APIRequest.updateSessionNote, payload);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request session data export to API. 
     */
    const exportSessionData = async (config: Record<string, boolean>) => {
        try {
            // Request Session data export file
            const payload = {id: session.id, ...config}
            const res: AxiosResponse = await Thomas.request(APIRequest.exportSession, payload, true);

            // Save file to client
            const saveConfig = `${APIRequest.exportSession.headers["Accept"]}`
            FileSaver.save(res.data, 
                            `Export ${session.date}`,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of session data for ${session.date} completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request session flag setting
     */
    const setSessionFlagged = async (flagged: boolean) => {
        try {
            // Request Session flag
            const payload = {id: session.id}
            const req = flagged ? APIRequest.setSessionFlagged : APIRequest.unsetSessionFlagged
            const isFlagged = await Thomas.request(req, payload) as unknown as boolean;

            const _session = {...session, flagged: isFlagged}
            setSession(_session);

            setPopup({
                title: "Session flagged!",
                message: `Session ${session.date} has been flagged.`,
                color: Color.red,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Annotation Session 
     * set comment.
     */
    const setAnnotationSessionComment = async (annotationSession: AnnotationSessionWData, comment: string) => {
        try {
            // Request Annotation Session comment set
            const payload = {id: annotationSession.id, comment: comment}
            const updatedAnnotationSession = await Thomas.request(APIRequest.setAnnotationSessionComment, payload) as AnnotationSessionWData;

            // Update state instance with new annotation session
            const _annotationSessionData = [...annotationSessionData];
            _annotationSessionData[_annotationSessionData.indexOf(annotationSession)] = updatedAnnotationSession
            setAnnotationSessionData(_annotationSessionData);   

            setPopup({
                title: "Annotation session commented!",
                message: `Annotation session starting ${moment.utc(annotationSession.start).local().format("Do MMMM YYYY")} has been commented.`,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Annotation Session 
     * comment be removed.
     */
    const removeAnnotationSessionComment = async (annotationSession: AnnotationSessionWData) => {
        try {
            // Request Annotation Session comment set
            const payload = {id: annotationSession.id}
            const _ = await Thomas.request(APIRequest.removeAnnotationSessionComment, payload);

            // Update state instace by removing comment
            const _annotationSessionData = [...annotationSessionData];
            _annotationSessionData[_annotationSessionData.indexOf(annotationSession)].comment = null
            setAnnotationSessionData(_annotationSessionData);

            setPopup({
                title: "Annotation session comment removed!",
                message: `Comment for annotation session starting ${moment.utc(annotationSession.start).local().format("Do MMMM YYYY")} has been removed.`,
                color: Color.red,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <Container>
            <BackNavButton 
                color=      {Color.darkGrey} 
                hoverColor= {Color.extraLightGrey}
                text=       "Sessions"
                link=       {SiteRoute.Sessions}
                rightSide=  {false}
            />
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            {session === undefined && 
                <LoadingComponent message="Fetching session..." />
            }
            {session === null && 
                <NoDataComponent message="Session query yielded no result." />
            }
            {session !== null && session !== undefined && 
                <DataViewContainer>
                    <HFlex style={{
                        alignItems: "center", 
                        justifyContent: "space-between",
                        marginBottom: "20px", 
                        paddingBottom: "30px",
                        borderBottom: "2px solid #21262c"
                    }}>
                        <HFlex>
                            <PageIcon icon={SessionIcon} />
                            <VFlex>
                                <h1 style={{display: "flex", alignItems: "center"}}>
                                    {moment(session.date).format("Do MMMM HH:mm YYYY")}
                                    {session.flagged &&
                                        <img 
                                            src={FlagIcon}
                                            style={{width: 30, height: 30, marginLeft: 30}}
                                        />
                                    }
                                    {session.flagged &&
                                        <span style={{color: Color.red, marginLeft: 10, fontSize: 30}}
                                        >Flagged</span>
                                    }
                                </h1>
                                {session.endTime && 
                                    <HFlex>
                                        <h3 style={{marginRight: 20}}>{moment(session.endTime).format("Do MMMM HH:mm")}</h3>
                                        <h3 style={{fontWeight: "normal"}}>({Math.round(moment(session.endTime).diff(session.date, "hours", true))}h )</h3>
                                    </HFlex>
                                }
                            </VFlex>
                        </HFlex>
                        <HFlex style={{width: "fit-content"}}>
                            {activeAnnotationSession !== null && 
                                <ActiveAnnotationSession 
                                    annotation={activeAnnotationSession} 
                                />
                            }
                            <SessionFlagComponent 
                                flagged=    {session.flagged}
                                setFlagged= {setSessionFlagged}
                            />
                        </HFlex>
                    </HFlex>
                    <HFlex style={{
                        justifyContent: "space-between", 
                        borderRadius: "5px", 
                        backgroundColor: Color.darkGrey, 
                        alignItems: "center", 
                        paddingLeft: "10px", 
                        paddingRight: "10px"
                    }}>
                        <SessionStatusUpdater 
                            currentStatus=  {session.status}
                            sessionID=      {session.id}
                            setPopup=       {setPopup}
                        />
                        <HFlex style={{width: "fit-content"}}>
                            <ShareButton 
                                link={ShareHandler.shareSession(session)}
                                setPopup={setPopup}
                            />
                            <ExportButton 
                                title=  {"Export session data"} 
                                config= {"data"}
                                export= {exportSessionData}
                            />
                        </HFlex>
                    </HFlex>
                    <VFlex style={{
                        borderRadius: "5px", 
                        boxSizing: "border-box",
                        backgroundColor: Color.darkGrey, 
                        padding: "15px",
                        marginTop: "20px",
                        height: "fit-content"
                    }}>
                        <h4>Note</h4>
                        <HFlex style={{alignItems: "center", marginTop: "10px"}}>
                            <DescriptionInput
                                enabled=        {true}
                                placeholder=    {"Input session note"}
                                spellCheck=     {false}
                                value=          {sessionNote ?? ""}
                                onChange=       {(e) => setSessionNote(e.target.value)}
                            />
                            {originalNote !== sessionNote && 
                                <Button 
                                    style=      {{marginLeft: "10px"}}
                                    onClick=    {updateNote}
                                    title=      {"Update session note"}
                                >Update</Button>
                            }
                        </HFlex>
                    </VFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <AnnotationSessionList sessionID={session.id} />
                        </ContentDiv>
                        <ContentDiv style={{width: "50%"}}>
                            <MapComponent 
                                link=           {SiteRoute.Map + `?session=${session.id}`}
                                centerPoint=    {[14.16, 56.76]}
                                dataType=       {"session"}
                            />
                        </ContentDiv>
                    </HFlex>
                    <ContentDiv style={{marginTop: 20, height: "auto"}}>
                        <h3 style={{marginBottom: 10}}>Data</h3>
                        {trackLinks === null && 
                            <LoadingComponent message="Fetching data..." />
                        }
                        {trackLinks !== null && trackLinks.length == 0 && 
                            <NoDataComponent message="No data for session." />
                        }
                        {trackLinks !== null && annotationSessionData !== null && trackLinks.length !== 0 && 
                            <div>
                                <HFlex style={{margin: "20px 0px", height: 50}}>
                                    <IconButton     
                                        color=      {selectedDataView === SelectedDataView.TrackLink ? Color.lightGrey : Color.extraDarkGrey}
                                        hoverColor= {selectedDataView === SelectedDataView.TrackLink ? Color.lightGrey : Color.extraLightGrey}
                                        icon=       {TrackLinkIcon}
                                        onClick=    {() => setSelectedDataView(SelectedDataView.TrackLink)}
                                        style=      {{textAlign: "center", textIndent: 10, width: 200}}
                                    >Track Links ({trackLinks.length})</IconButton>
                                    <IconButton     
                                        color=      {selectedDataView === SelectedDataView.AnontationSession ? Color.lightGrey : Color.extraDarkGrey}
                                        hoverColor= {selectedDataView === SelectedDataView.AnontationSession ? Color.lightGrey : Color.extraLightGrey}
                                        icon=       {SessionIcon}
                                        onClick=    {() => setSelectedDataView(SelectedDataView.AnontationSession)}
                                        style=      {{textAlign: "center", textIndent: 10, width: 200}}
                                    >Annotation sessions ({annotationSessionData.length})</IconButton>
                                </HFlex>
                                <HFlex style={{marginBottom: 20}}>
                                    <Tag 
                                        color=      {Color.lightGrey} 
                                        style=      {{marginRight: 10, cursor: "auto", fontSize: 12}}
                                    >{fomulCount} Fomuls</Tag>
                                    <Tag 
                                        color=      {Color.lightGrey} 
                                        style=      {{marginRight: 10, cursor: "auto", fontSize: 12}}
                                    >{trackDistanceCount} Track Distances</Tag>
                                </HFlex>
                                {selectedDataView === SelectedDataView.TrackLink && 
                                    trackLinks.map(tl => 
                                        <SessionTrackLinkList 
                                            key={tl.id}
                                            trackLink={tl} 
                                        />
                                )}
                                {selectedDataView === SelectedDataView.AnontationSession && 
                                    annotationSessionData.map(a => 
                                        <AnnotationSessionDataList 
                                            key=            {a.id}
                                            annotation=     {a} 
                                            setComment=     {setAnnotationSessionComment}
                                            removeComment=  {removeAnnotationSessionComment}
                                        />
                                )}
                            </div>
                        }
                    </ContentDiv>
                </DataViewContainer>
            }
        </Container>
    )   
}


export default SessionView;