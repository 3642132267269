import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";

import { TrackLinkMin } from "../api/LCSModels";
import { SiteView } from "../site/routes";

import Color from "../resources/colors";
import SearchIcon from "../resources/images/magnifying-glass.png";
import DeleteIcon from "../resources/images/delete-icon.png";

import PLACE_NAMES from "../pandora/place-names";

import ViewHeader from "../components/ViewHeader";
import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import PlacesDataList from "../components/data-lists/PlacesDataList";
import TrackLinkContainer from "../components/data/TrackLinkContainer";
import CompletionStatusSelector from "../components/CompletionStatusSelector";

import { HFlex, ViewContainer, Input, IconButton, QueryResultContainer, SearchInputContainer } from "../pandora/styled";



const Container = styled(ViewContainer)`
    overflow: visible;
`

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const ContentGrid = styled(ContentDiv)`
    display: grid;

    grid-template-column: auto;
    grid-template-row: repeat(7, auto);

    column-gap: 10px;
    row-gap: 5px;

    padding: 0;
    height: auto;

    h4 {
        font-size: 12px;
        width: auto;
        height: fit-content;
        margin: 0;

        text-align: center;
    }
`


const SearchTrackLinksView = (): React.ReactElement => {

    const QUERY_KEYS = ["section", "fromPlace", "fromNode", "toPlace", "toNode", "trackNumber", "direction"];

    // State
    const [query, setQuery] = useState<object>({});
    const [result, setResult] = useState<TrackLinkMin[] | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Effects
    useEffect(() => {

        // Attempt to retrieve query search params
        // stored in LocalStorage
        const _query = LocalStorage.get(LocalStorageKey.TrackLinkSearchParams)
        if (_query !== undefined && _query !== null) {
            setQuery(_query ?? {})
        }

        // Attempt to retrieve cached search result
        // stored in LocalStorage
        const cachedResult = LocalStorage.get(LocalStorageKey.TrackLinkSearchResult)
        if (cachedResult !== undefined && cachedResult !== null) {
            setResult(cachedResult)
        }

        // Set doc title
        document.title = `Search Track Links |  LCS Online`
    }, [])

    // Actions

    /**
     * Update search query. 
     */
    const updateQuery = (key: string, value: string | number) => {
        const _query = {...query};
        
        // Attempt to find Place signature for 
        // place name inputted
        if (key === "place") {
            value = Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === value) ?? value
        }

        _query[key] = value;
        Object.keys(_query).forEach(k => {
            if (_query[k] === "") delete _query[k];
        })
        setQuery(_query);
    }

    /**
     * Perform search request to API. 
     */
    const search = async () => {
        try {
            // Request track links for query from Thomas
            if (Object.keys(query).length === 0) {
                throw new Error("No query filter applied.")
            }

            setIsLoading(true);
            const _result: TrackLinkMin[] = await Thomas.request(APIRequest.getTrackLinkForQuery, query) as TrackLinkMin[]
            setResult(_result);
            setIsLoading(false);

            // Cache search result
            LocalStorage.set(LocalStorageKey.TrackLinkSearchResult, _result)
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
            setIsLoading(false);
        }

        // Update local storage with query params
        LocalStorage.set(LocalStorageKey.TrackLinkSearchParams, query)
    }

    /**
     * Clear search query stored in LocalStorage. 
     */
    const clear = (): void => {
        // Set empty query
        setQuery({});

        // Clear inputs with data options
        (document.getElementById("place-input") as HTMLInputElement).value = "";

        // Clear LocalStorage caching
        LocalStorage.remove(LocalStorageKey.TrackLinkSearchParams)
        LocalStorage.remove(LocalStorageKey.TrackLinkSearchResult)
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            search()
        }
    }

    return (
        <Container>
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            <ViewHeader view={SiteView.TrackLinks} />
            <HFlex>
                <ContentDiv style={{width: 300, height: "fit-content"}}>
                    <ContentGrid>
                        <SearchInputContainer>
                            <span><h6>Place</h6></span>
                            <Input
                                id=             {"place-input"}
                                key=            {"place"}
                                type=           "text"
                                placeholder=    {"-"}
                                defaultValue=   {query["place"] ?? ""}
                                list=           {"place-names-data-list"}
                                onChange=       {(e) => updateQuery("place", e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                            <PlacesDataList id={"place-names-data-list"} />
                        </SearchInputContainer>
                        {QUERY_KEYS.map(key => 
                            <SearchInputContainer>
                                <span><h6>{key}</h6></span>
                                <Input
                                    type=           "text"
                                    placeholder=    {"-"}
                                    value=          {query[key] || ""}
                                    onChange=       {(e) => updateQuery(key, e.target.value)}
                                    onKeyPress=     {onKeyPress}
                                />   
                            </SearchInputContainer>
                        )}
                        <SearchInputContainer style={{alignItems: "flex-start"}}>
                            <span style={{marginTop: 5}}><h6>Completion</h6></span>
                            <CompletionStatusSelector 
                                queryKey=   "completionStatus"
                                value=      {query["completionStatus"] || null }
                                setValue=   {updateQuery}
                            />
                        </SearchInputContainer>
                    </ContentGrid>
                    <IconButton 
                        color=      {Color.blue}
                        hoverColor= {Color.lightBlue}
                        icon=       {SearchIcon}
                        onClick=    {search}
                        style=      {{marginTop: 50, textAlign: "center", textIndent: 0, marginRight: 0, width: "auto"}}
                    >Search</IconButton>
                    <IconButton 
                        color=      {Color.extraDarkGrey}
                        hoverColor= {Color.red}
                        icon=       {DeleteIcon}
                        onClick=    {clear}
                        style=      {{marginTop: 10, textAlign: "center", textIndent: 0, marginRight: 0, width: "auto", height: 25, lineHeight: "25px"}}
                    >Clear search</IconButton>
                </ContentDiv>
                <QueryResultContainer style={{marginLeft: 20}}>
                    {isLoading && 
                        <LoadingComponent message="Fetching data..." />
                    }
                    {!isLoading && result !== null && result.length === 0 && 
                        <NoDataComponent message="Query yielded no results." />
                    }
                    {!isLoading && result === null &&
                        <NoDataComponent message="Filter Track Links to search" />
                    }
                    {!isLoading && result !== null && result.length !== 0 && 
                        <QueryResultContainer>
                            <h4 style={{marginTop: 0, marginBottom: 20}}>Query yielded {result.length} results</h4>
                            {result.map(tl => 
                                <TrackLinkContainer 
                                    trackLink=          {tl} 
                                    showDatesOption=    {true}
                                    css=                {{backgroundColor: Color.extraDarkGrey}}
                                />
                            )}
                        </QueryResultContainer>
                    }
                </QueryResultContainer>
            </HFlex>
        </Container>
    )
}

export default SearchTrackLinksView;

