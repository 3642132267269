import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import qs from "query-string";

import { ItemContainerAnimation } from "../pandora/animations";
import { VFlex } from "../pandora/styled";



const Container = styled(VFlex)`
    position: relative;
    width: 100%;
    min-width: 350px;
    height: 100%;
    box-sizing: border-box;
`

const MessageContainer = styled(VFlex)`
    height: auto;
    padding: 25px;
    border-radius: 5px;
    align-items: center;

    background: rgba(44, 50, 58, 0.6);
    animation: ${ItemContainerAnimation} 0.8s 1;
`

const Title = styled("h1")<{size: number, bold: boolean, css?: string}>`
    margin: auto;
    margin-top: 10px;
    max-width: 60%;

    text-align: center;
    font-size: ${props => props.size}px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    font-family: 'Montserrat';
    color: white;

    ${props => props.css ?? ""}
`


const ErrorView = (): React.ReactElement => {

    const location = useLocation()

    // Parser requested page from query string 
    // to display in 404 error message. 
    const queryParams = qs.parse(location.search)
    const { r: requestedPage } = queryParams;    

    return (
        <Container>
            <MessageContainer>
                <Title 
                    size=   {25} 
                    bold=   {true}
                    css=    {"margin-top: 20px; margin-bottom: 20px;"}
                >Oh boy!</Title>
                <Title 
                    size={13} 
                    bold={false}
                >{`The page "${requestedPage}" could not be found.`}</Title>
            </MessageContainer>
        </Container>
    );
}

export default ErrorView;