import React from "react";
import styled, { keyframes } from "styled-components";

import Color, { alpha } from "../resources/colors";
import LogoIcon from "../resources/images/lcs-online-logo.png";
import backgroundImage from "../resources/images/background-image-1.png";



const EntranceAnimation = keyframes`
    from {
        transform: scale(0.7);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`

const LoadingBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    
    background-image: url(${backgroundImage});
    background-size: cover;
`

const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: ${alpha(Color.darkGrey, 0.7)};
`

const CenterDiv = styled.div`
    top: 0;
    bottom: 0;
    margin: auto;
    width: 300px;
    height: 300px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: rgba(44, 50, 58, 0.6);

    animation: ${EntranceAnimation} 0.3s 1;
`

const LoadingImage = styled.img`
    width: 70%;
    height: auto;
`

const Title = styled("h1")<{size?: string, lower?: boolean}>`
    margin: auto;
    text-align: center;
    font-size: ${props => props.size ?? "20pt"};
    font-family: 'Montserrat';
    color: white;
    margin-top: 20px;
    margin-bottom: ${props => props.lower ? "20px" : "null"}
`

const LoadingScreen = (): React.ReactElement => {
    return (
        <LoadingBackground>
            <Overlay>
                <CenterDiv>
                    <LoadingImage src={LogoIcon}/>
                </CenterDiv>
                <div>
                    <Title size="15px" lower>
                        Copyright © 2021 Atritec AB. All rights reserved.
                    </Title>
                </div>
            </Overlay>
        </LoadingBackground>
    );
}

export default LoadingScreen;