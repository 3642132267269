import React from "react";
import styled from "styled-components";
import moment from "moment";

import Color from "../../resources/colors";
import ArrowIcon from "../../resources/images/right-arrow.svg";

import { TrackLinkChangeLog } from "../../api/LCSModels";
import { HFlex } from "../../pandora/styled";



const ItemContainer = styled(HFlex)`
    justify-content: space-between;
    align-items: center;

    width: auto;
    height: 30px;
    padding: 10px;

    border-radius: 5px;
    margin-top: 5px;

    background-color: ${Color.extraDarkGrey};

    .attribute {
        background-color: ${Color.darkGrey};
        padding: 5px;
        text-transform: capitalize;
        border-radius: 5px;
    }

    * {
        margin-right: 10px;
        font-weight: normal;
    }
`

const PropertyGrid = styled.div`
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: auto;

    column-gap: 10px;

    width: 50%;
`


interface TrackLinkChangeLogItemProps {
    changelog: TrackLinkChangeLog
}

const TrackLinkChangeLogItem = (props: TrackLinkChangeLogItemProps): React.ReactElement => {

    const { changelog } = props;
    const time = moment.utc(changelog.updated).local().format("HH:mm | YYYY MMMM Do")

    return (
        <ItemContainer>
            <PropertyGrid style={{width: "fit-content", alignItems: "center"}}>
                <h5 className="attribute">{changelog.attribute}</h5>
                <HFlex style={{alignItems: "center"}}>
                    <h5>{changelog.oldValue}</h5>
                    <img 
                        src={ArrowIcon}
                        style={{width: 10, height: 10, margin: "0px 10px"}}
                    />
                    <h5 style={{fontSize: 14, fontWeight: "bold"}}>{changelog.newValue}</h5>
                </HFlex>
            </PropertyGrid>
            <h5>{time}</h5>
        </ItemContainer>
    )
}

export default TrackLinkChangeLogItem;