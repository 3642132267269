
import APIRequest from "../api/APIRequest";
import { FomulGeo, TrackDistanceGeo, SessionGeo, TrackLinkGeo, PlaceGeo, LineGeo } from "../api/LCSModels";
import { SiteRoute } from "../site/routes";



export type MapData = FomulGeo | TrackDistanceGeo | TrackLinkGeo | SessionGeo | PlaceGeo | LineGeo

export interface MarkerData {
    fomul: FomulGeo[] 
    trackdistance: TrackDistanceGeo[] 
}

export default class MapSettings {

    static DEFAULT_ZOOM_LEVEL = 15;

    static Styles = {
        default: `mapbox://styles/atritecrasmus/ckq5nj2sb0p0718oh0zsakt1n`
    }

    static Types = {
        TrackLink: "tracklink",
        Session: "session",
        Fomul: "fomul",
        TrackDistance: "trackdistance",
        Place: "place",
        Line: "line"
    }

    static MarkerDataKeys = {
        fomuls: MapSettings.Types.Fomul,
        trackDistances: MapSettings.Types.TrackDistance
    }

    static QueryArgRequestMap = {
        [MapSettings.Types.Fomul]: APIRequest.getGeoFomuls,
        [MapSettings.Types.TrackDistance]: APIRequest.getGeoTrackDistances,
        [MapSettings.Types.Session]: APIRequest.getSessionGeoData, 
        [MapSettings.Types.TrackLink]: APIRequest.getTrackLinkGeoData,
        [MapSettings.Types.Place]: APIRequest.getPlaceGeoData,
        [MapSettings.Types.Line]: APIRequest.getLineGeoData
    }   


    /**
     * Get Zoom level in map for 
     * the given data type.
     */
    static getZoom = (type: string): number => {
        switch (type) {
            case MapSettings.Types.Fomul: 
            case MapSettings.Types.TrackDistance: 
                return MapSettings.DEFAULT_ZOOM_LEVEL;

            case MapSettings.Types.Session: 
            case MapSettings.Types.TrackLink: 
            case MapSettings.Types.Place:
            case MapSettings.Types.Line:
                return 13;

            default: 
                return MapSettings.DEFAULT_ZOOM_LEVEL;
        }
    }

    /**
     * Get the URL path to the data view
     * for a given data type. 
     */
    static getTypeRoute = (type: string): string => {
        switch (type) {
            case MapSettings.Types.Fomul: return SiteRoute.Fomul;
            case MapSettings.Types.TrackDistance: return SiteRoute.TrackDistance;
            case MapSettings.Types.Session: return SiteRoute.Session;
            case MapSettings.Types.TrackLink: return SiteRoute.TrackLink;
            case MapSettings.Types.Place: return SiteRoute.Network;
            case MapSettings.Types.Line: return SiteRoute.Network;
        }
    }

    /**
     * Setup marker data for given 
     * lists of fomuls and track distances. 
     * @returns all marker data for the current map data type. 
     */
    static constructMarkerData = (fomuls: FomulGeo[], trackDistances: TrackDistanceGeo[]): MarkerData => ({ 
        [MapSettings.MarkerDataKeys.fomuls]: fomuls, 
        [MapSettings.MarkerDataKeys.trackDistances]: trackDistances 
    } as unknown as MarkerData)

    /**
     * Count fomuls and track distances in data
     * with more than 1.
     */
    static getDataCount = (type: string, d: MapData): [number | null, number | null] => {
        let _fomulCount = null;
        let _trackDistanceCount = null;

        switch (type) {
            case MapSettings.Types.Place:
            case MapSettings.Types.Line:
            case MapSettings.Types.TrackLink:
            case MapSettings.Types.Session:
                const _data = (d as TrackLinkGeo | SessionGeo)
                _fomulCount = _data.fomuls.length
                _trackDistanceCount = _data.trackDistances.length    
                break;
        }

        return [_fomulCount, _trackDistanceCount]
    }

    /**
     * Retrieve fomul and track distance data for the given type. 
     * @param type Map data type
     * @param data map data
     * @returns Fomuls and Track Distnces for the given type. 
     */
    private static getMapData = (type: string, data: MapData): [FomulGeo[] | null, TrackDistanceGeo[] | null] => {
        let _fomuls = null;
        let _trackDistances = null;

        switch (type) {
            case MapSettings.Types.Place:
            case MapSettings.Types.Line:
            case MapSettings.Types.TrackLink:
            case MapSettings.Types.Session:
                const d = (data as TrackLinkGeo | SessionGeo)
                _fomuls = d.fomuls
                _trackDistances = d.trackDistances    
                break;
        }

        return [_fomuls, _trackDistances]
    }

    /**
     * Calculate average for given data type. Only used for map
     * data types with more than 1 fomul/TD. 
     * @param type Data type in map
     * @param data Mapdata with fomuls and track distances
     * @param key Which data point should be calculated
     * @returns Average
     */
    static getAverageDataPoint = (type: string, data: MapData, key: string): number | null => {
        const d = MapSettings.getMapData(type, data)

        const _fomuls = d[0]
        const _trackDistances = d[1]

        if (_fomuls === null || _trackDistances === null) return null;

        const fomulDataPoints = (_fomuls as FomulGeo[]).map(f => f[key]);
        const tdDataPoints = (_trackDistances as TrackDistanceGeo[]).map(f => f[key]);

        const combinedDataPoints = [...fomulDataPoints, ...tdDataPoints];
        const sum = combinedDataPoints.reduce((a, b) => a + b, 0);
        const avg = sum / combinedDataPoints.length

        return Math.round(avg)
    }
}


