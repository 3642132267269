import React, { useState } from "react";
import { AxiosResponse } from "axios";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import FileSaver from "../site/fileSaver";

import Color from "../resources/colors";
import { SiteView } from "../site/routes";

import ViewHeader from "../components/ViewHeader";
import UploadZone from "../components/upload/UploadZone";
import { SimpleSwitch } from "../components/Switch";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import { VFlex, HFlex, UploadContainer, Container } from "../pandora/styled";


const FormatView = (): React.ReactElement => {

    // State
    const [file, setFile] = useState<File | null>(null);
    const [disableDBMatching, setDisableDBMatching] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Format Data |  LCS Online`

    // Actions

    /**
     * Request file formatting to API 
     * and save returned, formatted file. 
     */
    const format = async () => {      
        try {
            setIsUploading(true);
            const payload = new FormData();
            payload.append("file", file);

            const queryStrings = {matchDatabase: !disableDBMatching};
            
            const res: AxiosResponse = await Thomas.request(APIRequest.formatTrackLinkData, payload, true, false, queryStrings);

            // Save file to client
            const saveConfig = `${APIRequest.formatTrackLinkData.headers["Accept"]}`
            const [, filename] = res.headers['content-disposition'].split('filename=');
            
            FileSaver.save(res.data, 
                            filename,
                            saveConfig)

            setPopup({
                title: "Formatting successful!",
                message: `Formatted Track Link data saved.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
            
        }
        setIsUploading(false);
    }


    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <ViewHeader view={SiteView.Format} />
            <UploadContainer>
                <UploadZone 
                    file=           {file}
                    isUploading=    {isUploading}
                    typeColor=      {Color.turqoise}
                    setFile=        {setFile}
                    setPopup=       {setPopup}
                    performUpload=  {format}
                />
                <HFlex className="switch-container">
                    <VFlex>
                        <h4>Offline mode</h4>
                        <p>
                            Switch on to skip matching of track links
                            <br></br>
                            to database instances for offline annotation.
                        </p>
                    </VFlex>
                    <SimpleSwitch 
                        value=     {disableDBMatching} 
                        onChange=  {setDisableDBMatching} 
                    />
                </HFlex>
            </UploadContainer>
        </Container>
    )
}

export default FormatView;


