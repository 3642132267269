import React from "react";
import styled from "styled-components";
import { LineChart, XAxis, YAxis, Line, CartesianGrid } from "recharts";

import { Point } from "../../pandora/pandora";


const Container = styled.div`
    width: 400px;
    height: 295px;
    padding: 15px 15px 10px 0px;
    border-radius: 5px;
`

/**
 * Plot properties
 * 
 * points - data points as x, y coordinates to display. 
 * profile - Track Link profile coordinates used for profile rendering. 
 */
interface PlotProps {
    points: Point[];
    profile?: Point[];
}


/**
 * Recharts plot. Refer to the Rechard library 
 * website https://recharts.org/en-US/ to learn more about 
 * it works and expected input format. 
 */
const Plot = (props: PlotProps): React.ReactElement => {

    return (
        <Container>
            <LineChart 
                width={350} 
                height={295} 
                data={props.points}
            >
                <CartesianGrid strokeDasharray="10" />
                <XAxis dataKey="x" type="number" domain={[-4000, 4000]} tick={{fontSize: 10}} stroke="white"/>
                <YAxis type="number" domain={[-500, 6000]} tick={{fontSize: 10}} stroke="white"/>
                {props.profile !== undefined && props.profile !== null && 
                    <Line 
                        type=       "monotone" 
                        dataKey=    "y"
                        data=       {props.profile} 
                        stroke=     "#ff5050" 
                        dot=        {false} 
                        strokeWidth={1}
                    />
                }
                <Line 
                    type=       "monotone" 
                    dataKey=    "y"
                    data=       {props.points} 
                    stroke=     "#5c7dcc" 
                    dot=        {false} 
                    strokeWidth={2}
                />
            </LineChart>
        </Container>
    )
};

export default Plot;