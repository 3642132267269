import React, { useState } from "react";
import styled from "styled-components";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";

import Color from "../../resources/colors";
import { SessionStatus } from "../../api/LCSModels";
import { GenericPopupProps } from "../GenericPopup";

import SessionStatusItem from "./SessionStatus";
import { HFlex } from "pandora/styled";


const Container = styled(HFlex)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;
    height: 40px;

    padding: 10px;
    border-radius: 5px;
`


interface SessionStatusUpdaterProps {
    currentStatus: SessionStatus;
    sessionID: number;
    setPopup: (value: GenericPopupProps) => void;
} 


const SessionStatusUpdater = (props: SessionStatusUpdaterProps): React.ReactElement => {

    // State
    const [selected, setSelected] = useState<SessionStatus>(props.currentStatus);

    // Actions

    /**
     * Set session status and perform API
     * request to update status in backend. 
     */
    const select = async (status: SessionStatus) => {
        setSelected(status);
        try {
            const payload = {id: props.sessionID, status: status}
            const _ = await Thomas.request(APIRequest.updateSessionStatus, payload)
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            props.setPopup({
                title: "Could not update session status",
                message: message,
                color: Color.red,
                setPopup: props.setPopup
            }) 
        }
    }

    return (
        <Container>
            {Object.keys(SessionStatus).reverse().map(s => 
                <SessionStatusItem 
                    key=        {s}
                    status=     {SessionStatus[s]}
                    selectable= {true}
                    selected=   {s === selected}
                    clicked=    {select}
                />
            )}

        </Container>
    )
}

export default SessionStatusUpdater;