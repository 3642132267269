import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import LocalStorage, { LocalStorageKey } from "../site/localStorage";

import { FomulMin, SearchResultType, SessionMin } from "../api/LCSModels";
import { SiteView } from "../site/routes";

import Color from "../resources/colors";
import SearchIcon from "../resources/images/magnifying-glass.png";
import DeleteIcon from "../resources/images/delete-icon.png";
import SessionIcon from "../resources/images/session-icon.svg";

import FOMUL_TYPES from "../pandora/fomul-types";
import PLACE_NAMES from "../pandora/place-names";

import ViewHeader from "../components/ViewHeader";
import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import Switch from "../components/Switch";

import FomulTypeDataList from "../components/data-lists/FomulTypeDataList";
import PlacesDataList from "../components/data-lists/PlacesDataList";

import SessionSelector from "../components/SessionSelector";
import DataStatusSelector from "../components/DataStatusSelector";
import SearchResultContainer from "../components/search/SearchResultContainer";
import { 
    HFlex, ViewContainer, Input, IconButton, QueryResultContainer, 
    SearchInputContainer, IntervalSearchInputContainer
} from "../pandora/styled";


const Container = styled(ViewContainer)`
    overflow: visible;
`

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const ContentGrid = styled(ContentDiv)`
    display: grid;

    grid-template-column: auto;
    grid-template-row: repeat(7, auto);

    column-gap: 10px;
    row-gap: 5px;

    padding: 0;
    height: auto;

    h4 {
        font-size: 12px;
        width: auto;
        height: fit-content;
        margin: 0;

        text-align: center;
    }

`


const SearchFomulsView = (): React.ReactElement => {

    const TEXT_KEYS = ["section", "addInfo"];
    const NUMBER_KEYS = ["frame", "version"];

    const KM_KEYS = ["kmMin", "kmMax"];
    const M_KEYS = ["mMin", "mMax"];
    const RADIUS_KEYS = ["radiusMin", "radiusMax"];

    const BOOL_KEYS = ["isCopy", "posDir", "exportStatus"];

    const SESSION_ID_KEY = "sessionID";

    // State
    const [query, setQuery] = useState<object>({});
    const [result, setResult] = useState<FomulMin[] | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Effects
    useEffect(() => {

        // Attempt to retrieve query search params
        // stored in LocalStorage
        const _query = LocalStorage.get(LocalStorageKey.FomulSearchParams)
        if (_query !== undefined && _query !== null) {
            setQuery(_query ?? {})
        }

        // Attempt to retrieve cached search result
        // stored in LocalStorage
        const cachedResult = LocalStorage.get(LocalStorageKey.FomulSearchResult)
        if (cachedResult !== undefined && cachedResult !== null) {
            setResult(cachedResult)
        }

        // Set doc title
        document.title = `Search Fomuls |  LCS Online`
    }, [])

    // Actions

    /**
     * Update search query. 
     */
    const updateQuery = (key: string, value: string | number |  boolean) => {
        const _query = {...query};

        // Attempt to find Fomul type code for 
        // fomul name inputted
        if (key === "type") {
            value = Object.keys(FOMUL_TYPES).find(key => FOMUL_TYPES[key] === value) ?? value
        }

        // Attempt to find Place signature for 
        // place name inputted
        if (key === "place") {
            value = Object.keys(PLACE_NAMES).find(key => PLACE_NAMES[key] === value) ?? value
        }

        _query[key] = value;
        Object.keys(_query).forEach(k => {
            if (_query[k] === "") delete _query[k];
        })
        setQuery(_query);
    }

    /**
     * Perform search request to API. 
     */
    const search = async () => {
        try {
            // Request track links for query from Thomas
            if (Object.keys(query).length === 0) {
                throw new Error("No query filter applied.")
            }

            setIsLoading(true);
            const _result: FomulMin[] = await Thomas.request(APIRequest.getFomulsForQuery, query) as FomulMin[]
            setResult(_result);
            setIsLoading(false);

            // Cache search result
            LocalStorage.set(LocalStorageKey.FomulSearchResult, _result)
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
            setIsLoading(false);
        }

        // Update local storage with query params
        LocalStorage.set(LocalStorageKey.FomulSearchParams, query)
    }

    /**
     * Clear search query stored in LocalStorage. 
     */
    const clear = (): void => {
        // Set empty query
        setQuery({});

        // Clear inputs with data options
        (document.getElementById("place-input") as HTMLInputElement).value = "";
        (document.getElementById("fomul-type-input") as HTMLInputElement).value = "";

        // Clear LocalStorage caching
        LocalStorage.remove(LocalStorageKey.FomulSearchParams)
        LocalStorage.remove(LocalStorageKey.FomulSearchResult)
    }

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation()
            search()
        }
    }

    const selectSession = (session: SessionMin) => updateQuery(SESSION_ID_KEY, session.id)

    return (
        <Container>
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            <ViewHeader view={SiteView.SearchFomuls} />
            <HFlex>
                <ContentDiv style={{width: 300, height: "fit-content"}}>
                    <ContentGrid>
                        <SearchInputContainer>
                            <span><h6>Type</h6></span>
                            <Input
                                id=             {"fomul-type-input"}
                                key=            {"type"}
                                type=           "text"
                                placeholder=    {"-"}
                                defaultValue=   {query["type"] ?? ""}
                                list=           {"fomul-type-data-list"}
                                onChange=       {(e) => updateQuery("type", e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                            <FomulTypeDataList id={"fomul-type-data-list"} />
                        </SearchInputContainer>
                        <SearchInputContainer>
                            <span><h6>Place</h6></span>
                            <Input
                                id=             {"place-input"}
                                key=            {"place"}
                                type=           "text"
                                placeholder=    {"-"}
                                defaultValue=   {query["place"] ?? ""}
                                list=           {"place-names-data-list"}
                                onChange=       {(e) => updateQuery("place", e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                            <PlacesDataList id={"place-names-data-list"} />
                        </SearchInputContainer>
                        {TEXT_KEYS.map(key => 
                            <SearchInputContainer>
                                <span><h6>{key}</h6></span>
                                <Input
                                    key=            {key}
                                    type=           "text"
                                    placeholder=    {"-"}
                                    value=          {query[key] || ""}
                                    onChange=       {(e) => updateQuery(key, e.target.value)}
                                    onKeyPress=     {onKeyPress}
                                />   
                            </SearchInputContainer>
                        )}
                        {NUMBER_KEYS.map(key => 
                            <SearchInputContainer>
                                <span><h6>{key}</h6></span>
                                <Input
                                key=            {key}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[key] || ""}
                                onChange=       {(e) => updateQuery(key, e.target.value)}
                                onKeyPress=     {onKeyPress}
                                />    
                            </SearchInputContainer>
                        )}
                        <IntervalSearchInputContainer>
                            <span><h6>Km</h6></span>
                            <Input
                                key=            {KM_KEYS[0]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[KM_KEYS[0]] || ""}
                                onChange=       {(e) => updateQuery(KM_KEYS[0], e.target.value)}
                                onKeyPress=     {onKeyPress}
                                />    
                            <div><h5> - </h5></div>
                            <Input                                        
                                key=            {KM_KEYS[1]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[KM_KEYS[1]] || ""}
                                onChange=       {(e) => updateQuery(KM_KEYS[1], e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                        </IntervalSearchInputContainer>
                        <IntervalSearchInputContainer>
                            <span><h6>M</h6></span>
                            <Input
                                key=            {M_KEYS[0]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[M_KEYS[0]] || ""}
                                onChange=       {(e) => updateQuery(M_KEYS[0], e.target.value)}
                                onKeyPress=     {onKeyPress}
                                />    
                            <div><h5> - </h5></div>
                            <Input                                        
                                key=            {M_KEYS[1]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[M_KEYS[1]] || ""}
                                onChange=       {(e) => updateQuery(M_KEYS[1], e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                        </IntervalSearchInputContainer>
                        <IntervalSearchInputContainer>
                            <span><h6>Radius</h6></span>
                            <Input
                                key=            {RADIUS_KEYS[0]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[RADIUS_KEYS[0]] || ""}
                                onChange=       {(e) => updateQuery(RADIUS_KEYS[0], e.target.value)}
                                onKeyPress=     {onKeyPress}
                                />    
                            <div><h5> - </h5></div>
                            <Input                                        
                                key=            {RADIUS_KEYS[1]}
                                type=           "number"
                                placeholder=    {"-"}
                                value=          {query[RADIUS_KEYS[1]] || ""}
                                onChange=       {(e) => updateQuery(RADIUS_KEYS[1], e.target.value)}
                                onKeyPress=     {onKeyPress}
                            />   
                        </IntervalSearchInputContainer>
                        {BOOL_KEYS.map(key => 
                            <SearchInputContainer>
                                <span><h6>{key}</h6></span>
                                <Switch 
                                    key=       {key}
                                    configKey= {key}
                                    value=     {query[key] || false} 
                                    onChange=  {updateQuery} 
                                />  
                            </SearchInputContainer>
                        )}
                        <SearchInputContainer style={{alignItems: "flex-start"}}>
                            <span style={{marginTop: 5}}><h6>Status</h6></span>
                            <DataStatusSelector 
                                queryKey=   "status"
                                value=      {query["status"] || null }
                                setValue=   {updateQuery}
                            />
                        </SearchInputContainer>
                        <SearchInputContainer style={{alignItems: "flex-start"}}>
                            <span style={{marginTop: 5}}><h6>Session</h6></span>
                            <SessionSelector 
                                selectSession=  {selectSession}
                                buttonTitle=    {query[SESSION_ID_KEY] ?? "Select"}
                                icon=           {SessionIcon}
                                buttonStyle=    {{backgroundColor: Color.darkGrey}}
                            />
                        </SearchInputContainer>
                    </ContentGrid>
                    <IconButton 
                        color=      {Color.blue}
                        hoverColor= {Color.lightBlue}
                        icon=       {SearchIcon}
                        onClick=    {search}
                        style=      {{marginTop: 50, textAlign: "center", textIndent: 0, marginRight: 0, width: "auto"}}
                    >Search</IconButton>
                    <IconButton 
                        color=      {Color.extraDarkGrey}
                        hoverColor= {Color.red}
                        icon=       {DeleteIcon}
                        onClick=    {clear}
                        style=      {{marginTop: 10, textAlign: "center", textIndent: 0, marginRight: 0, width: "auto", height: 25, lineHeight: "25px"}}
                    >Clear search</IconButton>
                </ContentDiv>
                <QueryResultContainer style={{marginLeft: 20}}>
                    {isLoading && 
                        <LoadingComponent message="Fetching data..." />
                    }
                    {!isLoading && result !== null && result.length === 0 && 
                        <NoDataComponent message="Query yielded no results." />
                    }
                    {!isLoading && result === null &&
                        <NoDataComponent message="Filter Fomuls to search" />
                    }
                    {!isLoading && result !== null && result.length !== 0 && 
                        <SearchResultContainer 
                            type=           {SearchResultType.Fomul}
                            result=         {result}
                            setPopup=       {setPopup}
                            triggerSearch=  {search}
                        />
                    }
                </QueryResultContainer>
            </HFlex>
        </Container>
    )
}

export default SearchFomulsView;

