import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import { SiteRoute } from "../site/routes";
import QueryParser from "../site/queryParser";
import ShareHandler from "../site/share";
import FileSaver from "../site/fileSaver";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { TrackLink, SessionMinWData, SessionMin, TrackLinkStatus, ExportOverride, TrackLinkChangeLog, Flag, Completion } from "../api/LCSModels";
import ProfileHandler from "../pandora/ProfileHandler";

import { TrackLinkStatusColorMap, Point } from "../pandora/pandora";
import Color from "../resources/colors";
import TrackLinkIcon from "../resources/images/tracklink-icon.svg";
import DistanceIcon from "../resources/images/track-distance-icon.svg";
import FlagIcon from "../resources/images/flag-icon-red.svg";

import ShareButton from "../components/ShareButton";
import ExportButton from "../components/ExportButton";
import CompletionTag from "../components/track-link/CompletionTag";
import PositionTag from "../components/PositionTag";

import ExportOverrideView from "../components/track-link/ExportOverrideView";
import TrackLinkSessionList from "../components/track-link/TrackLinkSessionList";
import TrackLinkChangeLogItem from "../components/track-link/TrackLinkChangeLogItem";
import Plot from "../components/data/Plot";
import TrackLinkFlag from "../components/track-link/TrackLinkFlag";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../components/ConfirmationPopup";

import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import MapComponent from "../components/MapComponent";

import { 
    PageIcon, HFlex, ViewContainer, 
    BackNavButton, VFlex, Tag, Input, 
    DataViewContainer
} from "../pandora/styled";






const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const DataInputContainer = styled(HFlex)`
    margin-top: 5px;
    align-items: center;

    h6 {
        width: 120px;
        margin-right: 10px;
        text-transform: capitalize;
    }
`

const ContentGrid = styled(ContentDiv)<{cols?: number}>`
    display: grid;

    grid-template-columns: repeat(${props => props.cols ?? 6}, 1fr);
    grid-teplate-row: repeat(2, auto);

    column-gap: 10px;
    row-gap: 15px;

    height: auto;

    h4 {
        font-size: 12px;
        width: auto;
        height: fit-content;
        margin: 0;

        text-align: center;
    }

    h3 {
        font-size: 15px;
        font-weight: normal;
        text-align: center;
    }
`

const Container = styled(ViewContainer)`
    overflow: visible;
`




const TrackLinkView = (): React.ReactElement => {

    // State
    const [trackLink, setTrackLink] = useState<TrackLink | null | undefined>(undefined);
    const [sessions, setSessions] = useState<SessionMinWData[] | null>(null);
    const [changeLogs, setChangeLogs] = useState<TrackLinkChangeLog[] | null>(null);
    const [profilePoints, setProfilePoints] = useState<Point[] | null>(null);

    const [fomulCount, setFomulCount] = useState<number>(0);
    const [trackDistanceCount, setTrackDistanceCount] = useState<number>(0);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

    // Effects

    /**
     * Request Track Link instance data from API. 
     */
    useEffect(() => {

        const getTrackLink = async () => { 
            try {
                const id = QueryParser.getQueryArg("id")
                if (id === null) 
                    throw new Error("Could not parse ID from query parameters.")

                // Request sessions from Thomas. 
                const payload = {id: id}
                const _trackLink: TrackLink = await Thomas.request(APIRequest.getTrackLink, payload) as TrackLink
                const _sessions: SessionMinWData[] = await Thomas.request(APIRequest.getSessionsForTrackLink, payload) as SessionMinWData[]
                const _changeLogs: TrackLinkChangeLog[] = await Thomas.request(APIRequest.getTrackLinkChangeLog, payload) as unknown as TrackLinkChangeLog[]

                setTrackLink(_trackLink);
                setSessions(_sessions);
                setChangeLogs(_changeLogs);

                // Set doc title
                document.title = `${_trackLink.fromPlace} ${_trackLink.fromNode} ${_trackLink.toPlace} ${_trackLink.toNode} |  LCS Online`
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    title: "Could not fetch Track Link",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 

                setTrackLink(null);
            }
        }

        getTrackLink()
    }, [])

    /**
     * Determine Fomul and TD count 
     * for Track Link. 
     */
    useEffect(() => {

        if (sessions === null) return;

        let _fomulCount = 0;
        let _trackDistanceCount = 0;
        sessions.forEach(s => {
            _fomulCount += s.fomuls.length
            _trackDistanceCount += s.trackDistances.length
        })

        setFomulCount(_fomulCount);
        setTrackDistanceCount(_trackDistanceCount);
    }, [sessions])

    /**
     * Recalculate Track Link profile points
     * on profile selection update. 
     */
    useEffect(() => {
        if (trackLink === undefined || trackLink === null) return;

        // Retrieve and store profile points
        const _profilePoints = ProfileHandler.getCalculatedProfile(trackLink.profile, 
                                                                   trackLink.extensionFormula, 
                                                                   0, 
                                                                   true)
        setProfilePoints(_profilePoints);
    }, [trackLink])
    
    // Actions

    /**
     * Request completion removal for TrackLink to API. 
     */
    const removeCompletion = () => {
        
        const callback = async () => {
            try {
                // Request track link completion status removal
                const payload = { id: trackLink.id }
                const wasRemoved = await Thomas.request(APIRequest.removeTrackLinkCompletion, payload)
    
                if (wasRemoved) {
                    const _trackLink = {...trackLink, completion: null}
                    setTrackLink(_trackLink);
    
                    setPopup({
                        title: "Removed completion",
                        message: "Completion removal for track link successful.",
                        color: Color.red,
                        setPopup: setPopup
                    }) 
                }
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    title: "Could not remove completion",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Attention!",
            message: "Are you sure you want to delete this completion?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request Track Link be marked completed. 
     */
     const completeTrackLink = async (session: SessionMin) => {
        try {
            // Request Track Link data export file
            const payload = {id: trackLink.id, sessionID: session.id}
            const completion: Completion = await Thomas.request(APIRequest.completeTrackLink, payload) as Completion;

            // Update completion on track link
            const _trackLink = {...trackLink, completion: completion};
            setTrackLink(_trackLink);

            setPopup({
                title: "Track Link completed!",
                message: `Track Link has been completed on session ${session.date}`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Track Link export override registration to API. 
     */
    const addExportOverride = async (fromDate: string, toDate: string) => {
        try {
            // Request export override deletion from Thomas. 
            const payload = {
                id: trackLink.id,
                fromDate: fromDate, 
                toDate: toDate
            }
            const newExportOverride: ExportOverride = await Thomas.request(APIRequest.addTrackLinkOverride, payload) as ExportOverride

            const _exportOverrides = [...trackLink.exportOverrides, newExportOverride]
            const _trackLink = {...trackLink, exportOverrides: _exportOverrides}
            setTrackLink(_trackLink);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not add Export Override",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Track Link export override deletion to API. 
     */
    const deleteExportOverride = async (override: ExportOverride) => {
        try {
            // Request export override deletion from Thomas. 
            const payload = {id: override.id}
            const _ = await Thomas.request(APIRequest.deleteTrackLinkOverride, payload)

            const _exportOverrides = [...trackLink.exportOverrides]
            _exportOverrides.splice(_exportOverrides.indexOf(override), 1)
            const _trackLink = {...trackLink, exportOverrides: _exportOverrides}
            setTrackLink(_trackLink);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not delete Export Override",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Set Track Link flag. 
     */
     const addTrackLinkFlag = async (comment: string) => {
        try {
            // Request export override deletion from Thomas. 
            const payload = {
                id: trackLink.id,
                comment: comment
            }
            const flag: Flag = await Thomas.request(APIRequest.setTrackLinkFlagged, payload) as unknown as Flag

            const _trackLink = {...trackLink, flag: flag}
            setTrackLink(_trackLink);
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not set Track Link flag",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request removal of Track Link flag. 
     */
    const removeTrackLinkFlag = () => {
        
        const callback = async () => {
            try {
                // Request Track Link flag removal 
                const payload = {id: trackLink.id}
                const succeeded = await Thomas.request(APIRequest.unsetTrackLinkFlagged, payload)
    
                if (succeeded) {
                    const _trackLink = {...trackLink, flag: null}
                    setTrackLink(_trackLink);
                } else {
                    throw new Error("Track Link flag was not removed.")
                }
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    title: "Could not remove flag",
                    message: message,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Attention!",
            message: "Are you sure you want to remove this flag?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request Track Link data export from API
     * and save data to disk. 
     */
    const exportTrackLinkData = async (config: Record<string, boolean>) => {
        try {
            // Request Track Link data export file
            const payload = {id: trackLink.id, ...config}
            const res: AxiosResponse = await Thomas.request(APIRequest.exportTrackLink, payload, true);

            // Save file to client
            const saveConfig = `${APIRequest.exportTrackLink.headers["Accept"]}`
            const signature = `${trackLink.section} ${trackLink.fromPlace} ${trackLink.fromNode} ${trackLink.toPlace} ${trackLink.toNode} ${trackLink.trackNumber ?? ""}`
            FileSaver.save(res.data, 
                            `Export ${signature}`,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of Track Link ${signature} completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <Container>
            <BackNavButton 
                color=      {Color.darkGrey} 
                hoverColor= {Color.extraLightGrey}
                text=       "Track Links"
                link=       {SiteRoute.TrackLinks}
                rightSide=  {false}
            />
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            {confirmation !== null && 
                <ConfirmationPopup confirmation={confirmation}/> 
            }
            {trackLink === undefined && 
                <LoadingComponent message="Fetching Track Link..." />
            }
            {trackLink === null && 
                <NoDataComponent message="Track Link query yielded no result." />
            }
            {trackLink !== null && trackLink !== undefined && 
                <DataViewContainer>
                    <HFlex style={{
                        alignItems: "center", 
                        justifyContent: "space-between",
                        marginBottom: "20px", 
                        paddingBottom: "30px",
                        borderBottom: "2px solid #21262c"
                    }}>
                        <HFlex>
                            <PageIcon icon={TrackLinkIcon} />
                            <h1 style={{fontWeight: "normal", display: "flex", alignItems: "center"}}>
                                <b>#{trackLink.id} </b>
                                <span style={{marginLeft: 10}}>Track Link</span>
                                {trackLink.flag !== null &&
                                    <img 
                                        src={FlagIcon}
                                        style={{width: 30, height: 30, marginLeft: 30}}
                                    />
                                }
                                {trackLink.flag !== null &&
                                    <span style={{color: Color.red, marginLeft: 10, fontSize: 30}}
                                    ><b>Flagged</b></span>
                                }
                            </h1>
                        </HFlex>
                        <HFlex style={{width: "fit-content"}}>
                            <TrackLinkFlag 
                                flag=       {trackLink.flag}
                                setFlag=    {addTrackLinkFlag}
                                removeFlag= {removeTrackLinkFlag}
                            />
                            <CompletionTag 
                                completion=         {trackLink.completion} 
                                removeCompletion=   {removeCompletion}
                                completeTrackLink=  {completeTrackLink}
                            />
                        </HFlex>
                    </HFlex>
                    <HFlex style={{
                        justifyContent: "space-between", 
                        borderRadius: "5px", 
                        backgroundColor: Color.darkGrey, 
                        alignItems: "center", 
                        paddingLeft: "15px", 
                        paddingRight: "10px"
                    }}>
                        <HFlex style={{height: 60, alignItems: "center"}}>
                            <h5 style={{marginRight: 15}}>v{trackLink.version}</h5>
                            <h5 style={{fontWeight: "normal", marginRight: 15}}><b>updated</b> {moment.utc(trackLink.updated).local().format("YYYY-MM-DD HH:mm")}</h5>
                            <Tag 
                                color=      {TrackLinkStatusColorMap[trackLink.status]} 
                                style=      {{marginRight: 15, cursor: "auto", fontSize: 12}}
                            >{TrackLinkStatus[trackLink.status]}</Tag>
                        </HFlex>
                        <HFlex style={{width: "fit-content"}}>
                            <ShareButton 
                                link=       {ShareHandler.shareTrackLink(trackLink)}
                                setPopup=   {setPopup}
                            />
                            <ExportButton 
                                title=  {"Export TrackLink data"} 
                                config= {"data"}
                                export= {exportTrackLinkData}
                            />
                        </HFlex>
                    </HFlex>
                    <ContentGrid 
                        style=  {{marginTop: 20}} 
                        cols=   {trackLink.direction.length === 0 || trackLink.direction === null ? 6 : 7}
                    >
                        <h4>Section</h4>
                        <h4>From place</h4>
                        <h4>From node</h4>
                        <h4>To place</h4>
                        <h4>To node</h4>
                        <h4>Track number</h4>
                        {trackLink.direction && <h4>Direction</h4>}
                        <h3>{trackLink.section}</h3>
                        <h3>{trackLink.fromPlace}</h3>
                        <h3>{trackLink.fromNode}</h3>
                        <h3>{trackLink.toPlace}</h3>
                        <h3>{trackLink.toNode}</h3>
                        <h3>{trackLink.trackNumber ?? ""}</h3>
                        {trackLink.direction && <h3>{trackLink.direction}</h3>}
                    </ContentGrid>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "20%", marginRight: "20px"}}>
                            <h4>Stretch</h4>
                            <VFlex style={{marginTop: 20, alignItems: "center"}}>
                                <PositionTag
                                    atKm={trackLink.fromKm}
                                    atM={trackLink.fromM}
                                    color={Color.extraDarkGrey}
                                    css={{
                                        cursor: "auto", 
                                        fontSize: 15,
                                        padding: 10
                                    }}
                                />
                                <img src={DistanceIcon} style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                    transform: "rotate(90deg)",
                                    width: 30,
                                    height: 30
                                }}/>
                                <PositionTag
                                    atKm={trackLink.toKm}
                                    atM={trackLink.toM}
                                    color={Color.extraDarkGrey}
                                    css={{
                                        cursor: "auto", 
                                        fontSize: 15,
                                        padding: 10
                                    }}
                                />
                            </VFlex>
                        </ContentDiv>
                        <ContentDiv style={{width: "50%", marginRight: "20px", minWidth: "20%"}}>
                            <h4>Data</h4>
                            {["linkLength", "direction", "extensionFormula", "profile"].map(key =>
                                <DataInputContainer>
                                    <h6 style={{marginRight: 10}}>{key}</h6>
                                    <Input
                                        type=           "text"
                                        placeholder=    {"-"}
                                        value=          {trackLink[key] ?? "null"}
                                        readOnly=       {true}
                                    />   
                                </DataInputContainer>
                            )}
                        </ContentDiv>
                        <ContentDiv style={{width: "30%", minWidth: 400}}>
                            <Plot 
                                points=     {[]}
                                profile=    {profilePoints}
                            />
                        </ContentDiv>
                    </HFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <h4>Export override</h4>
                            <ExportOverrideView 
                                trackLink=      {trackLink}
                                addOverride=    {addExportOverride}
                                deleteOverride= {deleteExportOverride}
                            />
                        </ContentDiv>
                        <ContentDiv style={{width: "50%", minHeight: 200}}>
                            <MapComponent 
                                link=           {SiteRoute.Map + `?tracklink=${trackLink.id}`}
                                centerPoint=    {[14.16, 56.76]}
                                dataType=       {"tracklink"}
                            />
                        </ContentDiv>
                    </HFlex>
                    <ContentDiv style={{marginTop: 20, height: "auto"}}>
                        <h3 style={{marginBottom: 10}}>Data</h3>
                        {sessions === null && 
                            <LoadingComponent message="Fetching session data..." />
                        }
                        {sessions !== null && sessions.length == 0 && 
                            <NoDataComponent message="No sessions for Track Link." />
                        }
                        {sessions !== null && sessions.length !== 0 && 
                            <div>
                                <HFlex style={{marginBottom: 20}}>
                                    <Tag 
                                        color=      {Color.lightGrey} 
                                        style=      {{marginRight: 10, cursor: "auto", fontSize: 12}}
                                    >{fomulCount} Fomuls</Tag>
                                    <Tag 
                                        color=      {Color.lightGrey} 
                                        style=      {{marginRight: 10, cursor: "auto", fontSize: 12}}
                                    >{trackDistanceCount} Track Distances</Tag>
                                </HFlex>
                                {sessions.map(s => 
                                    <TrackLinkSessionList 
                                        key={s.id}
                                        session={s} 
                                    />
                                )}
                            </div>
                        }
                    </ContentDiv>
                    {changeLogs !== null && changeLogs.length !== 0 &&
                        <ContentDiv style={{marginTop: 20, height: "auto"}}>
                            <h3 style={{marginBottom: 20}}>Changelog</h3>
                            {changeLogs.map((c, index) => 
                                <TrackLinkChangeLogItem 
                                    key=        {`changelog-${index}`}
                                    changelog=  {c}
                                />
                            )}
                        </ContentDiv>
                    }
                </DataViewContainer>
            }
        </Container>
    )   
}


export default TrackLinkView;