import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { AuditResponse } from "../api/LCSModels";

import LocalStorage, { LocalStorageKey } from "../site/localStorage";
import { SiteView } from "../site/routes";

import Color from "resources/colors";

import ErrorIconWhite from "../resources/images/error-icon-white.png";
import WarningIconWhite from "../resources/images/warning-icon-white.png";
import AuditIcon from "../resources/images/audit-icon.svg";
import HourglassIcon from "../resources/images/Hourglass.png";
import SectionIcon from "../resources/images/section-icon.svg";
import FomulIcon from "../resources/images/fomul-icon.svg";
import DateIcon from "../resources/images/Calendar.png";
import FileIcon from "../resources/images/export-icon.svg";
import DeleteIcon from "../resources/images/delete-icon.png";

import GenericPopup, { GenericPopupProps } from "components/GenericPopup";
import UploadZone from "components/upload/UploadZone";
import LoadingComponent from "components/LoadingComponent";
import NoDataComponent from "components/NoDataComponent";
import AuditWarningListItem from "../components/audit/AuditWarningListItem";
import AuditListItem from "../components/audit/AuditListItem";
import { Button, Container, HFlex, IconButton, MetaDataChildItem, UploadContainer, VFlex } from "../pandora/styled";
import ViewHeader from "../components/ViewHeader";

import { FadeAnimation } from "../pandora/animations";



const AuditButton = styled(Button)<{icon?: string}>`
    width: 400px;
    height: 35px;

    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 20px center;

    line-height: 35px;
    text-align: center;
    font-size: 15px;

    display: block;
    margin: 0 auto;

    &: hover {
        width: 420px;
    }
`

const AuditButtonContainer = styled(VFlex)`
    box-sizing: border-box;

    margin: 15px;
    padding: 15px;
    border-radius: 5px;
    
`

const AuditUploadContainer = styled(UploadContainer)`
    margin: 10px;
    background-color: ${Color.extraDarkGrey};

    h5 {
        margin: 10px 0px 20px;
        font-weight: normal;
    }
`

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};
`

const ContentGrid = styled(ContentDiv)`
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    column-gap: 10px;
    row-gap: 10px;

    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;
`

const AuditContainer = styled(VFlex)`
    height: 100%;
    padding: 0px;
    box-sizing: border-box;
    overflow-y: auto;
    opacity: 0;

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;

    .top-box {
        background-color: ${Color.extraDarkGrey};
        width: 100%;
        height: 50px;
    }

    h4 { 
        font-weight: normal;
    }

    img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    .fileName {
        display: flex;
        align-items: center;

        background-color: ${Color.extraDarkGrey};
        padding: 10px;
        border-radius: 5px;
        margin-right: 15px;
    }
`

enum SelectedDataType {
    Errors = "Errors",
    Warnings = "Warnings",
}


const AuditView = (): React.ReactElement => {

    // State
    const [filePrev, setFilePrev] = useState<File | null>(null);
    const [fileCurr, setFileCurr] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [auditResult, setAuditResult] = useState<AuditResponse>(null);
    const [errors, setErrors] = useState<any[]>([]);
    const [warnings, setWarnings] = useState<any[]>([]);

    const [pastAudits, setPastAudits] = useState<AuditResponse[]>([]);

    const [selectedAuditDataType, setSelectedAuditDataType] = useState<SelectedDataType>(SelectedDataType.Errors);

    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `BIS Audit |  LCS Online`

    // Effects

    useEffect(() => {
        // Fetch past audits from local storage
        const _pastAudits = (LocalStorage.get(LocalStorageKey.AuditHistory) || []).reverse()
        setPastAudits(_pastAudits);
    }, [])

    useEffect(() => {
        if (auditResult === null) return;
        const errors = getErrors();
        setErrors(errors);
    }, [auditResult])

    useEffect(() => {
        if (auditResult === null) return;
        const warnings = getWarnings();
        setWarnings(warnings);
    }, [auditResult])

    // Actions

    const getErrors = () => {
        var errors = [];
        auditResult.warnings.map(w => 
            {if (w.isError == true) {
                errors.push(w)
            }}
        )
        return errors;
    }

    const getWarnings = () => {
        var warnings = [];
        auditResult.warnings.map(w => 
            !w.isError && 
                warnings.push(w)
        )
        return warnings;
    }

    const selectAudit = (audit: AuditResponse) => {
        setAuditResult(audit);
        document.getElementById("content-container").scroll(0,0)
    }

    const addAuditToHistory = (audit: AuditResponse) => {
        const _audits = [audit, ...pastAudits];
        LocalStorage.set(LocalStorageKey.AuditHistory, _audits);

        setPastAudits(_audits);
    }

    const clearAuditHistory = () => {
        LocalStorage.set(LocalStorageKey.AuditHistory, []);
        setPastAudits([]);
    }


    /**
     * Audit
     */
    const audit = async () => {      
        try {

            if (!(filePrev && fileCurr))
                throw new Error("Please select two files.")

            setIsLoading(true);
            const payload = new FormData();
            payload.append("prev", filePrev);
            payload.append("curr", fileCurr);

            const auditResponse: AuditResponse = await Thomas.request(APIRequest.audit, payload) as AuditResponse;
            setAuditResult(auditResponse);

            // Add audit to history
            addAuditToHistory(auditResponse);

            setPopup({
                title: "Audit successful!",
                message: `Audit completed`,
                color: Color.green,
                setPopup: setPopup
            });
        } catch (e) {
            const message = Thomas.getErrorMessage(e);
            setPopup({
                message: message,
                setPopup: setPopup
            });
        }
        setIsLoading(false);
    }

    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            <ViewHeader view={SiteView.Audit} />
            {!auditResult &&
                <UploadContainer style={{height: "auto", backgroundColor: Color.darkGrey, justifyContent: "space-between"}}>
                    {!isLoading &&
                        <HFlex>
                            <AuditUploadContainer>
                                <h3>Previous state</h3>
                                <h5>Enter BIS export prior to state change.</h5>
                                    <UploadZone 
                                        file=           {filePrev}
                                        isUploading=    {isLoading}
                                        typeColor=      {Color.lightBlue}
                                        setFile=        {setFilePrev}
                                        setPopup=       {setPopup}
                                        noUpload=       {true}
                                    />
                            </AuditUploadContainer>
                            <AuditUploadContainer>
                                <h3>Current state</h3>
                                <h5>Enter BIS export after state change.</h5>
                                {!isLoading &&
                                    <UploadZone 
                                        file=           {fileCurr}
                                        isUploading=    {isLoading}
                                        typeColor=      {Color.lightPurple}
                                        setFile=        {setFileCurr}
                                        setPopup=       {setPopup}
                                        noUpload=       {true}
                                    />
                                } 
                            </AuditUploadContainer>
                        </HFlex>
                    }
                    <AuditButtonContainer>
                        {!isLoading &&
                            <AuditButton 
                                icon=       {AuditIcon}
                                onClick=    {audit}
                                color=      {Color.blue}
                                hoverColor= {Color.lightBlue}
                            >Audit</AuditButton>
                        }
                        {isLoading &&
                            <LoadingComponent message={"Uploading..."} />
                        }
                    </AuditButtonContainer>
                </UploadContainer>    
            }
            {auditResult &&
                <ContentDiv style={{width: "auto", height: "75vh", padding: 20}}>
                    <AuditContainer>
                        <HFlex style={{margin: "0px 0px 30px", justifyContent: "space-between", alignItems: "center"}}>
                            <HFlex style={{alignItems: "center"}}>
                                <h4 className="fileName">
                                    <img src={FileIcon} />
                                    <strong>Previous file: </strong>
                                </h4>
                                <h4 style={{marginRight: 50}}>{auditResult.metadata.fileNamePrev}</h4>
                                <h4 className="fileName">
                                    <img src={FileIcon} />
                                    <strong>Current file: </strong>
                                </h4>
                                <h4>{auditResult.metadata.fileNameCurr}</h4>
                            </HFlex>
                            <IconButton
                                color=      {Color.blue}
                                hoverColor= {Color.lightBlue}
                                icon=       {AuditIcon}
                                onClick=    {() => {
                                    setAuditResult(null);
                                    setFilePrev(null);
                                    setFileCurr(null);
                                }}
                                style=      {{textAlign: "center", marginRight: 10,  textIndent: 10, width: 200}}
                            >Perform new Audit</IconButton>
                        </HFlex>
                        <HFlex>
                            <ContentGrid className="top-box" style={{marginRight: 10}}>
                                <MetaDataChildItem>
                                    <img src={SectionIcon} />
                                    <h5><strong>Section:</strong> {auditResult.metadata.section}</h5> 
                                </MetaDataChildItem>
                                <MetaDataChildItem>
                                    <img src={HourglassIcon} />
                                    <h5><strong>Duration:</strong> {auditResult.metadata.auditDuration}</h5>
                                </MetaDataChildItem>
                                <MetaDataChildItem>
                                    <img src={DateIcon} />
                                    <h5><strong>Date:</strong> {auditResult.metadata.auditDate}</h5>
                                </MetaDataChildItem>
                                <MetaDataChildItem>
                                    <img src={FomulIcon} />
                                    <h5><strong>Changed fomuls:</strong> {auditResult.metadata.changedFomuls}</h5>
                                </MetaDataChildItem>
                                <MetaDataChildItem>
                                    <img src={WarningIconWhite} />
                                    <h5><strong>Total warnings:</strong> {auditResult.metadata.warningAmount}</h5>
                                </MetaDataChildItem>
                            </ContentGrid>
                        </HFlex>
                        <HFlex style={{margin: "40px 0px 10px", height: 50}}>
                            <IconButton
                                color=      {selectedAuditDataType === SelectedDataType.Errors ? Color.lightGrey : Color.extraDarkGrey}
                                hoverColor= {selectedAuditDataType === SelectedDataType.Errors ? Color.lightGrey : Color.extraLightGrey}
                                icon=       {ErrorIconWhite}
                                onClick=    {() => setSelectedAuditDataType(SelectedDataType.Errors)}
                                style=      {{textAlign: "center", textIndent: 10, width: 150}}
                            >Errors ({errors.length})</IconButton>
                            <IconButton  
                                color=      {selectedAuditDataType === SelectedDataType.Warnings ? Color.lightGrey : Color.extraDarkGrey}
                                hoverColor= {selectedAuditDataType === SelectedDataType.Warnings ? Color.lightGrey : Color.extraLightGrey}
                                icon=       {WarningIconWhite}
                                onClick=    {() => setSelectedAuditDataType(SelectedDataType.Warnings)}
                                style=      {{textAlign: "center", textIndent: 10, width: 150}}
                            >Warnings ({warnings.length})</IconButton>
                        </HFlex>
                        {selectedAuditDataType === SelectedDataType.Errors &&
                            <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                                {errors.map(w => 
                                    <AuditWarningListItem warning = {w} />
                                )}
                                {errors.length === 0 &&
                                    <NoDataComponent message={`No warnings found`} />
                                }
                            </ContentDiv>
                        }
                        {selectedAuditDataType === SelectedDataType.Warnings &&
                            <ContentDiv className="data-container" style={{padding: 0, height: "auto"}}>
                                {warnings.map(w => 
                                    <AuditWarningListItem
                                        warning = {w}
                                    />
                                )}
                                {warnings.length === 0 &&
                                    <NoDataComponent message={`No warnings found`} />
                                }
                            </ContentDiv>
                        }
                    </AuditContainer>
                </ContentDiv>
            }
            {pastAudits.length !== 0 &&
                <ContentDiv style={{marginTop: 30, padding: "20px 15px"}}>
                    <HFlex style={{alignItems: "center", justifyContent: "space-between"}}>
                        <h3 style={{marginBottom: 20}}>Audit history</h3>
                        <IconButton 
                            color=      {Color.extraDarkGrey}
                            hoverColor= {Color.extraLightGrey}
                            icon=       {DeleteIcon}
                            onClick=    {clearAuditHistory}
                            title=      {`Clear audit history`}
                            style=      {{
                                textAlign: "center", 
                                textIndent: 25,
                                width: "auto", 
                                height: 20,
                                lineHeight: "20px"
                            }}
                        >Clear history</IconButton>
                    </HFlex>
                    {pastAudits.map((a: AuditResponse, index) => 
                        <AuditListItem
                            key={`audit-${index}`}
                            audit={a}
                            select={selectAudit}
                        />
                    )}
                </ContentDiv>            
            }
        </Container>
    )
}

export default AuditView;
