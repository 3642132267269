
/**
 * Define all LCSOnline routes as enum. 
 */
export enum SiteRoute {
    
    Sessions = "/",
    
    Upload = "/upload",
    Export = "/export",
    Audit = "/audit",

    TrackLinks = "/tracklinks",
    SearchFomuls = "/search-fomuls",
    SearchTrackDistances = "/search-trackdistances",

    TrackLink = "/tracklink",
    Session = "/session",
    Fomul = "/fomul",
    TrackDistance = "/trackdistance",
    
    Network = "/network",
    NetworkMap = "/network-map",
    Section = "/section",
    Place = "/place",
    
    Format = "/format",
    Metrics = "/metrics",
    Map = "/map",
    Bin = "/bin",
    Error = "/404"
}

export enum SiteView {
    Sessions = SiteRoute.Sessions,

    Upload = SiteRoute.Upload,
    Export = SiteRoute.Export, 
    Audit = SiteRoute.Audit,

    TrackLinks = SiteRoute.TrackLinks,
    SearchFomuls = SiteRoute.SearchFomuls,
    SearchTrackDistances = SiteRoute.SearchTrackDistances,

    TrackLink = SiteRoute.TrackLink,
    Session = SiteRoute.Session,
    Fomul = SiteRoute.Fomul,
    TrackDistance = SiteRoute.TrackDistance,

    Network = SiteRoute.Network,
    NetworkMap = SiteRoute.NetworkMap,
    Section = SiteRoute.Section,
    Place = SiteRoute.Place,

    Format = SiteRoute.Format,
    Metrics = SiteRoute.Metrics,
    Map = SiteRoute.Map,
    Bin = SiteRoute.Bin,
    Error = SiteRoute.Error
}

export interface AppViewConfig {
    view: SiteView
    name: string
    description?: string
}

export class Site {

    // Routes
    public static routeExists = (route: string): boolean => {
        return (Object.values(SiteRoute) as string[]).includes(route)
    }

    // Views
    public static AppViews = class {

        // Define configurations for 
        // views in LCSOnline

        static Sessions: AppViewConfig = {
            view: SiteView.Sessions,
            name: "Sessions",
            description: "All registered measurement sessions can be found here. Easily navigate to the session you're looking for by using the filter. Sessions between today and start of previous month shown initially."
        }

        static TrackLinks: AppViewConfig = {
            view: SiteView.TrackLinks,
            name: "Track Links",
            description: "Search and filter LÖ Track Links in the entire railway network. Combine properties to narrow your search or retrieve an entire 'Driftplats' using the 'Place' query property."
        }

        static SearchFomuls: AppViewConfig = {
            view: SiteView.SearchFomuls,
            name: "Fomuls",
            description: "Search and filter Fomuls in the database. Combine properties to narrow your search or retrieve an entire 'Driftplats' using the 'Place' query property."
        }

        static SearchTrackDistances: AppViewConfig = {
            view: SiteView.SearchTrackDistances,
            name: "Track Distances",
            description: "Search and filter Track Distances in the database. Combine properties to narrow your search or retrieve an entire 'Driftplats' using the 'Place' query property."
        }

        static TrackLink: AppViewConfig = {
            view: SiteView.TrackLink,
            name: "Track Link",
            description: null
        }

        static Session: AppViewConfig = {
            view: SiteView.Session,
            name: "Session",
            description: null
        }        

        static Fomul: AppViewConfig = {
            view: SiteView.Fomul,
            name: "Fomul",
            description: null
        }      

        static TrackDistance: AppViewConfig = {
            view: SiteView.TrackDistance,
            name: "TrackDistance",
            description: null
        }    

        static Network: AppViewConfig = {
            view: SiteView.Network,
            name: "Railway Network",
            description: "Search and preview data from the entire railway network."
        }   

        static NetworkMap: AppViewConfig = {
            view: SiteView.NetworkMap,
            name: "Network map",
            description: ""
        }     
        
        static Upload: AppViewConfig = {
            view: SiteView.Upload,
            name: "Upload",
            description: "Select data type to upload, drag and drop files to upload."
        }

        static Export: AppViewConfig = {
            view: SiteView.Export,
            name: "Export",
            description: "Select various export formats to perform composite export queries. Configure export settings to determine export file encryption, date overrides and data exclusion."
        }

        static Audit: AppViewConfig = {
            view: SiteView.Audit,
            name: "BIS Audit",
            description: "Perform audits on Atritec annotation data in relation to BIS data."
        }

        static Format: AppViewConfig = {
            view: SiteView.Format,
            name: "Format Data",
            description: "Drag and drop a raw BIS Track Link export for a given track section to format for LCS use. A formatted TrackLink file will be downloaded automatically."
        }

        static Map: AppViewConfig = {
            view: SiteView.Map,
            name: "Map",
            description: ""
        }     

        static Bin: AppViewConfig = {
            view: SiteView.Bin,
            name: "Bin",
            description: "All deleted Fomuls and Track Distances."
        }    

        static Metrics: AppViewConfig = {
            view: SiteView.Metrics,
            name: "Metrics",
            description: "Track LCS annotation and key result data in real time."
        }     

        public static all = (): AppViewConfig[] => {
            return [
                Site.AppViews.Sessions,
                Site.AppViews.TrackLinks,
                Site.AppViews.SearchFomuls,
                Site.AppViews.SearchTrackDistances,
                Site.AppViews.Network,
                Site.AppViews.NetworkMap,
                Site.AppViews.Format,
                Site.AppViews.Export,
                Site.AppViews.Metrics,
                Site.AppViews.Audit,
                Site.AppViews.Map,
                Site.AppViews.Upload, 
                Site.AppViews.Bin
            ]
        }

        public static getDataViews = (): AppViewConfig[] => {
            return [
                Site.AppViews.Sessions,
                Site.AppViews.Network,
                Site.AppViews.TrackLinks,
                Site.AppViews.SearchFomuls,
                Site.AppViews.SearchTrackDistances
            ]
        }

        public static getToolViews = (): AppViewConfig[] => {
            return [
                Site.AppViews.Format,
                Site.AppViews.Export,
                Site.AppViews.Audit,
                Site.AppViews.Upload, 
                Site.AppViews.Bin
            ]
        }

        public static getViewConfig = (view: SiteView): AppViewConfig | null => {
            try {
                return Site.AppViews.all().filter(v => v.view === view)[0]
            } catch (e) {
                console.log(e)
                return null;
            }
        }
    }
}


