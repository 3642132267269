import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import { SiteRoute } from "../../site/routes";

import { Completion, CompletionStatus, SessionMin } from "../../api/LCSModels";
import { CompletionStatusColorMap, CompletionStatusLightColorMap, CompletionStatusLabelMap } from "../../pandora/pandora";

import CheckMarkIcon from "../../resources/images/check-mark-icon.png";
import SessionSelector from "../SessionSelector";
import PositionTag from "../PositionTag";
import { Tag, HFlex, VFlex, DeleteButton } from "../../pandora/styled";


const LinkTag = styled(Tag)`
    &: hover {
        padding: 10px;
    }    
`

const Container = styled(HFlex)<{color: string}>`
    align-items: center;
    padding: 10px;
    margin-left: 10px;

    border-radius: 5px;
    background-color: ${props => props.color};

    h5 { 
        margin-top: 5px;
    }
`


/**
 * Determine the status of a completion based
 * on the avaialable data in the completion object.
 * 
 * Completed - Completion is not null and has no associated partial completion. 
 * Partial - Completion is not null and has an associated partial completion.
 * NotCompleted - Completion is null. 
 */
const determineCompletionStatus = (c: Completion | null): CompletionStatus => {
    if (c === null) {
        return CompletionStatus.NotCompleted;
    } else if (c !== null && c.partial !== null) {
        return CompletionStatus.Partial;
    } else if (c !== null && c.partial === null) {
        return CompletionStatus.Completed;
    } else {
        throw new Error(`Unrecognised completion value ${c} pass to determineCompletionStatus`)
    }
}


interface CompletionTagProps {
    completion: Completion | null;
    removeCompletion: () => void;
    completeTrackLink: (session: SessionMin) => void;
}

const CompletionTag = (props: CompletionTagProps): React.ReactElement => {

    // State
    const { completion, removeCompletion, completeTrackLink } = props;
    
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const completionStatus = determineCompletionStatus(completion);

    return (
        <Container 
            color=          {CompletionStatusColorMap[completionStatus]}
            onMouseOver=    {() => setIsHovering(true)}
            onMouseLeave=   {() => setIsHovering(false)}
        >
            <VFlex 
                style={{
                    marginRight: (completionStatus !== CompletionStatus.NotCompleted ? 20 : 0),
                    height: "fit-content"
                }}
            >
                <HFlex style={{width: "fit-content", alignItems: "center"}}>
                    <h4 style={{marginRight: 10}}>{CompletionStatusLabelMap[completionStatus]}</h4>
                    {completion === null &&
                        <SessionSelector 
                            selectSession=  {completeTrackLink} 
                            buttonTitle=    {"Set completed"}
                            icon=           {CheckMarkIcon}
                        />
                    }
                </HFlex>
                {completion !== null && 
                    <h5 style={{fontWeight: "normal"}}>{moment(completion.session.date).format("Do MMMM HH:mm YYYY")}</h5>
                }
                {completion !== null && completion.partial !== null &&
                    <HFlex style={{marginTop: 10}}>
                        <PositionTag 
                            atKm=   {completion.partial.fromKm}
                            atM=    {completion.partial.fromM}
                            color=  {CompletionStatusLightColorMap[completionStatus]}
                            css=    {{marginRight: 5, cursor: "auto"}}
                        />
                        <h5 style={{fontWeight: "normal", marginRight: 5}}>-</h5>
                        <PositionTag 
                            atKm=   {completion.partial.toKm}
                            atM=    {completion.partial.toM}
                            color=  {CompletionStatusLightColorMap[completionStatus]}
                            css=    {{cursor: "auto"}}
                        />
                    </HFlex>
                }
            </VFlex>
            {completion !== null && 
                <HFlex style={{alignItems: "center"}}>
                    <Link to={`${SiteRoute.Session}?id=${completion.session.id}`} style={{display: "block"}}>
                        <LinkTag 
                            color=      {CompletionStatusLightColorMap[completionStatus]} 
                            hoverColor= {CompletionStatusLightColorMap[completionStatus]}
                            style=      {{width: 50}}
                            title=      {"Open session"}
                        >Session</LinkTag>
                    </Link>
                    {isHovering && 
                        <DeleteButton 
                            onClick={removeCompletion} 
                            style=  {{marginLeft: 5}}
                            title=  {"Remove completion"}
                        />
                    }
                </HFlex>
            }
        </Container>
    )
}

export default CompletionTag;



interface CompletionTagMiniProps {
    completion: Completion | null;
    css?: React.CSSProperties;
}

export const CompletionTagMini = (props: CompletionTagMiniProps): React.ReactElement => {
    const completionStatus = determineCompletionStatus(props.completion);
    return (
        <Tag 
            color=      {CompletionStatusColorMap[completionStatus]} 
            style=      {{cursor: "auto", ...props.css}}
        >{CompletionStatusLabelMap[completionStatus]}</Tag>
    )
}
