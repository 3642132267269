import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import { SiteRoute } from "../../site/routes";
import { SessionMin } from "../../api/LCSModels";

import Color from "../../resources/colors";

import { SessionStatusTagMini } from "../session/SessionStatus";
import { HFlex, VFlex, Tag, OpenTag } from "../../pandora/styled";



const Container = styled(VFlex)<{selectable: boolean}>`
    justify-content: center;
    
    height: 60px;
    padding: 10px;
    margin-top: 20px;

    box-sizing: border-box;
    border-radius: 5px;

    background-color: ${Color.darkGrey};
    transition: 0.3s ease;

    &: hover {
        ${props => props.selectable ? `transform: translateY(-2px);` : ``}
        background-color: ${props => props.selectable ? Color.extraLightGrey : Color.darkGrey};
        cursor: ${props => props.selectable ? "pointer" : "auto"};
    }
`

const FlexContainer = styled(HFlex)`
    align-items: center;
    > * {
        margin-right: 10px;
        cursor: "auto"
    }
`



interface SessionContainerProps {
    session: SessionMin
    style?: React.CSSProperties
    selectAction?: (session: SessionMin) => void;
}

/**
 * Container used to display Sessions in 
 * a standardized way in UI . 
 */
const SessionContainer = (props: SessionContainerProps): React.ReactElement => {
    
    const { session, style, selectAction } = props;    

    return (
        <Container 
            selectable= {selectAction !== undefined}
            style=      {style}
            onClick=    {() => selectAction !== undefined ? selectAction(session) : null}
        >
            <HFlex style={{justifyContent: "space-between", alignItems: "center"}}>
                <h4>{moment(session.date).format("Do MMMM HH:mm YYYY")}</h4>
                <FlexContainer>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{marginRight: 10, fontWeight: "normal", cursor: "auto"}}
                    >{session.fomulCount} Fomuls</Tag>
                    <Tag 
                        color=      {Color.lightGrey} 
                        style=      {{ fontWeight: "normal", cursor: "auto", marginRight: 30}}
                    >{session.trackDistanceCount} Track Distances</Tag>
                    <SessionStatusTagMini 
                        status={session.status} 
                    />
                    {selectAction === undefined &&
                        <Link to={`${SiteRoute.Session}?id=${session.id}`} style={{display: "block"}}>
                            <OpenTag 
                                color=      {Color.lightGrey} 
                                hoverColor= {Color.extraLightGrey}
                                style=      {{cursor: "pointer"}}
                                title=      {"Show Session data view"}
                            >Open Session</OpenTag>
                        </Link>
                    }
                </FlexContainer>
            </HFlex>
        </Container>
    )
}


export default SessionContainer;
