import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";

import Color from "../../resources/colors";
import ProfileIcon from "../../resources/images/profile-icon.svg"
import { AnnotationSession } from "../../api/LCSModels";

import { HFlex, VFlex } from "../../pandora/styled";
import LoadingComponent from "../LoadingComponent";
import NoDataComponent from "../NoDataComponent";



const ItemContainer = styled(HFlex)`
    justify-content: space-between;
    align-items: center;

    width: auto;
    height: 30px;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 5px;

    background-color: ${Color.extraDarkGrey};

    h6 { 
        font-weight: normal;
    }

    .comment {
        color: ${Color.red};
        font-style: italic;
        font-weight: bold;
        margin-top: 5px;
    }
`

interface AnnotationSessionItemProps {
    annotation: AnnotationSession
}

const AnnotationSessionItem = (props: AnnotationSessionItemProps): React.ReactElement => {

    const { annotation } = props;
    const start = moment.utc(annotation.start).local().format("HH:mm")
    const end = moment.utc(annotation.end).local().format("HH:mm")
    const date = moment.utc(annotation.end).local().format("Do MMMM")

    return (
        <ItemContainer style={{height: annotation.comment !== null ? 40 : 25}}>
            <HFlex style={{width: "fit-content", alignItems: "center"}}>
                <img src={ProfileIcon} style={{width: 15, height: 15, marginRight: 10}} />
                <VFlex>
                    <h6>{annotation.user.name}</h6>
                    {annotation.comment && 
                        <h6 className="comment">"{annotation.comment}"</h6>
                    }
                </VFlex>
            </HFlex>
            <h6>{start} - {end} | <b>{date}</b></h6>
        </ItemContainer>
    )
}




interface AnnotationSessionListProps {
    sessionID: number;
}

const AnnotationSessionList = (props: AnnotationSessionListProps): React.ReactElement => {

    // State
    const [annotations, setAnnotations] = useState<AnnotationSession[] | null>(null);
    const [timeSum, setTimeSum] = useState<number | null>(null);

    // Effects
    useEffect(() => {

        /**
         * Perform GET request to API to 
         * retrieve annotation sessions 
         * for a given session. 
         */
        const getAnnotations = async () => { 
            try {
                const payload = {id: props.sessionID}
                const _annotations: AnnotationSession[] = await Thomas.request(APIRequest.getAllAnnotationSessionsForSession, payload) as AnnotationSession[]
                setAnnotations(_annotations);
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                console.log(message)
            }
        }

        getAnnotations()
    }, [])

    /**
     * On annotation data update
     * perform summarisation of 
     * total hours of annotation time. 
     */
    useEffect(() => {
        if (annotations === null) return;

        let _timeSum = 0;
        annotations.forEach((a: AnnotationSession) => {
            const start = new Date(a.start);
            const end = new Date(a.end);
            const duration = end.valueOf() - start.valueOf();
            _timeSum += duration / (60*60*1000);
        })
        setTimeSum(_timeSum);
        
    }, [annotations])


    return (
        <div style={{position: "relative", overflow: "hidden", height: "100%"}}>
            <div style={{position: "absolute", right: 0, left: 0, top: 0, height: "20px"}}>
                <HFlex  style={{justifyContent: "space-between", padding: "5px", background: Color.darkGrey}}>
                    <h4>Annotation time</h4>
                    <h4 style={{marginRight: "10px"}}>{timeSum !== null ? `${Math.round(timeSum)} h` : "-"}</h4>
                </HFlex>
            </div>
            {annotations !== null && 
                <div style={{overflow: "auto", height: "calc(100% - 30px)", paddingTop: "30px"}}>
                    {annotations.map(a => 
                        <AnnotationSessionItem 
                            key=        {a.id}
                            annotation= {a} 
                        />    
                    )}
                </div>
            }
            {annotations !== null && annotations.length == 0 && 
                <NoDataComponent 
                    message="No annotation sessions..." 
                />
            }
            {annotations === null && 
                <LoadingComponent 
                    message= "Fetching annotations..." 
                    style=   {{marginTop: 30}}
                />
            }
        </div>
    )
}


export default AnnotationSessionList;


