
const FOMUL_TYPES = {
    "010": "Broräcke - 010",
    "011": "Bro sidobalk - 011",
    "012": "Snöblåsfläkt - 012",
    "013": "Fällbom - 013",
    "014": "Hängränna Stuprör - 014",
    "015": "Lastkaj - 015",
    "016": "Plattform - 016",
    "017": "Plattformstak - 017",
    "018": "Rangerbroms - 018",
    "019": "Skärning - 019",
    "020": "Snögalleri - 020",
    "021": "Staket Grind - 021",
    "022": "Växeldriv - 022",
    "023": "Växelklot - 023",
    "024": "Bromsskoställ - 024",
    "025": "Makadam - 025",
    "026": "Trappa - 026",
    "027": "Stoppbock - 027",
    "028": "Mur - 028",
    "029": "Bullerskydd - 029",
    "030": "Smörjapparat - 030",
    "031": "Slangställning - 031",
    "032": "Skåp Låda - 032",
    "033": "Vattenpost - 033",
    "034": "Plattformsskylt - 034",
    "035": "Ljusgalleri - 035",
    "036": "Kanalisation - 036",
    "037": "Fotsteg - 037",
    "038": "Kabelstege - 038",
    "039": "Färjeklaff - 039",
    "040": "Tungspets - 040",
    "041": "Korsande väg - 041",
    "042": "Fjädrande PLF-skydd - 042",
    "043": "Pyramidmatta - 043",
    "097": "Gles vegetation - 097",
    "098": "Vegetation - 098",
    "099": "Övrigt bana - 099",

    "100": "Isolator - 100",
    "101": "KTL-tråd - 101",
    "102": "Utliggare - 102",
    "109": "Övrigt KTL 15 kV - 109",
    "110": "Återgångsledning - 110",

    "150": "Fundament KTL -stolpe - 150",
    "151": "Isolatorbock - 151",
    "152": "KTL-konsol - 152",
    "153": "KTL-stolpe - 153",
    "154": "Skyddskåpa KTL-tråd - 154",
    "155": "Spännvikt KTL-tråd - 155",
    "199": "Övr KTL ej spänning - 199",

    "200": "Dvärgsignal - 200",
    "201": "Huvudljussignal - 201",
    "202": "Ljusförsignal - 202",
    "203": "Stopplykta - 203",
    "204": "Vägskyddsförsignal - 204",
    "205": "Vägskyddssignal - 205",
    "206": "Lokalställare - 206",
    "207": "Skyddsnät för signal - 207",
    "208": "Hinderfrihetslykta - 208",
    "210": "Fällbar dvsi, normalläge - 210",
    "211": "Fällbar dvsi, nedläge - 211",
    "220": "Signalpunktstavla ERTMS - 220",
    "299": "Övrig signal - 299",

    "300": "Högtalare - 300",
    "301": "Telefonpost - 301",
    "302": "Klocka - 302",
    "303": "Ledning el sign tele - 303",
    "304": "Lampa  belysning - 304",
    "305": "Värmepost - 305",
    "306": "Ringklocka alarm - 306",
    "399": "Övrig tele - 399",

    "400": "Hastighetstavla - 400",
    "401": "Ploglyfttavla - 401",
    "402": "Huvudsignaltavla - 402",
    "403": "Växelskärm - 403",
    "404": "Kryssmärke - 404",
    "498": "Km-Tavla utom U-sektion - 498",
    "499": "Skylt Tavla - 499",

    "500": "Brokonstruktion - 500",
    "501": "Ställningsbyggnad - 501",
    "502": "Tunnel - 502",
    "503": "Vägport - 503",
    "599": "Övrig byggnad - 599",

    "600": "Armatur - 600",
    "601": "Tak Vägg - 601",
    "602": "Fundament ej KTL - 602",
    "603": "Kran - 603",
    "604": "Stolpe ej KTL - 604",
    "605": "Stativ Konsol ej KTL - 605",
    "606": "VA ledningsrör - 606",
    "607": "Lina Stag - 607",
    "608": "Brunn - 608",
    "609": "Cistern - 609",
    "699": "Övrigt objekt - 699"
}


export default FOMUL_TYPES;