
import Color from "../resources/colors";
import { 
    DataStatus, 
    ExportStatus, 
    TrackLinkStatus, 
    SessionStatus, 
    CompletionStatus 
} from "../api/LCSModels";


export const DataStatusColorMap = {
    [DataStatus.Active]: Color.green,
    [DataStatus.Deprecated]: Color.yellow,
    [DataStatus.Deleted]: Color.red,
}

export const DataStatusLightColorMap = {
    [DataStatus.Active]: Color.lightGreen,
    [DataStatus.Deprecated]: Color.lightOrange,
    [DataStatus.Deleted]: Color.lightRed
}

export const DataStatusLabelMap = {
    [DataStatus.Active]: "Active",
    [DataStatus.Deprecated]: "Deprecated",
    [DataStatus.Deleted]: "Deleted"
}

export const ExportStatusColorMap = {
    [ExportStatus.Exported]: Color.green,
    [ExportStatus.NotExported]: Color.red,
}

export const TrackLinkStatusColorMap = {
    [TrackLinkStatus.Active]: Color.green,
    [TrackLinkStatus.Deleted]: Color.red,
}

export const SessionStatusColorMap = {
    [SessionStatus.NotStarted]: Color.red, 
    [SessionStatus.Annotating]: Color.orange, 
    [SessionStatus.Finished]: Color.blue, 
    [SessionStatus.Delivered]: Color.purple,
    [SessionStatus.Audited]: Color.green
}

export const CompletionStatusColorMap = {
    [CompletionStatus.NotCompleted]: Color.red,
    [CompletionStatus.Partial]: Color.orange,
    [CompletionStatus.Completed]: Color.green
}

export const CompletionStatusLightColorMap = {
    [CompletionStatus.NotCompleted]: Color.lightRed,
    [CompletionStatus.Partial]: Color.lightOrange,
    [CompletionStatus.Completed]: Color.lightGreen
}

export const CompletionStatusLabelMap = {
    [CompletionStatus.NotCompleted]: "Not completed",
    [CompletionStatus.Partial]: "Partially completed",
    [CompletionStatus.Completed]: "Completed"
}

export type Point = { x: number, y: number}




/**
 * Convert numeric coordinates to
 * degree, minute seconds format.
 * @param coordinate Coordinate number to convert.
 */
 function toDegreesMinutesAndSeconds(coordinate) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + "° " + minutes + "' " + seconds + "\" ";
}

/**
 * Format LONG LAT coordinate format. 
 * @param lat 
 * @param lng 
 */
export function convertDMS(lat, lng): [string, string] {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? "N" : "S";

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? "E" : "W";

    return [latitude + " " + latitudeCardinal, longitude + " " + longitudeCardinal];
}

/**
 *  Completion progress bar 
 *  color switch.
 */
export const getCompletionProgressColor = (progress: number): string => {
    switch (true) {
        case (progress === 100): 
        return Color.green;
        case (progress > 30): 
            return Color.yellow;
        case (progress < 30): 
            return Color.red;
    }
    return Color.lightGrey;
}


export const formatKilometers= (num: number): string | number => {
    return Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/100)/10) + 'k' : Math.sign(num)*Math.abs(num)
}