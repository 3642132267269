import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AxiosResponse } from "axios";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import FileSaver from "../site/fileSaver";

import { InvoiceExportConfig, TrackLinkDataExportConfig, TrackLinkExportConfig, ExtendedTrackLinkExportConfig, DataExportConfig, DirectExportConfig } from "../api/LCSModels";

import Color from "../resources/colors";
import TrackLinkIcon from "../resources/images/tracklink-icon.svg";
import InvoiceIcon from "../resources/images/export-icon.svg";
import OptionsIcon from "../resources/images/options-icon.svg";
import SessionIcon from "../resources/images/session-icon.svg";
import OfflineIcon from "../resources/images/offline-icon.png";
import { SiteView } from "../site/routes";

import ViewHeader from "../components/ViewHeader";
import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import LoadingComponent from "../components/LoadingComponent";
import SessionDateSelector from "../components/invoicing/SessionDateSelector";
import InvoicingOptions from "../components/invoicing/InvoicingOptions";
import UploadZone from "../components/upload/UploadZone";

import Switch from "../components/Switch";
import { HFlex, ViewContainer, VFlex, RippleButton, Input, ContentDiv, IconButton } from "../pandora/styled";
import { FadeAnimation } from "../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

enum ExportType {
    CompletedTrackLinks = "Completed Track Links",
    NonCompletedTrackLinks = "Non Completed Track Links",
    RemainingTrackLinks = "Remaining Track Links",
    FomulDeviations = "Fomul Completion Deviations",
    TrackDistanceDeviations = "Track Distance Completion Deviations",
    InvoiceData = "Invoice template",
    AllSessions = "All sessions",
    FomulDirect = "Fomul Offline Export",
    TrackDistanceDirect = "Track Distance Offline Export"
}

const ExportTypeDescriptionMap = {
    [ExportType.CompletedTrackLinks]: "Export Excel list of all completed Track Links.",
    [ExportType.NonCompletedTrackLinks]: "Export Excel list of all non completed track links.",
    [ExportType.RemainingTrackLinks]: "Export Excel list of all remaining Track Links at visited places.",
    [ExportType.FomulDeviations]: "Export non completed Track Links with Fomuls.",
    [ExportType.TrackDistanceDeviations]: "Export non completed Track Links with Track Distances.",
    [ExportType.InvoiceData]: "Export invoicing data for given sessions.",
    [ExportType.AllSessions]: "Export data for all sessions.",
    [ExportType.FomulDirect]: "Export Fomuls from .csv save file.",
    [ExportType.TrackDistanceDirect]: "Export Track Distances from .csv save file."
}

const ExportTypeColorMap = {
    [ExportType.CompletedTrackLinks]: Color.green,
    [ExportType.NonCompletedTrackLinks]: Color.red,
    [ExportType.RemainingTrackLinks]: Color.yellow,
    [ExportType.FomulDeviations]: Color.blue,
    [ExportType.TrackDistanceDeviations]: Color.orange,
    [ExportType.InvoiceData]: Color.purple,
    [ExportType.AllSessions]: Color.yellow,
    [ExportType.FomulDirect]: Color.lightOrange,
    [ExportType.TrackDistanceDirect]: Color.lightPurple
}

const ExportTypeRequestMap = {
    [ExportType.CompletedTrackLinks]: APIRequest.exportCompletedTrackLinks,
    [ExportType.NonCompletedTrackLinks]: APIRequest.exportNonCompletedTrackLinks,
    [ExportType.RemainingTrackLinks]: APIRequest.exportRemainingTrackLinks,
    [ExportType.FomulDeviations]: APIRequest.exportFomulsForNonCompletedTrackLinks,
    [ExportType.TrackDistanceDeviations]: APIRequest.exportTDsForNonCompletedTrackLinks,
    [ExportType.InvoiceData]: APIRequest.exportInvoiceData,
    [ExportType.AllSessions]: APIRequest.exportAllSessions,
    [ExportType.FomulDirect]: APIRequest.uploadFomulDirectExport,
    [ExportType.TrackDistanceDirect]: APIRequest.uploadTrackDistanceDirectExport
}

const ExportTypeConfigMap = {
    [ExportType.CompletedTrackLinks]: TrackLinkDataExportConfig,
    [ExportType.NonCompletedTrackLinks]: TrackLinkExportConfig,
    [ExportType.RemainingTrackLinks]: ExtendedTrackLinkExportConfig,
    [ExportType.FomulDeviations]: TrackLinkExportConfig,
    [ExportType.TrackDistanceDeviations]: TrackLinkExportConfig,
    [ExportType.InvoiceData]: InvoiceExportConfig,
    [ExportType.AllSessions]: DataExportConfig,
    [ExportType.FomulDirect]: DirectExportConfig,
    [ExportType.TrackDistanceDirect]: DirectExportConfig
}

const ExportConfigTitleMap = {
    encrypt: "Encrypt",
    shouldOverrideDate: "Override date",
    excludeFomul: "Exclude Fomul",
    excludeTrackDistance: "Exclude TD",
    section: "Section",
    year: "Measurement year",
    sessions: "Session dates",
    file: "Save file"
}

const ExportConfigDescriptionMap = {
    encrypt: "Encrypt export file name or references to filenames.",
    shouldOverrideDate: "Apply registered date overrides for Fomuls, TDs in export.",
    excludeFomul: "Exclude Fomul",
    excludeTrackDistance: "Exclude TD",
    section: "Specify section number for export.",
    year: "Specify measurement year",
    sessions: "Select sessions to include. Dates only, not datetime. No selection will return all in DB.",
    file: "Select .csv save file to export."
}

// -------------------------------------------------------



const Container = styled(ViewContainer)`
    overflow: visible;
`

const ExportTypeGrid = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-teplate-rows: auto;
    column-gap: 15px; 
    row-gap: 15px;

    margin-top: 20px;
`

const ExportTypeContainer = styled(ContentDiv)<{type: ExportType, selected: boolean}>`
    padding-bottom: 25px;
    border-bottom: 3px solid ${props => props.selected ? ExportTypeColorMap[props.type] : "transparent"};

    background-color: ${props => props.selected ? Color.lightGrey : Color.extraDarkGrey};
    cursor: pointer;
    transition: 0.3s ease;

    &: hover {
        transform: translateY(-2px);
        border-bottom: 3px solid ${props => ExportTypeColorMap[props.type]};
        background-color: ${Color.lightGrey};
    }

    .description {
        font-weight: normal;
        margin-top: 10px;
    }
`

const ExportConfigKeyContainerGrid = styled("div")<{maxKeys: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.maxKeys}, 1fr);
    grid-template-rows: auto;
    
    column-gap: 10px;
    row-gap: 10px;

    transition: 0.3s ease;
    overflow: visible;

    @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    @media only screen and (max-width: 1100px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`

const ExportConfigKeyContainer = styled(ContentDiv)`
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: ${Color.darkGrey};
    opacity: 0;
    transition: 0.3s ease;

    h5 {
        max-width: 100px;
    }

    animation: ${FadeAnimation} 0.5s ease 1;
    animation-fill-mode: forwards;
`

const UploadContainer = styled(VFlex)`
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${Color.darkGrey};
`




const ExportView = (): React.ReactElement => {

    // State
    const TRACK_LINK_EXPORT_TYPES = [ExportType.CompletedTrackLinks, ExportType.RemainingTrackLinks, ExportType.NonCompletedTrackLinks,
                                     ExportType.FomulDeviations, ExportType.TrackDistanceDeviations]
    const INVOICE_EXPORT_TYPES = [ExportType.InvoiceData]
    const SESSION_EXPORT_TYPES = [ExportType.AllSessions]
    const OFFLINE_EXPORT_TYPES = [ExportType.FomulDirect, ExportType.TrackDistanceDirect]

    const [selectedExportType, setSelectedExportType] = useState<ExportType>(ExportType.CompletedTrackLinks);
    const [exportConfig, setExportConfig] = useState<object>(ExportTypeConfigMap[ExportType.CompletedTrackLinks]);
    const [sessionDates, setSessionDates] = useState<Date[]>([]);
    const [file, setFile] = useState<File | null>(null);

    const [isShowingInvoicingOptions, setIsShowingInvoicingOptions] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);

    // Set doc title
    document.title = `Export | LCS Online`

    // Effects
    useEffect(() => {

        /**
         * Retrieve session dates used for 
         * invoice template export session 
         * query filtering. 
         */
        const getSessionDates = async () => {
            try {
                const res: AxiosResponse = await Thomas.request(APIRequest.getAllSessionDates, {}, true); 
                setSessionDates(res.data);
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
                setIsLoading(false);
            }
        }

        getSessionDates();
    }, []);
    
    /**
     * Update export config for selected export type.
     */
    useEffect(() => {
        setExportConfig(ExportTypeConfigMap[selectedExportType])
        setFile(null);
    }, [selectedExportType])

    // Actions

    /**
     * Update config for given key and value.
     * Config is passed in export request to API. 
     */
    const updateConfig = (key: string, value: number | boolean | Date[]) => {
        if (Number.isNaN(value)) value = null;
        const _config = {...exportConfig}
        _config[key] = value;
        setExportConfig(_config);
    }

    const exportRequiresUploadFile = (): boolean => selectedExportType === ExportType.FomulDirect || selectedExportType === ExportType.TrackDistanceDirect

    /**
     * Perform the export request to the API. 
     */
    const performExport = async () => {
        try {
            setIsLoading(true);
            const req = ExportTypeRequestMap[selectedExportType];  // Get the corresponding API request for the selected export type. 
            
            let payload: FormData | object;
            // Change payload type to FormData 
            // if it's a direct export requiring file
            if (exportRequiresUploadFile()) {
                payload = new FormData();
                (payload as FormData).append("file", file);    
            } else {
                payload = {...exportConfig};
            }

            const res: AxiosResponse = await Thomas.request(req, payload, true);
            setIsLoading(false);

            // Save file to client
            const saveConfig = `${req.headers["Accept"]}`
            const [, filename] = res.headers['content-disposition'].split('filename=');
            FileSaver.save(res.data, 
                            filename,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of ${selectedExportType} completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
            setIsLoading(false);
        }
    }


    return (
        <Container>
            {popup && 
                <GenericPopup 
                    title=          {popup.title}
                    message=        {popup.message}
                    color=          {popup.color}
                    setPopup=       {setPopup}
                />
            }
            {isShowingInvoicingOptions &&
                <InvoicingOptions 
                    close=          {() => setIsShowingInvoicingOptions(false)}
                    setPopup=       {setPopup}
                />
            }
            <ViewHeader view={SiteView.Export} />
            <VFlex>
                <ContentDiv>
                    <HFlex style={{alignItems: "center"}}>
                        <img src={TrackLinkIcon} style={{height: 20, width: 20, marginRight: 10}} />
                        <h4>Track Link Exports</h4>
                    </HFlex>
                    <ExportTypeGrid>
                        {TRACK_LINK_EXPORT_TYPES.map(exportType => 
                            <ExportTypeContainer 
                                type=       {exportType} 
                                selected=   {exportType === selectedExportType}
                                onClick=    {() => setSelectedExportType(exportType)}
                            >
                                <h5>{exportType}</h5>
                                <h5 className="description">{ExportTypeDescriptionMap[exportType]}</h5>
                            </ExportTypeContainer>
                        )}
                    </ExportTypeGrid>
                    <HFlex style={{alignItems: "center", marginTop: 40}}>
                        <img 
                            src={InvoiceIcon} 
                            style={{height: 20, width: 20, marginRight: 10}} 
                        />
                        <h4>Invoice Exports</h4>
                    </HFlex>
                    <ExportTypeGrid style={{marginBottom: 40}}>
                        {INVOICE_EXPORT_TYPES.map(exportType => 
                            <ExportTypeContainer 
                                type=       {exportType} 
                                selected=   {exportType === selectedExportType}
                                onClick=    {() => setSelectedExportType(exportType)}
                            >
                                <h5>{exportType}</h5>
                                <h5 className="description">{ExportTypeDescriptionMap[exportType]}</h5>
                                {selectedExportType === ExportType.InvoiceData &&
                                    <IconButton 
                                        color=      {Color.darkGrey}
                                        hoverColor= {Color.extraDarkGrey}
                                        icon=       {OptionsIcon}
                                        onClick=    {() => setIsShowingInvoicingOptions(!isShowingInvoicingOptions)}
                                        style=      {{marginTop: 20, textAlign: "center", textIndent: 30, width: "fit-content"}}
                                    >Invoicing options</IconButton>
                                }
                            </ExportTypeContainer>
                        )}
                    </ExportTypeGrid>
                    <HFlex style={{alignItems: "center", marginTop: 40}}>
                        <img 
                            src={SessionIcon} 
                            style={{height: 20, width: 20, marginRight: 10}} 
                        />
                        <h4>Session Exports</h4>
                    </HFlex>
                    <ExportTypeGrid style={{marginBottom: 40}}>
                        {SESSION_EXPORT_TYPES.map(exportType => 
                            <ExportTypeContainer 
                                type=       {exportType} 
                                selected=   {exportType === selectedExportType}
                                onClick=    {() => setSelectedExportType(exportType)}
                            >
                                <h5>{exportType}</h5>
                                <h5 className="description">{ExportTypeDescriptionMap[exportType]}</h5>
                            </ExportTypeContainer>
                        )}
                    </ExportTypeGrid>
                    <HFlex style={{alignItems: "center", marginTop: 40}}>
                        <img 
                            src={OfflineIcon} 
                            style={{height: 20, width: 20, marginRight: 10}} 
                        />
                        <h4>Offline Exports</h4>
                    </HFlex>
                    <ExportTypeGrid style={{marginBottom: 40}}>
                        {OFFLINE_EXPORT_TYPES.map(exportType => 
                            <ExportTypeContainer 
                                type=       {exportType} 
                                selected=   {exportType === selectedExportType}
                                onClick=    {() => setSelectedExportType(exportType)}
                            >
                                <h5>{exportType}</h5>
                                <h5 className="description">{ExportTypeDescriptionMap[exportType]}</h5>
                            </ExportTypeContainer>
                        )}
                    </ExportTypeGrid>
                    <ContentDiv style={{backgroundColor: Color.extraDarkGrey, marginTop: 30}}>
                        <h4 style={{fontWeight: "normal"}}><b>Configure</b> {selectedExportType}</h4>
                        <HFlex style={{marginTop: 20, justifyContent: "space-between", alignItems: "flex-end"}}>
                            <ExportConfigKeyContainerGrid maxKeys={Object.keys(exportConfig).length}>
                                {Object.keys(exportConfig).map(key =>
                                    <ExportConfigKeyContainer key={key}>
                                        <VFlex style={{marginRight: 20, width: "fit-content"}}>
                                            <h5>{ExportConfigTitleMap[key]}</h5>   
                                            <h5 style={{fontWeight: "normal", marginTop: 5}}>{ExportConfigDescriptionMap[key]}</h5>
                                        </VFlex>
                                        {typeof exportConfig[key] === "boolean" && 
                                            <Switch 
                                                key=       {`swt${key}`}
                                                configKey= {key}
                                                value=     {exportConfig[key]} 
                                                onChange=  {updateConfig} 
                                            />
                                        }
                                        {key === "sessions" &&
                                            <SessionDateSelector 
                                                sessions=       {sessionDates}
                                                selected=       {exportConfig["sessions"] ?? []}
                                                setSelected=    {updateConfig}
                                            />
                                        }
                                        {key === "file" &&
                                            <UploadContainer>
                                                <UploadZone 
                                                    file=           {file}
                                                    isUploading=    {isLoading}
                                                    typeColor=      {ExportTypeColorMap[selectedExportType]}
                                                    setFile=        {setFile}
                                                    setPopup=       {setPopup}
                                                    minimal=        {true}
                                                    noUpload=       {true}
                                                />
                                            </UploadContainer>
                                        }
                                        {(exportConfig[key] === null || typeof exportConfig[key] === "number") && 
                                            <Input
                                                key=            {`inp${key}`}
                                                type=           "number"
                                                placeholder=    "None"
                                                value=          {exportConfig[key] ?? ""} 
                                                onChange=       {(e) => updateConfig(key, parseInt(e.target.value))}
                                            />
                                        }
                                    </ExportConfigKeyContainer>                                        
                                )}
                            </ExportConfigKeyContainerGrid>
                            {!isLoading && 
                                <RippleButton 
                                    color=      {Color.green}
                                    hoverColor= {Color.lightGreen}
                                    onClick=    {performExport}
                                    style=      {{width: "fit-content", height: 40}}
                                >Export</RippleButton>
                            }
                            {isLoading && 
                                <LoadingComponent message="Exporting..." />
                            }
                        </HFlex>
                    </ContentDiv>
                </ContentDiv>
            </VFlex>
        </Container>
    )
}

export default ExportView;