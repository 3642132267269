import React from "react";

import FOMUL_TYPES from "../../pandora/fomul-types";



interface FomulTypeDataListProps {
    id: string;
}

/**
 * Data list used to present 
 * input options to the user. 
 */
const FomulTypeDataList = (props: FomulTypeDataListProps): React.ReactElement => {
    return (
        <datalist id={props.id}>
            {Object.keys(FOMUL_TYPES).map(type => 
                <option 
                    key=    {`option-${type}`}
                    value=  {FOMUL_TYPES[type]} 
                />
            )}
        </datalist>
    )
}

export default FomulTypeDataList;