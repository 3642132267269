import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Thomas from "../../api/Thomas";
import APIRequest from "../../api/APIRequest";
import { SearchResultType } from "../../api/LCSModels";

import Color from "../../resources/colors";
import EditIcon from "../../resources/images/edit-icon.svg";
import AddIcon from "../../resources/images/add-icon.png";
import DeleteIcon from "../../resources/images/delete-icon.png";

import { GenericPopupProps } from "../GenericPopup";
import { Confirmation } from "../ConfirmationPopup";

import Overlay from "../Overlay";
import PropertyTrackLinkSelector from "./PropertyTrackLinkSelector";
import LoadingComponent from "../LoadingComponent";
import NoDataComponent from "../NoDataComponent";
import Switch from "../Switch";

import { HFlex, VFlex, ContentDiv, Button, Input } from "../../pandora/styled";
import { ConfirmationPopupAnimation } from "../../pandora/animations";


// COMPONENT CONFIGURATION DATA ----------------------------

const ResultTypeBatchEditRequestMap = {
    [SearchResultType.Fomul]: APIRequest.editFomulBatch,
    [SearchResultType.TrackDistance]: APIRequest.editTrackDistanceBatch
}

enum PropertyType {
    String = "string",
    Number = "number",
    Boolean = "boolean",
    TrackLinkID = "trackLinkId"
}

const FOMUL_PROPERTIES = {
    atKm: PropertyType.Number,
    atM: PropertyType.Number,
    type: PropertyType.String,
    addInfo: PropertyType.String,
    note: PropertyType.String,
    radius: PropertyType.Number,
    station: PropertyType.String,
    isCopy: PropertyType.Boolean,
    posDir: PropertyType.Boolean,
    trackLinkID: PropertyType.TrackLinkID
}

const TRACK_DISTANCE_PROPERTIES = {
    objTrackLinkNumber: PropertyType.String,
    atKm: PropertyType.Number,
    atM: PropertyType.Number,
    horDist: PropertyType.Number,
    verDist: PropertyType.Number,
    refElev: PropertyType.Number,
    objElev: PropertyType.Number,
    posDir: PropertyType.Boolean,
    refTrackLinkID: PropertyType.TrackLinkID
}

const ResultTypeTrackLinkKeyIDMap = {
    [SearchResultType.Fomul]: "trackLinkID",
    [SearchResultType.TrackDistance]: "refTrackLinkID"
}

// -------------------------------------------------------



const Container = styled(HFlex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;    
    position: relative;
`

const SelectorContainer = styled(VFlex)`
    position: relative;
    
    width: 50%;
    min-width: 500px;
    height: auto;
    padding: 25px;
    border-radius: 5px;

    background-color: ${Color.extraDarkGrey};
    animation: ${ConfirmationPopupAnimation} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;

    p {
        font-size: 14px;
    }
`

const ContentListDiv = styled(ContentDiv)`
    max-height: 60vh;
    overflow-y: auto;

    .title-label, h5 {
        border: solid 2px ${Color.extraDarkGrey};
        user-select: none;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 5px;
    }

    .title-label {
        background-color: ${Color.extraDarkGrey};
        margin-bottom: 20px;
    }

    h5 {
        cursor: pointer;
        background-color: ${Color.darkGrey};
        transition: 0.3s ease;
    }

    .seletectable-property-tag, .property-tag {
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: 90% 50%;
        font-weight: normal;
    }

    .seletectable-property-tag:hover {
        background-color: ${Color.green};
        border: solid 2px ${Color.green} !important;
        background-image: url(${AddIcon});
    }

    .property-tag {
        width: auto;
        margin-right: 30px;
        margin-bottom: 0px !important;
    }

    .property-tag:hover {
        background-color: ${Color.red};
        border: solid 2px ${Color.red} !important;
        background-image: url(${DeleteIcon});
    }

    ::-webkit-scrollbar {
        width: 0px;
    } 
`

const PropertyInputContainer = styled.div`
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    column-gap: 30px;
`



interface SessionDateSelectorPopupProps {
    selectedData: number[];
    type: SearchResultType;
    close: Function;
    setPopup: (value: GenericPopupProps | null) => void;
    setConfirmation: (value: Confirmation | null) => void;
    triggerSearch: () => void;
}

/**
 * Property update selector allows for selection of 
 * properties for a given data type (Fomuls, TrackDistances) 
 * and inputting of new values for each such property. 
 * 
 * Used in batch updating of Fomuls and TrackDistances in search. 
 */
const PropertyUpdateSelector = (props: SessionDateSelectorPopupProps): React.ReactElement => {

    // State
    const { 
        selectedData, 
        type, 
        setPopup, 
        close, 
        triggerSearch, 
        setConfirmation 
    } = props;

    const [update, setUpdate] = useState<Record<string, number | string> | {}>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Actions

    /**
     * Get default value for the various 
     * types of property inputs in batch update. 
     */
    const getDefaultValue = (t: string): string | number | boolean => {
        switch (t) {
            case PropertyType.String: return "";
            case PropertyType.Number: return "";
            case PropertyType.Boolean: return false;
            case PropertyType.TrackLinkID: return null;
            default: {
                throw new Error(`Unknown value type ${t} passed to getDefaultValues`)
            }
        }
    }

    /**
     * Add property to selected properties 
     * for batch update. 
     */
    const selectProperty = (prop: string, t: string) => {
        if (!Object.keys(update).includes(prop)) {
            const val = getDefaultValue(t) 
            setUpdate({...update, [prop]: val});
        } else {
            const _update = {...update};
            delete _update[prop];
            setUpdate(_update);
        }
    }
    
    /**
     * Set property update value in update config. 
     */
    const updateProperty = (property: string, value: any) => {
        const _update = {...update, [property]: value};
        setUpdate(_update);
    }

    /**
     * 'Null' values are invalid for all properties
     * available in a batch update for Fomuls and TrackDistances. 
     */
    const updateIsInvalid = (): boolean => Object.values(update).includes(null)

    /**
     * Get input component for a given property 
     * data type. 
     */
    const getInputComponent = (prop: string): React.ReactElement => {
        const properties = (type === SearchResultType.Fomul) ? FOMUL_PROPERTIES : TRACK_DISTANCE_PROPERTIES

        const t = properties[prop]
        switch (t) {
            case PropertyType.String: {
                return (
                    <Input
                        type=           "text"
                        placeholder=    {prop}
                        value=          {update[prop]}
                        onChange=       {(e) => updateProperty(prop, e.target.value)}
                        fontSize=       {12}
                        style=          {{backgroundColor: Color.extraDarkGrey, height: 40}}
                    />   
                )
            }
            case PropertyType.Number: {
                return (
                    <Input
                        type=           "number"
                        placeholder=    {prop}
                        value=          {update[prop]}
                        onChange=       {(e) => updateProperty(prop, e.target.value)}
                        fontSize=       {12}
                        style=          {{backgroundColor: Color.extraDarkGrey, height: 40}}
                    />   
                )
            }
            case PropertyType.Boolean: {
                return (
                    <Switch 
                        configKey= {prop}
                        value=     {update[prop]} 
                        onChange=  {updateProperty} 
                    />
                )
            }
            case PropertyType.TrackLinkID: {
                return (
                    <PropertyTrackLinkSelector 
                        selectedTrackLinkID=    {update[ResultTypeTrackLinkKeyIDMap[type]]}
                        trackLinkIDKey=         {ResultTypeTrackLinkKeyIDMap[type]}
                        update=                 {updateProperty}
                        setPopup=               {setPopup}
                    />
                )
            }
            default: {
                throw new Error(`Unknown value type ${t} passed to getDefaultValues`)
            }
        }
    }
    
    /**
     * Get properties for current data
     * editing state. Depends on datatype, i.e. Fomuls or TrackDistances
     * and the selected properties to include in batch update. 
     */
    const getProperties = (): Record<string, string> => {
        const properties = {...(type === SearchResultType.Fomul ? FOMUL_PROPERTIES : TRACK_DISTANCE_PROPERTIES)}
        Object.keys(properties).forEach(key => {
            if (Object.keys(update).includes(key)) delete properties[key];
        })
        return properties
    }

    /**
     * Request batch update to API. 
     */
    const performUpdate = () => {

        const callback = async () => {
            try {
                setIsLoading(true);
                const req = ResultTypeBatchEditRequestMap[type]
                const payload = {IDs: selectedData, values: update}
                const _ = await Thomas.request(req, payload);
    
                setPopup({
                    title: "Updated batch!",
                    message: `Updated ${selectedData.length} ${type === SearchResultType.Fomul ? "Fomuls" : "Track Distances"}`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }

            // Trigger search to database again 
            // and close popup window.
            setIsLoading(false);
            triggerSearch();
            close();
        }

        // Block user from performing updates with null values

        setConfirmation({
            title: "Warning!",
            message: `Are you sure you want to update ${selectedData.length} ${type === SearchResultType.Fomul ? "Fomuls" : "Track Distances"}?`,
            callback: callback,
            reset: setConfirmation
        })
    }


    return (
        <Overlay 
            css=        {"z-index: 9;"} 
            onClick=    {() => close()}
        >
            <Container>
                <SelectorContainer  onClick={(e) => e.stopPropagation()}>
                    <HFlex style={{alignItems: "center", justifyContent: "space-between"}}>
                        <h3>Edit fields</h3>
                        <img 
                            src={EditIcon} 
                            style={{width: 20, height: 20}} 
                        />
                    </HFlex>
                    <p>Select properties to update for {selectedData.length} {type === SearchResultType.Fomul ? "Fomuls" : "Track Distances"}.</p>
                    <HFlex style={{marginTop: 20}}>
                        <ContentListDiv style={{width: "30%", marginRight: 10}}>
                            <h4 className="title-label">Fields</h4>
                            {Object.keys(getProperties()).map((prop, index) => 
                                <h5 
                                    key=        {`prop-${index}`}
                                    className=  "seletectable-property-tag"
                                    onClick=    {() => selectProperty(prop, getProperties()[prop])}
                                >{prop}</h5>
                            )}
                            {Object.keys(getProperties()).length === 0 &&
                                <NoDataComponent />
                            }
                        </ContentListDiv>
                        <ContentListDiv style={{width: "70%"}}>
                            <h4 className="title-label">Values</h4>
                            {Object.keys(update).map((prop, index) => 
                                <PropertyInputContainer 
                                    key=    {`update-${index}`}
                                    style=  {{alignItems: "center", marginBottom: 10}}
                                >
                                    <h5 
                                        className="property-tag"
                                        onClick={() => selectProperty(prop, getProperties()[prop])}
                                    >{prop}</h5>
                                    {getInputComponent(prop)}
                                </PropertyInputContainer>
                            )}
                        </ContentListDiv>
                    </HFlex>
                    <HFlex style={{justifyContent: "space-between", marginTop: 15}}>
                        <Button 
                            color=      {Color.darkGrey}
                            hoverColor= {Color.extraLightGrey}
                            onClick=    {() => close()}
                            title=      {"Cancel & close selection"}
                        >Close</Button>
                        {!isLoading && !updateIsInvalid() && Object.keys(update).length !== 0 &&
                            <Button 
                                color=      {Color.green}
                                hoverColor= {Color.lightGreen}
                                onClick=    {() => performUpdate()}
                                title=      {"Update data"}
                            >Update</Button>
                        }
                        {isLoading  &&
                            <LoadingComponent message="Updating..." />
                        }
                    </HFlex>
                </SelectorContainer>
            </Container>
        </Overlay>
    )
}

export default PropertyUpdateSelector;
