import React from "react";
import styled from "styled-components";

import NoDataIcon from "../resources/images/no-data-icon.svg";
import { VFlex } from "../pandora/styled";
import Color from "../resources/colors";


const NoDataContainer = styled(VFlex)<{css?: string}>`
    align-items: center;
    margin: 0;
    width: auto;
    height: fit-content;
    padding: 10px;
    border-radius: 5px;

    ${props => props.css ?? ""}
`

const Title = styled("h1")<{size: number, color?: string}>`
    margin: 0;
    max-width: 200px;
    margin-top: 5px;

    text-align: center !important;
    text-indent: 0 !important;
    font-size: ${props => props.size}px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: ${props => props.color ?? "white"};
`

const Icon = styled("div")<{icon: string, color?: string}>`
    width: 40px;
    height: 40px;

    mask: url(${props => props.icon}) no-repeat 50% 50%;
    mask-size: cover;
    background-color: ${props => props.color ?? "white"};
`


interface NoDataProps {
    message?: string;
    color?: Color;
    textColor?: string
    css?: string;
}

const NoDataComponent = (props: NoDataProps): React.ReactElement => {
    return (
        <NoDataContainer css={props.css}>
            <Icon
                icon=   {NoDataIcon}
                color=  {props.color}
            />
            {props.message !== undefined && 
                <Title 
                    size=   {13}
                    color=  {props.textColor}
                >{props.message}</Title>
            }
        </NoDataContainer>
    )
}

        

export default NoDataComponent;