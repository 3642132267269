import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { AxiosResponse } from "axios";

import { SiteRoute } from "../site/routes";
import QueryParser from "../site/queryParser";
import ShareHandler from "../site/share";
import FileSaver from "../site/fileSaver";

import Thomas from "../api/Thomas";
import APIRequest from "../api/APIRequest";
import { TrackDistance, DataStatus, ExportStatus, TrackLinkMin } from "../api/LCSModels";

import { DataStatusColorMap, ExportStatusColorMap } from "../pandora/pandora";
import Color from "../resources/colors";
import TrackDistanceIcon from "../resources/images/track-distance-icon.svg";

import ShareButton from "../components/ShareButton";
import ExportButton from "../components/ExportButton";

import GenericPopup, { GenericPopupProps } from "../components/GenericPopup";
import ConfirmationPopup, { Confirmation } from "../components/ConfirmationPopup";

import LoadingComponent from "../components/LoadingComponent";
import NoDataComponent from "../components/NoDataComponent";
import MapComponent from "../components/MapComponent";
import Switch from "../components/Switch";
import TrackLinkContainer from "../components/data/TrackLinkContainer";
import SessionContainer from "../components/data/SessionContainer";

import TrackLinkSelector from "../components/data/TrackLinkSelector";
import Plot from "../components/data/Plot";

import { 
    PageIcon, HFlex, ViewContainer, 
    VFlex, Tag, Input, RippleButton, BackNavButton,
    DataViewContainer
} from "../pandora/styled";
import { ScaleAnimation } from "../pandora/animations";



type Point = { x: number, y: number}

const ContentDiv = styled.div`
    width: auto;
    height: auto;

    border-radius: 5px;
    padding: 15px;

    background-color: ${Color.darkGrey};

    h4 {
        width: fit-content;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: -5px;
        margin-bottom: 10px;

        border-radius: 5px;
        background-color: ${Color.lightGrey};
    }
`

const Container = styled(ViewContainer)`
    overflow: visible;
`

const DataInputContainer = styled(HFlex)`
    margin-top: 5px;
    align-items: center;

    h6 {
        width: 100px;
        margin-right: 10px;
        text-transform: capitalize;
    }
`

const ActionButton = styled(RippleButton)`
    animation: ${ScaleAnimation} 0.2s linear 1;
`

const PointList = styled(VFlex)`
    height: 300px;
    padding: 10px;

    margin-left: -5px;
    margin-right: 10px;
    border-radius: 5px;

    overflow-y: auto;
    background: ${Color.extraDarkGrey}; 

    ::-webkit-scrollbar {
        width: 0px;
    }   
`



const TrackDistanceView = (): React.ReactElement => {

    // State
    const fieldnameMap = {
        "objTrackLinkNumber": "Obj TL Number",
        "horDist": "Horizontal", 
        "verDist": "Vertical", 
        "refElev": "Ref Elevation", 
        "objElev": "Obj Elevation",
        "softwareVer": "LCS Version"
    }

    const trackDistanceID = QueryParser.getQueryArg("id");
    const [originalTrackDistance, setOriginalTrackDistance] = useState<TrackDistance | null | undefined>(undefined);
    const [trackDistance, setTrackDistance] = useState<TrackDistance | null | undefined>(undefined);
    const [changedFields, setChangedFields] = useState<Record<string, any> | {}>({});

    const [points, setPoints] = useState<Point[] | null>(null);

    const [showTrackLinkSelector, setShowTrackLinkSelector] = useState<boolean>(false);
    const [popup, setPopup] = useState<GenericPopupProps | null>(null);
    const [confirmation, setConfirmation] = useState<Confirmation | null>(null);

    // Effects

    /**
     * On trackDistanceID update, which occurs when 
     * Track Distance is updated, reset current TrackDistance state
     * and request current TrackDistance from API. 
     */
    useEffect(() => { 
        setTrackDistance(undefined);
        setOriginalTrackDistance(undefined);
        setChangedFields({});
        getTrackDistance() 

        document.getElementById("content-container").scroll(0,0)
    }, [trackDistanceID])

    /**
     * Format and retrieve data for 
     * TD points on TD update.
     */
    useEffect(() => {
        if (trackDistance === undefined || trackDistance === null) return;
        let points: Point[] = [];
        for (let i = 0; i < trackDistance.points.length; i=i+2) {
            points.push({x: trackDistance.points[i], y: trackDistance.points[i + 1]})
        }
        setPoints(points);
    }, [trackDistance])
    

    // Actions

    /**
     * Request Track Distance instance from API. 
     */
    const getTrackDistance = async () => { 
        try {
            if (trackDistanceID === null) 
                throw new Error("Could not parse ID from query parameters.")

            // Request sessions from Thomas. 
            const payload = {id: trackDistanceID}
            const _trackDistance: TrackDistance = await Thomas.request(APIRequest.getTrackDistance, payload) as TrackDistance
            
            setOriginalTrackDistance(_trackDistance);
            setTrackDistance(_trackDistance);

            // Set doc title
            document.title = `#${_trackDistance.id} Track Distance |  LCS Online`
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                title: "Could not fetch Track Distance",
                message: message,
                color: Color.red,
                setPopup: setPopup
            }) 

            setTrackDistance(null);
        }
    }

    /**
     * Update current TrackDistance property 
     * in state. 
     */
    const updateField = (key: string, value: any) => {
        const _fomul = {...trackDistance};
        const _changedFields = {...changedFields};
        _fomul[key] = value;
        _changedFields[key] = value;

        setTrackDistance(_fomul);
        setChangedFields(_changedFields);
    }

    /**
     * Update Track Distance points property separately, 
     * since the coordinates are formatted in 
     * x, y pairs for UI rendering. 
     */
    const updatePoints = (index: number, key: string, value: number) => {
        const _points = [...points];
        _points[index][key] = value;
        const ps = []
        _points.forEach(p => ps.push(...Object.values(p)));
        updateField("points", ps);
    }

    /**
     * Request Track Distance update to API. 
     */
    const updateChanges = async () => {
        try {
            // Request Track Distance PUT update
            const payload = {id: trackDistance.id, ...changedFields}
            const newTrackDistance: TrackDistance = await Thomas.request(APIRequest.editTrackDistance, payload) as TrackDistance

            setTrackDistance(newTrackDistance);
            setOriginalTrackDistance(newTrackDistance);
            setChangedFields({});
            
            // Push route with new ID
            window.history.replaceState({}, document.title, `${window.location.origin}${SiteRoute.TrackDistance}?id=${newTrackDistance.id}`);

            setPopup({
                title: "Track Distance updated!",
                message: `Updated ${Object.keys(changedFields)}`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request TrackDistance Track Link reference update
     */
     const updateTrackDistanceTrackLink = (trackLink: TrackLinkMin) => {
        const callback = async () => {
            try {
                const payload = {id: trackDistance.id, trackLinkID: trackLink.id}
                const newTrackDistance: TrackDistance = await Thomas.request(APIRequest.setTrackDistanceTrackLinkRef, payload) as TrackDistance
    
                setTrackDistance(newTrackDistance);
                setOriginalTrackDistance(newTrackDistance);
                setChangedFields({});
                
                // Push route with new ID
                window.history.replaceState({}, document.title, `${window.location.origin}${SiteRoute.TrackDistance}?id=${newTrackDistance.id}`);

                setPopup({
                    title: "Updated Track Link!",
                    message: `Updated Track Distance Track Link reference.`,
                    color: Color.green,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
            setShowTrackLinkSelector(false);
        }

        setConfirmation({
            title: "Confirm!",
            message: `Are you sure you want to update the registered Track Link reference on this Track Distance?`,
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Reset changed fields to empty and 
     * set current TrackDistance as original TrackDistance. 
     */
    const discardChanges = async () => {
        setTrackDistance(originalTrackDistance);
        setChangedFields({});
    }

    const deleteTrackDistance = () => {

        const callback = async () => {
            try {
                // Request Fomul deletion 
                const payload = {id: trackDistance.id}
                const deletedTrackDistance: TrackDistance = await Thomas.request(APIRequest.deleteTrackDistance, payload) as TrackDistance

                setTrackDistance(deletedTrackDistance);
                setOriginalTrackDistance(deletedTrackDistance);
                setChangedFields({});
                
                setPopup({
                    title: "Track Distance deleted!",
                    message: `Deleted Track Distance with ID ${deletedTrackDistance.id}`,
                    color: Color.red,
                    setPopup: setPopup
                }) 
            } catch (e) {
                const message = Thomas.getErrorMessage(e)
                setPopup({
                    message: message,
                    setPopup: setPopup
                }) 
            }
        }

        setConfirmation({
            title: "Attention!",
            message: "Are you sure you want to delete this Track Distance?",
            callback: callback,
            reset: setConfirmation
        })
    }

    /**
     * Request undo of Fomul deletion to API. 
     */
    const undoTrackDistanceDeletion = async () => {
        try {
            // Request TrackDistance undo deletion 
            const payload = {id: trackDistance.id}
            const reactivatedTrackDistance: TrackDistance = await Thomas.request(APIRequest.undoTrackDistanceDeletion, payload) as TrackDistance

            setTrackDistance(reactivatedTrackDistance);
            setOriginalTrackDistance(reactivatedTrackDistance);
            setChangedFields({});
            
            setPopup({
                title: "Track Distance reactivated!",
                message: `Reactivated Track Distance with ID ${reactivatedTrackDistance.id}`,
                color: Color.red,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }

    /**
     * Request Track Distance export to API. 
     */
    const exportTrackDistance = async (config: Record<string, boolean>) => {
        try {
            // Request Session data export file
            const payload = {id: trackDistance.id, ...config}
            const res: AxiosResponse = await Thomas.request(APIRequest.exportTrackDistance, payload, true);

            // Save file to client
            const saveConfig = `${APIRequest.exportTrackDistance.headers["Accept"]}`
            FileSaver.save(res.data, 
                            `Export Track Distance ${trackDistance.id}.SKV`,
                            saveConfig)

            setPopup({
                title: "Export saved!",
                message: `Export of Track Distance with ID ${trackDistance.id} completed.`,
                color: Color.green,
                setPopup: setPopup
            }) 
        } catch (e) {
            const message = Thomas.getErrorMessage(e)
            setPopup({
                message: message,
                setPopup: setPopup
            }) 
        }
    }


    return (
        <Container>
            {popup && <GenericPopup 
                        title=          {popup.title}
                        message=        {popup.message}
                        color=          {popup.color}
                        setPopup=       {setPopup}
                    />
            }
            {confirmation !== null && 
                <ConfirmationPopup confirmation={confirmation}/> 
            }
            {trackDistance === undefined && 
                <LoadingComponent message="Fetching Track Distance..." />
            }
            {trackDistance === null && 
                <NoDataComponent message="Track Distance query yielded no result." />
            }
            {trackDistance !== null && trackDistance !== undefined && 
                <DataViewContainer>
                    <HFlex style={{
                        alignItems: "center", 
                        justifyContent: "space-between",
                        marginBottom: "20px", 
                        paddingBottom: "30px",
                        borderBottom: "2px solid #21262c"
                    }}>
                        <HFlex>
                            <PageIcon icon={TrackDistanceIcon} />
                            <h1 style={{fontWeight: "normal"}}><b>#{trackDistance.id}</b> Track Distance</h1>
                        </HFlex>
                        {Object.keys(changedFields).length !== 0 && trackDistance.status === DataStatus.Active &&
                            <HFlex>
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {discardChanges}
                                    title=      {"Discard updates"}
                                >Discard changes</ActionButton>
                                <ActionButton 
                                    color=      {Color.green} 
                                    hoverColor= {Color.lightGreen}
                                    onClick=    {updateChanges}
                                    title=      {"Save updates"}
                                >Save</ActionButton>
                            </HFlex>
                        }
                    </HFlex>
                    <HFlex style={{
                        justifyContent: "space-between", 
                        borderRadius: "5px", 
                        backgroundColor: Color.darkGrey, 
                        alignItems: "center", 
                        paddingLeft: "15px", 
                        paddingRight: "10px"
                    }}>
                        <HFlex style={{height: 60, alignItems: "center"}}>
                            <h5 style={{marginRight: 15}}>v{trackDistance.version}</h5>
                            <h5 style={{fontWeight: "normal", marginRight: 15}}><b>updated</b> {moment.utc(trackDistance.updated).local().format("YYYY-MM-DD HH:mm")}</h5>
                            <Tag 
                                color=      {DataStatusColorMap[trackDistance.status]} 
                                style=      {{marginRight: 15, cursor: "auto", fontSize: 12}}
                            >{DataStatus[trackDistance.status]}</Tag>
                            <Tag 
                                color=      {ExportStatusColorMap[trackDistance.exportStatus]} 
                                style=      {{cursor: "auto",  fontSize: 12}}
                            >{ExportStatus[trackDistance.exportStatus]}</Tag>
                        </HFlex>
                        <HFlex style={{width: "fit-content"}}>
                            {trackDistance.status === DataStatus.Active && 
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {deleteTrackDistance}
                                    title=      {"Mark Track Distance as deleted"}
                                >Delete</ActionButton>
                            }
                            {trackDistance.status === DataStatus.Deleted && 
                                <ActionButton 
                                    color=      {Color.red} 
                                    hoverColor= {Color.lightRed}
                                    style=      {{marginRight: 10}}
                                    onClick=    {undoTrackDistanceDeletion}
                                    title=      {"Undo Track Distance deletion"}
                                >Undo delete</ActionButton>
                            }
                            <ShareButton 
                                link={ShareHandler.shareTrackDistance(trackDistance)}
                                setPopup={setPopup}
                            />
                            <ExportButton 
                                title=  {"Export Track Distance"} 
                                config= {"base"}
                                export= {exportTrackDistance}
                            />
                        </HFlex>
                    </HFlex>
                    <SessionContainer session={trackDistance.session} />
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "30%", height: 30, marginRight: "20px"}}>
                            <HFlex style={{alignItems: "center"}}>
                                <Input
                                    type=           "number"
                                    placeholder=    "km"
                                    value=          {trackDistance.atKm}
                                    onChange=       {(e) => updateField("atKm", e.target.value)}
                                    fontSize=       {15}
                                    css=            {`margin-right: 5px; width: 60px;`}
                                    readOnly=       {trackDistance.status !== DataStatus.Active}
                                    color=          {Object.keys(changedFields).includes("atKm") ? Color.lightGreen : null}
                                />   
                                <h5 style={{width: "fit-content", marginRight: 10, whiteSpace: "nowrap"}}>km</h5>
                                <Input
                                    type=           "number"
                                    placeholder=    "m"
                                    value=          {trackDistance.atM}
                                    onChange=       {(e) => updateField("atM", e.target.value)}
                                    fontSize=       {15}
                                    css=            {`margin-right: 5px;`}
                                    readOnly=       {trackDistance.status !== DataStatus.Active}
                                    color=          {Object.keys(changedFields).includes("atM") ? Color.lightGreen : null}
                                />   
                                <h5>m</h5>
                            </HFlex>
                        </ContentDiv>
                        <TrackLinkContainer 
                            trackLink=          {trackDistance.refTrackLink} 
                            showTrackLinkChange={(trackDistance.status === DataStatus.Active)  ? () => setShowTrackLinkSelector(true) : undefined}
                            minimalColWidth=    {true}
                            showDatesOption=    {false}
                        />
                    </HFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <h4>Data</h4>
                            {["objTrackLinkNumber", "horDist", "verDist", "refElev", "objElev"].map(key =>
                                <DataInputContainer>
                                    <h6 style={{marginRight: 10}}>{fieldnameMap[key] || key}</h6>
                                    <Input
                                        type=           "text"
                                        placeholder=    {"-"}
                                        value=          {trackDistance[key]}
                                        onChange=       {(e) => updateField(key, e.target.value)}
                                        readOnly=       {trackDistance.status !== DataStatus.Active}
                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                    />   
                                </DataInputContainer>
                            )}
                        </ContentDiv>
                        <ContentDiv style={{width: "50%"}}>
                            <HFlex>
                                <VFlex style={{marginRight: 20}}>
                                    <h4>GPS</h4>
                                    <HFlex>
                                        <VFlex>
                                            {["northing", "easting", "gpsFix", "satellites", "pdop"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{fieldnameMap[key] || key}</h6>
                                                    <Input
                                                        type=           "text"
                                                        placeholder=    {"-"}
                                                        value=          {trackDistance[key]}
                                                        onChange=       {(e) => updateField(key, e.target.value)}
                                                        readOnly=       {trackDistance.status !== DataStatus.Active}
                                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                                    />   
                                                </DataInputContainer>
                                            )}
                                        </VFlex>
                                    </HFlex>
                                </VFlex>
                                <VFlex>
                                    <h4>Meta</h4>
                                    <HFlex>
                                        <VFlex>
                                            {["frame", "attributes", "softwareVer"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{fieldnameMap[key] || key}</h6>
                                                    <Input
                                                        type=           "text"
                                                        placeholder=    {"-"}
                                                        value=          {trackDistance[key] ?? "null"}
                                                        onChange=       {(e) => updateField(key, e.target.value)}
                                                        readOnly=       {trackDistance.status !== DataStatus.Active}
                                                        color=          {Object.keys(changedFields).includes(key) ? Color.lightGreen : null}
                                                    />   
                                                </DataInputContainer>
                                            )}
                                            {["posDir"].map(key =>
                                                <DataInputContainer>
                                                    <h6 style={{marginRight: 10}}>{key}</h6>
                                                    {trackDistance[key] !== null && trackDistance.status === DataStatus.Active &&
                                                        <Switch 
                                                            configKey= {key}
                                                            value=     {trackDistance[key]} 
                                                            onChange=  {updateField} 
                                                        />
                                                    }
                                                    {trackDistance[key] === null && <h6>NULL</h6>}
                                                    {trackDistance.status !== DataStatus.Active && <h6>{String(trackDistance[key])}</h6>}
                                                </DataInputContainer>
                                            )}
                                        </VFlex>
                                    </HFlex>
                                </VFlex>
                            </HFlex>
                        </ContentDiv>
                    </HFlex>
                    <HFlex style={{marginTop: 20, alignItems: "space-between"}}>
                        <ContentDiv style={{width: "50%", marginRight: "20px"}}>
                            <h4>Points</h4>
                            <HFlex style={{marginTop: 20}}>
                                <PointList>
                                    <HFlex style={{marginBottom: 5, justifyContent: "space-around"}}>
                                        <h5>X</h5>
                                        <h5>Y</h5>
                                    </HFlex>
                                    {points !== null && points.map((point, index) => 
                                        <HFlex key={`p${index}`} style={{marginTop: 5}}>
                                            <Input
                                                key=            {`${index}-x`}
                                                type=           "number"
                                                min=            {-7000}
                                                max=            {7000}
                                                placeholder=    {"x"}
                                                value=          {point.x}
                                                onChange=       {(e) => updatePoints(index, "x", parseInt(e.target.value))}
                                                style=          {{marginRight: 5, textAlign: "center"}}
                                                readOnly=       {trackDistance.status !== DataStatus.Active}
                                            />  
                                            <Input
                                                key=            {`${index}-y`}
                                                type=           "number"
                                                min=            {-500}
                                                max=            {4000}
                                                placeholder=    {"y"}
                                                value=          {point.y}
                                                onChange=       {(e) => updatePoints(index, "y", parseInt(e.target.value))}
                                                style=          {{textAlign: "center"}}
                                                readOnly=       {trackDistance.status !== DataStatus.Active}
                                            />  
                                        </HFlex>
                                    )}
                                </PointList>
                                <Plot points={points ?? []}/>
                            </HFlex>
                        </ContentDiv>
                        <ContentDiv style={{width: "50%", minHeight: 200}}>
                            <MapComponent 
                                link=        {SiteRoute.Map + `?trackdistance=${trackDistance.id}`}
                                centerPoint= {[trackDistance.longitude, trackDistance.latitude]}
                                dataType=    {"trackdistance"}
                            />
                        </ContentDiv>
                    </HFlex>
                    {(trackDistance.prevVersionID !== null || trackDistance.nextVersionID !== null) &&
                        <ContentDiv style={{marginTop: 20, height: 30, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <h4 style={{marginBottom: 0}}>Versions</h4>
                            <HFlex style={{height: 40}}>
                                {trackDistance.prevVersionID !== null &&
                                    <BackNavButton 
                                        color=      {Color.darkGrey} 
                                        hoverColor= {Color.extraLightGrey}
                                        text=       "Previous version"
                                        link=       {`${SiteRoute.TrackDistance}?id=${trackDistance.prevVersionID}`}
                                        rightSide=  {false}
                                        css=        {{marginLeft: 10}}
                                    />
                                }
                                {trackDistance.nextVersionID !== null &&
                                    <BackNavButton 
                                        color=      {Color.darkGrey} 
                                        hoverColor= {Color.extraLightGrey}
                                        text=       "Next version"
                                        link=       {`${SiteRoute.TrackDistance}?id=${trackDistance.nextVersionID}`}
                                        rightSide=  {true}
                                        css=        {{marginLeft: 10}}
                                    />
                                }
                            </HFlex>
                        </ContentDiv>
                    }
                </DataViewContainer>
            }
            {showTrackLinkSelector && trackDistance !== null && trackDistance !== undefined && 
                <TrackLinkSelector 
                    currentTrackLink=   {trackDistance.refTrackLink}
                    update=             {updateTrackDistanceTrackLink}
                    setPopup=           {setPopup}
                    close=              {() => setShowTrackLinkSelector(false)}
                />
            }
        </Container>
    )   
}


export default TrackDistanceView;