import React from "react";
import styled from "styled-components";
import {
    Switch,
    Route,
} from "react-router-dom";

import { SiteRoute } from "../site/routes";

import Color from "../resources/colors";

import SideBar from "../components/SideBar";
import TopBar from "../components/TopBar";
import { HFlex, VFlex } from "../pandora/styled";

import SessionsView from "./SessionsView";
import SearchTrackLinksView from "./SearchTrackLinksView";
import SearchFomulsView from "./SearchFomulsView";
import SearchTrackDistances from "./SearchTrackDistancesView";

import SessionView from "./SessionView";
import TrackLinkView from "./TrackLinkView";
import FomulView from "./FomulView";
import TrackDistanceView from "./TrackDistanceView";
import NetworkView from "./NetworkView";
import NetworkMapView from "./NetworkMapView";

import ExportView from "./ExportView";
import UploadView from "./UploadView";
import FormatView from "./FormatView";
import MapView from "./MapView";
import MetricsView from "./MetricsView";
import AuditView from "./AuditView";
import BinView from "./BinView";
import ErrorView from "./404";



const Container = styled(HFlex)`
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`

const ParentViewContainer = styled(VFlex)`
    position: absolute;
    left: calc(150px + 20px + 25px);

    height: 100vh;
    width: auto;
    right: 0;
    
    border-radius: 25px 0px 0px 0px;

    box-sizing: border-box;
    overflow: hidden;
`

const ContentContainer = styled.div`
    position: relative;
    background: ${Color.lightGrey};

    height: 100%;
    padding: 50px 30px 30px;

    overflow: auto;
`


const AppRouting = (): React.ReactElement => {

    return (
        <Container>
            <SideBar />
            <ParentViewContainer>
                <ContentContainer id="content-container">
                    <Switch>
                        <Route
                            path=           {SiteRoute.Sessions} 
                            component=      {SessionsView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Session} 
                            component=      {SessionView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.TrackLinks} 
                            component=      {SearchTrackLinksView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.SearchFomuls} 
                            component=      {SearchFomulsView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.SearchTrackDistances} 
                            component=      {SearchTrackDistances} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.TrackLink} 
                            component=      {TrackLinkView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Format} 
                            component=      {FormatView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Fomul} 
                            component=      {FomulView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.TrackDistance} 
                            component=      {TrackDistanceView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Network} 
                            component=      {NetworkView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.NetworkMap} 
                            component=      {NetworkMapView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Export} 
                            component=      {ExportView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Upload} 
                            component=      {UploadView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Audit} 
                            component=      {AuditView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Map} 
                            component=      {MapView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Metrics} 
                            component=      {MetricsView} 
                            exact 
                        />
                        <Route
                            path=           {SiteRoute.Bin} 
                            component=      {BinView} 
                            exact 
                        />
                        <Route 
                            path=           {SiteRoute.Error}
                            component=      {ErrorView}
                        />
                    </Switch>
                </ContentContainer>
            </ParentViewContainer>
        </Container>
    )
}

export default AppRouting;